import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseJobClient } from 'api';
import Modal from 'components/Modal';
import useAccountInfo from 'contexts/useAccountInfo';
import useModalStack from 'contexts/useModalStack';
import { createCaseForm } from 'pages/Case/CaseEditor/formUtils/caseFormConverters';
import useCaseEditorState from 'pages/Case/CaseEditor/useCaseEditorState';
import { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useResponse } from 'swaggerhooks';
import { toInputDateTimeString } from 'utils/date';
import useQueryParam from 'utils/useQueryParam';
import useSelectableCustomers from 'utils/useSelectableCustomers';
import useCaseJobEvents, {
  CaseJobEvent,
  JobFormIndexes,
} from './useCaseJobEvents';

const newCaseJobId = 'nytt';

const useJobSearchAndForm = (from: Date, to: Date) => {
  const modalStack = useModalStack();
  const selectableCustomers = useSelectableCustomers();
  const [selectedCaseJobIdString, setSelectedCaseJobId] = useQueryParam(
    'jobb',
    'NaN'
  );
  const selectedCaseJobId = Number(selectedCaseJobIdString);
  const [
    selectedCaseJobFormIndexOverride,
    setSelectedCaseJobFormIndexOverride,
  ] = useState<JobFormIndexes | null>(null);

  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  const search = useResponse(
    CaseJobClient,
    async (c) => {
      if (!selectedAkCompanyId) return [];
      return await c.searchCaseJobs(selectedAkCompanyId, from, to);
    },
    [selectedAkCompanyId, from.getTime(), to.getTime()]
  );

  const selectedCaseJob = useResponse(
    CaseJobClient,
    async (c) => {
      if (isNaN(selectedCaseJobId) || selectedCaseJobId === 0) return;

      // If caseJob is included in search results, then use that to speed things up. Otherwise fetch it via API.
      return (
        search.response?.find((cj) => cj.caseJob.id === selectedCaseJobId) ??
        (await c.getCaseJob(selectedCaseJobId))
      );
    },
    [selectedCaseJobId, search.response]
  );

  const showCaseSlideIn =
    !isNaN(selectedCaseJobId) || selectedCaseJobIdString === newCaseJobId;

  const caseEditorState = useCaseEditorState(
    selectedCaseJob.response?.case,
    (savedCase) => {
      search.update();
      setSelectedCaseJobFormIndexOverride(null);
      setSelectedCaseJobId(String(savedCase.caseJobs[0].id));
    }
  );

  const jobGroups = useFieldArray({
    control: caseEditorState.form.control,
    name: 'jobGroups',
  });

  const selectedCaseJobFormIndex = useMemo((): JobFormIndexes => {
    if (selectedCaseJobFormIndexOverride)
      return selectedCaseJobFormIndexOverride;

    // if no form index override exists: find indexes for job with selectedCaseJobId
    for (
      let jobGroupFormIndex = 0;
      jobGroupFormIndex < jobGroups.fields.length;
      jobGroupFormIndex++
    ) {
      const jobGroup = jobGroups.fields[jobGroupFormIndex];

      for (
        let jobInstanceFormIndex = 0;
        jobInstanceFormIndex < jobGroup.jobInstances.length;
        jobInstanceFormIndex++
      ) {
        const jobInstance = jobGroup.jobInstances[jobInstanceFormIndex];

        if (jobInstance.caseJobId === selectedCaseJobId)
          return {
            jobGroupFormIndex,
            jobInstanceFormIndex,
          };
      }
    }

    return {
      jobGroupFormIndex: 0,
      jobInstanceFormIndex: 0,
    };
  }, [selectedCaseJobFormIndexOverride, jobGroups.fields, selectedCaseJobId]);

  const setSelectedCaseJobFormIndex = (formIndex: JobFormIndexes) => {
    const caseJobId: number | undefined = caseEditorState.form.getValues(
      `jobGroups.${formIndex.jobGroupFormIndex}.jobInstances.${formIndex.jobInstanceFormIndex}.caseJobId`
    );

    if (caseJobId) {
      setSelectedCaseJobFormIndexOverride(null);
      setSelectedCaseJobId(String(caseJobId));
    } else {
      setSelectedCaseJobFormIndexOverride(formIndex);
    }
  };

  const showApproveDiscardChangesModal = (
    onApproveDiscardChanges: () => void
  ) => {
    const modalId = modalStack.push(
      <Modal
        title="Osparade ändringar"
        onClose={() => modalStack.pop(modalId)}
        buttons={[
          { label: 'Avbryt', onClick: () => modalStack.pop(modalId) },
          {
            label: 'Kasta ändringar',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: () => {
              onApproveDiscardChanges();
              modalStack.pop(modalId);
            },
          },
        ]}
      >
        Vill du verkligen kasta dina osparade ändringar?
      </Modal>
    );
  };

  const clearSelectedCaseJob = () => {
    const approveClearing = () => {
      caseEditorState.form.reset();
      setSelectedCaseJobId(null);
      setSelectedCaseJobFormIndexOverride(null);
    };

    if (caseEditorState.form.formState.isDirty) {
      showApproveDiscardChangesModal(approveClearing);
      return;
    }
    approveClearing();
  };

  const handleEventClick = (caseJobEvent: CaseJobEvent) => {
    const changeToClickedEvent = () => {
      setSelectedCaseJobFormIndexOverride(caseJobEvent.formIndex ?? null);
      if (caseJobEvent.caseJobId !== undefined) {
        setSelectedCaseJobId(String(caseJobEvent.caseJobId));
      }
    };

    // if formIndex is undefined, then the CaseJobEvent isn't currently open in the form.
    if (
      caseJobEvent.formIndex === undefined &&
      caseEditorState.form.formState.isDirty
    ) {
      showApproveDiscardChangesModal(changeToClickedEvent);
      return;
    }
    changeToClickedEvent();
  };

  const handleCreateCaseClick = (
    fromDate: Date,
    toDate: Date,
    regNr?: string
  ) => {
    /** Resets form to a new blank form with date & regNr presets */
    const resetForm = () => {
      const newForm = createCaseForm();

      if (selectableCustomers.length > 0)
        newForm.customerId = selectableCustomers[0].id;

      const formJobGroup = newForm.jobGroups[0];
      const formJobInstance = formJobGroup.jobInstances[0];

      formJobGroup.orderedStartUtc = toInputDateTimeString(fromDate);
      formJobGroup.orderedEndUtc = toInputDateTimeString(toDate);
      formJobInstance.actualStartUtc = toInputDateTimeString(fromDate);
      formJobInstance.actualEndUtc = toInputDateTimeString(toDate);

      if (regNr !== undefined)
        newForm.jobGroups[0].jobInstances[0].vehicleRegNr = regNr;

      setSelectedCaseJobFormIndexOverride({
        jobGroupFormIndex: 0,
        jobInstanceFormIndex: 0,
      });
      setSelectedCaseJobId(newCaseJobId);

      caseEditorState.form.reset(newForm);
    };

    if (caseEditorState.form.formState.isDirty) {
      showApproveDiscardChangesModal(resetForm);
      return;
    }
    resetForm();
  };

  const caseJobEvents = useCaseJobEvents(
    search.response ?? [],
    selectedCaseJob,
    caseEditorState.form,
    showCaseSlideIn
  );

  return {
    caseEditorState,

    caseJobEvents,
    handleEventClick,
    handleCreateCaseClick,
    search,
    showCaseSlideIn,
    selectedCaseJob,
    selectedCaseJobFormIndex,
    setSelectedCaseJobFormIndex,
    clearSelectedCaseJob,
  };
};

export default useJobSearchAndForm;
