import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import React, { useState } from 'react';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { AuthenticationClient } from 'api';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import LabelWrap from 'components/inputs/LabelWrap';
import LoadingSection from 'components/spinners/LoadingSection';
import {
  LoginForm,
  LoginTitle,
  ButtonsRow,
  ErrorText,
  Link,
} from './components';
import { useQueryParams } from 'utils/useQueryParam';
import { useNavigate } from 'react-router-dom';
import { LoginReturnUrlQueryParam } from 'contexts/useAccountInfo/RequireAuth';
import LoginOptionTabs from './LoginOptionTabs';

const PasswordLoginSection: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { onLoggedIn } = useAccountInfo();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const loginCall = useApiCall(
    AuthenticationClient,
    (client, username: string, password: string) =>
      client.passwordLogin(username, password)
  );

  const handleLoginClick = async (eve: React.FormEvent) => {
    eve.preventDefault();

    if (username && password) {
      const [signinResponse, error] = await loginCall.run(username, password);

      if (
        !error &&
        signinResponse?.success &&
        signinResponse.jwtToken &&
        signinResponse.jwtRefreshToken &&
        signinResponse.user
      ) {
        onLoggedIn(
          signinResponse.jwtToken,
          signinResponse.jwtRefreshToken,
          signinResponse.user
        );
        const navTo =
          queryParams.get(LoginReturnUrlQueryParam) ??
          Routes.customerPortal.index;
        navigate(navTo, { replace: true });
      }
    }
  };

  switch (loginCall.status) {
    case RequestStatus.Idle:
    case RequestStatus.Error:
    case RequestStatus.Fetched:
      return (
        <>
          <LoginOptionTabs />
          <LoginForm onSubmit={handleLoginClick}>
            <LoginTitle>Logga in på kundportalen</LoginTitle>

            <LabelWrap label="Användarnamn">
              <Input
                type="text"
                onChange={(e) => setUsername(e.currentTarget.value)}
                value={username}
              />
            </LabelWrap>
            <LabelWrap label="Lösenord">
              <Input
                type="password"
                onChange={(e) => setPassword(e.currentTarget.value)}
                value={password}
              />
            </LabelWrap>

            {(loginCall.status === RequestStatus.Error ||
              loginCall.response?.errorMessage) && (
              <ErrorText>
                {loginCall.response?.errorMessage ||
                  'Ett fel inträffade. Har du skrivit in korrekt lösenord?'}
              </ErrorText>
            )}

            <ButtonsRow>
              <Button type="submit" disabled={!username || !password}>
                Logga In
              </Button>
            </ButtonsRow>

            <Link to={Routes.account.forgotPassword}>Glömt lösenord?</Link>
          </LoginForm>
        </>
      );

    case RequestStatus.Fetching:
      return (
        <LoginForm>
          <LoadingSection>Loggar in...</LoadingSection>
        </LoginForm>
      );
  }
};

export default PasswordLoginSection;
