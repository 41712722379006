import { FC, useState } from 'react';
import { InputCell } from './inputCells';

interface EditableInputCellProps {
  initialValue: string;
  onValueSet(value: string): void;
}

export const EditableInputCell: FC<EditableInputCellProps> = ({
  initialValue,
  onValueSet,
}) => {
  const [value, setValue] = useState(initialValue);

  return (
    <InputCell
      autoFocus
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyUp={(eve) => {
        if (eve.key === 'Enter') {
          onValueSet(value);

          if (eve.target instanceof HTMLElement) {
            eve.target.blur();
          }
        }

        if (eve.key === 'Escape' && eve.target instanceof HTMLElement) {
          eve.target.blur();
        }
      }}
    />
  );
};
