import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Invoice, InvoiceType } from 'api';
import { CheckBoxButton } from 'components/inputs/PartialCheckbox';
import { CheckBoxCell } from 'components/Table/inputCells';
import {
  ColumnSetting,
  tableCellClassName,
  tableHeadClassName,
} from 'components/Table/utils';
import { css } from 'styled-components';
import { formatDate } from 'utils/date';
import {
  makeBooleanComparator,
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';

const sekNumberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

export interface InvoiceTableProps {
  selectedInvoiceIds: Set<number>;
  onToggleSelectInvoice(invoiceRow: Invoice): void;
}

export const useInvoiceColumnSettings = (
  onToggleSeletion: () => void,
  isAllSelected: boolean,
  isSomeSelected: boolean
): ColumnSetting<Invoice, InvoiceTableProps>[] => {
  const columnSettingsWithCheckboxes: ColumnSetting<
    Invoice,
    InvoiceTableProps
  >[] = [
    {
      head: (
        <CheckBoxButton
          checked={isAllSelected && isSomeSelected}
          onClick={onToggleSeletion}
        >
          {isAllSelected && isSomeSelected && (
            <FontAwesomeIcon icon={faCheck} />
          )}
          {!isAllSelected && isSomeSelected && (
            <FontAwesomeIcon icon={faMinus} />
          )}
        </CheckBoxButton>
      ),
      noPadding: true,
      css: css`
        &.${tableHeadClassName} {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
      `,
      render: (invoice, { selectedInvoiceIds, onToggleSelectInvoice }) => (
        <CheckBoxCell
          checked={selectedInvoiceIds.has(invoice.id)}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => onToggleSelectInvoice(invoice)}
        />
      ),
      width: 80,
    },
    {
      head: 'Fakturanr',
      render: (c) =>
        c.invoiceType === InvoiceType.Internal ? c.invoiceReferenceId : c.id,
      sortFunction: (a, b) =>
        a.invoiceType === InvoiceType.Internal
          ? makeStringComparator<Invoice, any>((c) => c.invoiceReferenceId)(
              a,
              b
            )
          : makeNumberComparator<Invoice, any>((c) => c.id)(a, b),
      width: 80,
    },
    {
      head: 'Fakturadatum',
      render: (c) => formatDate(c.invoiceDate),
      sortFunction: makeDateComparator((c) => c.invoiceDate),
      width: 100,
    },
    {
      head: 'Senast tillhanda',
      render: (c) => formatDate(c.paymentDate),
      width: 100,
    },
    {
      head: 'Sändare',
      render: (c) => c.senderName,
      width: 200,
    },
    {
      head: 'Mottagare',
      render: (c) => c.recipientName,
      width: 200,
    },
    {
      head: 'Kreditfaktura',
      render: (inv) => (inv.isCreditInvoice ? 'Ja' : 'Nej'),
      sortFunction: makeBooleanComparator((inv) => inv.isCreditInvoice),
      width: 100,
    },
    {
      head: 'Summa',
      render: (c) => sekNumberFormat.format(c.total),
      css: css`
        &.${tableCellClassName} {
          text-align: right;
        }
      `,
      width: 150,
    },
  ];

  return columnSettingsWithCheckboxes;
};
