import { Vehicle, VehicleClient } from 'api';
import { useEffect, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks';
import { groupItemsSorted } from 'utils/array';
import { conformRegNr } from 'utils/string';
import useAccountInfo, { useSelectedAkCompanyChanged } from './useAccountInfo';

const vehiclesAtom = atom<Vehicle[]>({
  key: 'vehicles',
  default: [],
});

export const useVehiclesInitialization = (isLoggedIn: boolean) => {
  const updateVehicles = useUpdateVehiclesCall();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  useEffect(() => {
    updateVehicles.run(isLoggedIn ? selectedAkCompanyId : null);
  }, [isLoggedIn]);

  useSelectedAkCompanyChanged((akCompany) => {
    updateVehicles.run(isLoggedIn ? akCompany : null);
  });
  return updateVehicles;
};

export const useUpdateVehiclesCall = () => {
  const [, setVehicles] = useRecoilState(vehiclesAtom);

  return useApiCall(VehicleClient, async (c, akCompanyId: number | null) => {
    setVehicles([]);

    if (akCompanyId === null) {
      return;
    }

    const vehicles = await c.getAkCompanyVehicles(akCompanyId);
    setVehicles(vehicles);
  });
};

const useVehicles = () => {
  return useRecoilValue(vehiclesAtom);
};

export const useVehicleByVehicleRegnr = () => {
  const vehicles = useVehicles();

  return useMemo(() => {
    const lookup = new Map<string, Vehicle>();
    vehicles.forEach((v) => lookup.set(conformRegNr(v.regNr), v));
    return lookup;
  }, [vehicles]);
};

export const useVehiclesByVehicleType = () => {
  const vehicles = useVehicles();

  return useMemo(
    () => new Map(groupItemsSorted(vehicles, (v) => v.vehicleType)),
    [vehicles]
  );
};

export default useVehicles;
