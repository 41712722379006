import {
  CaseStatus,
  JobType,
  PriceAdjustmentType,
  PriceListType,
  PriceType,
  UtilClient,
} from 'api';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useResponse } from 'swaggerhooks';

export interface useBasicDataValue {
  releaseVersion: string;
  caseStatusNames: Map<CaseStatus, string>;
  jobTypeNames: Map<JobType, string>;
  priceListTypeNames: Map<PriceListType, string>;
  priceTypeNames: Map<PriceType, string>;
  priceAdjustmentTypeNames: Map<PriceAdjustmentType, string>;
  roleNames: Map<number, string>;
  hQ_AkCompanyId: number;
}

const basicDataAtom = atom<useBasicDataValue>({
  key: 'basicData',
  default: {
    releaseVersion: '',
    caseStatusNames: new Map(),
    jobTypeNames: new Map(),
    priceListTypeNames: new Map(),
    priceTypeNames: new Map(),
    priceAdjustmentTypeNames: new Map(),
    roleNames: new Map(),
    hQ_AkCompanyId: -1,
  },
});

function objectToMap<K extends number, V extends string>(obj: {
  [key: string]: string | undefined;
}) {
  return new Map<K, V>(
    Object.entries(obj).map(([key, value]) => [
      Number(key) as K,
      (value ?? '') as V,
    ])
  );
}

export const useBasicDataInitialization = (isLoggedIn: boolean) => {
  const [, setBasicData] = useRecoilState(basicDataAtom);

  return useResponse(
    UtilClient,
    async (c) => {
      const basicData = await c.getBasicData();
      setBasicData({
        releaseVersion: basicData.releaseVersion,
        caseStatusNames: objectToMap(basicData.caseStatusNames),
        jobTypeNames: objectToMap(basicData.jobTypeNames),
        priceListTypeNames: objectToMap(basicData.priceListTypeNames),
        priceTypeNames: objectToMap(basicData.priceTypeNames),
        priceAdjustmentTypeNames: objectToMap(
          basicData.priceAdjustmentTypeNames
        ),
        roleNames: objectToMap(basicData.roleNames),
        hQ_AkCompanyId: basicData.hQ_AkCompanyId,
      });
    },
    [isLoggedIn]
  );
};

const useBasicData = () => {
  return useRecoilValue(basicDataAtom);
};

export default useBasicData;
