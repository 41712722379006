import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';

const useSelectedAkCompany = () => {
  const {
    accountInfo: { selectedAkCompanyId, user },
  } = useAccountInfo();
  const { akCompaniesById } = usePricingSetup();

  const selectedAkCompany = akCompaniesById.get(selectedAkCompanyId ?? -1);
  return {
    selectedAkCompany,
    isAkCompanyOwner:
      selectedAkCompany !== undefined &&
      selectedAkCompany?.akCompanyOwnerId === user?.id,
  };
};

export default useSelectedAkCompany;
