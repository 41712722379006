import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Button, { FABStyle } from 'components/inputs/Button';
import DateSpanPicker from 'components/inputs/DateSpanPicker';
import SearchInput from 'components/inputs/SearchInput';
import PageHeader, { PageHeaderTitleClassName } from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import { generatePath, useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import useCaseColumnSettings, {
  useCaseTableRenderProps,
} from './useCaseColumnSettings';
import useDebouncedValue from 'utils/useDebouncedValue';
import { getInitialDateRange } from 'components/inputs/DateSpanPresetsSelector';
import useUpdateInterval from 'utils/useUpdateInterval';
import { useEffect } from 'react';
import MediaQuery from 'constants/MediaQuery';

const [initialFrom, initialTo] = getInitialDateRange();
const searchInputsAtom = atom({
  key: 'searchInputsAtom',
  default: {
    searchString: '',
    fromDate: initialFrom,
    toDate: initialTo,
  },
});

const MyPageHeader = styled(PageHeader)`
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  padding-bottom: 20px;

  .${PageHeaderTitleClassName} {
    grid-column: 1/2;
  }
`;
const MyDateSpanPicker = styled(DateSpanPicker)`
  grid-column: 1/3;
  grid-row: 2/3;
`;
const CreateCaseButton = styled(Button)`
  margin-left: auto;

  ${MediaQuery.phone} {
    ${FABStyle}
  }
`;

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const CaseListPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  const [searchInputs, setSearchInputs] = useRecoilState(searchInputsAtom);
  const [debouncedSearchInputs] = useDebouncedValue(searchInputs);

  const searchResult = useApiCall(
    CaseClient,
    async (
      c,
      akCompany: number | null,
      searchInputs: typeof debouncedSearchInputs
    ) =>
      akCompany !== null
        ? await c.searchCases(
            akCompany,
            searchInputs.searchString,
            searchInputs.fromDate,
            searchInputs.toDate
          )
        : null
  );

  const { startIntervalImmediately } = useUpdateInterval(
    () => searchResult.run(selectedAkCompanyId, debouncedSearchInputs),
    10000,
    true
  );

  useEffect(() => {
    searchResult.setResponse(undefined);
    startIntervalImmediately(); // By clearing response, the loading spinner will show again.
  }, [selectedAkCompanyId, debouncedSearchInputs]);

  const columnSettings = useCaseColumnSettings();
  const tableRenderProps = useCaseTableRenderProps();

  const renderContent = () => {
    switch (searchResult.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
      case RequestStatus.Fetched:
        if (searchResult.response === undefined) return <LoadingSection />;

        return (
          <MyTable
            columnSettings={columnSettings}
            rows={searchResult.response ?? []}
            onRowClick={(c) =>
              navigate(generatePath(Routes.cases.case, { id: String(c.id) }))
            }
            renderProps={tableRenderProps}
          />
        );

      case RequestStatus.Error:
        return (
          <ErrorTryAgain
            onTryAgain={() =>
              searchResult.run(selectedAkCompanyId, debouncedSearchInputs)
            }
          />
        );
    }
  };

  return (
    <>
      <MyPageHeader title="Ärenden" leftAlignActions>
        <MyDateSpanPicker
          from={searchInputs.fromDate}
          to={searchInputs.toDate}
          onSpanPicked={(from, to) =>
            setSearchInputs((si) => ({ ...si, fromDate: from, toDate: to }))
          }
        />

        <CreateCaseButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => navigate(Routes.cases.create)}
          small
        >
          Nytt ärende
        </CreateCaseButton>
      </MyPageHeader>
      {renderContent()}
    </>
  );
};

export default CaseListPage;
