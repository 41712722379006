import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseMessage } from 'api';
import Theme from 'constants/theme';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid ${Theme.colors.border.main};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderIcon = styled(FontAwesomeIcon)`
  color: ${Theme.colors.bg.accent1};
`;

const HeaderText = styled.span`
  flex: 1;
  font-weight: 600;
`;

const HeaderDate = styled.span`
  font-style: italic;
`;

export const Header: FC<PropsWithChildren<{ message: CaseMessage }>> = ({
  message,
  children,
}) => (
  <HeaderWrapper>
    <HeaderText>{children}</HeaderText>
    <HeaderDate
      title={`Skapad: ${formatDateTime(
        message.createdUtc,
        true
      )}\nÄndrad: ${formatDateTime(message.modifiedUtc, true)}`}
    >
      {formatDateTime(message.createdUtc)}
    </HeaderDate>
  </HeaderWrapper>
);

export const MessageText = styled.div`
  white-space: pre-wrap;
  word-break: break-all;
`;
