import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

serviceWorkerRegistration.register({
  onUpdate(registration) {
    if (registration && registration.waiting) {
      console.log(
        'AK Services: Skip waiting for all tabs to close. Enables refreshing immediately'
      );

      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});
