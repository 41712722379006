import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import SpinnerIcon from './SpinnerIcon';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  * {
    margin: 10px;
  }
`;

const LoadingSection: FC<PropsWithChildren> = ({ children }) => (
  <Wrapper>
    <SpinnerIcon />
    {children}
  </Wrapper>
);

export default LoadingSection;
