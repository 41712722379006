import { useEffect, useState } from 'react';
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import {
  JobGroupFormInputs,
  JobInstanceFormInputs,
} from '../formUtils/caseJobFormConverters';

const useJobGroupCalculatedValue = <R>(
  form: UseFormReturn<CaseFormInputs>,
  jobgroupIndex: number,
  watchedJobGroupProps: (keyof JobGroupFormInputs)[],
  watchedJobInstanceProps: (keyof JobInstanceFormInputs)[],
  valueCalculator: (
    formValues: DeepPartial<CaseFormInputs>,
    jobgroupIndex: number
  ) => R,
  defaultValue: R
): R => {
  const [totalInvocedTime, setTotalInvocedTime] = useState<R>(defaultValue);
  const { watch, getValues } = form;

  useEffect(() => {
    const subscription = watch((allValues, { name: fieldPath }) => {
      // Keeping these string parts in variables for type safety.
      const caseJobGroupsPart: keyof CaseFormInputs = 'jobGroups';
      const caseJobInstancesPart: keyof JobGroupFormInputs = 'jobInstances';

      const jobGroupRegex = new RegExp(
        `${caseJobGroupsPart}\\.\\d+\\.(${watchedJobGroupProps.join('|')})`
      );
      const jobInstanceRegex = new RegExp(
        `${caseJobGroupsPart}\\.\\d+\\.${caseJobInstancesPart}\\.\\d+\\.(${watchedJobInstanceProps.join(
          '|'
        )})`
      );

      if (
        !fieldPath ||
        jobGroupRegex.test(fieldPath) ||
        jobInstanceRegex.test(fieldPath)
      ) {
        setTotalInvocedTime(valueCalculator(allValues, jobgroupIndex));
      }
    });

    // Calculate total invoiced time on mount.
    setTotalInvocedTime(valueCalculator(getValues(), jobgroupIndex));

    return () => {
      subscription.unsubscribe();
    };
  }, [
    getValues,
    jobgroupIndex,
    valueCalculator,
    watch,
    watchedJobGroupProps,
    watchedJobInstanceProps,
  ]);

  return totalInvocedTime;
};

export default useJobGroupCalculatedValue;
