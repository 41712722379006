import { FC } from 'react';
import styled from 'styled-components';
import Button from './inputs/Button';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  * {
    margin: 10px;
  }
`;

interface Props {
  onTryAgain(): void;
  message?: string;
}

const ErrorTryAgain: FC<Props> = ({ message, onTryAgain }) => {
  return (
    <Wrapper>
      {message ?? 'Kunde inte ladda in innehållet'}
      <Button onClick={onTryAgain}>Försök igen</Button>
    </Wrapper>
  );
};

export default ErrorTryAgain;
