import Theme from 'constants/theme';
import styled from 'styled-components';
import FocusableCell from './FocusableCell';

import {
  ColumnSetting,
  focusableTableCellClassName,
  makeColumnClassName,
  tableCellClassName,
} from './utils';

const Container = styled.div<{ colored?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  min-width: 100%;

  &:nth-child(odd) {
    background-color: ${Theme.colors.bg.background2};
  }

  &:hover > * {
    background: rgba(0, 0, 0, 0.1);
  }
  &:hover > .${focusableTableCellClassName} {
    background: rgba(0, 0, 0, 0.15);
  }
`;

const Cell = styled.div<{ noPadding?: boolean }>`
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ noPadding }) => (noPadding ? '' : 'padding: 5px 3px;')}
  cursor: default;
`;

interface Props<
  Row extends Object,
  RenderProps extends Object | undefined = undefined
> {
  columnSettings: ColumnSetting<Row, RenderProps>[];
  renderProps?: RenderProps;
  row: Row;
  onClick?(row: Row): void;
  className?: string;
}

const TableRow = <
  Row extends Object,
  RenderProps extends Object | undefined = undefined
>({
  columnSettings,
  renderProps,
  row,
  onClick,
  className,
}: Props<Row, RenderProps>) => {
  const handleClick = () => {
    if (onClick) onClick(row);
  };

  return (
    <Container className={className} onClick={handleClick}>
      {columnSettings.map((setting, i) => {
        if (setting.focusable) {
          return (
            <FocusableCell
              className={`${makeColumnClassName(i)} ${tableCellClassName}`}
              columnSetting={setting}
              key={i}
              renderProps={renderProps}
              row={row}
            />
          );
        }

        const cellContent = setting.render(row, renderProps!, false);
        return (
          <Cell
            className={`${makeColumnClassName(i)} ${tableCellClassName}`}
            key={i}
            noPadding={setting.noPadding}
            title={typeof cellContent === 'string' ? cellContent : undefined}
          >
            {cellContent}
          </Cell>
        );
      })}
    </Container>
  );
};

export default TableRow;
