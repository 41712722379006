import { AkCompanyClient, WebUser } from 'api';
import { useEffect } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks';
import useAccountInfo, { useSelectedAkCompanyChanged } from './useAccountInfo';

interface UsersAtomValue {
  users: WebUser[];
  usersById: Map<number, WebUser>;
}

const usersAtom = atom<UsersAtomValue>({
  key: 'users',
  default: {
    users: [],
    usersById: new Map(),
  },
});

export const useUsersInitialization = (isLoggedIn: boolean) => {
  const updateUsers = useUpdateUsersCall();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  useEffect(() => {
    updateUsers.run(isLoggedIn ? selectedAkCompanyId : null);
  }, [isLoggedIn]);

  useSelectedAkCompanyChanged((akCompany) => {
    updateUsers.run(isLoggedIn ? akCompany : null);
  });

  return updateUsers;
};

export const useUpdateUsersCall = () => {
  const [, setUsers] = useRecoilState(usersAtom);

  return useApiCall(AkCompanyClient, async (c, akCompanyId: number | null) => {
    if (akCompanyId === null) {
      setUsers({
        users: [],
        usersById: new Map(),
      });
      return;
    }

    const users = await c.getAkCompanyUsers(akCompanyId);
    setUsers({
      users,
      usersById: new Map(users.map((user) => [user.id, user])),
    });
  });
};

const useUsers = () => {
  return useRecoilValue(usersAtom);
};

export default useUsers;
