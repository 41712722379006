import FormErrorList from 'components/FormErrorList';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import MediaQuery from 'constants/MediaQuery';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import { FC, useMemo } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { validEmailRegex } from 'utils/regex';
import useSelectableCustomers from 'utils/useSelectableCustomers';
import { UserFormInputs } from '../../ManageUsers/UserModal/userFormConverters';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  ${MediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  form: UseFormReturn<UserFormInputs>;
}

const CustomerUserForm: FC<Props> = ({ form }) => {
  const selectableCustomers = useSelectableCustomers();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { hQ_AkCompanyId } = useBasicData();
  const {
    register,
    formState: { errors },
  } = form;

  const stationConnectedCustomers = useMemo(
    () =>
      // Filter away central customers if user hasn't selected hQ_AkCompanyId (aka if user isn't a HQ/admin user)
      selectedAkCompanyId === hQ_AkCompanyId
        ? selectableCustomers
        : selectableCustomers.filter(
            (c) => c.akCompanyId !== hQ_AkCompanyId,
            [selectableCustomers]
          ),
    [hQ_AkCompanyId, selectableCustomers, selectedAkCompanyId]
  );

  const mkField = <N extends keyof UserFormInputs>(
    name: N,
    label: string,
    options?: RegisterOptions<UserFormInputs, N>
  ) => (
    <LabelWrap label={label}>
      <Input {...register(name, options)} />
    </LabelWrap>
  );

  return (
    <>
      <Wrapper>
        <LabelWrap label="Kund">
          <Controller
            control={form.control}
            name="customerMemberships.0.customerId"
            rules={{
              validate: (v) => {
                const n = Number(v);
                return !isNaN(n) && n >= 0 ? undefined : 'Välj en kund';
              },
            }}
            render={({ field }) => (
              <Select {...field} value={field.value ?? -1}>
                <option disabled value="-1">
                  Välj en kund
                </option>

                {stationConnectedCustomers.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Select>
            )}
          />
        </LabelWrap>

        {mkField('userName', 'Användarnamn', {
          validate: (v) =>
            /^[\w\d\-._@+]*$/.test(v)
              ? undefined
              : 'Användarnamnet får endast innehålla bokstäver (A-Z), siffror och - . _ @ +',
        })}
        {mkField('fullName', 'Namn')}
        {mkField('email', 'E-post', {
          validate: (v) =>
            validEmailRegex.test(v)
              ? undefined
              : 'Email-adressen är inte giltig',
        })}
        {mkField('phoneNumber', 'Telefonnummer')}

        <Checkbox type="checkbox" {...register('deactivated')}>
          Deaktiverad
        </Checkbox>
      </Wrapper>
      <FormErrorList errors={errors} />
    </>
  );
};

export default CustomerUserForm;
