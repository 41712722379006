import Modal from 'components/Modal';
import { FC } from 'react';

interface Props {
  onClose(): void;
}

const InvoicingTimeInfoModal: FC<Props> = ({ onClose }) => {
  return (
    <Modal
      title="Bokad och fakturerad tid skiljer sig"
      onClose={onClose}
      buttons={[{ label: 'Ok', onClick: onClose }]}
    >
      <h2>Längre än planerat</h2>
      <p>
        Om jobbet blev längre än planerat ska det registreras här, den extra
        tiden räknas som övertid på fakturan.
      </p>
      <h2>Kortare än planerat</h2>
      <p>
        Om jobbet blev kortare än planerat beror det på kundavtalet om tiden som
        faktureras ska ändras eller inte.
      </p>
    </Modal>
  );
};

export default InvoicingTimeInfoModal;
