import { overwritesPhoneSmallTheme } from './utils';

const tabletTheme = overwritesPhoneSmallTheme({
  sizes: {
    font: {
      huge: '40px',
    },
  } as const,
});

export default tabletTheme;
