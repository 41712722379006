import { overwritesPhoneSmallTheme } from './utils';
import selectArrowLight from 'assets/selectarrow-light.svg';
import logoDark from 'assets/logo-dark.svg';

const darkTheme = overwritesPhoneSmallTheme({
  colors: {
    bg: {
      accent1: '#ff1222',
      background1: '#333',
      background2: '#444',
      background3: '#555',
      background4: '#777',
      input: '#4e4e4e',
      opacityOverlay: '#0009',
    },
    fg: {
      background1: '#fff',
      background2: '#fff',
      background3: '#fff',
      background4: '#fff',
      input: '#fff',
    },
    border: {
      main: '#888',
      input: '#7e7e7e',
    },
    shadow: {
      dropdown: '0 0 20px rgba(0,0,0,0.2)',
    },
  },
  images: {
    logo: `url('${logoDark}')`,
    selectArrow: `url('${selectArrowLight}')`,
  },
} as const);

export default darkTheme;
