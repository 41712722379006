import { useEffect, useRef, useState } from 'react';

const useDebouncedValue = <V>(
  value: V,
  timeout: number = 500
): [debouncedValue: V, isDebouncing: boolean] => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setIsDebouncing] = useState(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }

    setIsDebouncing(true);
    timeoutRef.current = setTimeout(() => {
      setIsDebouncing(false);
      setDebouncedValue(value);
    }, timeout);
  }, [value]);

  return [debouncedValue, isDebouncing];
};

export default useDebouncedValue;
