import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserClient } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 100px;

  input {
    width: 100%;
  }

  button {
    margin-left: auto;
  }
`;

const ForgotPasswordPage: FC = () => {
  const [username, setUsername] = useState('');

  const forgotPasswordCall = useApiCall(UserClient, (c, username: string) =>
    c.forgotPassword(username)
  );

  const handleForgotPasswordClick = async () => {
    await forgotPasswordCall.run(username);
  };

  const renderContent = () => {
    switch (forgotPasswordCall.status) {
      case RequestStatus.Error:
      case RequestStatus.Idle:
        return (
          <Section>
            <SectionHeader title="Återställ lösenord" />
            <LabelWrap label="Användarnamn">
              <Input
                onChange={(eve) => setUsername(eve.target.value)}
                type="text"
                value={username}
              />
            </LabelWrap>
            <Button onClick={handleForgotPasswordClick}>
              Skicka återställnings-email
            </Button>
          </Section>
        );

      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        return (
          <>
            <FontAwesomeIcon
              icon={faEnvelopeCircleCheck}
              size="8x"
              style={{ marginBottom: 10 }}
            />
            En länk för att återställa ditt konto har skickats till din epost.
          </>
        );
    }
  };

  return <Wrapper>{renderContent()}</Wrapper>;
};

export default ForgotPasswordPage;
