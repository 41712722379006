import { AkCompany, Customer, PriceList, UtilClient } from 'api';
import { useEffect, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks';

export interface usePricingSetupValue {
  akCompaniesById: Map<number, AkCompany>;
  customersById: Map<number, Customer>;
  priceListsById: Map<number, PriceList>;

  externalPriceListIdsByCustomerId: Map<number, number[]>;
  internalPriceListIdsByCustomerId: Map<number, number[]>;
  customerIdsByAkCompanyId: Map<number, number[]>;
  akCompanyIdsByCustomerId: Map<number, number[]>;
}

const pricingSetupAtom = atom<usePricingSetupValue>({
  key: 'pricingSetup',
  default: {
    akCompaniesById: new Map(),
    customersById: new Map(),
    priceListsById: new Map(),

    externalPriceListIdsByCustomerId: new Map(),
    internalPriceListIdsByCustomerId: new Map(),
    customerIdsByAkCompanyId: new Map(),
    akCompanyIdsByCustomerId: new Map(),
  },
});

function objectToMap<K extends number, V>(obj: { [key: string]: V }) {
  return new Map<K, V>(
    Object.entries(obj).map(([key, value]) => [Number(key) as K, value])
  );
}

export const usePricingSetupInitialization = (isLoggedIn: boolean) => {
  const updatePricingSetup = useUpdatePricingSetupCall();

  useEffect(() => {
    if (isLoggedIn) updatePricingSetup.run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return updatePricingSetup;
};

export const useUpdatePricingSetupCall = () => {
  const [, setPricingSetup] = useRecoilState(pricingSetupAtom);

  return useApiCall(UtilClient, async (c) => {
    const pricingSetup = await c.getPricingSetup();
    const akCompanyIdsByCustomerId = new Map<number, number[]>();

    for (const akCompanyIdStr in pricingSetup.customerIdsByAkCompanyId) {
      const akCompanyId = Number(akCompanyIdStr);

      for (const customerId of pricingSetup.customerIdsByAkCompanyId[
        akCompanyIdStr
      ]) {
        let array = akCompanyIdsByCustomerId.get(customerId);
        if (!array) {
          array = [];
          akCompanyIdsByCustomerId.set(customerId, array);
        }

        array.push(akCompanyId);
      }
    }

    setPricingSetup({
      akCompaniesById: objectToMap(pricingSetup.akCompaniesById),
      customersById: objectToMap(pricingSetup.customersById),
      priceListsById: objectToMap(pricingSetup.priceListsById),

      externalPriceListIdsByCustomerId: objectToMap(
        pricingSetup.externalPriceListIdsByCustomerId
      ),
      internalPriceListIdsByCustomerId: objectToMap(
        pricingSetup.internalPriceListIdsByCustomerId
      ),
      customerIdsByAkCompanyId: objectToMap(
        pricingSetup.customerIdsByAkCompanyId
      ),
      akCompanyIdsByCustomerId,
    });
  });
};

const usePricingSetup = () => {
  return useRecoilValue(pricingSetupAtom);
};

export const useAllPriceLists = () => {
  const pricingSetup = usePricingSetup();

  return useMemo(
    () => Array.from(pricingSetup.priceListsById.values()),
    [pricingSetup.priceListsById]
  );
};

export default usePricingSetup;
