const AppTextKey = {
  AKCOMPANY_START_PAGE_TEXT: 'AKCOMPANY_START_PAGE_TEXT',
  CUSTOMER_CREATE_CASE_PAGE_TEXT: 'CUSTOMER_CREATE_CASE_PAGE_TEXT',
} as const;

export const AppTextKeyLocationDescription: {
  [k in typeof AppTextKey[keyof typeof AppTextKey]]: string;
} = {
  [AppTextKey.AKCOMPANY_START_PAGE_TEXT]:
    'Text på startsidan för stationsanvändare',
  [AppTextKey.CUSTOMER_CREATE_CASE_PAGE_TEXT]:
    'Instruktioner till kundanvändare innan de skapar ärende',
};

export default AppTextKey;
