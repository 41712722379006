import { ICase } from 'api';
import { ColumnSetting } from 'components/Table/utils';
import useBasicData, { useBasicDataValue } from 'contexts/useBasicData';
import usePricingSetup, {
  usePricingSetupValue,
} from 'contexts/usePricingSetup';
import { useMemo } from 'react';
import { formatDateTime } from 'utils/date';
import {
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';

export interface CaseTableRenderProps
  extends useBasicDataValue,
    usePricingSetupValue {}

export const useCaseTableRenderProps = (): CaseTableRenderProps => {
  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();

  return useMemo(
    () => ({
      ...basicData,
      ...pricingSetup,
    }),
    [basicData, pricingSetup]
  );
};

const useCaseColumnSettings = () => {
  return useMemo(
    (): ColumnSetting<ICase, CaseTableRenderProps>[] => [
      {
        head: 'Datum',
        render: (c) => formatDateTime(c.caseDateUtc),
        sortFunction: makeDateComparator((c) => c.caseDateUtc),
        width: 150,
      },
      {
        head: 'Ärende',
        render: (c) => c.id,
        sortFunction: makeNumberComparator((c) => c.id),
        width: 80,
      },
      {
        head: 'Status',
        render: (c, { caseStatusNames }) => caseStatusNames.get(c.caseStatus),
        sortFunction: makeNumberComparator((c) => c.caseStatus),
        width: 100,
      },
      {
        head: 'Adress',
        render: (c) => c.streetAddress,
        sortFunction: makeStringComparator((c) => c.streetAddress),
        width: 200,
      },
      {
        head: 'Ort',
        render: (c) => c.city,
        sortFunction: makeStringComparator((c) => c.city),
        width: 200,
      },
      {
        head: 'Kund',
        render: (c, { customersById }) => customersById.get(c.customerId)?.name,
        sortFunction: makeStringComparator(
          (c, { customersById }) => customersById.get(c.customerId)?.name ?? ''
        ),
        width: 200,
      },
      {
        head: 'Referensnr',
        render: (c) => c.externalReference,
        sortFunction: makeStringComparator((c) => c.externalReference),
        width: 100,
      },
    ],
    []
  );
};

export default useCaseColumnSettings;
