import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader, { PageHeaderSize } from 'components/PageHeader';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { FC, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ open: boolean; keepTitleForTablet?: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 100vw;
  width: 100vw;
  z-index: 1;
  transition: left 0.2s;
  overflow: hidden;

  background-color: ${Theme.colors.bg.background1};

  ${({ open }) =>
    open &&
    css`
      left: 0;
    `}

  ${MediaQuery.tablet} {
    ${({ keepTitleForTablet }) =>
      keepTitleForTablet
        ? css`
            position: static;
            display: flex;
            flex-direction: column;
          `
        : css`
            display: contents;
          `}
  }
`;

const MyPageHeader = styled(PageHeader)<{ keepTitleForTablet?: boolean }>`
  ${MediaQuery.tablet} {
    ${({ keepTitleForTablet }) =>
      !keepTitleForTablet &&
      css`
        display: none;
      `}
  }
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  margin: auto;
  margin-right: 0;

  border: none;
  border-radius: 1000px;
  background-color: ${Theme.colors.bg.background4};
  color: ${Theme.colors.fg.background4};
  cursor: pointer;
  font-size: 20px;
`;

interface Props extends PropsWithChildren {
  open?: boolean;
  onClose(): void;
  title: ReactNode;
  keepTitleForTablet?: boolean;
  headerSize?: PageHeaderSize;
  className?: string;
}

/**
 * When on a phone, the children of this component will be viewed fullscreen when the 'open' prop is true.
 * On tablets and desktop this component will pass through all of its children.
 */
const PhoneSlideInView: FC<Props> = ({
  title,
  open,
  onClose,
  keepTitleForTablet,
  children,
  headerSize,
  className,
}) => {
  return (
    <Wrapper
      open={!!open}
      keepTitleForTablet={keepTitleForTablet}
      className={className}
    >
      <MyPageHeader
        title={title}
        keepTitleForTablet={keepTitleForTablet}
        size={headerSize}
      >
        <CloseButton
          onClick={(eve) => {
            eve.preventDefault();
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      </MyPageHeader>
      {children}
    </Wrapper>
  );
};

export default PhoneSlideInView;
