import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Vehicle, VehicleClient } from 'api';
import Button from 'components/inputs/Button';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { ButtonCell } from 'components/Table/inputCells';
import {
  tableCellClassName,
  tableHeadClassName,
  ColumnSetting,
} from 'components/Table/utils';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useModalStack from 'contexts/useModalStack';
import useVehicles, { useUpdateVehiclesCall } from 'contexts/useVehicles';
import { useCallback, useMemo } from 'react';
import {
  generatePath,
  Outlet,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { makeStringComparator } from 'utils/sorting';

const HeaderRight = styled.div`
  margin-left: auto;
`;

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

interface TableRenderProps {
  onDeleteVehicle(vehicle: Vehicle): void;
}

const columnSettings: ColumnSetting<Vehicle, TableRenderProps>[] = [
  {
    head: 'Regnr',
    render: (v) => v.regNr,
    width: 100,
    sortFunction: makeStringComparator((v) => v.regNr),
  },
  {
    head: 'Typ',
    render: (v) => v.vehicleType,
    width: 100,
    sortFunction: makeStringComparator((v) => v.vehicleType),
  },
  {
    head: 'Ta bort',
    render: (v, { onDeleteVehicle }) => (
      <ButtonCell
        onClick={(eve) => {
          eve.stopPropagation();
          onDeleteVehicle(v);
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </ButtonCell>
    ),
    width: 1,
    noPadding: true,
    css: css`
      max-width: 100px;
      min-width: 80px;
    `,
  },
];

const ManageVehiclesPage: React.FC = () => {
  const navigate = useNavigate();
  const modalStack = useModalStack();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const updateVehiclesCall = useUpdateVehiclesCall();
  const vehicles = useVehicles();

  const saveVehicleCall = useApiCall(VehicleClient, (c, vehicle: Vehicle) =>
    c.saveVehicle(vehicle)
  );

  const onVehicleSaved = useCallback(() => {
    updateVehiclesCall.run(selectedAkCompanyId);
  }, [selectedAkCompanyId, updateVehiclesCall]);

  const handleDeleteVehicle = useCallback(
    async (vehicle: Vehicle) => {
      const modalId = modalStack.push(
        <Modal
          title={`Ta bort "${vehicle.regNr}"?`}
          buttons={[
            {
              label: 'Avbryt',
              onClick: () => {
                modalStack.pop(modalId);
              },
            },
            {
              label: 'Ta bort',
              icon: <FontAwesomeIcon icon={faTrash} />,
              onClick: async () => {
                modalStack.pop(modalId);

                const [updatedVehicle, error] = await saveVehicleCall.run(
                  new Vehicle({
                    ...vehicle,
                    isRemoved: true,
                  })
                );

                if (updatedVehicle && !error) {
                  updateVehiclesCall.run(selectedAkCompanyId);
                }
              },
            },
          ]}
          onClose={() => modalStack.pop(modalId)}
        >
          Är du säker på att du vill ta bort fordonet "{vehicle.regNr}"?
        </Modal>
      );
    },
    [modalStack, saveVehicleCall, selectedAkCompanyId, updateVehiclesCall]
  );

  const tableRenderProps = useMemo(
    (): TableRenderProps => ({
      onDeleteVehicle: handleDeleteVehicle,
    }),
    [handleDeleteVehicle]
  );

  const renderContent = () => {
    if (
      [saveVehicleCall.status, updateVehiclesCall.status].includes(
        RequestStatus.Fetching
      )
    ) {
      return <LoadingSection />;
    }

    return (
      <MyTable
        columnSettings={columnSettings}
        rows={vehicles.filter((v) => !v.isRemoved)}
        onRowClick={(c) =>
          navigate(
            generatePath(Routes.settings.manageVehicles.vehicle, {
              id: String(c.id),
            })
          )
        }
        renderProps={tableRenderProps}
      />
    );
  };

  return (
    <>
      <PageHeader title="Fordon">
        <HeaderRight>
          <Button
            small
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              navigate(Routes.settings.manageVehicles.add);
            }}
          >
            Lägg till fordon
          </Button>
        </HeaderRight>
      </PageHeader>
      {renderContent()}

      <Outlet context={onVehicleSaved} />
    </>
  );
};

export const useOnVehicleSaved = () =>
  useOutletContext<(savedVehicle: Vehicle) => void>();

export default ManageVehiclesPage;
