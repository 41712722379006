import { AkCompanyClient, UserClient } from 'api';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Modal from 'components/Modal';
import OverlayedSpinner from 'components/spinners/OverlayedSpinner';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import { FC, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { useOnUserSaved } from '..';
import UserModal from './UserModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MyTable = styled.table`
  border-spacing: 10px;

  th {
    text-align: end;
  }
`;

const AddUserModal: FC = () => {
  const navigate = useNavigate();
  const { roleNames } = useBasicData();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const onUserSaved = useOnUserSaved();

  const [ssn, setSsn] = useState('');
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);

  const getUserBySsnCall = useApiCall(UserClient, (c, ssn: string) =>
    c.getBySocialSecurityNumber(ssn)
  );
  const linkUserToCompanyCall = useApiCall(
    AkCompanyClient,
    (c, userId: number, companyId: number) =>
      c.setAkCompanyHasUser(companyId, userId, true)
  );

  const handleClose = () => {
    navigate(Routes.settings.manageUsers.index);
  };

  const handleContinueClick = async () => {
    const [user, error] = await getUserBySsnCall.run(ssn);

    if (!error) {
      if (user) {
        if (selectedAkCompanyId !== null) {
          const [, linkingError] = await linkUserToCompanyCall.run(
            user.id,
            selectedAkCompanyId
          );

          if (!linkingError) {
            onUserSaved(user);
          }
        }
      } else {
        setShowCreateUserModal(true);
      }
    }
  };

  if (showCreateUserModal) {
    return <UserModal createNew prefillFields={{ userName: ssn }} />;
  }

  switch (linkUserToCompanyCall.status) {
    case RequestStatus.Error:
    case RequestStatus.Idle: {
      const loading = getUserBySsnCall.status === RequestStatus.Fetching;
      return (
        <Modal
          title="Lägg till användare"
          buttons={[
            {
              label: 'Fortsätt',
              onClick: handleContinueClick,
              disabled: ssn.length !== 12 || loading,
            },
            { label: 'Avbryt', onClick: handleClose, disabled: loading },
          ]}
          onClose={loading ? undefined : handleClose}
        >
          {loading ? (
            <LoadingSection />
          ) : (
            <Wrapper>
              <LabelWrap label="Personnummer">
                <Input
                  value={ssn}
                  onChange={(eve) =>
                    setSsn(eve.target.value.replace(/\D/g, ''))
                  }
                />
              </LabelWrap>
            </Wrapper>
          )}
        </Modal>
      );
    }

    case RequestStatus.Fetching:
      return <OverlayedSpinner>Lägger till {ssn}...</OverlayedSpinner>;

    case RequestStatus.Fetched:
      return (
        <Modal
          title="Användare tillagd"
          onClose={handleClose}
          buttons={[
            {
              label: 'Redigera användare',
              onClick: () =>
                getUserBySsnCall.response
                  ? navigate(
                      generatePath(Routes.settings.manageUsers.user, {
                        id: String(getUserBySsnCall.response.id),
                      })
                    )
                  : undefined,
            },
            { label: 'Avbryt', onClick: handleClose },
          ]}
        >
          En existerande användare har nu lagts till.
          {getUserBySsnCall.response && (
            <MyTable>
              <tbody>
                <tr>
                  <th>Namn</th>
                  <td>{getUserBySsnCall.response.fullName}</td>
                </tr>
                <tr>
                  <th>Personnummer</th>
                  <td>{getUserBySsnCall.response.userName}</td>
                </tr>
                <tr>
                  <th>Epost</th>
                  <td>{getUserBySsnCall.response.email}</td>
                </tr>
                <tr>
                  <th>Roller</th>
                  <td>
                    {getUserBySsnCall.response.roles
                      .map((roleId) => roleNames.get(roleId))
                      .join(', ')}
                  </td>
                </tr>
                <tr>
                  <th>Deaktiverad</th>
                  <td>
                    {getUserBySsnCall.response.deactivated ? 'Ja' : 'Nej'}
                  </td>
                </tr>
              </tbody>
            </MyTable>
          )}
        </Modal>
      );
  }
};

export default AddUserModal;
