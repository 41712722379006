export function groupItemsSorted<
  O,
  K extends string | number | symbol = string
>(items: O[], groupLabler: (o: O) => K): [label: string, items: O[]][] {
  return (
    Object.entries(groupItems<O, K>(items, groupLabler)) as [string, O[]][]
  ).sort(([a], [b]) => (a > b ? 1 : a === b ? 0 : -1));
}

export function groupItems<O, K extends string | number | symbol>(
  items: O[],
  groupLabler: (o: O) => K
): { [groupName in K]: O[] } {
  const groups = {} as { [label in K]: O[] };

  items.forEach((obj) => {
    const groupName = groupLabler(obj);
    let group = groups[groupName];

    if (!group) group = groups[groupName] = [];

    group.push(obj);
  });

  return groups;
}
