import { faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PriceAdjustmentType } from 'api';
import Button from 'components/inputs/Button';
import Select from 'components/inputs/Select';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { PriceListFormInputs } from '../../formUtils/priceListFormConverters';
import PriceAdjustmentInfoModal from './PriceAdjustmentInfoModal';

const AddablePriceAdjustmentItemButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px ${Theme.sizes.padding.screenInset};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px ${Theme.sizes.padding.screenInset};
`;

const InfoButton = styled.button`
  margin: 0;
  padding: 0;

  font: inherit;
  font-weight: 600;
  background: transparent;
  border: none;
  color: ${Theme.colors.bg.selection};
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-right: 5px;
  }
`;

const Title = styled.div`
  align-self: flex-end;
  margin-right: auto;
  width: 100%;

  font-size: ${Theme.sizes.font.medium};
  font-weight: bold;
  text-align: left;

  ${MediaQuery.tablet} {
    width: auto;
  }
`;

interface Props {
  control: Control<PriceListFormInputs, any>;
  onAddPriceAdjustment(adjustmentType: PriceAdjustmentType): void;
  editMode: boolean;
}

const PriceAdjustmentsTitle: FC<Props> = ({
  control,
  onAddPriceAdjustment,
  editMode,
}) => {
  const basicData = useBasicData();
  const modalStack = useModalStack();
  const [selectedPriceAdjustmentType, setSelectedPriceAdjustmentType] =
    useState<PriceAdjustmentType | -1>(-1);

  const priceAdjustments = useWatch({
    control,
    name: 'priceAdjustments',
  });

  const notRemovedCurrentPriceAdjustments = useMemo(
    () => priceAdjustments.filter((pa) => !pa.isRemoved),
    [priceAdjustments]
  );

  const selectablePriceAdjustmentTypes = useMemo(
    () =>
      // Only allow one PriceAdjustment of each PriceAdjustmentType
      Array.from(basicData.priceAdjustmentTypeNames.entries()).filter(
        ([type]) =>
          !notRemovedCurrentPriceAdjustments.find(
            (cpa) => cpa.priceAdjustmentType === type
          )
      ),
    [basicData.priceAdjustmentTypeNames, notRemovedCurrentPriceAdjustments]
  );

  const handleMoreInfoClick = useCallback((eve: React.MouseEvent) => {
    eve.preventDefault();
    const modalId = modalStack.push(
      <PriceAdjustmentInfoModal onClose={() => modalStack.pop(modalId)} />
    );
  }, []);

  if (editMode && notRemovedCurrentPriceAdjustments.length === 0) {
    return (
      <AddablePriceAdjustmentItemButtons>
        {Array.from(basicData.priceAdjustmentTypeNames.entries()).map(
          ([type, typeName]) => (
            <Button
              small
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={(e) => {
                e.preventDefault();
                onAddPriceAdjustment(type);
              }}
              key={type}
            >
              {typeName}
            </Button>
          )
        )}

        <InfoButton onClick={handleMoreInfoClick}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Läs mer
        </InfoButton>
      </AddablePriceAdjustmentItemButtons>
    );
  }

  return (
    <Wrapper>
      <Title>
        Prisjusteringar{' '}
        <InfoButton onClick={handleMoreInfoClick}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </InfoButton>
      </Title>

      {editMode && (
        <>
          {selectablePriceAdjustmentTypes.length > 0 && (
            <Select
              value={selectedPriceAdjustmentType}
              onChange={(eve) => {
                setSelectedPriceAdjustmentType(Number(eve.target.value));
              }}
            >
              <option disabled value={-1}>
                Välj en prisjustering
              </option>
              {selectablePriceAdjustmentTypes.map(([type, typeName]) => (
                <option value={type} key={type}>
                  {typeName}
                </option>
              ))}
            </Select>
          )}

          <Button
            small
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={(eve) => {
              eve.preventDefault();
              onAddPriceAdjustment(selectedPriceAdjustmentType);
              setSelectedPriceAdjustmentType(-1);
            }}
            disabled={
              selectedPriceAdjustmentType === -1 ||
              selectablePriceAdjustmentTypes.length === 0
            }
          >
            Lägg till
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default PriceAdjustmentsTitle;
