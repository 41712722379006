import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PriceAdjustmentType } from 'api';
import Input, { inputStyle } from 'components/inputs/Input';
import Theme from 'constants/theme';
import { PriceAdjustmentFormInputs } from '../../formUtils/priceAdjustmentFormConverters';
import { PriceListFormInputs } from '../../formUtils/priceListFormConverters';
import { FC, ReactNode } from 'react';
import { Control, useController, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import MediaQuery from 'constants/MediaQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px ${Theme.sizes.padding.screenInset};

  border-top: 1px solid ${Theme.colors.border.main};
  &:nth-child(odd) {
    background-color: ${Theme.colors.bg.background2};
  }

  ${MediaQuery.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const InfoButton = styled.button`
  margin: 0;
  padding: 0;

  font: inherit;
  font-weight: 600;
  background: transparent;
  border: none;
  color: ${Theme.colors.bg.selection};
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-right: 5px;
  }
`;

const DeleteButton = styled(InfoButton).attrs(() => ({ title: 'Ta bort' }))`
  margin-left: auto;
  color: ${Theme.colors.bad};
`;

const Label = styled.div`
  font-weight: 600;

  ${MediaQuery.tablet} {
    width: 140px;
    text-align: right;
  }
`;

const InputWrapper = styled.label`
  ${inputStyle};
  display: inline-block;
  height: auto;
  padding: 0;
  padding-right: 5px;

  &:focus-within {
    box-shadow: 0 0 0 2px ${Theme.colors.bg.selection};
  }
`;

const MyInput = styled(Input)`
  width: 40px;
  height: auto;
  padding: 2px 5px;
  padding-right: 0;
  margin: 0;

  border: none;
  background: transparent;
  font: inherit;
  text-align: right;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    outline: none;
  }
`;

const Setting = styled.div``;
const SettingWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

interface Props {
  register: UseFormRegister<PriceListFormInputs>;
  control: Control<PriceListFormInputs, any>;
  itemIndex: number;
  onRemoveFromForm(itemIndex: number): void;
  editMode: boolean;
}

const PriceAdjustmentItem: FC<Props> = ({
  register,
  control,
  itemIndex,
  onRemoveFromForm,
  editMode,
}) => {
  const { field: idField } = useController({
    control,
    name: `priceAdjustments.${itemIndex}.priceAdjustmentId`,
  });
  const { field: priceAdjustmentTypeField } = useController({
    control,
    name: `priceAdjustments.${itemIndex}.priceAdjustmentType`,
  });
  const { field: nameField } = useController({
    control,
    name: `priceAdjustments.${itemIndex}.name`,
  });
  const { field: isRemovedField } = useController({
    control,
    name: `priceAdjustments.${itemIndex}.isRemoved`,
  });

  const mkInput = (name: keyof PriceAdjustmentFormInputs, unit?: ReactNode) => {
    return (
      <InputWrapper>
        <MyInput
          type="number"
          {...register(`priceAdjustments.${itemIndex}.${name}`)}
          disabled={!editMode}
        />
        {unit ?? null}
      </InputWrapper>
    );
  };

  const renderSetting = () => {
    switch (priceAdjustmentTypeField.value) {
      case PriceAdjustmentType.OffHours_17_To_07:
        return (
          <Setting>
            {mkInput('priceMultiplier', '%')}{' '}
            <i>(Gäller mellan 17:00 till 07:00 nästkommande dag)</i>
          </Setting>
        );

      case PriceAdjustmentType.BulkDiscount_X_Plus_Hours:
        return (
          <Setting>
            {mkInput('priceMultiplier', '%')} reducerat pris. Gäller
            beställningar på {mkInput('xValue')} eller fler timmar.
          </Setting>
        );

      case PriceAdjustmentType.Overtime_StartingUnplannedHour_X:
        return (
          <Setting>
            {mkInput('priceMultiplier', '%')} Påbörjas fr.o.m. timme{' '}
            {mkInput('xValue')} efter bokad sluttid.{' '}
            <i>
              (Fyll i 1 om övertid ska börja gälla direkt efter bokad sluttid.)
            </i>
          </Setting>
        );

      case PriceAdjustmentType.LateCancel_PriceReduktion:
        return (
          <Setting>
            {mkInput('priceMultiplier', '%')} reducerat pris för sen avbokning.
          </Setting>
        );
    }
  };

  if (isRemovedField.value) {
    return null;
  }

  return (
    <Wrapper>
      <Label>{nameField.value}:</Label>

      <SettingWrapper>
        {renderSetting()}
        {editMode && (
          <DeleteButton
            onClick={(e) => {
              e.preventDefault();

              // PriceAdjustment hasn't been saved to DB (0 == no ID), so just remove it from the form.
              if (idField.value === 0) {
                onRemoveFromForm(itemIndex);
              } else {
                isRemovedField.onChange(true);
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </DeleteButton>
        )}
      </SettingWrapper>
    </Wrapper>
  );
};

export default PriceAdjustmentItem;
