import { CaseStatus, CustomerCase, CustomerCaseJob } from 'api';
import {
  CustomerCaseJobFormInputs,
  caseJobFormToCaseJob,
  createCaseJobForm,
} from './caseJobFormConverters';

export interface CustomerCaseFormInputs {
  akCompanyId: number;
  streetAddress: string;
  zipCode: string;
  city: string;
  locationDescription: string;
  externalReference: string;
  meetupLocation: string;
  freetextNotes: string;
  caseJobs: CustomerCaseJobFormInputs[];
}

export function createCustomerCaseForm(
  theCase?: CustomerCase
): CustomerCaseFormInputs {
  if (theCase) {
    return {
      akCompanyId: theCase.akCompanyId,
      streetAddress: theCase.streetAddress,
      zipCode: theCase.zipCode,
      city: theCase.city,
      locationDescription: theCase.locationDescription,
      externalReference: theCase.externalReference,
      meetupLocation: theCase.meetupLocation,
      freetextNotes: theCase.freetextNotes,
      caseJobs: theCase.caseJobs.map(createCaseJobForm),
    };
  }

  return {
    akCompanyId: -1,
    streetAddress: '',
    zipCode: '',
    city: '',
    locationDescription: '',
    externalReference: '',
    meetupLocation: '',
    freetextNotes: '',
    caseJobs: [createCaseJobForm()],
  };
}

export function customerCaseFormToCustomerCase(
  formInputs: CustomerCaseFormInputs,
  customerId: number
): CustomerCase {
  return new CustomerCase({
    id: 0,
    caseStatus: CaseStatus.Open,
    customerId: customerId,
    akCompanyId: formInputs.akCompanyId,
    streetAddress: formInputs.streetAddress,
    zipCode: formInputs.zipCode,
    city: formInputs.city,
    locationDescription: formInputs.locationDescription,
    externalReference: formInputs.externalReference,
    meetupLocation: formInputs.meetupLocation,
    freetextNotes: formInputs.freetextNotes,
    caseJobs: formInputs.caseJobs.reduce((accumulator, formJob) => {
      for (let i = 0; i < formJob.amount; i++) {
        accumulator.push(caseJobFormToCaseJob(formJob));
      }

      return accumulator;
    }, [] as CustomerCaseJob[]),
  });
}
