import { AkCompanyRole } from 'api';
import WebRoleIds from 'constants/RoleIds';
import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import { LoginReturnUrlQueryParam } from 'contexts/useAccountInfo/RequireAuth';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import { FC } from 'react';
import { matchPath, Navigate, useLocation } from 'react-router-dom';

/** Automatically navigates back to a route that currently logged in user has access to */
const NavigateToFallback: FC = () => {
  const {
    isLoggedIn,
    accountInfo: { user },
  } = useAccountInfo();
  const userClaims = useUserWebClaims();
  const location = useLocation();

  if (!isLoggedIn || !user) {
    const searchParams = new URLSearchParams();
    searchParams.set(LoginReturnUrlQueryParam, location.pathname);

    if (matchPath(Routes.account.login.index, location.pathname)) return null;

    return (
      <Navigate
        replace
        to={`${Routes.account.login.index}?${searchParams.toString()}`}
      />
    );
  }

  if (
    userClaims.akCompanyRoles.has(AkCompanyRole.CompanyUser) ||
    userClaims.akCompanyRoles.has(AkCompanyRole.CompanyManager) ||
    userClaims.webRoleIds.has(WebRoleIds.admin)
  ) {
    return <Navigate replace to={Routes.index} />;
  }

  if (userClaims.customer) {
    return <Navigate replace to={Routes.customerPortal.bookedCases.index} />;
  }

  if (matchPath(Routes.account.login.index, location.pathname)) return null;
  return <Navigate replace to={Routes.account.login.index} />;
};

export default NavigateToFallback;
