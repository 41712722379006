import Theme from 'constants/theme';
import styled, { css } from 'styled-components';

export const inputStyle = css`
  padding: 5px;
  margin: 0;
  height: calc(1.5em + 10px);
  width: auto;

  background-color: ${Theme.colors.bg.input};
  color: ${Theme.colors.fg.input};
  border: 1px solid ${Theme.colors.border.input};
  border-radius: 0;
  font: inherit;
  -webkit-appearance: none;

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator {
    margin: 0;
    padding: 0;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const Input = styled.input`
  ${inputStyle}
`;

export default Input;
