import { Customer } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { useMemo } from 'react';

const useSelectableCustomers = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { customersById, customerIdsByAkCompanyId } = usePricingSetup();

  const selectableCustomers = useMemo(() => {
    if (selectedAkCompanyId !== null) {
      return (customerIdsByAkCompanyId
        .get(selectedAkCompanyId)
        ?.map((customerId) => customersById.get(customerId))
        .filter((c) => !!c) ?? []) as Customer[];
    }
    return [];
  }, [customerIdsByAkCompanyId, customersById, selectedAkCompanyId]);

  return selectableCustomers;
};

export default useSelectableCustomers;
