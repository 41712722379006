import PartialCheckBox from 'components/inputs/PartialCheckbox';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC } from 'react';
import styled from 'styled-components';
import { getReadableForegroundColor, safeColor } from 'utils/color';
import { makeStringComparator } from 'utils/sorting';
import GroupSection, { Item, Items } from './GroupSection';
import useJobsFilterState from './useJobsFilterState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid ${Theme.colors.border.main};

  ${MediaQuery.tablet} {
    width: 300px;
  }
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const SectionTitle = styled.div`
  padding: 20px;
  padding-left: ${Theme.sizes.padding.screenInset};
  padding-bottom: 5px;

  font-size: ${Theme.sizes.font.medium};
  font-weight: bold;
  border-bottom: 2px solid ${Theme.colors.border.main};
`;

const SectionScroll = styled.div`
  overflow: auto;
`;

interface Props {
  jobsFilterState: ReturnType<typeof useJobsFilterState>;
}

const JobsFilter: FC<Props> = ({ jobsFilterState }) => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { customerIdsByAkCompanyId, customersById } = usePricingSetup();

  const { filterState, selectCustomers, deselectCustomers } = jobsFilterState;

  if (selectedAkCompanyId === null) return <Wrapper />;

  return (
    <Wrapper>
      <Section>
        <SectionTitle>Kunder</SectionTitle>
        <SectionScroll>
          <Items>
            {customerIdsByAkCompanyId
              .get(selectedAkCompanyId)
              ?.map((customerId) => {
                const checked = filterState.selectedCustomerIds.has(customerId);
                const customer = customersById.get(customerId);

                if (!customer || customer.isRemoved) return null;

                return (
                  <Item
                    key={customerId}
                    onClick={() =>
                      checked
                        ? deselectCustomers([customerId])
                        : selectCustomers([customerId])
                    }
                  >
                    <PartialCheckBox
                      fullyChecked={checked}
                      style={{
                        backgroundColor: checked
                          ? customer.calendarColor
                          : undefined,
                        borderColor: safeColor(
                          customer.calendarColor,
                          '#000000'
                        )
                          .saturate(1)
                          .lightness(30)
                          .hex(),
                        color: getReadableForegroundColor(
                          customer.calendarColor
                        ),
                      }}
                    />
                    {customer.name}
                  </Item>
                );
              })}
          </Items>
        </SectionScroll>
      </Section>
    </Wrapper>
  );
};

export default JobsFilter;
