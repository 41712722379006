import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Input from 'components/inputs/Input';
import OptionInput, {
  inputClassName,
  OptionBase,
  OptionInputProps,
} from 'components/inputs/OptionInput';
import Select from 'components/inputs/Select';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 300px));
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin: 20px 0px;
`;

export const FullWitdhCell = styled.div`
  grid-column: 1/-1;
`;

const noEditStyle = css`
  height: auto;
  padding: 0;
  padding-bottom: 5px;

  border: none;
  font-weight: 800;
  color: currentColor;
  appearance: none;
  background: none;

  &,
  &:disabled {
    opacity: 1;
  }
`;

// All of the inputs in the Case editor form should be disabled once an invoice has been created (hence the required 'noEdit' prop).
type NoEditProp = { noEdit: boolean };
const noEditAttrs = ({ noEdit }: NoEditProp) => ({
  disabled: noEdit,
  noEdit,
});

export const FormInput = styled(Input).attrs(noEditAttrs)`
  ${({ noEdit }) => noEdit && noEditStyle}
`;
export const FormSelect = styled(Select).attrs(noEditAttrs)`
  ${({ noEdit }) => noEdit && noEditStyle}
`;
export const FormTextArea = styled(AutoSizedTextArea).attrs(noEditAttrs)`
  ${({ noEdit }) => noEdit && noEditStyle}
`;

export const FormOptionInput = styled(OptionInput).attrs(noEditAttrs)`
  .${inputClassName} {
    ${({ noEdit }) => noEdit && noEditStyle}
  }
` as <T extends OptionBase>(
  props: OptionInputProps<T> & NoEditProp
) => ReactElement;

export default InputGrid;
