import { faCalendarDay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import React, { FC } from 'react';
import {
  RegisterOptions,
  useFieldArray,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import styled, { css } from 'styled-components';
import InputGrid, { FormInput, FormSelect, FullWitdhCell } from '../components';
import { JobGroupFormInputs } from '../formUtils/caseJobFormConverters';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import useStartupData from 'contexts/useBasicData';
import Checkbox from 'components/inputs/Checkbox';
import Theme from 'constants/theme';
import MediaQuery from 'constants/MediaQuery';
import { humanReadableTimeDuration } from 'utils/date';
import CancelCaseJobModal from './CancelCaseJobModal';
import { JobStatus } from 'api';
import useModalStack from 'contexts/useModalStack';
import CaseJobInstances from './CaseJobInstances';
import IconButton from 'components/inputs/IconButton';
import Modal from 'components/Modal';
import { JobFormIndexes } from 'pages/PlannerTool/useCaseJobEvents';
import useJobGroupTotalInvoicedTime from './useJobGroupTotalInvoicedTime';
import useJobGroupTotalOrderedTime from './useJobGroupTotalOrderedTime';

const showInCalendarButtonClass = 'show-casejob-in-calendar';

const Wrapper = styled.div<{ highlight: boolean }>`
  position: relative;
  padding-bottom: 20px;
  margin-inline: calc(${Theme.sizes.padding.screenInset} / 2);

  border-radius: 10px;
  border: 1px solid ${Theme.colors.border.input};

  &:not(:hover) .${showInCalendarButtonClass} {
    visibility: hidden;
  }

  background-color: ${Theme.colors.bg.background2};
  ${({ highlight }) =>
    highlight &&
    css`
      border: 1px solid ${Theme.colors.bg.selection};
    `}
`;

const MyInputGrid = styled(InputGrid)`
  margin: 0;
  padding: 10px 0;
  padding-inline: calc(${Theme.sizes.padding.screenInset} / 2);
`;

interface MySectionHeaderProps {
  highlighted: boolean;
}

const MySectionHeader = styled(SectionHeader)<MySectionHeaderProps>`
  align-items: center;

  ${({ highlighted }) =>
    highlighted &&
    css`
      border-bottom: 1px solid ${Theme.colors.bg.selection};

      &:not(:hover),
      &:hover {
        & .${showInCalendarButtonClass} {
          visibility: visible;
        }
      }
    `}
`;

const ShowInCalendarButton = styled(IconButton)`
  margin: 0;
  margin-left: 20px;
  padding: 0;
  font-size: 0.9em;

  ${MediaQuery.phone} {
    display: none;
  }
`;

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

interface Props {
  form: UseFormReturn<CaseFormInputs>;
  /** Removes this CaseJobFields section from the form. Can only be called for CaseJobs that hasn't been saved */
  onDelete(): void;
  groupFormIndex: number;

  highlightedIndex: JobFormIndexes | undefined;
  noEdit: boolean;
  onShowInCalendarClick?(jobInstanceIndex: number): void;

  className?: string;
}

const CaseJobGroupFields: FC<Props> = ({
  form,
  onDelete,
  groupFormIndex,
  highlightedIndex,
  noEdit,
  onShowInCalendarClick,
  className,
}) => {
  const { register, control: formControl, setValue, getValues } = form;

  const modalStack = useModalStack();
  const { jobTypeNames } = useStartupData();

  const jobInstancesFields = useFieldArray({
    control: formControl,
    name: `jobGroups.${groupFormIndex}.jobInstances`,
  });

  const jobGroupFieldPath = <P extends keyof JobGroupFormInputs>(
    jobGroupProp: P
  ) => `jobGroups.${groupFormIndex}.${jobGroupProp}` as const;

  const [jobType, orderedStartDate, orderedEndDate] = useWatch({
    control: formControl,
    name: [
      jobGroupFieldPath('jobType'),
      jobGroupFieldPath('orderedStartUtc'),
      jobGroupFieldPath('orderedEndUtc'),
    ],
  });

  const isEditable = !noEdit;

  const totalOrderedTime = useJobGroupTotalOrderedTime(form, groupFormIndex);
  const totalInvoicedTime = useJobGroupTotalInvoicedTime(form, groupFormIndex);

  const mkField = (
    fieldName: keyof JobGroupFormInputs,
    label: string,
    type: React.HTMLInputTypeAttribute = 'text',
    registerOptions?: RegisterOptions,
    forminputProps?: React.HTMLProps<HTMLInputElement>
  ) => (
    <LabelWrap label={label}>
      <FormInput
        type={type}
        {...register(jobGroupFieldPath(fieldName), registerOptions)}
        noEdit={!isEditable}
        {...forminputProps}
        as={undefined}
      />
    </LabelWrap>
  );

  const mkSelect = (
    fieldName: keyof JobGroupFormInputs,
    label: string,
    options: Map<number, string>
  ) => (
    <LabelWrap label={label}>
      <FormSelect
        {...register(jobGroupFieldPath(fieldName), {
          valueAsNumber: true,
        })}
        noEdit={!isEditable}
      >
        {Array.from(options).map(([value, label]) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </LabelWrap>
  );

  const mkCheckbox = (
    fieldName: keyof JobGroupFormInputs,
    label: string,
    registerOptions?: RegisterOptions
  ) => (
    <CheckboxWrap>
      <Checkbox
        {...register(jobGroupFieldPath(fieldName), registerOptions)}
        disabled={!isEditable}
      >
        {label}
      </Checkbox>
    </CheckboxWrap>
  );

  const groupIsHighlighted =
    highlightedIndex?.jobGroupFormIndex === groupFormIndex;

  return (
    <Wrapper className={className} highlight={groupIsHighlighted}>
      <MySectionHeader
        title={
          <>
            {jobInstancesFields.fields.length}st {jobTypeNames.get(jobType)}
            {onShowInCalendarClick && (
              <ShowInCalendarButton
                className={showInCalendarButtonClass}
                iconColor={Theme.colors.bg.accent1}
                onClick={(eve) => {
                  eve.preventDefault();
                  onShowInCalendarClick(0);
                }}
              >
                <FontAwesomeIcon icon={faCalendarDay} />
              </ShowInCalendarButton>
            )}
          </>
        }
        highlighted={groupIsHighlighted}
      >
        {!noEdit && (
          <IconButton
            iconColor={Theme.colors.bad}
            onClick={(eve) => {
              eve.preventDefault();

              const jobsToCancel = form.getValues(
                `jobGroups.${groupFormIndex}.jobInstances`
              );

              const changeAllJobStatuses = (newStatus: JobStatus) => {
                jobsToCancel.forEach((job, formIndex) => {
                  form.setValue(
                    `jobGroups.${groupFormIndex}.jobInstances.${formIndex}.jobStatus`,
                    newStatus
                  );
                });

                // Delete job instances which haven't been saved yet
                jobInstancesFields.remove(
                  jobsToCancel
                    .filter((j) => j.caseJobId === 0)
                    .map((j) => j.caseJobId)
                );
              };

              const jobGroupHasAnySavedJobs = jobsToCancel.find(
                (job) => job.caseJobId !== 0
              );
              if (jobGroupHasAnySavedJobs) {
                const modalId = modalStack.push(
                  <CancelCaseJobModal
                    onClose={() => {
                      modalStack.pop(modalId);
                    }}
                    onSetJobStatus={(newStatus) => {
                      changeAllJobStatuses(newStatus);
                      modalStack.pop(modalId);
                    }}
                  />
                );
              } else {
                const modalId = modalStack.push(
                  <Modal
                    title="Släng jobbgrupp?"
                    onClose={() => modalStack.pop(modalId)}
                    buttons={[
                      {
                        icon: <FontAwesomeIcon icon={faTrash} />,
                        label: 'Släng jobbgrupp',
                        onClick: () => {
                          modalStack.pop(modalId);
                          onDelete();
                        },
                      },
                      {
                        label: 'Avbryt',
                        onClick: () => modalStack.pop(modalId),
                      },
                    ]}
                  >
                    Vill du verkligen slänga den här jobbgruppen?
                  </Modal>
                );
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        )}
      </MySectionHeader>

      <MyInputGrid>
        {mkSelect('jobType', 'Typ', jobTypeNames)}
        {mkCheckbox('isUnplannedJob', 'Akut jobb')}

        {mkField('orderedStartUtc', 'Bokad starttid', 'datetime-local', {
          max: orderedEndDate,
          validate: (value) => {
            if (!value) return 'Ange en bokad starttid';
          },

          onChange: (eve) => {
            if ('target' in eve && eve.target instanceof HTMLInputElement) {
              const orderedStartInput = new Date(eve.target.value);
              const orderedEndInput = new Date(
                getValues(jobGroupFieldPath('orderedEndUtc'))
              );

              if (orderedStartInput > orderedEndInput) {
                setValue(jobGroupFieldPath('orderedEndUtc'), eve.target.value);
              }
            }
          },
        })}

        {mkField('orderedEndUtc', 'Bokad sluttid', 'datetime-local', {
          min: orderedStartDate,
          validate: (value) => {
            if (!value) return 'Ange en bokad sluttid';
          },

          onChange: (eve) => {
            if ('target' in eve && eve.target instanceof HTMLInputElement) {
              const orderedEndInput = new Date(eve.target.value);
              const orderedStartInput = new Date(
                getValues(jobGroupFieldPath('orderedStartUtc'))
              );

              if (orderedStartInput > orderedEndInput) {
                setValue(
                  jobGroupFieldPath('orderedStartUtc'),
                  eve.target.value
                );
              }
            }
          },
        })}

        <FullWitdhCell>
          <div>
            <b>Bokad tid:</b> {humanReadableTimeDuration(totalOrderedTime)}
          </div>
          <div>
            <b>Fakturerad tid:</b>{' '}
            {humanReadableTimeDuration(totalInvoicedTime)}
          </div>
        </FullWitdhCell>
      </MyInputGrid>

      <CaseJobInstances
        form={form}
        groupFormIndex={groupFormIndex}
        noEdit={noEdit}
        caseJobInstances={jobInstancesFields}
        highlightedIndex={highlightedIndex}
      />
    </Wrapper>
  );
};

export default CaseJobGroupFields;
