import { PriceAdjustment, PriceAdjustmentType } from 'api';

export interface PriceAdjustmentFormInputs {
  priceAdjustmentId: number;
  isRemoved: boolean;
  name: string;
  priceAdjustmentType: PriceAdjustmentType;
  /** xValue is an optional value that is used differently depending on PriceAdjustmentType. Read the backend code to learn more */
  xValue: number;
  /** NOTE: the form value = PriceAdjustment.priceMultiplier * 100, to display it in percent. */
  priceMultiplier: number;
}

export function createPriceAdjustmentFormFromExisting(
  priceAdjustment: PriceAdjustment
): PriceAdjustmentFormInputs {
  let priceMultiplier = priceAdjustment.priceMultiplier;

  if (
    priceAdjustment.priceAdjustmentType ===
      PriceAdjustmentType.BulkDiscount_X_Plus_Hours ||
    priceAdjustment.priceAdjustmentType ===
      PriceAdjustmentType.LateCancel_PriceReduktion
  ) {
    priceMultiplier *= -1;
  }

  return {
    priceAdjustmentId: priceAdjustment.id,
    isRemoved: priceAdjustment.isRemoved,
    name: priceAdjustment.name,
    priceAdjustmentType: priceAdjustment.priceAdjustmentType,
    priceMultiplier: Math.round(priceMultiplier * 100),
    xValue: priceAdjustment.xValue,
  };
}

export function createNewPriceAdjustmentForm(
  priceAdjustmentType: PriceAdjustmentType,
  priceAdjustmentTypeNames: Map<PriceAdjustmentType, string>
): PriceAdjustmentFormInputs {
  const base: PriceAdjustmentFormInputs = {
    priceAdjustmentId: 0,
    isRemoved: false,
    name: priceAdjustmentTypeNames.get(priceAdjustmentType) ?? '',
    priceAdjustmentType: priceAdjustmentType,
    priceMultiplier: 1 * 100,
    xValue: 0,
  };

  switch (priceAdjustmentType) {
    case PriceAdjustmentType.BulkDiscount_X_Plus_Hours:
      return {
        ...base,
        priceMultiplier: 0.1 * 100,
        xValue: 48,
      };
    case PriceAdjustmentType.OffHours_17_To_07:
      return {
        ...base,
        priceMultiplier: 1.2 * 100,
        xValue: 0,
      };
    case PriceAdjustmentType.Overtime_StartingUnplannedHour_X:
      return {
        ...base,
        priceMultiplier: 1.2 * 100,
        xValue: 2,
      };
    case PriceAdjustmentType.LateCancel_PriceReduktion:
      return {
        ...base,
        priceMultiplier: 0.7 * 100,
      };

    default:
      return base;
  }
}

export function priceAdjustmentFormToPriceAdjustment(
  form: PriceAdjustmentFormInputs,
  base?: PriceAdjustment
): PriceAdjustment {
  const now = new Date();
  let priceMultiplier = form.priceMultiplier;

  if (
    form.priceAdjustmentType ===
      PriceAdjustmentType.BulkDiscount_X_Plus_Hours ||
    form.priceAdjustmentType === PriceAdjustmentType.LateCancel_PriceReduktion
  ) {
    priceMultiplier *= -1;
  }

  return new PriceAdjustment({
    id: base?.id ?? 0,
    isRemoved: form.isRemoved,
    priceListId: base?.priceListId ?? 0,
    name: form.name,
    priceAdjustmentType: form.priceAdjustmentType,
    xValue: form.xValue,
    priceMultiplier: priceMultiplier / 100,

    createdUtc: base?.createdUtc ?? now,
    modifiedUtc: base?.modifiedUtc ?? now,
    createdById: base?.createdById ?? -1,
    modifiedById: base?.modifiedById ?? -1,
    timestamp: base?.timestamp ?? '',
  });
}
