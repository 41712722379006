import Tabstrip from 'components/Tabstrip';
import Routes from 'constants/routes';
import { Outlet } from 'react-router-dom';

const AdminInvoicingRoute = () => {
  return (
    <>
      <Tabstrip
        tabs={[
          {
            label: 'Interna Fakturor',
            route: Routes.invoiceQueue.internal,
          },
          {
            label: 'Externa fakturaunderlag',
            route: Routes.invoiceQueue.external,
          },
          {
            label: 'Avgiftsunderlag',
            route: Routes.invoiceQueue.fee,
          },
        ]}
      />

      <Outlet />
    </>
  );
};

export default AdminInvoicingRoute;
