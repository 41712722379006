import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import { FC } from 'react';
import HQIcon from './HQIcon';
import StationIcon from './StationIcon';

interface Props {
  className?: string;
}

const SelectedAkCompanyIcon: FC<Props> = ({ className }) => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { hQ_AkCompanyId } = useBasicData();

  if (selectedAkCompanyId === hQ_AkCompanyId) {
    return <HQIcon className={className} />;
  }

  return <StationIcon className={className} />;
};

export default SelectedAkCompanyIcon;
