import {
  faComment,
  faEdit,
  faPaperclip,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseAttachment, CaseClient, CaseMessage } from 'api';
import TextButton from 'components/inputs/TextButton';
import Theme from 'constants/theme';
import useUsers from 'contexts/useUsers';
import { FC } from 'react';
import styled from 'styled-components';
import { useApiCall } from 'swaggerhooks';
import { Header, HeaderIcon, MessageText, Wrapper } from './components';

const actionsClassName = 'comment-action-buttons';

const MyWrapper = styled(Wrapper)`
  .${actionsClassName} {
    opacity: 0;
  }

  &:hover .${actionsClassName} {
    opacity: 1;
  }
`;

const Attachments = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 10px;
  padding-right: 40px; // give space for the action buttons
`;

const Attachment = styled.a`
  color: ${Theme.colors.bg.accent1};
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  bottom: 10px;
  right: 10px;

  background-color: ${Theme.colors.bg.background1};
  box-shadow: 0 0 5px 5px ${Theme.colors.bg.background1};
`;

interface Props {
  caseMessage: CaseMessage;
  onEditClick(): void;
  onDeleteClick(): void;
}

const CommentViewer: FC<Props> = ({
  caseMessage,
  onEditClick,
  onDeleteClick,
}) => {
  const users = useUsers();

  const downloadAttachmentCall = useApiCall(
    CaseClient,
    (c, attachmentId: number) => c.downloadAttachment(attachmentId)
  );

  const handleDownloadClick = async (attachment: CaseAttachment) => {
    const [fileResponse, error] = await downloadAttachmentCall.run(
      attachment.id
    );

    if (fileResponse && !error) {
      const tmpLink = document.createElement('a');
      tmpLink.href = URL.createObjectURL(fileResponse.data);
      tmpLink.download = attachment.displayName + attachment.fileEnding;

      tmpLink.click();
    }
  };

  return (
    <MyWrapper>
      <Header message={caseMessage}>
        <HeaderIcon icon={faComment} />{' '}
        {users.usersById.get(caseMessage.createdById)?.fullName || 'Kommentar'}
      </Header>

      <MessageText>{caseMessage.text}</MessageText>

      <Attachments>
        {caseMessage.caseAttachments
          .filter((attachment) => !attachment.isRemoved)
          .map((attachment) => (
            <Attachment
              key={attachment.id}
              onClick={() => handleDownloadClick(attachment)}
            >
              <FontAwesomeIcon icon={faPaperclip} />{' '}
              {attachment.displayName + attachment.fileEnding}
            </Attachment>
          ))}
      </Attachments>

      <Actions className={actionsClassName}>
        <TextButton
          onClick={(eve) => {
            eve.preventDefault();
            onDeleteClick();
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </TextButton>

        <TextButton
          onClick={(eve) => {
            eve.preventDefault();
            onEditClick();
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </TextButton>
      </Actions>
    </MyWrapper>
  );
};

export default CommentViewer;
