import { FC, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'components/Layout';
import CaseListPage from 'pages/CaseList';
import CasePage from 'pages/Case';
import LoginRoute from 'pages/Account/Login';
import PasswordLoginSection from 'pages/Account/Login/PasswordLoginSection';
import BankIdLoginSection from 'pages/Account/Login/BankIdLoginSection';
import Theme, { ThemeProvider } from 'constants/theme';
import RouteConst from 'constants/routes';
import { RenderModalStack } from 'contexts/useModalStack';
import { RecoilRoot } from 'recoil';
import ApiCallConfiguration from 'contexts/ApiCallConfiguration';
import AppInitialization from 'components/AppInitialization';
import RequireAuth from 'contexts/useAccountInfo/RequireAuth';
import ManageAkCompaniesPage from 'pages/Settings/ManageAkCompanies';
import SettingsRoute from 'pages/Settings/SettingsRoute';
import EmployeeListPage from 'pages/Settings/ManageUsers';
import AddUserModal from 'pages/Settings/ManageUsers/UserModal/AddUserModal';
import UserModal from 'pages/Settings/ManageUsers/UserModal/UserModal';
import ManageCustomersPage from 'pages/Settings/ManageCustomers';
import ManagePriceListsPage from 'pages/Settings/ManagePriceLists';
import AkCompanyInvoicingRoute from 'pages/AkCompanyInvoicing/AkCompanyInvoicingRoute';
import CreateInvoicePage from 'pages/AkCompanyInvoicing/CreateInvoicePage';
import InternalInvoicesPage from 'pages/AkCompanyInvoicing/InternalInvoicesPage';
import ManageVehiclesPage from 'pages/Settings/ManageVehicles';
import VehicleModal from 'pages/Settings/ManageVehicles/VehicleModal/VehicleModal';
import JobsCalendar from 'pages/PlannerTool/CasesCalendar';
import JobListPage from 'pages/PlannerTool/JobList';
import JobsGantt from 'pages/PlannerTool/JobsGantt';
import JobsPage from 'pages/PlannerTool';
import { QueryParamUpdatesContextProvider } from 'utils/useQueryParam';
import ManageMyAkCompany from 'pages/Settings/ManageMyAkCompany';
import ManageCustomerUsersPage from 'pages/Settings/ManageCustomerUsers';
import CustomerUserModal from 'pages/Settings/ManageCustomerUsers/CustomerUserModal';
import ConfirmAccountPage from 'pages/Account/ConfirmAccountPage';
import { ValidRouteString } from 'constants/routeConfiguration';
import { createGlobalStyle } from 'styled-components';
import NavigateToFallback from 'NavigateToFallback';
import CustomerJobsPage from 'pages/customerPages/JobsPage';
import ForgotPasswordPage from 'pages/Account/ForgotPasswordPage';
import ResetPasswordPage from 'pages/Account/ResetPasswordPage';
import CreateCustomerCasePage from 'pages/customerPages/CreateCustomerCasePage';
import AdminInvoicingRoute from 'pages/AdminInvoicing/AdminInvoicingRoute';
import InternalInvoicesQueuePage from 'pages/AdminInvoicing/InternalInvoicesQueuePage';
import ExternalInvoicesQueuePage from 'pages/AdminInvoicing/ExternalInvoicesQueuePage';
import { RouteReloadsAppVersion } from 'utils/useFrontendVersionCheck';
import StartPage from 'pages/StartPage';
import FeeInvoicesQueuePage from 'pages/AdminInvoicing/FeeInvoiceQueuePage';
import ManageAppTexts from 'pages/Settings/ManageAppTexts';
import EditAppText from 'pages/Settings/ManageAppTexts/EditAppText';

const GlobalStyle = createGlobalStyle`
  html, body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;

    background-color: ${Theme.colors.bg.background1};
    color: ${Theme.colors.fg.background1};
    font-size: ${Theme.sizes.font.small};
    font-family: 'Open Sans', sans-serif;
  }

  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
  }
`;

const App: FC = () => {
  const mkRoute = (
    path: ValidRouteString,
    element: ReactNode,
    routeChild?: ReactNode
  ) => {
    return (
      <Route
        element={
          // using path as key to force React to remount RouteReloadsAppVersion when changing route
          <RouteReloadsAppVersion key={path}>
            <RequireAuth route={path}>{element}</RequireAuth>
          </RouteReloadsAppVersion>
        }
        path={path}
      >
        {routeChild}
      </Route>
    );
  };

  return (
    <RecoilRoot>
      <QueryParamUpdatesContextProvider>
        <ThemeProvider>
          <GlobalStyle />

          <ApiCallConfiguration>
            <AppInitialization>
              <Layout>
                <Routes>
                  {mkRoute(
                    RouteConst.account.login.index,
                    <LoginRoute />,
                    <>
                      <Route
                        path={RouteConst.account.login.index}
                        element={
                          <Navigate
                            replace
                            to={RouteConst.account.login.stationUser}
                          />
                        }
                      />

                      {mkRoute(
                        RouteConst.account.login.stationUser,
                        <BankIdLoginSection />
                      )}
                      {mkRoute(
                        RouteConst.account.login.customerUser,
                        <PasswordLoginSection />
                      )}
                    </>
                  )}

                  {mkRoute(
                    RouteConst.account.confirmAccount,
                    <ConfirmAccountPage />
                  )}
                  {mkRoute(
                    RouteConst.account.forgotPassword,
                    <ForgotPasswordPage />
                  )}
                  {mkRoute(
                    RouteConst.account.resetPassword,
                    <ResetPasswordPage />
                  )}

                  {mkRoute(RouteConst.index, <StartPage />)}

                  {mkRoute(RouteConst.cases.index, <CaseListPage />)}
                  {mkRoute(RouteConst.cases.case, <CasePage />)}
                  {mkRoute(RouteConst.cases.create, <CasePage createNew />)}

                  {mkRoute(
                    RouteConst.plannerTool.index,
                    <JobsPage />,
                    <>
                      {mkRoute(
                        RouteConst.plannerTool.monthView,
                        <JobsCalendar />
                      )}
                      {mkRoute(
                        RouteConst.plannerTool.listView,
                        <JobListPage />
                      )}
                      {mkRoute(
                        RouteConst.plannerTool.weekView,
                        <JobsGantt type="week" />
                      )}
                      {mkRoute(
                        RouteConst.plannerTool.dayView,
                        <JobsGantt type="day" />
                      )}
                    </>
                  )}

                  {mkRoute(
                    RouteConst.invoices.index,
                    <AkCompanyInvoicingRoute />,
                    <>
                      {/* Automatically navigate to CreateInvoicePage page when landing on /fakturor */}
                      <Route
                        path={RouteConst.invoices.index}
                        element={
                          <Navigate replace to={RouteConst.invoices.create} />
                        }
                      />

                      {mkRoute(
                        RouteConst.invoices.create,
                        <CreateInvoicePage />
                      )}
                      {mkRoute(
                        RouteConst.invoices.internal,
                        <InternalInvoicesPage />
                      )}
                    </>
                  )}

                  {mkRoute(
                    RouteConst.invoiceQueue.index,
                    <AdminInvoicingRoute />,
                    <>
                      <Route
                        path={RouteConst.invoiceQueue.index}
                        element={
                          <Navigate
                            replace
                            to={RouteConst.invoiceQueue.internal}
                          />
                        }
                      />

                      {mkRoute(
                        RouteConst.invoiceQueue.internal,
                        <InternalInvoicesQueuePage />
                      )}
                      {mkRoute(
                        RouteConst.invoiceQueue.external,
                        <ExternalInvoicesQueuePage />
                      )}
                      {mkRoute(
                        RouteConst.invoiceQueue.fee,
                        <FeeInvoicesQueuePage />
                      )}
                    </>
                  )}

                  {mkRoute(
                    RouteConst.settings.index,
                    <SettingsRoute />,
                    <>
                      {/* Automatically navigate to managePricelists page when landing on /admin */}
                      <Route
                        path={RouteConst.settings.index}
                        element={
                          <Navigate
                            replace
                            to={RouteConst.settings.manageMyAkCompany.index}
                          />
                        }
                      />

                      {mkRoute(
                        RouteConst.settings.manageMyAkCompany.index,
                        <ManageMyAkCompany />
                      )}

                      {mkRoute(
                        RouteConst.settings.manageUsers.index,
                        <EmployeeListPage />,
                        <>
                          {mkRoute(
                            RouteConst.settings.manageUsers.add,
                            <AddUserModal />
                          )}
                          {mkRoute(
                            RouteConst.settings.manageUsers.user,
                            <UserModal />
                          )}
                        </>
                      )}
                      {mkRoute(
                        RouteConst.settings.manageCustomers.index,
                        <ManageCustomersPage />
                      )}
                      {mkRoute(
                        RouteConst.settings.manageCustomerUsers.index,
                        <ManageCustomerUsersPage />,
                        mkRoute(
                          RouteConst.settings.manageCustomerUsers.user,
                          <CustomerUserModal />
                        )
                      )}
                      {mkRoute(
                        RouteConst.settings.managePriceLists.index,
                        <ManagePriceListsPage />
                      )}
                      {mkRoute(
                        RouteConst.settings.managePriceLists.listsForCustomer,
                        <ManagePriceListsPage />
                      )}
                      {mkRoute(
                        RouteConst.settings.manageAkCompanies.index,
                        <ManageAkCompaniesPage />,
                        undefined
                      )}
                      {mkRoute(
                        RouteConst.settings.manageAppTexts.index,
                        <ManageAppTexts />,
                        undefined
                      )}
                      {mkRoute(
                        RouteConst.settings.manageAppTexts.appText,
                        <EditAppText />,
                        undefined
                      )}

                      {mkRoute(
                        RouteConst.settings.manageVehicles.index,
                        <ManageVehiclesPage />,
                        <>
                          {mkRoute(
                            RouteConst.settings.manageVehicles.add,
                            <VehicleModal createNew />
                          )}
                          {mkRoute(
                            RouteConst.settings.manageVehicles.vehicle,
                            <VehicleModal />
                          )}
                        </>
                      )}
                    </>
                  )}

                  {mkRoute(
                    RouteConst.customerPortal.index,
                    <Navigate
                      to={RouteConst.customerPortal.bookedCases.index}
                    />
                  )}

                  {mkRoute(
                    RouteConst.customerPortal.bookedCases.index,
                    <CustomerJobsPage />
                  )}
                  {mkRoute(
                    RouteConst.customerPortal.createCase,
                    <CreateCustomerCasePage />
                  )}

                  <Route path="*" element={<NavigateToFallback />} />
                </Routes>

                <RenderModalStack />
              </Layout>
            </AppInitialization>
          </ApiCallConfiguration>
        </ThemeProvider>
      </QueryParamUpdatesContextProvider>
    </RecoilRoot>
  );
};

export default App;
