import Theme from 'constants/theme';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const LoginForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: ${Theme.sizes.padding.screenInset};
  padding-bottom: calc(2 * ${Theme.sizes.padding.screenInset});
`;

export const LoginTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: ${Theme.sizes.font.medium};
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

export const ErrorText = styled.div`
  color: ${Theme.colors.bad};
`;

export const Link = styled(NavLink)`
  color: ${Theme.colors.bg.accent1};
  text-decoration: none;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`;
