import { faCoins, faListUl, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LabelWrap from 'components/inputs/LabelWrap';
import { FC } from 'react';
import {
  Control,
  RegisterOptions,
  useController,
  UseFormRegisterReturn,
} from 'react-hook-form';
import styled, { css } from 'styled-components';
import { ExpenseFormInputs } from '../formUtils/expenseFormConverters';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import Theme from 'constants/theme';
import TextButton from 'components/inputs/TextButton';
import PriceListSelect from './PriceListSelect';
import { PriceItem } from 'api';
import { FormInput } from '../components';

const Wrapper = styled.div`
  border-bottom: 1px solid ${Theme.colors.border.main};
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
  padding: 10px;
  font-weight: 600;

  background-color: ${Theme.colors.bg.background2};
  color: ${Theme.colors.fg.background2};
`;

const MyTextButton = styled(TextButton)`
  margin-left: auto;
`;

const Inputs = styled.div<{ removed?: boolean }>`
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 300px));
  grid-template-rows: auto auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  padding: 0 10px;
  box-sizing: border-box;
  max-height: 150px;
  transition: max-height 0.2s;

  // simulates bottom padding, but doesn't force the height
  &::after {
    content: '';
    display: block;
    height: 20px;
  }

  input {
    width: auto;
    min-width: auto;
  }

  & > *:nth-child(1) {
    grid-area: 1/1/2/3;
  }

  ${({ removed }) =>
    removed &&
    css`
      max-height: 0;
    `}
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  input {
    flex: 1;
  }
`;

interface Props {
  defaultValues: ExpenseFormInputs;
  register(
    name: keyof ExpenseFormInputs,
    options?: RegisterOptions
  ): UseFormRegisterReturn;
  /** Removes this ExpenseFields section from the form. Can only be called for Expenses that hasn't been saved */
  onDelete(): void;
  expenseFormIndex: number;
  formControl: Control<CaseFormInputs, any>;
  noEdit: boolean;
}

const ExpenseFields: FC<Props> = ({
  defaultValues,
  register,
  onDelete,
  expenseFormIndex,
  formControl,
  noEdit,
}) => {
  const { field: isRemovedField } = useController({
    control: formControl,
    name: `caseExpenses.${expenseFormIndex}.isRemoved`,
  });
  const { field: customerField } = useController({
    control: formControl,
    name: 'customerId',
  });
  const { field: nameField } = useController({
    control: formControl,
    name: `caseExpenses.${expenseFormIndex}.name`,
  });
  const { field: unitPriceField } = useController({
    control: formControl,
    name: `caseExpenses.${expenseFormIndex}.unitPrice`,
    rules: { min: 0 },
  });
  const { field: amountField } = useController({
    control: formControl,
    name: `caseExpenses.${expenseFormIndex}.amount`,
    rules: { min: 0 },
  });

  const isNewExpense = defaultValues.expenseId === 0;
  const isRemoved = isRemovedField.value;

  const handleSelectPriceItem = (priceItem: PriceItem) => {
    nameField.onChange(priceItem.name);
    unitPriceField.onChange(priceItem.price);

    if (amountField.value <= 0) {
      amountField.onChange(1);
    }
  };

  // You can only undo removing before it's been saved as 'removed'.
  if (defaultValues.isRemoved && isRemoved) {
    return null;
  }

  return (
    <Wrapper>
      {isRemoved ? (
        <Title>
          <FontAwesomeIcon icon={faTrash} /> Makulerad
          <MyTextButton
            onClick={(e) => {
              e.preventDefault();
              isRemovedField.onChange(false);
            }}
          >
            Ångra
          </MyTextButton>
        </Title>
      ) : (
        <Title>
          <FontAwesomeIcon icon={faCoins} />{' '}
          {isNewExpense ? 'Ny utgift' : 'Utgift'}
          {!noEdit && (
            <MyTextButton
              onClick={(e) => {
                e.preventDefault();
                if (isNewExpense) {
                  onDelete();
                } else {
                  isRemovedField.onChange(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />{' '}
              {isNewExpense ? 'Ta bort' : 'Makulera'}
            </MyTextButton>
          )}
        </Title>
      )}

      <Inputs removed={isRemoved}>
        <LabelWrap label="Namn">
          <Horizontal>
            <FormInput
              type="text"
              value={nameField.value}
              onChange={nameField.onChange}
              noEdit={isRemoved || noEdit}
            />

            <PriceListSelect
              customerId={Number(customerField.value)}
              onSelectPriceItem={handleSelectPriceItem}
            >
              <TextButton onClick={(e) => e.preventDefault()}>
                <FontAwesomeIcon icon={faListUl} />
              </TextButton>
            </PriceListSelect>
          </Horizontal>
        </LabelWrap>

        <LabelWrap label="Styckpris">
          <FormInput
            type="number"
            min={0}
            {...unitPriceField}
            noEdit={isRemoved || noEdit}
          />
        </LabelWrap>

        <LabelWrap label="Antal">
          <FormInput
            type="number"
            min={0}
            {...amountField}
            noEdit={isRemoved || noEdit}
          />
        </LabelWrap>
      </Inputs>
    </Wrapper>
  );
};

export default ExpenseFields;
