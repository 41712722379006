import { CaseJobClient } from 'api';
import Calendar from 'components/Calendar';
import useTimespanState, {
  TimespanMode,
} from 'pages/PlannerTool/useTimespanState';
import { FC } from 'react';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks';
import useQueryParam from 'utils/useQueryParam';
import CustomerCaseSlideIn from './CustomerCaseSlideIn';
import CustomerJobEventLine from './CustomerCaseEventLine';
import TimeNavigatorHeader from './TimeNavigatorHeader';
import useCustomerJobEvents from './useCustomerJobEvents';

const Horizontal = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Vertical = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const caseQueryParam = 'arende';

const CustomerJobsPage: FC = () => {
  const { bufferedFrom, bufferedTo, from, isDebouncingTimespan, setTimespan } =
    useTimespanState(TimespanMode.Month);
  const [selectedCaseIdStr, setSelectedCaseIdStr] =
    useQueryParam(caseQueryParam);
  const { customerCaseEvents, searchResults } = useCustomerJobEvents(
    bufferedFrom,
    bufferedTo
  );
  const selectedCaseId = Number(selectedCaseIdStr);

  const selectedJob = useResponse(
    CaseJobClient,
    async (c) => {
      if (isNaN(selectedCaseId)) return null;

      // Get casejob from search result if it exists there (quicker)
      const caseFromSearchResult = searchResults.response?.find(
        (sr) => sr.customerCase.id === selectedCaseId
      )?.customerCase;

      if (caseFromSearchResult) return caseFromSearchResult;

      return null;
    },
    [selectedCaseId, searchResults.response]
  );

  return (
    <Horizontal>
      <Vertical>
        <TimeNavigatorHeader
          onDateSelected={setTimespan}
          selectedDate={from}
          loading={
            isDebouncingTimespan ||
            searchResults.status === RequestStatus.Fetching
          }
        />

        <Calendar
          events={customerCaseEvents}
          renderEventLine={(eve, start, end) => (
            <CustomerJobEventLine
              containerStart={start}
              containerEnd={end}
              from={eve.from}
              to={eve.to}
              caseEvent={eve}
              caseEventClassName={`customer-case-event-${eve.caseId}`}
              selected={eve.caseId === selectedCaseId}
              onClick={(event) => setSelectedCaseIdStr(String(event.caseId))}
              key={eve.caseId}
            />
          )}
          showDate={from}
        />
      </Vertical>

      <CustomerCaseSlideIn
        openCase={selectedJob}
        onClose={() => setSelectedCaseIdStr(null)}
      />
    </Horizontal>
  );
};

export default CustomerJobsPage;
