import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvoiceClient, InvoiceType } from 'api';
import Button from 'components/inputs/Button';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks';
import {
  InvoiceTableProps,
  useInvoiceColumnSettings,
} from './createInvoiceColumnSetting';
import SelectInvoicesTable from './SelectInvoicesTable';
import { useInvoiceSelections } from './useInvoiceSelections';
import useZipDownloader from './useZipDownloader';

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface Props {
  title: string;
  invoiceType: InvoiceType;
}

const DownloadInvoices: FC<Props> = ({ title, invoiceType }) => {
  const queuedInvoices = useResponse(InvoiceClient, (c) =>
    c.getInvoicesAwaitingAdminDownload(invoiceType)
  );

  const invoiceHistory = useResponse(InvoiceClient, async (c) => {
    const to = new Date();
    const from = new Date(to.getFullYear() - 1, to.getMonth(), to.getDate());

    const invoiceHistory = await c.getInvoiceHistory(invoiceType, from, to);
    return invoiceHistory;
  });

  const selectedIdsInQueue = useInvoiceSelections(queuedInvoices);
  const selectedIdsInHistory = useInvoiceSelections(invoiceHistory);

  const allSelectedInvoices = useMemo(() => {
    const queued = selectedIdsInQueue.selectedInvoiceIds;
    const history = selectedIdsInHistory.selectedInvoiceIds;

    return new Set<number>([...queued, ...history]);
  }, [
    selectedIdsInQueue.selectedInvoiceIds,
    selectedIdsInHistory.selectedInvoiceIds,
  ]);

  const { downloadZipCall } = useZipDownloader();

  const downloadCheckedInvoicesOnClick = async (invoices: number[]) => {
    await downloadZipCall.run(invoices);

    selectedIdsInQueue.resetSelection();
    selectedIdsInHistory.resetSelection();

    queuedInvoices.update();
    invoiceHistory.update();
  };

  // Set columnsettings

  const columnSettingsForQueuedInvoices = useInvoiceColumnSettings(
    selectedIdsInQueue.handleToggleSelectAllInvoices,
    selectedIdsInQueue.hasSelectedAllInvoices,
    selectedIdsInQueue.hasSomeSelectedInvoices
  );

  const columnSettingsForInvoiceHistory = useInvoiceColumnSettings(
    selectedIdsInHistory.handleToggleSelectAllInvoices,
    selectedIdsInHistory.hasSelectedAllInvoices,
    selectedIdsInHistory.hasSomeSelectedInvoices
  );

  // Manage rendering

  const queuedRenderProps: InvoiceTableProps = {
    selectedInvoiceIds: selectedIdsInQueue.selectedInvoiceIds,
    onToggleSelectInvoice: selectedIdsInQueue.handleToggleOneInvoiceSelection,
  };

  const historyRenderProps: InvoiceTableProps = {
    selectedInvoiceIds: selectedIdsInHistory.selectedInvoiceIds,
    onToggleSelectInvoice: selectedIdsInHistory.handleToggleOneInvoiceSelection,
  };

  const renderInvoiceQueue = () => {
    switch (queuedInvoices.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        if (!queuedInvoices.response) return null;
        return (
          <SelectInvoicesTable
            tableHeader="Köade fakturor"
            invoices={queuedInvoices.response}
            columnSettings={columnSettingsForQueuedInvoices}
            renderProps={queuedRenderProps}
          />
        );
    }
  };

  const renderInvoiceHistory = () => {
    switch (invoiceHistory.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        if (!invoiceHistory.response) return null;
        return (
          <SelectInvoicesTable
            tableHeader="Nedladdade fakturor"
            invoices={invoiceHistory.response}
            columnSettings={columnSettingsForInvoiceHistory}
            renderProps={historyRenderProps}
          />
        );
    }
  };

  const disableButtons =
    queuedInvoices.status === RequestStatus.Fetching ||
    selectedIdsInQueue.selectedInvoiceIds.size +
      selectedIdsInHistory.selectedInvoiceIds.size ===
      0;

  return (
    <>
      <PageHeader title={title}>
        <HeaderRight>
          <Button
            small
            icon={<FontAwesomeIcon icon={faFileArrowDown} />}
            onClick={() =>
              downloadCheckedInvoicesOnClick(Array.from(allSelectedInvoices))
            }
            disabled={disableButtons}
          >
            Hämta fakturor
          </Button>
        </HeaderRight>
      </PageHeader>

      {renderInvoiceQueue()}
      {renderInvoiceHistory()}
    </>
  );
};

export default DownloadInvoices;
