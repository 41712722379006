import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';

import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as XmarkImg } from 'assets/xmark.svg';

import Button from '../inputs/Button';
import Theme from 'constants/theme';
import MediaQuery from 'constants/MediaQuery';

const fadeOutClassname = 'fade-out-modal';
const fadingTime = 0.2;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  background: ${Theme.colors.bg.opacityOverlay};

  &:not(.${fadeOutClassname}) {
    animation: ${fadeIn} ${fadingTime}s;
  }
  &.${fadeOutClassname} {
    animation: ${fadeIn} reverse ${fadingTime}s;
  }
`;

export const slideIn = keyframes`
0% {
  opacity: 0;
  transform: translateY(20px);
}
100% {
  opacity: 1;
  transform: translateY(0px);
}
`;
const ModalBody = styled.div<{ fullScreenOnPhones?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ fullScreenOnPhones }) =>
    fullScreenOnPhones
      ? css`
          width: 100%;
          height: 100%;

          ${MediaQuery.tablet} {
            width: auto;
            height: auto;
            max-height: 90vh;
            max-width: 90vw;
          }
        `
      : css`
          max-height: 90vh;
          max-width: 90vw;
        `}

  background: ${Theme.colors.bg.background1};

  &:not(.${fadeOutClassname}) {
    animation: ${slideIn} ${fadingTime}s;
  }
  &.${fadeOutClassname} {
    animation: ${slideIn} reverse ${fadingTime}s;
  }
`;

const ModalContent = styled.div<{ scrollContent?: boolean }>`
  flex: 1;
  padding: 20px;

  ${({ scrollContent }) =>
    scrollContent &&
    css`
      overflow: auto;
    `}
`;

const ModalTitleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalTitle = styled.h1`
  flex: 1;
  margin: 20px;
  margin-bottom: 0;
  font-size: ${Theme.sizes.font.large};
`;

const ModalTitleCloseButton = styled.button`
  align-self: flex-start;
  padding: 5px;
  margin: 20px;
  margin-bottom: 0;
  border: 0;
  font-size: 25px;
  background: transparent;
  color: inherit;
  cursor: pointer;
`;

const ModalButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 10px 20px 20px;
  gap: 10px;
`;

export const modalTitleClass = 'modal-title';
export const modalTitleCloseButtonClass = 'modal-title-close-button';
export const modalBodyClass = 'modal-body';
export const modalContentClass = 'modal-content';
export const modalButtonsClass = 'modal-buttons';

export interface ModalButton {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onClick?(eve: React.MouseEvent): void;
  disabled?: boolean;
}

interface Props extends PropsWithChildren {
  title?: ReactNode;
  buttons?: ModalButton[];
  onClose?(eve: React.MouseEvent): void;
  fullscreenOnPhones?: boolean;
  scrollContent?: boolean;
  className?: string;
}

const Modal: FC<Props> = ({
  title,
  buttons,
  onClose,
  fullscreenOnPhones,
  scrollContent,
  children,
  className,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    return () => {
      // Clone all of the DOM elements and let them fade out on unmount.
      const clonedModalDOM = overlayRef.current?.cloneNode(true);

      if (clonedModalDOM instanceof HTMLElement) {
        clonedModalDOM.classList.add(fadeOutClassname);
        clonedModalDOM
          .querySelector(`.${modalBodyClass}`)
          ?.classList.add(fadeOutClassname);
        document.body.appendChild(clonedModalDOM);

        setTimeout(() => {
          document.body.removeChild(clonedModalDOM);
        }, fadingTime * 900);
      }
    };
  }, []);

  return (
    <Overlay
      className={className}
      onClick={(eve) => {
        eve.stopPropagation();
        onClose?.(eve);
      }}
      ref={overlayRef}
    >
      <ModalBody
        onClick={(eve) => eve.stopPropagation()}
        fullScreenOnPhones={fullscreenOnPhones}
        className={modalBodyClass}
      >
        <ModalTitleRow>
          {title && (
            <ModalTitle className={modalTitleClass}>{title}</ModalTitle>
          )}
          {onClose && (
            <ModalTitleCloseButton
              className={modalTitleCloseButtonClass}
              onClick={onClose}
            >
              <XmarkImg width={20} height={20} />
            </ModalTitleCloseButton>
          )}
        </ModalTitleRow>

        <ModalContent
          scrollContent={scrollContent}
          className={modalContentClass}
        >
          {children}
        </ModalContent>

        {buttons && buttons.length > 0 && (
          <ModalButtons className={modalButtonsClass}>
            {buttons.map((button, i) => (
              <Button
                disabled={button.disabled}
                icon={button.icon}
                key={i}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}
          </ModalButtons>
        )}
      </ModalBody>
    </Overlay>
  );
};

export default Modal;
