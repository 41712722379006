import { AkCompany } from 'api';

export interface AkCompanyForm {
  isRemoved: boolean;
  organizationNumber: string;
  vat: string;
  contactPerson: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  zipCode: string;
  city: string;
  akCompanyOwnerId?: number | undefined;
  phoneNumber: string;
  email: string;
  bankgiro: string;
  plusgiro: string;
  invoiceReferenceIdPrefix: string;
}

export function createAkCompanyForm(base?: AkCompany): AkCompanyForm {
  if (base) {
    return {
      isRemoved: base.isRemoved,
      organizationNumber: base.organizationNumber,
      name: base.name,
      vat: base.vat,
      addressLine1: base.addressLine1,
      addressLine2: base.addressLine2,
      zipCode: base.zipCode,
      city: base.city,
      akCompanyOwnerId: base.akCompanyOwnerId,
      phoneNumber: base.phoneNumber,
      email: base.email,
      bankgiro: base.bankgiro,
      plusgiro: base.plusgiro,
      contactPerson: base.contactPerson,
      invoiceReferenceIdPrefix: base.invoiceReferenceIdPrefix,
    };
  }

  return {
    isRemoved: false,
    organizationNumber: '',
    name: '',
    vat: '',
    addressLine1: '',
    addressLine2: '',
    zipCode: '',
    city: '',
    akCompanyOwnerId: undefined,
    phoneNumber: '',
    email: '',
    bankgiro: '',
    contactPerson: '',
    invoiceReferenceIdPrefix: '',
    plusgiro: '',
  };
}

export function AkCompanyFormToAkCompany(
  form: AkCompanyForm,
  base?: AkCompany
): AkCompany {
  return new AkCompany({
    id: base?.id ?? 0,
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    createdById: base?.createdById ?? -1,
    modifiedById: base?.modifiedById ?? -1,
    timestamp: base?.timestamp ?? '',

    isRemoved: form.isRemoved,
    name: form.name,
    organizationNumber: form.organizationNumber,
    vat: form.vat,
    addressLine1: form.addressLine1,
    addressLine2: form.addressLine2,
    zipCode: form.zipCode,
    city: form.city,
    akCompanyOwnerId: form.akCompanyOwnerId,
    phoneNumber: form.phoneNumber,
    email: form.email,
    bankgiro: form.bankgiro,
    contactPerson: form.contactPerson,
    invoiceReferenceIdPrefix: form.invoiceReferenceIdPrefix,
    plusgiro: form.plusgiro,
  });
}
