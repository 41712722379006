import { IPriceList } from 'api';
import { useMemo } from 'react';
import { MaxDate } from 'utils/date';
import { makeDateComparator } from 'utils/sorting';

export interface PriceListWithActiveTo extends IPriceList {
  activeToUtc: Date;
}

const usePriceListsWithActiveTo = (priceLists: IPriceList[]) => {
  return useMemo(() => {
    const priceListsByCustomerId = new Map<number, IPriceList[]>();

    for (const pl of priceLists) {
      const priceLists = priceListsByCustomerId.get(pl.customerId);

      if (!priceLists) {
        priceListsByCustomerId.set(pl.customerId, [pl]);
        continue;
      }

      priceLists.push(pl);
    }

    return [...priceListsByCustomerId.values()]
      .map((priceLists) => {
        const priceListsWithActiveTo = priceLists
          .sort(makeDateComparator((pl) => pl.activeFromUtc))
          .map(
            (pl, index, priceLists): PriceListWithActiveTo => ({
              ...pl,
              activeToUtc: priceLists[index + 1]?.activeFromUtc ?? MaxDate,
            })
          );

        return priceListsWithActiveTo;
      })
      .flat()
      .sort((a, b) => a.activeFromUtc.getTime() - b.activeFromUtc.getTime());
  }, [priceLists]);
};

export default usePriceListsWithActiveTo;
