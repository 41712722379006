import { InvoiceClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC } from 'react';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks';
import useUpdateInterval from 'utils/useUpdateInterval';
import useInvoiceColumnSettings from './useInvoiceColumnSettings';
import usePdfDownloader from './usePdfDownloader';

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const InternalInvoicesPage: FC = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const pricingSetup = usePricingSetup();

  const invoicesResponse = useResponse(
    InvoiceClient,
    async (c) =>
      selectedAkCompanyId === null
        ? null
        : await c.getAkCompanyInternalInvoices(selectedAkCompanyId),
    [selectedAkCompanyId]
  );

  useUpdateInterval(
    () => {
      invoicesResponse.update();
    },
    10000,
    true
  );

  const { currentlyDownloading, downloadPdfCall } = usePdfDownloader();

  const columnSettings = useInvoiceColumnSettings((invoiceId) =>
    downloadPdfCall.run(invoiceId)
  );

  const renderContent = () => {
    switch (invoicesResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
      case RequestStatus.Fetched:
        if (!invoicesResponse.response) return <LoadingSection />;

        return (
          <MyTable
            columnSettings={columnSettings}
            rows={invoicesResponse.response}
            initialSortState={{ ascending: false, sortedColumnIndex: 0 }}
            renderProps={{ currentlyDownloading, pricingSetup }}
          />
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => invoicesResponse.update()} />;
    }
  };

  return (
    <>
      <PageHeader title="Skapade fakturor" />
      {renderContent()}
    </>
  );
};

export default InternalInvoicesPage;
