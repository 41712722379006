import { WebUser, CustomerUser, AkCompanyUser, AkCompanyRole } from 'api';

export interface FormAkCompanyMembership {
  akCompanyId: number;
  companyRoles: AkCompanyRole[];
}

export interface UserFormInputs {
  userName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  roles: number[];
  akCompanyMemberships: FormAkCompanyMembership[];
  customerMemberships: { customerId: number }[];
  deactivated: boolean;
}

export function createUserForm(webUser?: WebUser): UserFormInputs {
  if (webUser) {
    return {
      userName: webUser.userName,
      email: webUser.email,
      phoneNumber: webUser.phoneNumber,
      fullName: webUser.fullName,
      roles: [...webUser.roles],
      akCompanyMemberships: Array.from(
        webUser.akCompanyMemberships
          .reduce((acc, curr) => {
            let company = acc.get(curr.akCompanyId);
            if (!company) {
              company = {
                akCompanyId: curr.akCompanyId,
                companyRoles: [],
              };
              acc.set(curr.akCompanyId, company);
            }

            company.companyRoles.push(curr.companyRole);
            return acc;
          }, new Map<number, FormAkCompanyMembership>())
          .values()
      ),
      customerMemberships: webUser.customerMemberships.map((m) => ({
        customerId: m.customerId,
      })),
      deactivated: webUser.deactivated,
    };
  }

  return {
    userName: '',
    email: '',
    phoneNumber: '',
    fullName: '',
    roles: [],
    akCompanyMemberships: [],
    customerMemberships: [],
    deactivated: false,
  };
}

export function userFormToWebUser(
  formInputs: UserFormInputs,
  base?: WebUser
): WebUser {
  return new WebUser({
    id: base?.id ?? 0,
    userName: formInputs.userName,
    email: formInputs.email,
    phoneNumber: formInputs.phoneNumber,
    fullName: formInputs.fullName,
    roles: formInputs.roles,
    akCompanyMemberships: formInputs.akCompanyMemberships
      .map((membership) =>
        membership.companyRoles.map(
          (role) =>
            new AkCompanyUser({
              userId: 0, // NOTE: userId will be replaced with the correct one in backend
              akCompanyId: membership.akCompanyId,
              companyRole: role,
            })
        )
      )
      .flat(),
    // Frontend will give the illusion that a user only can be assigned to one Customer at a time.
    customerMemberships: formInputs.customerMemberships[0]
      ? [
          new CustomerUser({
            // NOTE: userId will be replaced with the correct one in backend
            userId: 0,
            customerId: formInputs.customerMemberships[0].customerId,
          }),
        ]
      : [],
    deactivated: formInputs.deactivated,
    emailConfirmed: base?.emailConfirmed ?? false,
  });
}
