import {
  faEdit,
  faInfoCircle,
  faPlus,
  faSave,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Customer,
  IPriceList,
  PriceList,
  PriceListClient,
  PriceListType,
} from 'api';
import FormErrorList from 'components/FormErrorList';
import Button, { FABStyle } from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import TextButton from 'components/inputs/TextButton';
import { makeActivatableMediaQuery } from 'components/mediaQueries/ActivatableMediaQuery';
import OverlayedSpinner from 'components/spinners/OverlayedSpinner';
import Tabstrip from 'components/Tabstrip';
import MediaQuery from 'constants/MediaQuery';
import WebRoleIds from 'constants/RoleIds';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import usePricingSetup, {
  useUpdatePricingSetupCall,
} from 'contexts/usePricingSetup';
import React, { FC, useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { getAllEnumValues } from 'utils/enum';
import { createPriceItemForm } from '../formUtils/priceItemFormConverters';
import {
  createPriceListForm,
  PriceListFormInputs,
  priceListFormToPriceList,
} from '../formUtils/priceListFormConverters';
import ConfirmEditPriceListModal from './ConfirmEditPriceListModal';
import PriceAdjustments from './PriceAdjustments';
import PriceItemsTable from './PriceItemsTable';
import PriceListDateSpan from './PriceListDateSpan';
import PriceListUpdateInfoModal from './PriceListUpdateInfoModal';

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MyTabStrip = styled(Tabstrip)`
  ${MediaQuery.tablet} {
    display: none;
  }
`;
const TabletVisible = styled.div<{ alwaysVisible: boolean }>`
  display: none;
  ${({ alwaysVisible }) =>
    alwaysVisible
      ? css`
          display: contents;
        `
      : makeActivatableMediaQuery(
          'tablet',
          css`
            display: contents;
          `
        )}
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, auto));
  flex-direction: row;
  gap: 10px 20px;
  padding: 20px ${Theme.sizes.padding.screenInset};

  & > * {
    flex: 1;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const TableWrap = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid ${Theme.colors.border.main};
  border-top: 1px solid ${Theme.colors.border.main};
`;
const FabButton = styled(Button)`
  ${FABStyle}
  position: absolute;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px ${Theme.sizes.padding.screenInset};
  border-top: 1px solid ${Theme.colors.border.main};
`;

const InfoLine = styled.div`
  margin-bottom: 10px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 10px;
`;

const StyledFormErrorList = styled(FormErrorList)`
  margin: 0;
  margin-right: auto;
  width: 100%;

  ${MediaQuery.tablet} {
    width: auto;
  }
`;

enum Tabs {
  General,
  Prices,
  PriceAdjustments,
}

interface Props {
  priceList: IPriceList | undefined;
  defaultCustomerId?: number;
  saveAsNewPricelist: boolean;
  priceListsToCompareTo: IPriceList[];
  onIsDirtyChanged?(isDirty: boolean): void;
  onPriceListSaved(priceList: IPriceList): void;
}

const PriceListEditor: FC<Props> = ({
  priceList,
  defaultCustomerId,
  saveAsNewPricelist,
  priceListsToCompareTo,
  onIsDirtyChanged,
  onPriceListSaved,
}) => {
  const [selectedTab, setSelectedTab] = useState(Tabs.General);
  const modalStack = useModalStack();
  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const userWebClaims = useUserWebClaims();

  const {
    control,
    setValue,
    getValues,
    register,
    handleSubmit,
    formState,
    reset,
    trigger,
    watch,
  } = useForm({
    defaultValues: createPriceListForm(priceList, defaultCustomerId),
    mode: 'onChange',
  });
  const priceItemsFieldArray = useFieldArray({
    control: control,
    name: 'priceItems',
  });
  const isDirty = formState.isDirty;
  const isValid = formState.isValid;
  const formErrors = formState.errors;

  const customerId = watch('customerId');
  const priceListCustomer = pricingSetup.customersById.get(customerId);

  const userHasPricelistOwnership =
    !priceListCustomer || priceListCustomer.akCompanyId === selectedAkCompanyId;
  const hasBeenActivated =
    (!saveAsNewPricelist &&
      priceList &&
      priceList.activeFromUtc <= new Date()) ||
    false;
  const [editOverride, setEditOverride] = useState(false);

  const editMode =
    userHasPricelistOwnership && (!hasBeenActivated || editOverride);

  const savePriceListCall = useApiCall(
    PriceListClient,
    (c, priceList: PriceList) => c.savePriceList(priceList)
  );
  const updatePricingSetupCall = useUpdatePricingSetupCall();

  // Reset form to 'priceList' prop when it changes
  useEffect(() => {
    const priceListCustomer = pricingSetup.customersById.get(
      priceList?.customerId ?? -1
    );

    if (
      !userWebClaims.webRoleIds.has(WebRoleIds.admin) &&
      saveAsNewPricelist &&
      priceListCustomer?.akCompanyId !== selectedAkCompanyId
    ) {
      // Set customer to -1 (none selected) if that customer isn't owned by selected AkCompany
      reset({ ...createPriceListForm(priceList), customerId: -1 });
    } else {
      reset(createPriceListForm(priceList, defaultCustomerId));
    }

    setEditOverride(false);

    setTimeout(() => {
      trigger(['customerId', 'activeFromUtc']);
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceList, saveAsNewPricelist]);

  useEffect(() => {
    onIsDirtyChanged?.(isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const handleAddPriceItem = (eve: React.MouseEvent) => {
    eve.preventDefault();
    priceItemsFieldArray.append(createPriceItemForm());
  };

  const submitHandler: SubmitHandler<PriceListFormInputs> = async (
    priceListFormInputs
  ) => {
    const [updatedPriceList, error] = await savePriceListCall.run(
      priceListFormToPriceList(
        priceListFormInputs,
        saveAsNewPricelist ? undefined : priceList,
        userWebClaims
      )
    );

    if (updatedPriceList && !error) {
      reset(createPriceListForm(updatedPriceList));
      await updatePricingSetupCall.run();
      onPriceListSaved(updatedPriceList);
    }
  };

  return (
    <Form onSubmit={handleSubmit(submitHandler)}>
      <MyTabStrip
        activeTab={selectedTab}
        tabs={[
          {
            label: 'Prislista',
            onClick: () => setSelectedTab(Tabs.General),
          },
          {
            label: 'Priser',
            onClick: () => setSelectedTab(Tabs.Prices),
          },
          {
            label: 'Prisjusteringar',
            onClick: () => setSelectedTab(Tabs.PriceAdjustments),
          },
        ]}
      />

      <TabletVisible alwaysVisible={selectedTab === Tabs.General}>
        <Inputs>
          <LabelWrap label="Listnamn">
            <Input {...register('name')} disabled={!editMode} />
          </LabelWrap>
          <LabelWrap label="Kund">
            {editMode ? (
              <Select
                {...register('customerId', {
                  valueAsNumber: true,
                  validate: (v) => (v === -1 ? 'Ange kund' : undefined),
                  onChange: () => trigger('activeFromUtc'),
                })}
              >
                <option value={-1} disabled>
                  Välj kund
                </option>

                {pricingSetup.customerIdsByAkCompanyId
                  .get(selectedAkCompanyId ?? -1)
                  ?.map((customerId) =>
                    pricingSetup.customersById.get(customerId)
                  )
                  .filter(
                    (customer): customer is Customer =>
                      !!customer &&
                      customer.akCompanyId === selectedAkCompanyId &&
                      !customer.isRemoved
                  )
                  .map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
              </Select>
            ) : (
              // Disabling the <Select> above will cause react-hook-form to treat its
              // value as undefined for some reason, so I use this disabled <Input> instead.
              <Input value={priceListCustomer?.name} readOnly disabled />
            )}
          </LabelWrap>

          <PriceListDateSpan
            control={control}
            getValues={getValues}
            trigger={trigger}
            disabled={!editMode}
          />

          {userWebClaims.webRoleIds.has(WebRoleIds.admin) && (
            <LabelWrap label="Typ">
              <Select
                {...register('priceListType', {
                  valueAsNumber: true,
                  disabled: !editMode,
                })}
              >
                {getAllEnumValues(PriceListType).map((plt) => (
                  <option key={plt} value={plt}>
                    {basicData.priceListTypeNames.get(plt)}
                  </option>
                ))}
              </Select>
            </LabelWrap>
          )}
        </Inputs>
      </TabletVisible>

      <MainContent>
        <TabletVisible alwaysVisible={selectedTab === Tabs.Prices}>
          <TableWrap>
            <PriceItemsTable
              fieldArray={priceItemsFieldArray}
              register={register}
              control={control}
              setValue={setValue}
              getValues={getValues}
              editMode={editMode}
            />

            {editMode && (
              <FabButton
                icon={<FontAwesomeIcon icon={faPlus} />}
                small
                onClick={handleAddPriceItem}
              >
                Nytt pris
              </FabButton>
            )}
          </TableWrap>
        </TabletVisible>

        <TabletVisible alwaysVisible={selectedTab === Tabs.PriceAdjustments}>
          <PriceAdjustments
            register={register}
            control={control}
            editMode={editMode}
          />
        </TabletVisible>
      </MainContent>

      {editMode ? (
        <BottomRow>
          <InfoLine>
            <FontAwesomeIcon icon={faInfoCircle} /> Kom ihåg att ev. uppdatera
            ärenden som använder denna prislista.{' '}
            <TextButton
              onClick={(eve) => {
                eve.preventDefault();
                const modalId = modalStack.push(
                  <PriceListUpdateInfoModal
                    onClose={() => modalStack.pop(modalId)}
                  />
                );
              }}
            >
              Läs mer
            </TextButton>
          </InfoLine>

          <Actions>
            <StyledFormErrorList errors={formErrors} />

            {isDirty && (
              <Button
                onClick={(eve) => {
                  eve.preventDefault();
                  reset();
                }}
                icon={<FontAwesomeIcon icon={faUndo} />}
              >
                Ångra ändringar
              </Button>
            )}
            <Button
              disabled={!isDirty || !isValid}
              icon={<FontAwesomeIcon icon={faSave} />}
            >
              Spara
            </Button>
          </Actions>
        </BottomRow>
      ) : (
        <BottomRow>
          {hasBeenActivated && userHasPricelistOwnership && (
            <Actions>
              <Button
                icon={<FontAwesomeIcon icon={faEdit} />}
                onClick={(eve) => {
                  eve.preventDefault();

                  const modalId = modalStack.push(
                    <ConfirmEditPriceListModal
                      onConfirm={() => {
                        setEditOverride(true);
                        modalStack.pop(modalId);
                      }}
                      onClose={() => modalStack.pop(modalId)}
                    />
                  );
                }}
              >
                Redigera
              </Button>
            </Actions>
          )}
        </BottomRow>
      )}

      {savePriceListCall.status === RequestStatus.Fetching && (
        <OverlayedSpinner>Sparar..</OverlayedSpinner>
      )}
    </Form>
  );
};

export default PriceListEditor;
