import useBasicData from 'contexts/useBasicData';
import usePricingSetup from 'contexts/usePricingSetup';
import useSelectedCustomerId, {
  useCustomerHasCentralAgreement,
} from 'contexts/useSelectedCustomer';
import { useMemo } from 'react';

const useCustomerSelectableAkCompanies = () => {
  const { hQ_AkCompanyId } = useBasicData();
  const selectedCustomerId = useSelectedCustomerId();
  const { akCompaniesById, akCompanyIdsByCustomerId } = usePricingSetup();
  const isCentralAgreementCustomer = useCustomerHasCentralAgreement();

  return useMemo(() => {
    // Central agreement customers may choose any existing company
    if (isCentralAgreementCustomer)
      return (
        Array.from(akCompaniesById)
          .filter(([, company]) => company.id !== hQ_AkCompanyId)
          .map(([, company]) => ({
            value: company.id,
            label: company.name,
          })) ?? []
      );

    // Regular customers may only choose between its stations
    return (
      akCompanyIdsByCustomerId
        .get(selectedCustomerId ?? -1)
        ?.filter((companyId) => companyId !== hQ_AkCompanyId)
        .map((akCompanyId) => ({
          value: akCompanyId,
          label: akCompaniesById.get(akCompanyId)?.name ?? '',
        })) ?? []
    );
  }, [
    akCompaniesById,
    akCompanyIdsByCustomerId,
    hQ_AkCompanyId,
    isCentralAgreementCustomer,
    selectedCustomerId,
  ]);
};

export default useCustomerSelectableAkCompanies;
