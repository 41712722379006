import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import Theme from 'constants/theme';
import { JobFormIndexes } from 'pages/PlannerTool/useCaseJobEvents';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { getVerticalScrollParent } from 'utils/dom';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import { createNewJobGroupFormInputs } from '../formUtils/caseJobFormConverters';
import CaseJobGroupFields from './CaseJobGroupFields';

const makeJobGroupClassName = (groupIndex: number) => `job-group-${groupIndex}`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding-bottom: 20px;
`;

const AddButton = styled(Button)`
  margin: 10px ${Theme.sizes.padding.screenInset};
`;

interface Props {
  form: UseFormReturn<CaseFormInputs>;
  highlightJobIndex?: JobFormIndexes;
  onShowJobInCalendarClick?(jobIndex: JobFormIndexes): void;
  noEdit: boolean;
}

const CaseJobs: FC<Props> = ({
  form,
  highlightJobIndex,
  onShowJobInCalendarClick,
  noEdit,
}) => {
  const { control: formControl, getValues } = form;

  const {
    fields: jobGroupFieldArray,
    append,
    remove,
  } = useFieldArray({
    control: formControl,
    name: 'jobGroups',
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const sortedJobGroupFieldArray = useMemo(
    () => jobGroupFieldArray.map((f, i) => [f, i] as const),
    [jobGroupFieldArray]
  );

  // Automatically scroll to active caseJob (highlightCaseJobIndex)
  useEffect(() => {
    if (!wrapperRef.current || highlightJobIndex === undefined) return;

    const jobFieldsElement = wrapperRef.current
      .getElementsByClassName(
        makeJobGroupClassName(highlightJobIndex.jobGroupFormIndex)
      )
      .item(0);

    jobFieldsElement?.scrollIntoView({ behavior: 'smooth' });
  }, [highlightJobIndex]);

  const handleAppendJobGroup = (eve: React.MouseEvent) => {
    eve.preventDefault();

    const group = createNewJobGroupFormInputs();
    group.orderedStartUtc = getValues(
      `jobGroups.${sortedJobGroupFieldArray.length - 1}.orderedStartUtc`
    );
    group.orderedEndUtc = getValues(
      `jobGroups.${sortedJobGroupFieldArray.length - 1}.orderedEndUtc`
    );

    append(group);

    // Scroll down to bottom after the next render.
    setTimeout(() => {
      const scrollParent = getVerticalScrollParent(wrapperRef.current);
      scrollParent?.scrollTo({
        top: scrollParent.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  return (
    <Wrapper ref={wrapperRef}>
      {sortedJobGroupFieldArray.map(([field, jobGroupFormIndex]) => (
        <CaseJobGroupFields
          key={field.id}
          form={form}
          onDelete={() => remove(jobGroupFormIndex)}
          groupFormIndex={jobGroupFormIndex}
          highlightedIndex={highlightJobIndex}
          noEdit={noEdit}
          onShowInCalendarClick={
            onShowJobInCalendarClick
              ? (jobInstanceFormIndex) =>
                  onShowJobInCalendarClick({
                    jobGroupFormIndex,
                    jobInstanceFormIndex,
                  })
              : undefined
          }
          className={makeJobGroupClassName(jobGroupFormIndex)}
        />
      ))}

      {!noEdit && (
        <AddButton
          onClick={handleAppendJobGroup}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          Lägg till jobb
        </AddButton>
      )}
    </Wrapper>
  );
};

export default CaseJobs;
