export function getVerticalScrollParent(node: Element | null): Element | null {
  return (
    node &&
    (node.scrollHeight > node.clientHeight
      ? node
      : getVerticalScrollParent(node.parentElement))
  );
}

export function getHorisontalScrollParent(
  node: Element | null
): Element | null {
  return (
    node &&
    (node.scrollWidth > node.clientWidth
      ? node
      : getHorisontalScrollParent(node.parentElement))
  );
}

/**
 * Creates a proxy for a html element, where you just need to provide the setter.
 * Useful if you just want to listen to when the value property of an input element is set,
 * because an event isn't dispatched (react-hook-form reset() does this for example).
 */
export function createElementSetterProxy<T extends HTMLElement>(
  element: T,
  setter: (target: T, p: string | symbol, value: any, receiver: any) => boolean
) {
  return new Proxy(element, {
    get(target, key) {
      const value = (element as any)[key];
      if (value instanceof Function) {
        return value.bind(element);
      } else if (key === 'target') {
        return target;
      } else {
        return value;
      }
    },
    set: setter,
  });
}
