import { IPriceList, PriceListType } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import usePricingSetup from 'contexts/usePricingSetup';
import { useMemo } from 'react';

const useFilteredPriceLists = <PL extends IPriceList>(
  priceLists: PL[],
  filterCustomerId: number
): PL[] => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();

  return useMemo(
    () =>
      priceLists
        .filter((pl) => {
          const activeFromFilter =
            isNaN(filterCustomerId) || pl.customerId === filterCustomerId;

          const customer = pricingSetup.customersById.get(pl.customerId);
          const isFromSelectedAkCompany =
            customer?.akCompanyId === selectedAkCompanyId;
          const isFromHQCompany =
            customer?.akCompanyId === basicData.hQ_AkCompanyId;

          const isInternal =
            pl.priceListType === PriceListType.Internal ||
            pl.priceListType === PriceListType.InternalAndExternal;

          return (
            activeFromFilter &&
            (isFromSelectedAkCompany || (isFromHQCompany && isInternal))
          );
        })
        .sort((a, b) => a.activeFromUtc.getTime() - b.activeFromUtc.getTime()),
    [
      basicData.hQ_AkCompanyId,
      priceLists,
      pricingSetup.customersById,
      selectedAkCompanyId,
      filterCustomerId,
    ]
  );
};

export default useFilteredPriceLists;
