import { Case, CaseStatus } from 'api';
import InvoiceCheck from 'assets/InvoiceCheck';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px ${Theme.sizes.padding.screenInset};
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 5px 20px;

  border-radius: 1000px;
  font-size: ${Theme.sizes.font.medium};
  color: ${Theme.colors.fg.background2};
  border: 2px solid ${Theme.colors.border.main};

  svg {
    font-size: 1.2em;
  }
`;

interface Props {
  caseModel: Case;
}

const CaseStatusDisplay: FC<Props> = ({ caseModel }) => {
  if (caseModel?.caseStatus === CaseStatus.InvoicesCompleted)
    return (
      <Wrapper>
        <Inner>
          <InvoiceCheck />
          Fakturerad
        </Inner>
      </Wrapper>
    );

  return null;
};

export default CaseStatusDisplay;
