import selectArrowDark from 'assets/selectarrow-dark.svg';
import logoLight from 'assets/logo-light.svg';

/** This is the base theme, optimized for the smallest screens,
 * and all larger screen sizes can overwrite values. */
const phoneSmallTheme = {
  colors: {
    bg: {
      accent1: 'rgb(226, 4, 19)',
      background1: 'rgb(255, 255, 255)',
      background2: 'rgb(239, 239, 239)',
      background3: 'rgb(208, 208, 208)',
      background4: 'rgb(150, 150, 150)',
      input: 'rgb(255,255,255)',
      selection: '#1E90FF',
      opacityOverlay: 'rgba(0,0,0,0.3)',
    },
    fg: {
      accent1: 'rgb(255, 255, 255)',
      background1: 'rgb(0,0,0)',
      background2: 'rgb(0,0,0)',
      background3: 'rgb(0,0,0)',
      background4: '#fff',
      input: 'rgb(0,0,0)',
      selection: '#fff',
      opacityOverlay: '#fff',
    },
    border: {
      main: '#ddd',
      input: '#bbb',
    },
    shadow: {
      dropdown: '0 0 10px rgba(0,0,0,0.2)',
    },

    good: '#5f0',
    warn: '#f90',
    bad: '#f00',
  },

  images: {
    logo: `url('${logoLight}')`,
    selectArrow: `url('${selectArrowDark}')`,
  },

  sizes: {
    padding: {
      screenInset: 10,
    },
    font: {
      huge: '25px',
      large: '20px',
      medium: '17px',
      small: '12px',
    },
  },
} as const;

export default phoneSmallTheme;
