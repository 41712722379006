import Theme from 'constants/theme';
import styled from 'styled-components';

const PillButton = styled.button`
  padding: 1px 10px;
  border-radius: 1000px;
  border: 1px solid ${Theme.colors.bg.accent1};
  font: inherit;
  font-weight: 600;
  color: ${Theme.colors.bg.accent1};
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${Theme.colors.bg.accent1};
    color: ${Theme.colors.fg.accent1};
  }
`;

export default PillButton;
