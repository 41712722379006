import { InvoiceType } from 'api';
import { FC } from 'react';
import DownloadInvoices from './DownloadInvoices';

const FeeInvoicesQueuePage: FC = () => {
  return (
    <>
      <DownloadInvoices
        title={'Avgiftsunderlag'}
        invoiceType={InvoiceType.InternalFee}
      />
    </>
  );
};

export default FeeInvoicesQueuePage;
