import { CaseAttachment } from 'api';
import { useMemo, useState } from 'react';

const useAttachmentsEditorState = (attachments: CaseAttachment[]) => {
  const [savedAttachments, setSavedAttachments] = useState(attachments);
  const [queuedFiles, setQueuedFiles] = useState<File[]>([]);

  const removeSaved = (attachmentId: number) => {
    setSavedAttachments((ca) =>
      ca.map((a) =>
        a.id === attachmentId
          ? new CaseAttachment({
              ...a,
              isRemoved: true,
            })
          : a
      )
    );
  };

  const pickFile = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = true;

    fileInput.onchange = (eve) => {
      if (!(eve.target instanceof HTMLInputElement)) return;

      const files = Array.from(eve.target.files ?? []);
      setQueuedFiles((qf) => [...qf, ...files]);
    };

    fileInput.click();
  };

  const removeQueuedFile = (file: File) => {
    setQueuedFiles((qf) => qf.filter((f) => f !== file));
  };

  const reset = (attachments: CaseAttachment[]) => {
    setSavedAttachments(attachments);
    setQueuedFiles([]);
  };

  return useMemo(
    () => ({
      savedAttachments,
      queuedFiles,

      removeSaved,
      pickFile,
      removeQueuedFile,
      reset,
    }),
    [savedAttachments, queuedFiles]
  );
};

export type AttachmentState = ReturnType<typeof useAttachmentsEditorState>;

export default useAttachmentsEditorState;
