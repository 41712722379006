import { CaseClient, CustomerCase } from 'api';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import usePricingSetup from 'contexts/usePricingSetup';
import useSelectedCustomerId, {
  useCustomerHasCentralAgreement,
} from 'contexts/useSelectedCustomer';
import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { caseQueryParam } from '../JobsPage';
import CreateCaseInfo from './CreateCaseInfo';
import CustomerCaseEditor from './CustomerCaseEditor';
import {
  customerCaseFormToCustomerCase,
  createCustomerCaseForm,
  CustomerCaseFormInputs,
} from './CustomerCaseEditor/formUtils/caseFormConverters';
import useCustomerSelectableAkCompanies from './CustomerCaseEditor/formUtils/useSelectableAkCompanies';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const MyHeader = styled(PageHeader)`
  position: sticky;
  top: 0;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  background-color: ${Theme.colors.bg.background1};

  @media only screen and (max-width: 800px) {
    width: auto;
    margin: 0;
  }
`;

const CreateCustomerCasePage: FC = () => {
  const selectedCustomerId = useSelectedCustomerId();
  const { akCompaniesById } = usePricingSetup();
  const selectableAkCompanues = useCustomerSelectableAkCompanies();
  const isCentralAgreementCustomer = useCustomerHasCentralAgreement();

  const form = useForm({
    defaultValues: createCustomerCaseForm(),
    mode: 'all',
  });
  const navigate = useNavigate();
  const formAkCompanyId = form.watch('akCompanyId');

  const defaultAkCompanyId = selectableAkCompanues?.[0]?.value;

  // Automatically choose default akCompany if customer doesn't have central agreement.
  // (non-central customers doesn't get to pick between akCompanies)
  // Inside an effect because akCompanyIdsByCustomerId loads async.
  useEffect(() => {
    if (
      !isCentralAgreementCustomer &&
      defaultAkCompanyId !== undefined &&
      form.getValues('akCompanyId') === -1
    ) {
      form.setValue('akCompanyId', defaultAkCompanyId);
    }
  }, [defaultAkCompanyId, isCentralAgreementCustomer]);

  const createCustomerCaseCall = useApiCall(
    CaseClient,
    (client, customerCase: CustomerCase) =>
      client.createCustomerCase(customerCase)
  );

  if (selectedCustomerId === null)
    return <b>Ditt konto är inte kopplat till någon kund. Kontakta support.</b>;

  if (defaultAkCompanyId === undefined)
    return <b>Din organisation har ingen station kopplad till sig.</b>;

  const handleSubmit: SubmitHandler<CustomerCaseFormInputs> = async (
    formValues
  ) => {
    const [createdCaseResponse, error] = await createCustomerCaseCall.run(
      customerCaseFormToCustomerCase(formValues, selectedCustomerId)
    );

    if (!error && createdCaseResponse) {
      navigate(
        `${Routes.customerPortal.bookedCases.index}?${caseQueryParam}=${createdCaseResponse.id}`
      );
    }
  };

  return (
    <Wrapper>
      {createCustomerCaseCall.status === RequestStatus.Fetching ? (
        <LoadingSection />
      ) : (
        <>
          <MyHeader
            title={
              formAkCompanyId === -1
                ? 'Skapa nytt ärende'
                : `Skicka ett nytt ärende till ${
                    akCompaniesById.get(formAkCompanyId)?.name
                  }`
            }
          />

          <CreateCaseInfo />

          <CustomerCaseEditor form={form} submitHandler={handleSubmit} />
        </>
      )}
    </Wrapper>
  );
};

export default CreateCustomerCasePage;
