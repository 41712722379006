import { Customer } from 'api';
import Color from 'color';

export interface CustomerForm {
  isRemoved: boolean;
  name: string;
  contactPerson: string;
  organizationNumber: string;
  vat: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  zipCode: string;
  invoiceEmail: string;
  requireExternalReference: boolean;
  calendarColor: string;
  invoiceNrOfPaymentDays: number;
}

function randomColor() {
  return Color.hsv(Math.random() * 360, 100, Math.random() * 20 + 80).hex();
}

export function createCustomerForm(customer?: Customer): CustomerForm {
  if (customer) {
    return {
      isRemoved: customer.isRemoved,
      name: customer.name,
      contactPerson: customer.contactPerson,
      organizationNumber: customer.organizationNumber,
      vat: customer.vat,
      addressLine1: customer.addressLine1,
      addressLine2: customer.addressLine2,
      city: customer.city,
      zipCode: customer.zipCode,
      invoiceEmail: customer.invoiceEmail,
      requireExternalReference: customer.requireExternalReference,
      calendarColor: customer.calendarColor,
      invoiceNrOfPaymentDays: customer.invoiceNrOfPaymentDays,
    };
  }

  return {
    isRemoved: false,
    name: '',
    contactPerson: '',
    organizationNumber: '',
    vat: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zipCode: '',
    invoiceEmail: '',
    requireExternalReference: false,
    calendarColor: randomColor(),
    invoiceNrOfPaymentDays: 30,
  };
}

export function customerFormToCustomer(
  form: CustomerForm,
  akCompanyId: number,
  base?: Customer
): Customer {
  return new Customer({
    id: base?.id ?? 0,
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    createdById: base?.createdById ?? -1,
    modifiedById: base?.modifiedById ?? -1,
    timestamp: base?.timestamp ?? '',
    akCompanyId: base?.akCompanyId ?? akCompanyId,

    isRemoved: form.isRemoved,
    name: form.name,
    contactPerson: form.contactPerson,
    organizationNumber: form.organizationNumber,
    vat: form.vat,
    addressLine1: form.addressLine1,
    addressLine2: form.addressLine2,
    city: form.city,
    zipCode: form.zipCode,
    invoiceEmail: form.invoiceEmail,
    requireExternalReference: form.requireExternalReference,
    calendarColor: form.calendarColor,
    invoiceNrOfPaymentDays: form.invoiceNrOfPaymentDays,
  });
}
