import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LabelWrap from 'components/inputs/LabelWrap';
import React, { FC } from 'react';
import {
  Controller,
  RegisterOptions,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import InputGrid, { FormInput, FullWitdhCell } from '../../components';
import {
  JobGroupFormInputs,
  JobInstanceFormInputs,
} from '../../formUtils/caseJobFormConverters';
import { CaseFormInputs } from '../../formUtils/caseFormConverters';
import Checkbox from 'components/inputs/Checkbox';
import VehicleInput from './VehicleInput';
import UserInput from './UserInput';
import Theme from 'constants/theme';
import { JobStatus, JobType } from 'api';
import useModalStack from 'contexts/useModalStack';
import InvoicingTimeInfoModal from '../InovicingTimeInfoModal';
import { conformRegNr } from 'utils/string';
import { humanReadableTimeDurationBetweenDates } from 'utils/date';
import CaseJobInstanceAccordion from './CaseJobInstanceAccordion';

const MyInputGrid = styled(InputGrid)`
  margin: 0;
  padding: 5px calc(${Theme.sizes.padding.screenInset} / 2);
  padding-bottom: 20px;
`;

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const InfoButton = styled.button`
  margin: 0;
  padding: 0;
  font: inherit;
  font-weight: 600;
  background: transparent;
  border: none;
  color: ${Theme.colors.bg.selection};
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-left: 5px;
  }
`;

interface Props {
  form: UseFormReturn<CaseFormInputs>;
  /** Removes this CaseJobFields section from the form. Can only be called for CaseJobs that hasn't been saved */
  onDelete(): void;

  jobGroupFormIndex: number;
  jobInstanceFormIndex: number;
  noEdit: boolean;
  className?: string;
}

const CaseJobInstanceFields: FC<Props> = ({
  form,
  onDelete,
  jobGroupFormIndex,
  jobInstanceFormIndex,
  noEdit,
  className,
}) => {
  const { control, register, getValues, setValue } = form;
  const modalStack = useModalStack();

  const jobGroupFieldPath = <P extends keyof JobGroupFormInputs>(
    jobGroupProp: P
  ) => `jobGroups.${jobGroupFormIndex}.${jobGroupProp}` as const;

  const jobInstanceFieldPath = <P extends keyof JobInstanceFormInputs>(
    jobInstanceProp: P
  ) =>
    `jobGroups.${jobGroupFormIndex}.jobInstances.${jobInstanceFormIndex}.${jobInstanceProp}` as const;

  const [
    jobType,
    jobStatus,
    useActualStartEnd,
    actualStartDate,
    actualEndDate,
  ] = useWatch({
    control,
    name: [
      jobGroupFieldPath('jobType'),
      jobInstanceFieldPath('jobStatus'),
      jobInstanceFieldPath('useActualStartEnd'),
      jobInstanceFieldPath('actualStartUtc'),
      jobInstanceFieldPath('actualEndUtc'),
    ],
  });

  const jobIsCanceled = [JobStatus.Discarded, JobStatus.LateCancel].includes(
    jobStatus
  );
  const isEditable = !jobIsCanceled && !noEdit;

  const mkField = (
    fieldName: keyof JobInstanceFormInputs,
    label: string,
    type: React.HTMLInputTypeAttribute = 'text',
    registerOptions?: RegisterOptions,
    forminputProps?: React.HTMLProps<HTMLInputElement>
  ) => (
    <LabelWrap label={label}>
      <FormInput
        type={type}
        {...register(jobInstanceFieldPath(fieldName), registerOptions)}
        noEdit={!isEditable}
        {...forminputProps}
        as={undefined}
      />
    </LabelWrap>
  );

  return (
    <CaseJobInstanceAccordion
      form={form}
      jobGroupFormIndex={jobGroupFormIndex}
      jobInstanceFormIndex={jobInstanceFormIndex}
      onSetJobInstanceStatus={(status) =>
        form.setValue(jobInstanceFieldPath('jobStatus'), status)
      }
      noEdit={noEdit}
      onDeleteFormSection={onDelete}
      className={className}
    >
      <MyInputGrid>
        <LabelWrap label="Förarnamn">
          <Controller
            name={jobInstanceFieldPath('driverName')}
            control={control}
            render={({ field }) => (
              <UserInput
                value={field.value}
                onChange={field.onChange}
                noEdit={!isEditable}
              />
            )}
          />
        </LabelWrap>

        <LabelWrap label="Registreringsnummer">
          <Controller
            name={jobInstanceFieldPath('vehicleRegNr')}
            control={control}
            render={({ field }) => (
              <VehicleInput
                value={field.value}
                onChange={(v) => field.onChange(conformRegNr(v))}
                noEdit={!isEditable}
              />
            )}
          />
        </LabelWrap>

        {jobType !== JobType.TMA && (
          <>
            {mkField('totalKilometers', 'Total km', 'number', undefined, {
              min: 0,
            })}
            <div></div>
          </>
        )}

        <FullWitdhCell>
          <CheckboxWrap>
            <Checkbox
              {...register(jobInstanceFieldPath('useActualStartEnd'), {
                onChange: (eve) => {
                  if (
                    'target' in eve &&
                    eve.target instanceof HTMLInputElement &&
                    eve.target.checked
                  ) {
                    setValue(
                      jobInstanceFieldPath('actualStartUtc'),
                      getValues(jobGroupFieldPath('orderedStartUtc'))
                    );
                    setValue(
                      jobInstanceFieldPath('actualEndUtc'),
                      getValues(jobGroupFieldPath('orderedEndUtc'))
                    );
                  } else {
                    setValue(jobInstanceFieldPath('actualStartUtc'), undefined);
                    setValue(jobInstanceFieldPath('actualEndUtc'), undefined);
                  }
                },
              })}
              disabled={!isEditable}
            >
              Bokad tid och tiden som ska faktureras skiljer sig
            </Checkbox>

            <InfoButton
              onClick={(eve) => {
                eve.preventDefault();
                const modalId = modalStack.push(
                  <InvoicingTimeInfoModal
                    onClose={() => modalStack.pop(modalId)}
                  />
                );
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </InfoButton>
          </CheckboxWrap>
        </FullWitdhCell>

        {useActualStartEnd && (
          <>
            {mkField('actualStartUtc', 'Faktisk starttid', 'datetime-local', {
              max: actualEndDate,
              validate: (value) => {
                if (!value) return 'Ange en faktisk starttid';
              },

              onChange: (eve) => {
                if ('target' in eve && eve.target instanceof HTMLInputElement) {
                  const actualStartInput = new Date(eve.target.value);
                  const actualEndInputStr = getValues(
                    jobInstanceFieldPath('actualEndUtc')
                  );

                  if (!actualEndInputStr) return;

                  if (new Date(actualEndInputStr) < actualStartInput) {
                    setValue(
                      jobInstanceFieldPath('actualEndUtc'),
                      eve.target.value
                    );
                  }
                }
              },
            })}

            {mkField('actualEndUtc', 'Faktisk sluttid', 'datetime-local', {
              min: actualStartDate,
              validate: (value) => {
                if (!value) return 'Ange en faktisk sluttid';
              },

              onChange: (eve) => {
                if ('target' in eve && eve.target instanceof HTMLInputElement) {
                  const actualEndInput = new Date(eve.target.value);
                  const actualStartInputStr = getValues(
                    jobInstanceFieldPath('actualStartUtc')
                  );

                  if (!actualStartInputStr) return;

                  if (new Date(actualStartInputStr) > actualEndInput) {
                    setValue(
                      jobInstanceFieldPath('actualStartUtc'),
                      eve.target.value
                    );
                  }
                }
              },
            })}

            <FullWitdhCell>
              <b>Fakturerad tid:</b>{' '}
              {actualStartDate &&
                actualEndDate &&
                humanReadableTimeDurationBetweenDates(
                  new Date(actualStartDate),
                  new Date(actualEndDate)
                )}
            </FullWitdhCell>
          </>
        )}
      </MyInputGrid>
    </CaseJobInstanceAccordion>
  );
};

export default CaseJobInstanceFields;
