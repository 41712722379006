import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface Props {
  label: string;
  counter: number;
  faIcon: IconDefinition;
}

/**
 *
 * Tab label with the label-text and a FontawesomeIcon with a counter,
 * the presented counter number is contained within the constraints of the
 * FontAwesomeIcon using span-elements as described in the FA-documentation.
 *
 * The counter will present a number, overlaying the icon in the upper right corner.
 *
 */
const TabCounterLabel: FC<Props> = ({ label, counter, faIcon }) => {
  return (
    <>
      <span className="fa-layers fa-fw fa-lg">
        <FontAwesomeIcon icon={faIcon} transform={'down-2'} />
        {counter > 0 && (
          <span
            className="fa-layers-counter fa-layers-top-right fa-2x"
            style={{ border: '3px solid white' }}
          >
            {counter}
          </span>
        )}
      </span>
      {` ${label}`}
    </>
  );
};

export default TabCounterLabel;
