import { CaseMessage, MessageType } from 'api';
import { saveMessageFunction } from '../../useCaseEditorState';
import { FC } from 'react';
import CommentEditor from './CommentEditor';
import CommentViewer from './CommentViewer';
import { Header, MessageText, Wrapper } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';

interface Props {
  caseMessage: CaseMessage;
  onEditMessage(message: CaseMessage): void;
  onDeleteMessage(message: CaseMessage): void;
  edit?: boolean;

  onSaveMessage: saveMessageFunction;
  onAbortEdit(): void;
  savingMessage: boolean;
}

const Message: FC<Props> = ({
  caseMessage,
  onEditMessage,
  onDeleteMessage,
  edit,
  onSaveMessage,
  onAbortEdit,
  savingMessage,
}) => {
  switch (caseMessage.messageType) {
    case MessageType.UserComment:
      return edit ? (
        <CommentEditor
          caseMessage={caseMessage}
          onSaveMessage={onSaveMessage}
          onAbortEdit={onAbortEdit}
          savingMessage={savingMessage}
        />
      ) : (
        <CommentViewer
          caseMessage={caseMessage}
          onEditClick={() => onEditMessage(caseMessage)}
          onDeleteClick={() => onDeleteMessage(caseMessage)}
        />
      );

    case MessageType.InvoiceStepUpdate:
      return (
        <Wrapper>
          <Header message={caseMessage}>Fakturauppdatering</Header>
          <MessageText>{caseMessage.text}</MessageText>
        </Wrapper>
      );

    case MessageType.SystemMessage:
      return (
        <Wrapper>
          <Header message={caseMessage}>
            <FontAwesomeIcon icon={faGears} /> Systemmeddelande
          </Header>
          <MessageText>{caseMessage.text}</MessageText>
        </Wrapper>
      );
  }
};

export default Message;
