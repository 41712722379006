import useAccountInfo, {
  useAccountInfoInitialization,
} from 'contexts/useAccountInfo';
import { useBasicDataInitialization } from 'contexts/useBasicData';
import { usePricingSetupInitialization } from 'contexts/usePricingSetup';
import { useUsersInitialization } from 'contexts/useUsers';
import { useVehiclesInitialization } from 'contexts/useVehicles';
import { FC, PropsWithChildren } from 'react';
import { RequestStatus } from 'swaggerhooks';
import useFrontendVersionPoll from 'utils/useFrontendVersionCheck';
import OverlayedSpinner from './spinners/OverlayedSpinner';

const AppInitialization: FC<PropsWithChildren> = ({ children }) => {
  const { isLoggedIn } = useAccountInfo();

  const { status: accountInfoStatus } =
    useAccountInfoInitialization(isLoggedIn);
  const { status: basicDataStatus } = useBasicDataInitialization(isLoggedIn);
  const { status: pricingSetupStatus } =
    usePricingSetupInitialization(isLoggedIn);
  const { status: vehiclesStatus } = useVehiclesInitialization(isLoggedIn);
  const { status: usersStatus } = useUsersInitialization(isLoggedIn);

  useFrontendVersionPoll();

  return (
    <>
      {[
        accountInfoStatus,
        basicDataStatus,
        pricingSetupStatus,
        vehiclesStatus,
        usersStatus,
      ].includes(RequestStatus.Fetching) && (
        <OverlayedSpinner>Laddar...</OverlayedSpinner>
      )}
      {/* account info is required before routes can be rendered (permissions) */}
      {(!isLoggedIn || accountInfoStatus !== RequestStatus.Fetching) &&
        children}
    </>
  );
};

export default AppInitialization;
