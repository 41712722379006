import { getMondayOfWeek, getWeekNumber } from 'components/Calendar/utils';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import moment from 'moment';
import { FC, useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { toInputDateString } from 'utils/date';

const overflowAtAnimationEnd = keyframes`
  0% {
    overflow: hidden;
  }
  100% { 
    overflow: visible;
  }
`;

const Wrapper = styled.div<{ closed: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  overflow: hidden;

  ${MediaQuery.phone} {
    height: 50px;
    padding: 0 ${Theme.sizes.padding.screenInset};
    margin: 0 calc(-1 * ${Theme.sizes.padding.screenInset});

    border: 0px solid ${Theme.colors.border.main};
    border-width: 1px 0;
    background-color: ${Theme.colors.bg.background2};
    transition: height 0.2s;

    ${({ closed }) =>
      closed
        ? css`
            height: 0;
            border: none;
          `
        : css`
            animation: ${overflowAtAnimationEnd} 0.2s forwards;
          `}

    &::before {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border: 10px solid transparent;
      border-bottom-color: ${Theme.colors.border.main};
    }
  }
`;

const LabelInput = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const MyInput = styled(Input)`
  width: 4em;
`;

interface Props {
  selectedDate: Date;
  onDateSelected(date: Date): void;
  type: 'day' | 'week' | 'month';
  closed?: boolean;
  className?: string;
}

const TimeInputs: FC<Props> = ({
  selectedDate,
  onDateSelected,
  type,
  closed,
  className,
}) => {
  const monthOptions = useMemo(() => {
    const date = new Date();

    return new Array(12).fill('').map((_, m) => {
      date.setMonth(m);
      return {
        month: m,
        label: date.toLocaleDateString(undefined, { month: 'long' }),
      };
    });
  }, []);

  const renderInputs = () => {
    switch (type) {
      case 'day':
        return (
          <LabelInput>
            Dag:
            <Input
              type="date"
              value={toInputDateString(
                // The user-selected date is 1 day after the shown time span from-date
                moment(selectedDate).add(1, 'day').toDate()
              )}
              onChange={(eve) =>
                onDateSelected(
                  moment(eve.target.value).subtract(1, 'day').toDate()
                )
              }
            />
          </LabelInput>
        );

      case 'week':
        return (
          <>
            <LabelInput>
              Vecka:
              <MyInput
                type="number"
                value={getWeekNumber(selectedDate)}
                onChange={(eve) => {
                  onDateSelected(
                    getMondayOfWeek(
                      Number(eve.target.value),
                      selectedDate.getFullYear()
                    )
                  );
                }}
              />
            </LabelInput>
            <LabelInput>
              År:
              <MyInput
                type="number"
                value={selectedDate.getFullYear()}
                onChange={(eve) => {
                  onDateSelected(
                    getMondayOfWeek(
                      getWeekNumber(selectedDate),
                      Number(eve.target.value)
                    )
                  );
                }}
              />
            </LabelInput>
          </>
        );

      case 'month':
        return (
          <>
            <LabelInput>
              Månad:
              <Select
                value={selectedDate.getMonth()}
                onChange={(eve) =>
                  onDateSelected(
                    new Date(
                      selectedDate.getFullYear(),
                      Number(eve.target.value),
                      1
                    )
                  )
                }
              >
                {monthOptions.map((opt) => (
                  <option value={opt.month} key={opt.month}>
                    {opt.label}
                  </option>
                ))}
              </Select>
            </LabelInput>
            <LabelInput>
              År:
              <MyInput
                type="number"
                value={selectedDate.getFullYear()}
                onChange={(eve) => {
                  onDateSelected(
                    new Date(
                      Number(eve.target.value),
                      selectedDate.getMonth(),
                      selectedDate.getDate()
                    )
                  );
                }}
              />
            </LabelInput>
          </>
        );
    }
  };

  return (
    <Wrapper closed={!!closed} className={className}>
      {renderInputs()}
    </Wrapper>
  );
};

export default TimeInputs;
