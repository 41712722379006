export const arraySpreadIf = <V>(
  condition: boolean | undefined,
  value: V
): V[] => (condition ? [value] : []);

export const objectSpreadIf = <V, K extends string>(
  condition: boolean | undefined,
  key: K,
  value: V
) => (condition ? { [key]: value } : {}) as { [k in K]?: V };
