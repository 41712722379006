import Checkbox from 'components/inputs/Checkbox';
import MultiSelect from 'components/inputs/MultiSelect';
import Theme from 'constants/theme';
import styled, { css } from 'styled-components';

const baseInputStyle = css`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 5px 3px;

  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px inset ${Theme.colors.bg.selection};
  }
`;

export const InputCell = styled.input`
  ${baseInputStyle}
`;

export const SelectCell = styled.select`
  ${baseInputStyle}

  option {
    background-color: ${Theme.colors.bg.input};
    color: ${Theme.colors.fg.input};
  }
`;

export const MultiSelectCell = styled(MultiSelect)`
  ${baseInputStyle}
`;

export const ButtonCell = styled.button`
  height: 100%;
  width: 100%;
  padding: 0;
  color: inherit;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font: inherit;
  font-weight: 600;

  &:hover,
  &:focus {
    &:not(:disabled) {
      color: ${Theme.colors.bg.accent1};
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px inset ${Theme.colors.bg.selection};
  }
`;

export const CheckBoxCell = styled(Checkbox)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:focus,
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 2px inset ${Theme.colors.bg.selection};
  }
`;
