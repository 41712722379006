import Theme from 'constants/theme';
import { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';
import DaySection from './DaySection';
import {
  DateSpan,
  getWeekDays,
  getWeekNumber,
  groupIntoSequentialLanes,
} from './utils';
import WeekEventLanes from './WeekEventLanes';

export const CalendarClassName = 'calendar-component';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  * {
    user-select: none;
  }
`;

const WeekRow = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
`;
const WeekNumber = styled.div<{ onClick?: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  margin: 5px 0;
  border-radius: 10px 0 0 10px;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${Theme.colors.bg.selection};
        color: ${Theme.colors.fg.selection};

        &::after {
          content: '';
          pointer-events: none;
          position: absolute;
          inset: 0;
          left: calc(2em - 1px);
          margin: 5px;
          margin-left: 0;
          border-radius: 0 10px 10px 0;
          background-color: ${Theme.colors.bg.opacityOverlay};
          border: 2px solid ${Theme.colors.bg.selection};
          border-left: none;
          opacity: 0.5;
        }
      }
    `}
`;

const WeekdayRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const WeekdayLabel = styled.div`
  flex: 1;
  text-align: center;
`;

interface Props<DS extends DateSpan> {
  showDate: Date;
  events: DS[];
  renderEventLine(
    event: DS,
    containerStart: Date,
    containerEnd: Date
  ): ReactNode;

  onDateBodyClick?(startOfDay: Date): void;
  onDateNumberClick?(startOfDay: Date): void;
  onWeekRowClick?(startOfWeek: Date, endOfWeek: Date): void;
  shouldCloseWeekOverflow?(event: MouseEvent): boolean;
}

const MonthCalendar = <DS extends DateSpan>({
  showDate,
  events,
  renderEventLine,
  onDateBodyClick,
  onDateNumberClick,
  onWeekRowClick,
  shouldCloseWeekOverflow,
}: Props<DS>) => {
  const weekdayNames = useMemo(() => getWeekDays(), []);
  const eventLanes = useMemo(() => groupIntoSequentialLanes(events), [events]);

  const firstDayOfMonth = new Date(
    showDate.getFullYear(),
    showDate.getMonth(),
    1
  );
  const lastDayOfMonth = new Date(
    showDate.getFullYear(),
    showDate.getMonth() + 1,
    0
  );

  const renderWeekRows = () => {
    const currentDate = new Date(firstDayOfMonth);
    // start the first week row from monday (previous month if this month doesn't start on a monday)
    currentDate.setDate(
      currentDate.getDate() - (currentDate.getDay() || 7) + 1
    );

    const weekRows: ReactNode[] = [];

    while (currentDate <= lastDayOfMonth) {
      const weekDays: ReactNode[] = [];
      const weekNumber = getWeekNumber(currentDate);
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );

      do {
        weekDays.push(
          <DaySection
            key={currentDate.getDate()}
            isOtherMonth={currentDate.getMonth() !== showDate.getMonth()}
            onBodyClick={onDateBodyClick}
            onDateNumberClick={onDateNumberClick}
            date={new Date(currentDate)}
          />
        );
        currentDate.setDate(currentDate.getDate() + 1);
      } while (currentDate.getDay() !== 1);

      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        0,
        0,
        0,
        -1
      );

      weekRows.push(
        <WeekRow key={weekNumber}>
          <WeekNumber
            onClick={
              onWeekRowClick
                ? () => onWeekRowClick(startOfWeek, endOfWeek)
                : undefined
            }
          >
            {weekNumber}
          </WeekNumber>
          {weekDays}
          <WeekEventLanes
            lanes={eventLanes}
            from={startOfWeek}
            to={endOfWeek}
            renderEventLine={renderEventLine}
            shouldCloseWeekOverflow={shouldCloseWeekOverflow}
          />
        </WeekRow>
      );
    }

    return weekRows;
  };

  return (
    <Wrapper className={CalendarClassName}>
      <WeekdayRow>
        <WeekNumber />
        {weekdayNames.map((weekday, i) => (
          <WeekdayLabel key={i}>{weekday}</WeekdayLabel>
        ))}
      </WeekdayRow>

      {renderWeekRows()}
    </Wrapper>
  );
};

export default MonthCalendar;
