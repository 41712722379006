import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import Tooltip, { tooltipCSSVariables, Props as TooltipProps } from '.';

const moreInfoClassName = 'warning-popup-more-info';
const MyTooltip = styled(Tooltip)`
  ${css`
    ${tooltipCSSVariables.border}: ${Theme.colors.bad};
    ${tooltipCSSVariables.background}: ${Theme.colors.warn};
    ${tooltipCSSVariables.foreground}: #000;
  `}

  display: flex;
  flex-direction: row;
  align-items: center;

  .${moreInfoClassName} {
    width: 0;
    max-height: 0;
    transition: width 0.2s, max-height 0.1s, padding 0.2s;
    overflow: hidden;
  }

  &:hover {
    z-index: 2;
  }

  &:hover .${moreInfoClassName} {
    width: 300px;
    max-height: 500px;
    padding-left: 5px;
    transition-duration: 0.2s, 3s, 0.2s;
  }
`;

interface Props extends TooltipProps {}

const WarningTooltip: FC<PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return (
    <MyTooltip {...props}>
      <FontAwesomeIcon icon={faWarning} />
      <div className={moreInfoClassName}>{children}</div>
    </MyTooltip>
  );
};

export default WarningTooltip;
