import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Case, CreateInvoicesRequest, InvoiceClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Button from 'components/inputs/Button';
import PageHeader from 'components/PageHeader';
import SectionHeader from 'components/SectionHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo, {
  useSelectedAkCompanyChanged,
} from 'contexts/useAccountInfo';
import { useCaseTableRenderProps } from 'pages/CaseList/useCaseColumnSettings';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';
import {
  CaseRow,
  CreateInvoiceTableRenderProps,
  useInvocableColumnSettings,
  useNonInvocableColumnSettings,
} from './createInvoiceColumnSettings';
import PreviewInvoiceModal from './PreviewInvoiceModal';

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const MyTable = styled(Table)`
  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const CheckboxTable = styled(Table)`
  margin-bottom: 40px;

  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const MySectionHeader = styled(SectionHeader)`
  padding-inline: ${Theme.sizes.padding.screenInset};
`;

const CreateInvoicePage: React.FC = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const navigate = useNavigate();
  const [selectedCaseIds, setSelectedCaseIds] = useState(new Set<number>());
  const [previewInvoice, setPreviewInvoice] = useState(false);

  useSelectedAkCompanyChanged(
    useCallback(() => {
      setSelectedCaseIds(new Set());
    }, [])
  );

  const invoicingModel = useResponse(
    InvoiceClient,
    async (c) =>
      selectedAkCompanyId !== null
        ? await c.validateAndPreviewInvoices(selectedAkCompanyId)
        : null,
    [selectedAkCompanyId]
  );
  const createInvoiceCall = useApiCall(
    InvoiceClient,
    (c, createInvoiceRequest: CreateInvoicesRequest) =>
      c.createInvoices(createInvoiceRequest)
  );

  const caseTableRenderProps = useCaseTableRenderProps();

  const hasSelectedAllCases = useMemo(() => {
    return !invoicingModel.response?.invoiceSuccessCases.find(
      (c) => !selectedCaseIds.has(c.id)
    );
  }, [invoicingModel.response, selectedCaseIds]);

  const handleToggleSelectAllCases = useCallback(() => {
    if (
      invoicingModel.status === RequestStatus.Fetched &&
      invoicingModel.response
    ) {
      if (hasSelectedAllCases) {
        setSelectedCaseIds(new Set());
      } else {
        setSelectedCaseIds(
          new Set(
            invoicingModel.response.invoiceSuccessCases.map((ic) => ic.id)
          )
        );
      }
    }
  }, [invoicingModel, hasSelectedAllCases]);

  const invoicableColumnSettings = useInvocableColumnSettings(
    handleToggleSelectAllCases,
    hasSelectedAllCases,
    selectedCaseIds.size > 0
  );
  const nonInvoicableColumnSettings = useNonInvocableColumnSettings();

  const handleToggleCaseSelection = useCallback((caseObj: Case) => {
    setSelectedCaseIds((currentSelection) => {
      const newSelection = new Set(currentSelection);
      if (newSelection.has(caseObj.id)) {
        newSelection.delete(caseObj.id);
      } else {
        newSelection.add(caseObj.id);
      }

      return newSelection;
    });
  }, []);

  const handleRowClick = useCallback((caseObj: Case) => {
    navigate(generatePath(Routes.cases.case, { id: String(caseObj.id) }));
  }, []);

  const handleCreateInvoiceClick = useCallback(
    async (invoiceDate: Date) => {
      setPreviewInvoice(false);

      if (selectedAkCompanyId === null) return;

      await createInvoiceCall.run(
        new CreateInvoicesRequest({
          akCompanyId: selectedAkCompanyId,
          invoiceDate: invoiceDate,
          selectedCaseIds: Array.from(selectedCaseIds),
        })
      );
      await invoicingModel.update();
    },
    [createInvoiceCall, invoicingModel, selectedAkCompanyId, selectedCaseIds]
  );

  const renderProps = useMemo((): CreateInvoiceTableRenderProps => {
    return {
      ...caseTableRenderProps,
      selectedCaseIds,
      onToggleSelectCase: handleToggleCaseSelection,
    };
  }, [caseTableRenderProps, handleToggleCaseSelection, selectedCaseIds]);

  const renderContent = () => {
    if (createInvoiceCall.status === RequestStatus.Fetching)
      return <LoadingSection />;

    switch (invoicingModel.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        if (!invoicingModel.response) return null;
        return (
          <>
            {invoicingModel.response.invoiceSuccessCases.length > 0 && (
              <CheckboxTable
                initialSortState={{ ascending: false, sortedColumnIndex: 1 }}
                columnSettings={invoicableColumnSettings}
                rows={invoicingModel.response.invoiceSuccessCases}
                renderProps={renderProps}
                onRowClick={handleRowClick}
              />
            )}
            {invoicingModel.response.invoiceFailedCases.length > 0 && (
              <>
                <MySectionHeader title="Ej fakturerbara" />
                <MyTable
                  initialSortState={{ ascending: false, sortedColumnIndex: 1 }}
                  columnSettings={nonInvoicableColumnSettings}
                  rows={invoicingModel.response.invoiceFailedCases.map(
                    (fail): CaseRow => ({
                      ...fail.case,
                      failureReason: fail.failureReason,
                    })
                  )}
                  renderProps={renderProps}
                  onRowClick={handleRowClick}
                />
              </>
            )}
          </>
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => invoicingModel.update()} />;
    }
  };

  const disableButtons =
    createInvoiceCall.status === RequestStatus.Fetching ||
    invoicingModel.status === RequestStatus.Fetching;

  return (
    <>
      <PageHeader title="Att fakturera">
        <HeaderRight>
          <Button
            small
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setPreviewInvoice(true)}
            disabled={disableButtons || selectedCaseIds.size === 0}
          >
            Skapa faktura
          </Button>
        </HeaderRight>
      </PageHeader>

      {renderContent()}

      {previewInvoice && (
        <PreviewInvoiceModal
          selectedCaseIds={Array.from(selectedCaseIds)}
          onClose={() => setPreviewInvoice(false)}
          onCreateInvoiceClick={handleCreateInvoiceClick}
        />
      )}
    </>
  );
};

export default CreateInvoicePage;
