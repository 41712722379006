import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import SectionHeader from 'components/SectionHeader';
import React, { FC, useRef } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import { createExpenseForm } from '../formUtils/expenseFormConverters';
import ExpenseFields from './ExpenseFields';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Scroll = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 20px;
`;

interface Props {
  formControl: Control<CaseFormInputs, any>;
  register: UseFormRegister<CaseFormInputs>;
  noEdit: boolean;
}

const ExpensesSection: FC<Props> = ({ formControl, register, noEdit }) => {
  const { fields, prepend, remove } = useFieldArray({
    control: formControl,
    name: 'caseExpenses',
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleAddExpense = (eve: React.MouseEvent) => {
    eve.preventDefault();
    prepend(createExpenseForm());
    wrapperRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Wrapper ref={wrapperRef}>
      <SectionHeader title="Utgifter">
        {!noEdit && (
          <Button
            onClick={handleAddExpense}
            icon={<FontAwesomeIcon icon={faPlus} />}
            small
          >
            Lägg till
          </Button>
        )}
      </SectionHeader>

      <Scroll>
        {fields
          .sort((a, b) => a.expenseId - b.expenseId)
          .map((field, index) => (
            <ExpenseFields
              key={field.id}
              defaultValues={field}
              register={(name, options) =>
                register(`caseExpenses.${index}.${name}`, options)
              }
              onDelete={() => remove(index)}
              expenseFormIndex={index}
              formControl={formControl}
              noEdit={noEdit}
            />
          ))}
      </Scroll>
    </Wrapper>
  );
};

export default ExpensesSection;
