import { CaseJob, CaseJobClient, CaseJobSearchResult } from 'api';
import ColorDot from 'components/ColorDot';
import { EditableInputCell } from 'components/Table/editableCells';
import { ColumnSetting } from 'components/Table/utils';
import useBasicData, { useBasicDataValue } from 'contexts/useBasicData';
import usePricingSetup, {
  usePricingSetupValue,
} from 'contexts/usePricingSetup';
import { useCallback, useMemo } from 'react';
import { useApiCall } from 'swaggerhooks';
import { formatDateTime } from 'utils/date';
import {
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';
import useQueuedUpdating, { EditOperation } from 'utils/useQueuedUpdating';

export interface CaseTableRenderProps
  extends useBasicDataValue,
    usePricingSetupValue {
  enqueueEdit: (
    caseJobID: number,
    currentCaseJob: EditOperation<CaseJobSearchResult>
  ) => void;
}

interface useCaseJobTableRenderPropsReturn {
  renderProps: CaseTableRenderProps;
  queuedEdits: Map<number, EditOperation<CaseJobSearchResult>[]>;
}

export const useCaseJobTableRenderProps = (
  caseJobsById: Map<number, CaseJobSearchResult>,
  onCaseJobUpdated: (caseJob: CaseJob) => void
): useCaseJobTableRenderPropsReturn => {
  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();

  const updateCaseJobCall = useApiCall(CaseJobClient, (c, caseJob: CaseJob) =>
    c.saveCaseJob(caseJob)
  );

  const handleUpdateCaseJob = useCallback(
    async (caseJob: CaseJobSearchResult) => {
      const [updatedCasejob, error] = await updateCaseJobCall.run(
        caseJob.caseJob
      );

      if (updatedCasejob && !error) {
        onCaseJobUpdated(updatedCasejob);
      }
    },
    [onCaseJobUpdated, updateCaseJobCall]
  );

  const queuedCaseJobUpdating = useQueuedUpdating(
    caseJobsById,
    handleUpdateCaseJob
  );

  const renderProps = useMemo(
    (): CaseTableRenderProps => ({
      ...basicData,
      ...pricingSetup,
      enqueueEdit: queuedCaseJobUpdating.enqueueEditOperation,
    }),
    [basicData, pricingSetup, queuedCaseJobUpdating.enqueueEditOperation]
  );

  return useMemo(
    () => ({
      renderProps,
      queuedEdits: queuedCaseJobUpdating.queuedEdits,
    }),
    [queuedCaseJobUpdating.queuedEdits, renderProps]
  );
};

const useCaseJobColumnSettings = () => {
  return useMemo(
    (): ColumnSetting<CaseJobSearchResult, CaseTableRenderProps>[] => [
      {
        head: 'Ärendedatum',
        render: (cjsr) => formatDateTime(cjsr.case.caseDateUtc),
        width: 150,
        sortFunction: makeDateComparator((cjsr) => cjsr.case.caseDateUtc),
      },
      {
        head: 'Bokat från',
        render: (cjsr) => formatDateTime(cjsr.caseJob.orderedStartUtc),
        width: 150,
        sortFunction: makeDateComparator(
          (cjsr) => cjsr.caseJob.orderedStartUtc
        ),
      },
      {
        head: 'Bokat till',
        render: (cjsr) => formatDateTime(cjsr.caseJob.orderedEndUtc),
        width: 150,
        sortFunction: makeDateComparator((cjsr) => cjsr.caseJob.orderedEndUtc),
      },
      {
        head: 'Jobbtyp',
        render: (cjsr, { jobTypeNames }) =>
          jobTypeNames.get(cjsr.caseJob.jobType),
        width: 100,
        sortFunction: makeStringComparator(
          (cjsr, { jobTypeNames }) =>
            jobTypeNames.get(cjsr.caseJob.jobType) ?? ''
        ),
      },
      {
        head: 'Status',
        render: (cjsr, { caseStatusNames }) =>
          caseStatusNames.get(cjsr.case.caseStatus),
        width: 100,
        sortFunction: makeNumberComparator((cjsr) => cjsr.case.caseStatus),
      },
      {
        head: 'Kund',
        render: (cjsr, { customersById }) => {
          const customer = customersById.get(cjsr.case.customerId);
          return (
            <>
              {customer?.calendarColor && (
                <ColorDot color={customer.calendarColor}>
                  {customer?.name}
                </ColorDot>
              )}
            </>
          );
        },
        width: 150,
        sortFunction: makeStringComparator(
          (cjsr, { customersById }) =>
            customersById.get(cjsr.case.customerId)?.name ?? ''
        ),
      },
      {
        head: 'Adress',
        render: (cjsr) =>
          `${cjsr.case.streetAddress} ${cjsr.case.zipCode} ${cjsr.case.city}`,
        width: 150,
        sortFunction: makeStringComparator(
          (cjsr) =>
            `${cjsr.case.streetAddress} ${cjsr.case.zipCode} ${cjsr.case.city}`
        ),
      },
      {
        head: 'Regnr',
        focusable: true,
        render: (cjsr, { enqueueEdit }, focused) =>
          focused ? (
            <EditableInputCell
              initialValue={cjsr.caseJob.vehicleRegNr}
              onValueSet={(regnr) =>
                enqueueEdit(
                  cjsr.caseJob.id,
                  (cjsr) =>
                    new CaseJobSearchResult({
                      ...cjsr,
                      caseJob: new CaseJob({
                        ...cjsr.caseJob,
                        vehicleRegNr: regnr,
                      }),
                    })
                )
              }
            />
          ) : (
            cjsr.caseJob.vehicleRegNr
          ),
        width: 80,
        sortFunction: makeStringComparator((cjsr) => cjsr.caseJob.vehicleRegNr),
      },
      {
        head: 'Förarnamn',
        focusable: true,
        render: (cjsr, { enqueueEdit }, focused) =>
          focused ? (
            <EditableInputCell
              initialValue={cjsr.caseJob.driverName}
              onValueSet={(driverName) =>
                enqueueEdit(
                  cjsr.caseJob.id,
                  (cjsr) =>
                    new CaseJobSearchResult({
                      ...cjsr,
                      caseJob: new CaseJob({
                        ...cjsr.caseJob,
                        driverName: driverName,
                      }),
                    })
                )
              }
            />
          ) : (
            cjsr.caseJob.driverName
          ),
        width: 150,
        sortFunction: makeStringComparator((cjsr) => cjsr.caseJob.driverName),
      },
    ],
    []
  );
};

export default useCaseJobColumnSettings;
