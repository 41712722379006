import Modal from 'components/Modal';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';

import { ApiCallGlobalConfig, ApiCallGlobalConfigContext } from 'swaggerhooks';

import {
  ApiException,
  AuthenticationClient,
  RefreshJwtTokenResponse,
} from 'api';
import useModalStack from '../useModalStack';
import ApiErrorModalIds from './ApiErrorModalIds';
import useAccountInfo, { ApiTokens } from 'contexts/useAccountInfo';
import { pollersErrorModalId } from 'constants/AppConstants';

const Pre = styled.pre`
  max-width: 90vw;
  max-height: 60vh;
  overflow: auto;
`;

const ApiCallConfiguration: React.FC<PropsWithChildren> = ({ children }) => {
  const { push: pushModal, pop: popModal } = useModalStack();
  const { onLogOut, onTokenRefreshed } = useAccountInfo();

  const refreshJWTTokenPromise =
    useRef<Promise<RefreshJwtTokenResponse | null> | null>(null);
  const isRefreshingJWTToken = useRef(false);

  const authorizationHeaderFetch = useCallback(
    (
      [...args]: Parameters<typeof fetch>,
      token: string
    ): ReturnType<typeof fetch> =>
      fetch(args[0], {
        ...(args[1] ?? {}),
        headers: {
          ...(args[1]?.headers ?? {}),
          Authorization: `Bearer ${token}`,
        },
      }),
    []
  );

  const tokenAutorefreshFetch: typeof fetch = useCallback(
    async (...args: Parameters<typeof fetch>) => {
      const { token, refreshToken } = ApiTokens;

      if (!token) {
        return fetch(...args);
      }

      let upToDateToken = token;
      // If a new token is being fetched, wait for it instead of trying to fetch with the current, expired token.
      if (isRefreshingJWTToken.current) {
        upToDateToken =
          (await refreshJWTTokenPromise.current)?.jwtToken ?? token;
      }

      // Try to do the actual request
      const response = await authorizationHeaderFetch(args, upToDateToken);

      // If we got 401 unauthorized response, try to get a new token and then redo the api call.
      if (response.status === 401) {
        if (!refreshToken) {
          onLogOut();
          return response;
        }

        // There should only be one validateRefreshToken api call running at a time. If there's none currently running, start one.
        // If 'token' does not equal 'ApiTokens.token', we won't fetch a new token,
        // because a new one was already fetched in the background while one of the 'await':s above was running.
        // await:ing 'refreshJWTTokenPromise.current' will return the latest fetched token.
        if (!isRefreshingJWTToken.current && token === ApiTokens.token) {
          isRefreshingJWTToken.current = true;

          refreshJWTTokenPromise.current = (async () => {
            const authClient = new AuthenticationClient(undefined, {
              fetch: (...args) => authorizationHeaderFetch(args, token),
            });

            let refreshTokenResponse: RefreshJwtTokenResponse | null = null;
            try {
              refreshTokenResponse = await authClient.refreshJwtToken(
                token,
                refreshToken
              );

              if (
                refreshTokenResponse.jwtRefreshToken &&
                refreshTokenResponse.jwtToken
              ) {
                onTokenRefreshed(
                  refreshTokenResponse.jwtToken,
                  refreshTokenResponse.jwtRefreshToken
                );
              }
            } catch (err) {
              // eslint-disable-next-line no-console
              console.log('Error while trying to refresh login token', err);
              onLogOut();
            }

            return refreshTokenResponse;
          })();
        }

        // refreshJWTTokenPromise.current should never be null here, but TS likes this "if" :P
        if (refreshJWTTokenPromise.current) {
          const refreshTokenResponse = await refreshJWTTokenPromise.current;
          isRefreshingJWTToken.current = false;

          if (
            refreshTokenResponse?.jwtToken &&
            refreshTokenResponse?.jwtRefreshToken
          ) {
            // do the api call again, but now with updated token
            return authorizationHeaderFetch(
              args,
              refreshTokenResponse.jwtToken
            );
          }
        }
      }
      return response;
    },
    [authorizationHeaderFetch, onLogOut, onTokenRefreshed]
  );

  const apiCallConfig = useMemo(
    (): ApiCallGlobalConfig => ({
      onApiError: (err, apiCallOptions) => {
        const errorModal = (
          modalTypeId: string,
          title: string,
          body: ReactNode
        ) =>
          pushModal(
            <Modal
              buttons={[
                {
                  label: 'Ok',
                  onClick: () => {
                    popModal(modalTypeId);
                    popModal(apiCallOptions.errorModalId);
                    popModal(pollersErrorModalId);
                  },
                },
              ]}
              title={title}
            >
              {body}
            </Modal>,
            modalTypeId
          );

        if (err instanceof ApiException) {
          console.log(
            'API error',
            err,
            err.message,
            err.response,
            err.headers,
            err.name,
            err.result,
            err.status,
            err.stack
          );

          if (err.status === 403) {
            errorModal(
              ApiErrorModalIds.AccessDenied,
              'Fel',
              'Du har tyvärr inte tillgång till den här funktionen.'
            );
          } else if (err.status === 401) {
            errorModal(
              ApiErrorModalIds.LoggedOut,
              'Utloggad',
              'Du har blivit utloggad. Logga in och försök igen.'
            );
          } else {
            errorModal(
              ApiErrorModalIds.ServerError,
              'Fel',
              <>
                Ett serverfel har inträffat :&apos;(
                <br />
                <br />
                {process.env.NODE_ENV === 'development' && (
                  <Pre>{err.response}</Pre>
                )}
              </>
            );
          }
        } else {
          console.log('API error', err);

          if (err instanceof TypeError) {
            errorModal(
              ApiErrorModalIds.ConnectionError,
              'Anslutningsfel',
              'Kunde inte ansluta till servern. Kontrollera din internetanslutning.'
            );
          }
        }
      },
      constructClient: (clientConstructor) =>
        new clientConstructor(undefined, {
          fetch: ApiTokens.token
            ? tokenAutorefreshFetch
            : (...args: Parameters<typeof fetch>) => fetch(...args),
        }),
    }),
    [popModal, pushModal, tokenAutorefreshFetch]
  );

  return (
    <ApiCallGlobalConfigContext.Provider value={apiCallConfig}>
      {children}
    </ApiCallGlobalConfigContext.Provider>
  );
};

export default ApiCallConfiguration;
