import { CaseClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import OverlayedSpinner from 'components/spinners/OverlayedSpinner';
import Routes from 'constants/routes';
import { FC } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { RequestStatus, useResponse } from 'swaggerhooks';
import CaseEditor from './CaseEditor';
import useCaseEditorState from './CaseEditor/useCaseEditorState';

interface Props {
  createNew?: boolean;
}

const CasePage: FC<Props> = ({ createNew }) => {
  const params = useParams<{ id: string }>();
  const caseId = Number(params.id);
  const navigate = useNavigate();

  const caseResponse = useResponse(
    CaseClient,
    async (c) => (isNaN(caseId) ? undefined : await c.getCase(caseId)),
    [caseId]
  );

  const {
    form,
    submitHandler,
    handleSaveMessage,
    handleReopenCase,
    saveCaseCall,
    saveCaseMessageCall,
    uploadAttachmentCall,
    reopenCaseCall,
  } = useCaseEditorState(caseResponse.response, (updatedCase) => {
    caseResponse.update();
    navigate(generatePath(Routes.cases.case, { id: String(updatedCase.id) }));
  });

  if (createNew) {
    return (
      <>
        <PageHeader title="Nytt ärende" />

        <CaseEditor
          form={form}
          submitHandler={submitHandler}
          onSaveMessage={async () => {}}
          isSavingMessage={false}
          onReopenCase={() => {}}
        />
      </>
    );
  }

  switch (caseResponse.status) {
    case RequestStatus.Idle:
    case RequestStatus.Fetching:
      return <LoadingSection>Hämtar ärende...</LoadingSection>;

    case RequestStatus.Fetched:
      if (!caseResponse.response) return null;

      return (
        <>
          <PageHeader title={`Ärende ${caseResponse.response.id}`} />

          <CaseEditor
            form={form}
            originalCase={caseResponse.response}
            submitHandler={submitHandler}
            onSaveMessage={handleSaveMessage}
            isSavingMessage={
              saveCaseMessageCall.status === RequestStatus.Fetching ||
              uploadAttachmentCall.status === RequestStatus.Fetching
            }
            onReopenCase={handleReopenCase}
          />
          {saveCaseCall.status === RequestStatus.Fetching && (
            <OverlayedSpinner>Sparar ärende</OverlayedSpinner>
          )}
          {reopenCaseCall.status === RequestStatus.Fetching && (
            <OverlayedSpinner>Öppnar ärende</OverlayedSpinner>
          )}
        </>
      );

    case RequestStatus.Error:
      return <ErrorTryAgain onTryAgain={() => caseResponse.update()} />;
  }
};

export default CasePage;
