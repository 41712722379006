import Theme from 'constants/theme';
import { FC, HTMLProps, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const IconWrapClassName = 'button-icon-wrap';
export const LabelWrapClassName = 'button-label-wrap';

interface ButtonStyleProps {
  small?: boolean;
}

export const buttonStyle = css<ButtonStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: ${({ small }) => (small ? '5px 15px' : '10px 20px')};
  min-height: calc(1.5em + 10px);

  border-radius: 0;
  border: none;
  background: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
  font: inherit;
  font-weight: 600;
  cursor: pointer;

  &:active:not(:disabled) {
    opacity: 0.5;
  }
  &:disabled {
    background-color: ${Theme.colors.bg.background3};
    cursor: default;
  }
`;

export const FABStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  min-height: 0;
  min-width: 0;
  height: 60px;
  width: 60px;

  border-radius: 1000px;
  font-size: 40px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.5);

  .${LabelWrapClassName} {
    display: none;
  }
  .${IconWrapClassName} {
    margin: 0;
    font-size: 20px;
  }
`;

const StyledButton = styled.button<ButtonStyleProps>`
  ${buttonStyle}
`;

const IconWrap = styled.span`
  margin-right: 5px;
`;
const LabelWrap = styled.span``;

interface Props extends HTMLProps<HTMLButtonElement>, ButtonStyleProps {
  icon?: ReactNode;
}

const Button: FC<Props> = ({ icon, children, ...props }) => (
  <StyledButton {...props} as={undefined}>
    {icon && <IconWrap className={IconWrapClassName}>{icon}</IconWrap>}
    {children && (
      <LabelWrap className={LabelWrapClassName}>{children}</LabelWrap>
    )}
  </StyledButton>
);

export default Button;
