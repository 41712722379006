import styled from 'styled-components';
import Theme from 'constants/theme';
import MediaQuery from 'constants/MediaQuery';
import makeThemeGetters from 'utils/makeThemeGetters';
import { Outlet } from 'react-router-dom';
import desktopTheme from 'constants/theme/desktopTheme';

const cssVarOverrides = makeThemeGetters(desktopTheme);

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${Theme.colors.bg.background2};

  ${MediaQuery.tablet} {
    /* override theme to keep all desktop sizes while on tablet  */
    ${cssVarOverrides.cssVariableDefs}
  }
`;

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  background-color: ${Theme.colors.bg.background1};

  ${MediaQuery.tablet} {
    flex: 0;
    width: 400px;
    max-width: 95vw;
    margin: auto;

    box-shadow: 0 0 50px rgb(0 0 0 / 20%);
  }
`;

const Title = styled.h1`
  padding: 0 ${Theme.sizes.padding.screenInset};
  padding-top: calc(1 * ${Theme.sizes.padding.screenInset});
  margin: 0;
  font-size: ${Theme.sizes.font.large};
`;

const LoginRoute: React.FC = () => {
  return (
    <Wrapper>
      <Card>
        <Title>AK Services</Title>

        <Outlet />
      </Card>
    </Wrapper>
  );
};

export default LoginRoute;
