import { faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Button from 'components/inputs/Button';
import TextButton from 'components/inputs/TextButton';
import SpinnerIcon from 'components/spinners/SpinnerIcon';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import AttachmentsEditor from './AttachmentsEditor';
import useAttachmentsEditorState from './AttachmentsEditor/useAttachmentsEditorState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const TextArea = styled(AutoSizedTextArea)`
  min-height: 4em;
  max-height: 300px;
  resize: vertical;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const SendButton = styled(Button)`
  margin-left: auto;
`;

interface Props {
  disabled: boolean;
  onAddMessage(message: string, queuedFiles: File[]): void;
  savingMessage: boolean;
}

const AddMessage: FC<Props> = ({ disabled, onAddMessage, savingMessage }) => {
  const attachments = useAttachmentsEditorState([]);
  const [message, setMessage] = useState('');

  const handleCreateMessage = (eve?: React.MouseEvent) => {
    eve?.preventDefault();
    onAddMessage(message, attachments.queuedFiles);
    setMessage('');
    attachments.reset([]);
  };

  return (
    <Wrapper>
      <AttachmentsEditor state={attachments} />

      <TextArea
        placeholder="Skriv ett meddelande..."
        value={message}
        onChange={(eve) => setMessage(eve.currentTarget.value)}
        onKeyDown={(eve) => {
          if (eve.ctrlKey && eve.key === 'Enter') {
            handleCreateMessage();
          }
        }}
        disabled={disabled}
      />

      <Actions>
        <TextButton
          disabled={disabled}
          onClick={(eve) => {
            eve.preventDefault();
            attachments.pickFile();
          }}
        >
          <FontAwesomeIcon icon={faPaperclip} /> Bifoga fil
        </TextButton>

        <SendButton
          onClick={handleCreateMessage}
          icon={
            savingMessage ? (
              <SpinnerIcon size="small" />
            ) : (
              <FontAwesomeIcon icon={faPaperPlane} />
            )
          }
          disabled={disabled}
          small
        >
          {savingMessage ? 'Skickar...' : 'Skicka'}
        </SendButton>
      </Actions>
    </Wrapper>
  );
};

export default AddMessage;
