import {
  faArrowRight,
  faClockRotateLeft,
  faCopy,
  faPlus,
  faStopwatch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { PriceListWithActiveTo } from './usePriceListsWithActiveTo';

enum WrapperStatus {
  expired,
  active,
  future,
}

const Wrapper = styled.span<{ status: WrapperStatus }>`
  padding: 2px 10px;
  margin: 10px;
  border-radius: 1000px;
  font-size: ${Theme.sizes.font.small};
  cursor: default;

  ${({ status }) => {
    switch (status) {
      case WrapperStatus.expired:
        return css`
          background-color: ${Theme.colors.bg.background2};
          color: ${Theme.colors.fg.background2};
          border: 1px dashed ${Theme.colors.border.input};
          font-weight: 600;
        `;

      case WrapperStatus.active:
        return css`
          background-color: ${Theme.colors.bg.selection};
          color: ${Theme.colors.fg.selection};
          font-weight: 700;
        `;

      case WrapperStatus.future:
        return css`
          color: ${Theme.colors.bg.selection};
          border: 1px dashed ${Theme.colors.bg.selection};
          font-weight: 600;
        `;
    }
  }}
`;

interface Props {
  priceList: PriceListWithActiveTo | undefined;
  savesAsNewPricelist: boolean;
}

const PriceListStatusIndicator: FC<Props> = ({
  priceList,
  savesAsNewPricelist,
}) => {
  const now = new Date();
  const isActive =
    priceList && priceList.activeFromUtc <= now && priceList.activeToUtc >= now;
  const isOld = priceList && priceList.activeFromUtc <= now;

  if (savesAsNewPricelist) {
    return (
      <>
        <FontAwesomeIcon icon={priceList ? faCopy : faPlus} />{' '}
      </>
    );
  }

  if (isActive) {
    return (
      <Wrapper status={WrapperStatus.active} title="Prislistan är aktiv">
        <FontAwesomeIcon icon={faArrowRight} /> Aktiv
      </Wrapper>
    );
  }

  if (isOld) {
    return (
      <Wrapper
        status={WrapperStatus.expired}
        title="Prislistan har ersatts med en nyare prislista"
      >
        <FontAwesomeIcon icon={faClockRotateLeft} /> Utgången
      </Wrapper>
    );
  }

  if (priceList) {
    return (
      <Wrapper
        status={WrapperStatus.future}
        title="Prislistan har inte blivit aktiv ännu"
      >
        <FontAwesomeIcon icon={faStopwatch} /> Framtida
      </Wrapper>
    );
  }

  return null;
};

export default PriceListStatusIndicator;
