import {
  hasAccessToRoute,
  ValidRouteString,
} from 'constants/routeConfiguration';
import NavigateToFallback from 'NavigateToFallback';
import { FC, PropsWithChildren } from 'react';
import useUserWebClaims from './useUserWebClaims';

export const LoginReturnUrlQueryParam = 'from';

interface Props extends PropsWithChildren {
  route: ValidRouteString;
}

const RequireAuth: FC<Props> = ({ children, route }) => {
  const userClaims = useUserWebClaims();

  if (!hasAccessToRoute(route, userClaims)) {
    return <NavigateToFallback />;
  }

  return <>{children}</>;
};

export default RequireAuth;
