import React, { FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

export const canvasClassName = 'zoom-and-scroll-canvas';

const ScrollWrapper = styled.div`
  overflow: auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  position: relative;
`;

const Canvas = styled.div<{ width: number; height: number }>`
  transform-origin: top left;
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `}
`;

const CanvasCrop = styled.div`
  width: min-content;
  height: min-content;
  overflow: hidden;
`;

type Vec2 = [x: number, y: number];

function getPoint(touch: React.Touch | Touch): Vec2 {
  return [touch.clientX, touch.clientY];
}

function distance(a: Vec2, b: Vec2) {
  const x = a[0] - b[0];
  const y = a[1] - b[1];

  return Math.hypot(x, y);
}
function multiply(a: Vec2, b: Vec2): Vec2 {
  return [a[0] * b[0], a[1] * b[1]];
}

function center(a: Vec2, b: Vec2): Vec2 {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
}

function subtract(a: Vec2, b: Vec2): Vec2 {
  return [a[0] - b[0], a[1] - b[1]];
}
function add(a: Vec2, b: Vec2): Vec2 {
  return [a[0] + b[0], a[1] + b[1]];
}

function getTouch(touchEve: React.TouchEvent, touchId: number) {
  return Array.from(touchEve.touches).find(
    (touch) => touch.identifier === touchId
  );
}

interface Props extends PropsWithChildren {
  canvasWidth: number;
  canvasHeight: number;
  maxScale?: number;
  className?: string;
}

const ZoomAndScroll: FC<Props> = ({
  canvasHeight,
  canvasWidth,
  maxScale = 20,
  children,
  className,
}) => {
  const startTouches = useRef<[React.Touch, React.Touch] | null>(null);
  const scrollAreaStartTouchesCenter = useRef<Vec2 | null>(null);
  const lastTouches = useRef<[React.Touch, React.Touch] | null>(null);
  const minCanvasScale = useRef(1);
  const canvasScale = useRef(1);
  const scrollRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLDivElement>(null);
  const canvasCropRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!scrollRef.current) return;

    let lastOffsetDims: Vec2 | null = null;

    const resizeObserver = new ResizeObserver(() => {
      if (!scrollRef.current) return;
      const scrollDiv = scrollRef.current;

      const currentOffsetDims: Vec2 = [
        scrollDiv.offsetWidth,
        scrollDiv.offsetHeight,
      ];

      // Only update if dimensions have changed by 40px
      if (lastOffsetDims && distance(lastOffsetDims, currentOffsetDims) < 40)
        return;
      lastOffsetDims = currentOffsetDims;

      const verticalScroll = scrollDiv.scrollWidth < scrollDiv.scrollHeight;
      minCanvasScale.current = verticalScroll
        ? scrollDiv.clientWidth / canvasWidth
        : scrollDiv.clientHeight / canvasHeight;

      canvasScale.current = Math.min(minCanvasScale.current, maxScale);
      if (canvasRef.current) {
        const canvasDiv = canvasRef.current;
        canvasDiv.style.transform = `scale(${canvasScale.current})`;

        if (canvasCropRef.current) {
          canvasCropRef.current.style.width = `${
            canvasDiv.offsetWidth * canvasScale.current
          }px`;
          canvasCropRef.current.style.height = `${
            canvasDiv.offsetHeight * canvasScale.current
          }px`;
        }
      }
    });

    resizeObserver.observe(scrollRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [canvasHeight, canvasWidth, maxScale]);

  useLayoutEffect(() => {
    if (!scrollRef.current) return;
    const scrollDiv = scrollRef.current;

    const touchMoveHandler = (eve: TouchEvent) => {
      // Prevent scrolling when zooming
      if (eve.touches.length > 1) eve.preventDefault();
    };

    scrollDiv.addEventListener('touchstart', touchMoveHandler, {
      passive: false,
    });
    scrollDiv.addEventListener('touchmove', touchMoveHandler, {
      passive: false,
    });

    return () => {
      scrollDiv.removeEventListener('touchmove', touchMoveHandler);
      scrollDiv.removeEventListener('touchstart', touchMoveHandler);
    };
  }, []);

  return (
    <ScrollWrapper
      className={className}
      onTouchStart={(eve) => {
        if (!scrollRef.current) return;

        if (eve.touches.length === 2) {
          const startA = eve.touches.item(0);
          const startB = eve.touches.item(1);

          startTouches.current = [startA, startB];

          const startCenter = center(getPoint(startA), getPoint(startB));
          const scrollWrapBoundingRect =
            scrollRef.current?.getBoundingClientRect();

          const scrollWrapXY: Vec2 = [
            scrollWrapBoundingRect.left,
            scrollWrapBoundingRect.top,
          ];

          // Where is the pinch center relative to the ScrollWrapper element
          const scrollWrapStartCenter = subtract(startCenter, scrollWrapXY);

          // Where is the pinch center relative to the inside scrollable area of ScrollWrapper
          const scrollAreaStartCenter = add(scrollWrapStartCenter, [
            scrollRef.current.scrollLeft,
            scrollRef.current.scrollTop,
          ]);
          scrollAreaStartTouchesCenter.current = scrollAreaStartCenter;
        }
      }}
      onTouchMove={(eve) => {
        if (
          !startTouches.current ||
          !canvasRef.current ||
          !scrollRef.current ||
          !scrollAreaStartTouchesCenter.current
        )
          return;

        const [startA, startB] = startTouches.current;

        const currentA = getTouch(eve, startA.identifier);
        const currentB = getTouch(eve, startB.identifier);

        if (!currentA || !currentB) return;

        lastTouches.current = [currentA, currentB];
        const currentCenter = center(getPoint(currentA), getPoint(currentB));

        const scrollWrapBoundingRect =
          scrollRef.current.getBoundingClientRect();
        const scrollWrapXY: Vec2 = [
          scrollWrapBoundingRect.left,
          scrollWrapBoundingRect.top,
        ];
        // Where is the pinch center relative to the ScrollWrapper element
        const scrollWrapTouchCenter = subtract(currentCenter, scrollWrapXY);

        const startDist = distance(getPoint(startA), getPoint(startB));
        const currDist = distance(getPoint(currentA), getPoint(currentB));

        const scaleChange = currDist / startDist;
        const renderedScale = Math.min(
          maxScale,
          Math.max(minCanvasScale.current, canvasScale.current * scaleChange)
        );

        const scrollTo = subtract(
          multiply(scrollAreaStartTouchesCenter.current, [
            renderedScale / canvasScale.current,
            renderedScale / canvasScale.current,
          ]),
          scrollWrapTouchCenter
        );
        scrollRef.current.scrollTo({
          left: scrollTo[0],
          top: scrollTo[1],
        });

        const canvasDiv = canvasRef.current;
        canvasDiv.style.transform = `scale(${renderedScale})`;

        if (canvasCropRef.current) {
          canvasCropRef.current.style.width = `${
            canvasDiv.offsetWidth * renderedScale
          }px`;
          canvasCropRef.current.style.height = `${
            canvasDiv.offsetHeight * renderedScale
          }px`;
        }
      }}
      onTouchEnd={() => {
        if (!startTouches.current || !canvasRef.current || !lastTouches.current)
          return;
        const [startA, startB] = startTouches.current;

        const startDist = distance(getPoint(startA), getPoint(startB));
        const [endA, endB] = lastTouches.current;

        const endDist = distance(getPoint(endA), getPoint(endB));

        canvasScale.current = Math.min(
          maxScale,
          Math.max(
            minCanvasScale.current,
            canvasScale.current * (endDist / startDist)
          )
        );

        startTouches.current = null;
      }}
      ref={scrollRef}
    >
      {/* CanvasCrop is needed because Canvas, which is scaled using css 'transform', */}
      {/* still takes up the same width & height as it would without the transform style. So CanvasCrop forces Canvas to be its transformed dimensions */}
      <CanvasCrop ref={canvasCropRef}>
        <Canvas
          width={canvasWidth}
          height={canvasHeight}
          ref={canvasRef}
          className={canvasClassName}
        >
          {children}
        </Canvas>
      </CanvasCrop>
    </ScrollWrapper>
  );
};

export default ZoomAndScroll;
