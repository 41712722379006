import Theme from 'constants/theme';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Dot = styled.span`
  display: inline-block;
  min-width: 1.6em;
  min-height: 1.1em;

  border-radius: 1000px;
  border: 1px solid ${Theme.colors.border.input};
`;

const HCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface Props extends PropsWithChildren {
  color: string;
}

const ColorDot: FC<Props> = ({ color, children }) =>
  children ? (
    <HCenter>
      <Dot style={{ backgroundColor: color }} />
      {children}
    </HCenter>
  ) : (
    <Dot style={{ backgroundColor: color }} />
  );

export default ColorDot;
