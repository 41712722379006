import Tabstrip from 'components/Tabstrip';
import Routes from 'constants/routes';
import { FC } from 'react';

const LoginOptionTabs: FC = () => (
  <Tabstrip
    tabs={[
      {
        label: 'Station',
        route: Routes.account.login.stationUser,
      },
      {
        label: 'Kundportal',
        route: Routes.account.login.customerUser,
      },
    ]}
  />
);

export default LoginOptionTabs;
