import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AkCompany, AkCompanyClient } from 'api';
import Modal from 'components/Modal';
import PageHeader, { PageHeaderButton } from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { ButtonCell } from 'components/Table/inputCells';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Theme from 'constants/theme';
import useModalStack from 'contexts/useModalStack';
import usePricingSetup, {
  useUpdatePricingSetupCall,
} from 'contexts/usePricingSetup';
import useUsers from 'contexts/useUsers';
import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { makeStringComparator } from 'utils/sorting';
import EditAkCompanyModal from './EditAkCompanyModal';

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const ManageAkCompaniesPage: FC = () => {
  const { akCompaniesById } = usePricingSetup();
  const { usersById } = useUsers();
  const updatePricingSetupCall = useUpdatePricingSetupCall();
  const modalStack = useModalStack();

  const [editAkCompanyId, setEditAkCompanyId] = useState<number | null>(null);

  const saveAkCompanyCall = useApiCall(
    AkCompanyClient,
    (c, company: AkCompany) => c.saveAkCompany(company)
  );

  const allCompanies = useMemo(
    () => Array.from(akCompaniesById.values()).filter((c) => !c.isRemoved),
    [akCompaniesById]
  );

  const handleSaveAkCompany = useCallback(async (customer: AkCompany) => {
    setEditAkCompanyId(null);
    await saveAkCompanyCall.run(customer);
    await updatePricingSetupCall.run();
  }, []);

  const handleDeleteAkCompany = useCallback(async (customer: AkCompany) => {
    const modalId = modalStack.push(
      <Modal
        title={`Ta bort "${customer.name}"?`}
        buttons={[
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(modalId),
          },
          {
            label: 'Ta bort',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: async () => {
              modalStack.pop(modalId);
              await saveAkCompanyCall.run(
                new AkCompany({ ...customer, isRemoved: true })
              );
              await updatePricingSetupCall.run();
            },
          },
        ]}
        onClose={() => modalStack.pop(modalId)}
      >
        Är du säker på att du vill ta bort stationen "{customer.name}"?
      </Modal>
    );
  }, []);

  const renderContent = () => {
    if (
      saveAkCompanyCall.status === RequestStatus.Fetching ||
      updatePricingSetupCall.status === RequestStatus.Fetching
    )
      return <LoadingSection />;

    return (
      <MyTable
        columnSettings={[
          {
            head: 'Namn',
            render: (c) => c.name,
            width: 100,
            sortFunction: makeStringComparator((c) => c.name),
          },
          {
            head: 'Org. nummer',
            render: (c) => c.organizationNumber,
            width: 100,
            sortFunction: makeStringComparator((c) => c.organizationNumber),
          },
          {
            head: 'Adress',
            render: (c) => `${c.addressLine1}\n${c.addressLine2}`,
            width: 100,
            sortFunction: makeStringComparator(
              (c) => `${c.addressLine1}\n${c.addressLine2}`
            ),
          },
          {
            head: 'Postnr',
            render: (c) => c.zipCode,
            width: 100,
            sortFunction: makeStringComparator((c) => c.zipCode),
          },
          {
            head: 'Ort',
            render: (c) => c.city,
            width: 100,
            sortFunction: makeStringComparator((c) => c.city),
          },
          {
            head: 'Stationsägare',
            render: (c) =>
              usersById.get(c.akCompanyOwnerId ?? -1)?.fullName ?? '',
            width: 100,
            sortFunction: makeStringComparator(
              (c) => usersById.get(c.akCompanyOwnerId ?? -1)?.fullName ?? ''
            ),
          },
          {
            head: 'Telefon',
            render: (c) => c.phoneNumber,
            width: 100,
            sortFunction: makeStringComparator((c) => c.phoneNumber),
          },
          {
            head: 'Epost',
            render: (c) => c.email,
            width: 100,
            sortFunction: makeStringComparator((c) => c.email),
          },
          {
            head: 'Ta bort',
            css: css`
              max-width: 100px;
              min-width: 80px;
            `,
            noPadding: true,
            render: (customer) => (
              <ButtonCell onClick={() => handleDeleteAkCompany(customer)}>
                <FontAwesomeIcon icon={faTrash} />
              </ButtonCell>
            ),
            width: 1,
          },
        ]}
        onRowClick={(company) => setEditAkCompanyId(company.id)}
        rows={allCompanies}
      />
    );
  };

  return (
    <>
      <PageHeader title="Stationer">
        <PageHeaderButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          small
          onClick={() => setEditAkCompanyId(0)}
        >
          Lägg till
        </PageHeaderButton>
      </PageHeader>

      {renderContent()}

      {editAkCompanyId !== null && (
        <EditAkCompanyModal
          akCompany={akCompaniesById.get(editAkCompanyId)}
          onCancel={() => setEditAkCompanyId(null)}
          onSaveAkCompany={handleSaveAkCompany}
        />
      )}
    </>
  );
};

export default ManageAkCompaniesPage;
