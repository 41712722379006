import {
  faAsterisk,
  faHourglass3,
  faTrash,
  faTruck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import Theme from 'constants/theme';
import { JobStatus } from 'api';
import useModalStack from 'contexts/useModalStack';
import Accordion from 'components/Accordion';
import IconButton from 'components/inputs/IconButton';
import CancelCaseJobModal from '../CancelCaseJobModal';
import TextButton from 'components/inputs/TextButton';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { CaseFormInputs } from '../../formUtils/caseFormConverters';
import {
  JobGroupFormInputs,
  JobInstanceFormInputs,
} from '../../formUtils/caseJobFormConverters';
import { humanReadableTimeDuration } from 'utils/date';
import MediaQuery from 'constants/MediaQuery';

const TitleWrap = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 20px max-content max-content;
  gap: 0 10px;
  align-items: center;
  text-align: left;
`;

const TitleIcon = styled(FontAwesomeIcon)`
  grid-area: 1/1/3/2;
`;

const TitleDriverAndRegnr = styled.span`
  grid-area: 1/2/2/3;
`;

const TitleExtraTimeText = styled.span`
  grid-area: 2/2/3/4;
  font-weight: normal;
  white-space: nowrap;

  ${MediaQuery.tablet} {
    grid-area: 1/3/2/4;
  }
`;

const WarnIcon = styled(FontAwesomeIcon)`
  color: ${Theme.colors.warn};
`;

const ErrIcon = styled(TitleIcon)`
  color: ${Theme.colors.bad};
`;

interface Props extends PropsWithChildren {
  form: UseFormReturn<CaseFormInputs>;
  jobGroupFormIndex: number;
  jobInstanceFormIndex: number;

  noEdit: boolean;
  onSetJobInstanceStatus(jobStatus: JobStatus): void;
  onDeleteFormSection(): void;
  className?: string;
}

const CaseJobInstanceAccordion: FC<Props> = ({
  form,
  jobGroupFormIndex,
  jobInstanceFormIndex,

  noEdit,
  onSetJobInstanceStatus,
  onDeleteFormSection,
  className,
  children,
}) => {
  const modalStack = useModalStack();
  const { control } = form;

  const jobGroupFieldPath = <P extends keyof JobGroupFormInputs>(
    jobGroupProp: P
  ) => `jobGroups.${jobGroupFormIndex}.${jobGroupProp}` as const;

  const jobInstanceFieldPath = <P extends keyof JobInstanceFormInputs>(
    jobInstanceProp: P
  ) =>
    `jobGroups.${jobGroupFormIndex}.jobInstances.${jobInstanceFormIndex}.${jobInstanceProp}` as const;

  const [
    caseJobId,
    jobStatus,
    regNr,
    driverName,
    orderedStartDate,
    orderedEndDate,
    actualStartDate,
    actualEndDate,
  ] = useWatch({
    control,
    name: [
      jobInstanceFieldPath('caseJobId'),
      jobInstanceFieldPath('jobStatus'),
      jobInstanceFieldPath('vehicleRegNr'),
      jobInstanceFieldPath('driverName'),
      jobGroupFieldPath('orderedStartUtc'),
      jobGroupFieldPath('orderedEndUtc'),
      jobInstanceFieldPath('actualStartUtc'),
      jobInstanceFieldPath('actualEndUtc'),
    ],
  });

  const isNewJob = caseJobId === 0;
  const jobIsCanceled = [JobStatus.Discarded, JobStatus.LateCancel].includes(
    jobStatus
  );

  const renderTitle = () => {
    const orderedTotalTime =
      new Date(orderedEndDate).getTime() - new Date(orderedStartDate).getTime();

    const actualTotalTime =
      actualStartDate && actualEndDate
        ? new Date(actualEndDate).getTime() -
          new Date(actualStartDate).getTime()
        : orderedTotalTime;

    const totalTimeDiff = actualTotalTime - orderedTotalTime;
    const timeDiffElement = totalTimeDiff ? (
      <TitleExtraTimeText title="Fakturerad tid skiljer sig från bokad tid.">
        <WarnIcon icon={faHourglass3} /> {totalTimeDiff > 0 ? '+' : '-'}
        {humanReadableTimeDuration(Math.abs(totalTimeDiff))}
      </TitleExtraTimeText>
    ) : null;

    switch (jobStatus) {
      case JobStatus.Discarded:
        return (
          <TitleWrap>
            <TitleIcon icon={faTrash} />
            <i>Makulerad</i>
          </TitleWrap>
        );

      case JobStatus.LateCancel:
        return (
          <TitleWrap>
            <TitleIcon icon={faTrash} />
            <i>Sen avbokning</i>
            {timeDiffElement}
          </TitleWrap>
        );

      default:
        return (
          <TitleWrap>
            {!regNr || !driverName ? (
              <ErrIcon icon={faAsterisk} title="Saknar förare eller fordon" />
            ) : (
              <TitleIcon icon={faTruck} />
            )}
            <TitleDriverAndRegnr>
              {regNr} {regNr && driverName && '-'} {driverName}
            </TitleDriverAndRegnr>

            {timeDiffElement}
          </TitleWrap>
        );
    }
  };

  const renderActions = () => {
    if (noEdit) return null;

    if (jobIsCanceled) {
      return (
        <TextButton
          onClick={(eve) => {
            eve.preventDefault();
            eve.stopPropagation();
            onSetJobInstanceStatus(JobStatus.Normal);
          }}
        >
          Öppna
        </TextButton>
      );
    }

    return (
      <IconButton
        onClick={(eve) => {
          eve.preventDefault();
          eve.stopPropagation();

          if (isNewJob) {
            onDeleteFormSection();
          } else {
            const modalId = modalStack.push(
              <CancelCaseJobModal
                onClose={() => modalStack.pop(modalId)}
                onSetJobStatus={(newJobStatus) => {
                  modalStack.pop(modalId);
                  onSetJobInstanceStatus(newJobStatus);
                }}
              />
            );
          }
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    );
  };

  return (
    <Accordion
      isDefaultOpen={isNewJob}
      title={renderTitle()}
      actions={renderActions()}
      className={className}
    >
      {children}
    </Accordion>
  );
};

export default CaseJobInstanceAccordion;
