import { Case } from 'api';
import OverlayedSpinner from 'components/spinners/OverlayedSpinner';
import MediaQuery from 'constants/MediaQuery';
import CaseEditor from 'pages/Case/CaseEditor';
import useCaseEditorState from 'pages/Case/CaseEditor/useCaseEditorState';
import { FC } from 'react';
import styled from 'styled-components';
import { RequestStatus } from 'swaggerhooks';
import { JobFormIndexes } from './useCaseJobEvents';

export const caseSlideInTabletWidth = 500;

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${MediaQuery.tablet} {
    min-width: ${caseSlideInTabletWidth}px;
  }
`;

interface Props {
  caseEditorState: ReturnType<typeof useCaseEditorState>;
  originalCase?: Case;
  highlightCaseJobIndex?: JobFormIndexes;
  onShowJobInCalendarClick(jobFormIndexes: JobFormIndexes): void;
}

const CaseSlideIn: FC<Props> = ({
  caseEditorState,
  originalCase,
  highlightCaseJobIndex,
  onShowJobInCalendarClick,
}) => {
  return (
    <Wrapper>
      <CaseEditor
        form={caseEditorState.form}
        onSaveMessage={caseEditorState.handleSaveMessage}
        isSavingMessage={
          caseEditorState.saveCaseMessageCall.status ===
            RequestStatus.Fetching ||
          caseEditorState.uploadAttachmentCall.status === RequestStatus.Fetching
        }
        submitHandler={caseEditorState.submitHandler}
        originalCase={originalCase}
        mobileView
        highlightCaseJobIndex={highlightCaseJobIndex}
        onShowJobInCalendarClick={onShowJobInCalendarClick}
        onReopenCase={caseEditorState.handleReopenCase}
      />
      {caseEditorState.saveCaseCall.status === RequestStatus.Fetching && (
        <OverlayedSpinner>Sparar ärende...</OverlayedSpinner>
      )}
      {caseEditorState.reopenCaseCall.status === RequestStatus.Fetching && (
        <OverlayedSpinner>Öppnar ärende...</OverlayedSpinner>
      )}
    </Wrapper>
  );
};

export default CaseSlideIn;
