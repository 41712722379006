import { faCopy, faEdit, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/Modal';
import { FC } from 'react';

interface Props {
  onConfirm(): void;
  onClose(): void;
}

const ConfirmEditPriceListModal: FC<Props> = ({ onConfirm, onClose }) => (
  <Modal
    title={
      <>
        <FontAwesomeIcon icon={faWarning} /> Redigera prislista?
      </>
    }
    onClose={onClose}
    buttons={[
      {
        label: 'Avbryt',
        onClick: () => onClose(),
      },
      {
        label: 'Redigera',
        icon: <FontAwesomeIcon icon={faEdit} />,
        onClick: () => onConfirm(),
      },
    ]}
  >
    <p>
      <b>Varning:</b> Den här prislistan är eller har varit aktiv. Eventuell
      redigering kan förändra slutsumman för ofakturerade ärenden.
    </p>

    <p>
      Om du vill lägga in nya prissättningar bör du istället{' '}
      <b>
        duplicera <FontAwesomeIcon icon={faCopy} />
      </b>{' '}
      en befintlig prislista och ändra på "Aktiv från"-datumet, alternativt
      skapa en ny prislista.
    </p>
  </Modal>
);

export default ConfirmEditPriceListModal;
