import Modal, { modalContentClass } from 'components/Modal';
import { FC } from 'react';
import styled from 'styled-components';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    overflow: auto;
    max-width: 1500px;
  }
`;

interface Props {
  onClose(): void;
}

const PriceAdjustmentInfoModal: FC<Props> = ({ onClose }) => {
  return (
    <MyModal
      title="Prisjusteringar"
      onClose={onClose}
      buttons={[{ label: 'Ok', onClick: onClose }]}
    >
      <h2>OB-tid prisökning</h2>
      <p>
        OB-tid prisökning anger hur mycket timtaxan ska öka under obekväm
        arbetstid (före klockan 07:00 och efter klockan 17:00).{' '}
      </p>
      <p>
        <b>Exempel:</b> Om man ställer in OB-tid prisökning till 200%, timtaxan
        till 10kr/timme och ett uppdrag pågår från kl. 16:00 till 18:00 så blir
        priset (10kr/h * 1h) + (10kr/h * 1h * 200%) = 30kr.
      </p>
      <h2>Övertidspåslag</h2>
      <p>
        Övertidspåslag anger hur mycket timtaxan ska öka när den faktiska
        uppdragstiden blir längre än den planerade uppdragstiden.
        <br />
        Det går också att ställa in så att övertidspåslaget tar effekt efter ett
        visst antal timmar.
      </p>
      <p>
        <b>Exempel:</b> Övertidspåslag är inställt till 200% och gäller 2 timmar
        efter den bokade sluttiden. Timpriset är 10kr/timme.
        <ul>
          <li>
            Uppdrag 1 är bokat att utföras mellan kl. 12:00 och 14:00, men blir
            inte klart förens kl. 16:00.
            <br /> Då den faktiska sluttiden är exakt 2 timmar efter den bokade
            sluttiden så räknas ingen övertid in, och det totala priset blir
            10kr/h * 4h = 40kr.
          </li>
          <li>
            Uppdrag 2 är bokat att utföras mellan kl. 12:00 och 14:00, men blir
            inte klart förens kl. 17:00.
            <br /> Då den faktiska sluttiden är 3 timmar efter den bokade
            sluttiden så räknas övertiden till 1 timme, och det totala priset
            blir (10kr/h * 4h) + (10kr/h * 200% * 1h) = 60kr.
          </li>
        </ul>
      </p>
      <h2>Långbokning</h2>
      <p>
        Långbokning anger hur mycket rabatt ett uppdrag får om uppdragstiden
        överstiger ett visst antal timmar.
      </p>
      <p>
        <b>Exempel:</b> Timtaxan är 10kr/timme och långboknings-rabatten är 20%
        och gäller beställningar på 4 eller fler timmar.
        <br />
        <ul>
          <li>
            Ett uppdrag som har pågått i 3 timmar får ingen rabatt, priset blir
            30kr.
          </li>
          <li>
            Ett uppdrag som har pågått i 4 timmar får däremot rabatt, priset
            blir 10kr/h * 4h * 80% = 32kr
          </li>
        </ul>
      </p>
    </MyModal>
  );
};

export default PriceAdjustmentInfoModal;
