import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AkCompany, AkCompanyClient } from 'api';
import SelectedAkCompanyIcon from 'components/AkCompanyIcons/SelectedAkCompanyIcon';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import LoadingSection from 'components/spinners/LoadingSection';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { useUpdatePricingSetupCall } from 'contexts/usePricingSetup';
import useUsers from 'contexts/useUsers';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import useSelectedAkCompany from 'utils/useSelectedAkCompany';
import {
  AkCompanyForm,
  AkCompanyFormToAkCompany,
  createAkCompanyForm,
} from '../ManageAkCompanies/AkCompanyFormConverters';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
`;

const Title = styled.h1`
  margin-top: 20px;
  padding: 20px ${Theme.sizes.padding.screenInset};

  font: inherit;
  font-weight: 700;
  font-size: ${Theme.sizes.font.large};
  text-align: center;
`;

const Inputs = styled.div<{ editMode: boolean }>`
  display: grid;
  grid-template-columns: 100%;
  gap: ${({ editMode }) => (editMode ? 20 : 30)}px;
  padding: 20px ${Theme.sizes.padding.screenInset};

  ${MediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
    gap: ${({ editMode }) => (editMode ? 20 : 40)}px;
  }
`;

const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 1.5em;

  border-bottom: 1px dashed ${Theme.colors.border.main};
  font-weight: 600;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px ${Theme.sizes.padding.screenInset};
  gap: 10px;
`;

const ManageMyAkCompany: FC = () => {
  const { isAkCompanyOwner, selectedAkCompany } = useSelectedAkCompany();
  const { users, usersById } = useUsers();
  const updatePricingSetupCall = useUpdatePricingSetupCall();

  const saveAkCompanyCall = useApiCall(
    AkCompanyClient,
    (c, company: AkCompany) => c.saveAkCompany(company)
  );

  const { register, reset, formState, handleSubmit, getValues } = useForm({
    defaultValues: createAkCompanyForm(selectedAkCompany),
  });

  useEffect(() => {
    reset(createAkCompanyForm(selectedAkCompany));
  }, [selectedAkCompany]);

  const { isDirty, isValid } = formState;

  const submit = handleSubmit(async (formValues) => {
    const company = AkCompanyFormToAkCompany(formValues, selectedAkCompany);
    await saveAkCompanyCall.run(company);
    await updatePricingSetupCall.run();
  });

  const mkInput = (name: keyof AkCompanyForm, label: string) => (
    <LabelWrap label={label}>
      {isAkCompanyOwner ? (
        <Input {...register(name)} />
      ) : (
        <Value>{getValues(name)}</Value>
      )}
    </LabelWrap>
  );

  if (
    [saveAkCompanyCall.status, updatePricingSetupCall.status].includes(
      RequestStatus.Fetching
    )
  ) {
    return <LoadingSection />;
  }

  return (
    <Wrapper>
      <Center>
        <Title>
          <SelectedAkCompanyIcon /> {selectedAkCompany?.name ?? 'Din station'}
        </Title>

        <Inputs editMode={isAkCompanyOwner}>
          {mkInput('name', 'Namn')}

          <LabelWrap label="Stationsägare">
            {isAkCompanyOwner ? (
              <Select
                {...register('akCompanyOwnerId', {
                  setValueAs: (value) =>
                    value === '' ? undefined : Number(value),
                })}
                disabled
              >
                <option value="">Ingen</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.fullName}
                  </option>
                ))}
              </Select>
            ) : (
              <Value>
                {usersById.get(getValues('akCompanyOwnerId') ?? -1)?.fullName ??
                  ''}
              </Value>
            )}
          </LabelWrap>
          {mkInput('phoneNumber', 'Telefon')}
          {mkInput('email', 'Epost')}
          {mkInput('contactPerson', 'Kontaktperson')}

          {mkInput('addressLine1', 'Adressrad 1')}
          {mkInput('addressLine2', 'Adressrad 2')}
          {mkInput('zipCode', 'Postnr')}
          {mkInput('city', 'Ort')}
          {mkInput('organizationNumber', 'Organisationsnummer')}

          {mkInput('bankgiro', 'Bankgiro')}
          {mkInput('plusgiro', 'Plusgiro')}
          {mkInput('invoiceReferenceIdPrefix', 'Prefix till fakturanummer')}
        </Inputs>

        {isAkCompanyOwner && (
          <Buttons>
            {isDirty && (
              <Button
                icon={<FontAwesomeIcon icon={faRotateLeft} />}
                onClick={() => reset()}
              >
                Återställ
              </Button>
            )}
            <Button disabled={!isDirty || !isValid} onClick={() => submit()}>
              Spara
            </Button>
          </Buttons>
        )}
      </Center>
    </Wrapper>
  );
};

export default ManageMyAkCompany;
