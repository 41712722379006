import Theme from 'constants/theme';
import moment from 'moment';
import styled from 'styled-components';
import { DateSpan } from './utils';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

const Inner = styled.button`
  padding: 0 10px;
  margin: 5px;

  border: none;
  font: inherit;
  background-color: ${Theme.colors.bg.selection};
  color: ${Theme.colors.fg.selection};
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
`;

interface Props<DS extends DateSpan> {
  day: Date;
  events: DS[];
  containerStart: Date;
  containerEnd: Date;
  onClick(eve: React.MouseEvent): void;
}

const OverflowedEvents = <DS extends DateSpan>({
  containerStart,
  containerEnd,
  day,
  events,
  onClick,
}: Props<DS>) => {
  const getPercentageOfWeek = (date: Date) =>
    ((date.getTime() - containerStart.getTime()) /
      (containerEnd.getTime() - containerStart.getTime())) *
    100;

  const fromPercent = getPercentageOfWeek(moment(day).startOf('day').toDate());
  const toPercent = getPercentageOfWeek(moment(day).endOf('day').toDate());

  return (
    <Wrapper
      style={{
        left: `${fromPercent}%`,
        right: `${100 - toPercent}%`,
      }}
    >
      <Inner onClick={onClick}>+{events.length}</Inner>
    </Wrapper>
  );
};

export default OverflowedEvents;
