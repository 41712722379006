import { CustomerCaseJob, JobStatus, JobType } from 'api';
import moment from 'moment';
import { toInputDateTimeString } from 'utils/date';

export interface CustomerCaseJobFormInputs {
  amount: number;
  orderedStartUtc: string;
  orderedEndUtc: string;
}

export function createCaseJobForm(
  caseJob?: CustomerCaseJob
): CustomerCaseJobFormInputs {
  const defaultStartingAmount = 1;

  if (caseJob) {
    return {
      amount: defaultStartingAmount,
      orderedStartUtc: isNaN(caseJob.orderedStartUtc.getTime())
        ? ''
        : toInputDateTimeString(caseJob.orderedStartUtc),
      orderedEndUtc: isNaN(caseJob.orderedEndUtc.getTime())
        ? ''
        : toInputDateTimeString(caseJob.orderedEndUtc),
    };
  }

  const from = moment(new Date()).startOf('day').toDate();

  return {
    amount: defaultStartingAmount,
    orderedStartUtc: toInputDateTimeString(from),
    orderedEndUtc: '',
  };
}

export function caseJobFormToCaseJob(
  formInputs: CustomerCaseJobFormInputs
): CustomerCaseJob {
  return new CustomerCaseJob({
    id: 0,
    jobType: JobType.TMA, // Customers are only allowed to create TMA jobs for now.
    jobStatus: JobStatus.Normal,
    orderedStartUtc: new Date(formInputs.orderedStartUtc),
    orderedEndUtc: new Date(formInputs.orderedEndUtc),
  });
}
