import { faTrash, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerCase, JobStatus, JobType } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import KeyValueList from 'components/KeyValueList';
import PhoneSlideInView from 'components/Modal/PhoneSlideInView';
import SectionHeader from 'components/SectionHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import useBasicData from 'contexts/useBasicData';
import usePricingSetup from 'contexts/usePricingSetup';
import { caseSlideInTabletWidth } from 'pages/PlannerTool/CaseSlideIn';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { ApiResponse, RequestStatus } from 'swaggerhooks';
import { formatDateTime } from 'utils/date';
import { oneLineAddressString } from '../utils';

const MySlideIn = styled(PhoneSlideInView)`
  ${MediaQuery.tablet} {
    transition: width 0.2s;
    border-left: 1px solid ${Theme.colors.border.main};

    ${({ open }) =>
      open
        ? css`
            width: ${caseSlideInTabletWidth}px;
          `
        : css`
            width: 0;
          `}
  }
`;

const ScrollWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Section = styled.section`
  padding: 10px ${Theme.sizes.padding.screenInset};
`;

const JobHeader = styled(SectionHeader)`
  padding-inline: 0;
  margin-bottom: 10px;
`;

const PreText = styled.pre`
  margin: 0;
  padding: 0;
  font-family: inherit;
  white-space: pre-wrap;
`;

const MyKvList = styled(KeyValueList)``;

interface Props {
  onClose(): void;
  openCase: ApiResponse<CustomerCase | null>;
}

const CustomerCaseSlideIn: FC<Props> = ({ onClose, openCase }) => {
  const { akCompaniesById } = usePricingSetup();
  const { caseStatusNames, jobTypeNames } = useBasicData();

  const renderContent = () => {
    switch (openCase.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        if (!openCase.response) return null;
        const caseModel = openCase.response;

        return (
          <ScrollWrap>
            <Section>
              <MyKvList
                colonKey
                rows={[
                  {
                    key: 'Status',
                    value: caseStatusNames.get(caseModel.caseStatus),
                  },
                  { key: 'Er referens', value: caseModel.externalReference },
                  {
                    key: 'Station',
                    value:
                      akCompaniesById.get(caseModel.akCompanyId)?.name ??
                      'Okänd',
                  },
                  {
                    key: 'Adress',
                    value: oneLineAddressString(caseModel),
                  },
                  {
                    key: 'Mötesplats',
                    value: caseModel.meetupLocation ?? '',
                  },
                ]}
              />
            </Section>

            {caseModel.locationDescription.trim() && (
              <Section>
                <b>Platsbeskrivning:</b>
                <PreText>{caseModel.locationDescription}</PreText>
              </Section>
            )}

            {caseModel.locationDescription.trim() && (
              <Section>
                <b>Kommentar:</b>
                <PreText>{caseModel.freetextNotes}</PreText>
              </Section>
            )}

            {caseModel.caseJobs.map((job) => {
              const isDiscarded = [
                JobStatus.Discarded,
                JobStatus.LateCancel,
              ].includes(job.jobStatus);

              return (
                <Section key={job.id}>
                  <JobHeader
                    title={
                      <>
                        <FontAwesomeIcon
                          icon={isDiscarded ? faTrash : faTruck}
                        />{' '}
                        {jobTypeNames.get(job.jobType) || 'Jobb'}
                        {isDiscarded && <i> - Makulerad</i>}
                      </>
                    }
                  />
                  <MyKvList
                    colonKey
                    rows={[
                      {
                        key: 'Från',
                        value: formatDateTime(job.orderedStartUtc),
                      },
                      { key: 'Till', value: formatDateTime(job.orderedEndUtc) },
                    ]}
                  />
                </Section>
              );
            })}
          </ScrollWrap>
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => openCase.update()} />;
    }
  };

  return (
    <MySlideIn
      onClose={onClose}
      open={!!openCase.response}
      title={`Ärende ${openCase.response?.id ?? ''}`}
      keepTitleForTablet
    >
      {renderContent()}
    </MySlideIn>
  );
};

export default CustomerCaseSlideIn;
