import { AccordionTogglerClassName } from 'components/Accordion';
import PillButton from 'components/inputs/PillButton';
import Theme from 'constants/theme';
import { JobFormIndexes } from 'pages/PlannerTool/useCaseJobEvents';
import { FC } from 'react';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import styled, { css, keyframes } from 'styled-components';
import { CaseFormInputs } from '../../formUtils/caseFormConverters';
import { createNewJobInstanceFormInputs } from '../../formUtils/caseJobFormConverters';
import CaseJobInstanceFields from './CaseJobInstanceFields';

export const makeJobInstanceClassName = (jobIndex: number) =>
  `job-instance-${jobIndex}`;

const blink = keyframes`
    50% {
      background-color: var(--blink-bg);
      color: var(--blink-fg);
    }
  `;

const MyCaseJobInstanceFields = styled(CaseJobInstanceFields)<{
  highlighted: boolean;
}>`
  --blink-bg: ${Theme.colors.bg.selection};
  --blink-fg: ${Theme.colors.fg.selection};

  .${AccordionTogglerClassName} {
    ${({ highlighted }) =>
      highlighted &&
      css`
        animation: ${blink} 0.8s;
      `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddInstanceButton = styled(PillButton)`
  margin-top: 10px;
`;

interface Props {
  form: UseFormReturn<CaseFormInputs>;
  groupFormIndex: number;
  noEdit: boolean;
  caseJobInstances: UseFieldArrayReturn<
    CaseFormInputs,
    `jobGroups.${number}.jobInstances`,
    'id'
  >;
  highlightedIndex: JobFormIndexes | undefined;
}

const CaseJobInstances: FC<Props> = ({
  form,
  groupFormIndex,
  noEdit,
  caseJobInstances,
  highlightedIndex,
}) => {
  const handleAddCaseJobInstance = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    caseJobInstances.append(createNewJobInstanceFormInputs());
  };

  return (
    <Wrapper>
      {caseJobInstances.fields.map((jobInstanceField, formIndex) => (
        <MyCaseJobInstanceFields
          key={jobInstanceField.id}
          form={form}
          jobGroupFormIndex={groupFormIndex}
          jobInstanceFormIndex={formIndex}
          noEdit={noEdit}
          onDelete={() => {
            caseJobInstances.remove(formIndex);
          }}
          highlighted={
            groupFormIndex === highlightedIndex?.jobGroupFormIndex &&
            formIndex === highlightedIndex?.jobInstanceFormIndex
          }
          className={makeJobInstanceClassName(formIndex)}
        />
      ))}

      {!noEdit && (
        <AddInstanceButton onClick={handleAddCaseJobInstance}>
          + Lägg till fordon
        </AddInstanceButton>
      )}
    </Wrapper>
  );
};

export default CaseJobInstances;
