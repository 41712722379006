import { Vehicle } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { CaseJobEvent } from 'pages/PlannerTool/useCaseJobEvents';
import { useEffect } from 'react';
import makeUseLocalStorageState from 'utils/useLocalStorageState';

export interface JobsFilterState {
  stateInitializedForAkCompanies: number[];
  selectedCustomerIds: Set<number>;
}

const useJobsFilterSavedState = makeUseLocalStorageState<JobsFilterState>(
  'akspay-jobsFilterState',
  {
    selectedCustomerIds: new Set(),
    stateInitializedForAkCompanies: [],
  },
  (serialized) => {
    const parsed = JSON.parse(serialized);

    return {
      selectedCustomerIds: new Set(parsed?.selectedCustomerIds),
      stateInitializedForAkCompanies:
        parsed?.stateInitializedForAkCompanies ?? [],
    };
  },
  (value) =>
    JSON.stringify({
      selectedCustomerIds: Array.from(value.selectedCustomerIds),
      stateInitializedForAkCompanies: value.stateInitializedForAkCompanies,
    })
);

export interface VehicleWithJobs {
  vehicle: Vehicle;
  jobs: CaseJobEvent[];
}

export interface VehicleTypeGroup {
  vehicleTypeName: string;
  vehicles: VehicleWithJobs[];
}

const useJobsFilterState = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { customerIdsByAkCompanyId } = usePricingSetup();
  const [state, setState] = useJobsFilterSavedState();

  useEffect(() => {
    // Automatically update filter state to show all customers
    // for a company that the user hasn't viewed before.
    if (
      selectedAkCompanyId !== null &&
      !state.stateInitializedForAkCompanies.includes(selectedAkCompanyId) &&
      customerIdsByAkCompanyId.size > 0
    ) {
      setState((state) => ({
        ...state,
        selectedCustomerIds: new Set([
          ...Array.from(state.selectedCustomerIds),
          ...(customerIdsByAkCompanyId.get(selectedAkCompanyId) ?? []),
        ]),

        stateInitializedForAkCompanies: [
          ...state.stateInitializedForAkCompanies,
          selectedAkCompanyId,
        ],
      }));
    }
  }, [
    customerIdsByAkCompanyId,
    selectedAkCompanyId,
    state.stateInitializedForAkCompanies,
    setState,
  ]);

  const selectCustomers = (customerIds: number[]) => {
    setState((state) => ({
      ...state,
      selectedCustomerIds: new Set([
        ...Array.from(state.selectedCustomerIds),
        ...customerIds,
      ]),
    }));
  };

  const deselectCustomers = (customerIds: number[]) => {
    setState((state) => ({
      ...state,
      selectedCustomerIds: new Set(
        Array.from(state.selectedCustomerIds).filter(
          (id) => !customerIds.includes(id)
        )
      ),
    }));
  };

  return {
    filterState: state,
    selectCustomers,
    deselectCustomers,
  };
};

export default useJobsFilterState;
