import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { checkBoxStyle } from './Checkbox';

interface CheckBoxButtonProps {
  checked?: boolean;
  disabled?: boolean;
}

export const CheckBoxButton = styled.button<CheckBoxButtonProps>`
  ${checkBoxStyle}
  background: transparent;
  color: currentColor;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${Theme.colors.bg.accent1};
      border-color: ${Theme.colors.bg.accent1};
      color: ${Theme.colors.fg.accent1};

      & > * {
        opacity: 1;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    css`
      background-color: ${Theme.colors.bg.background4};
      border-color: ${Theme.colors.bg.background4};
      color: ${Theme.colors.fg.background4};
    `}
`;

interface Props {
  onClick?(eve: React.MouseEvent): void;
  disabled?: boolean;

  fullyChecked?: boolean;
  partiallyChecked?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const PartialCheckBox: FC<Props> = ({
  onClick,
  fullyChecked,
  partiallyChecked,
  disabled,
  className,
  style,
}) => (
  <CheckBoxButton
    onClick={onClick}
    disabled={disabled}
    checked={fullyChecked}
    className={className}
    style={style}
  >
    {fullyChecked ? (
      <FontAwesomeIcon icon={faCheck} />
    ) : partiallyChecked ? (
      <FontAwesomeIcon icon={faMinus} />
    ) : null}
  </CheckBoxButton>
);

export default PartialCheckBox;
