import Theme from 'constants/theme';
import React, { useCallback, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import { safeColor } from 'utils/color';
import { createElementSetterProxy } from 'utils/dom';

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
`;

const Clicker = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 50px;
  height: 25px;

  border-radius: 1000px;
  border: 1px solid ${Theme.colors.border.input};
`;

type Props = React.HTMLProps<HTMLInputElement>;

const ColorInput = React.forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, externalRef) => {
    const clickerRef = useRef<HTMLLabelElement | null>(null);
    const ownInputRef = useRef<HTMLInputElement | null>(null);

    const handleValueChanged = useCallback((value: string) => {
      if (clickerRef.current) {
        const color = safeColor(value, '#000');
        clickerRef.current.style.backgroundColor = color.hex();
        clickerRef.current.style.borderColor = color
          .saturate(1)
          .lightness(30)
          .hex();
      }
    }, []);

    useLayoutEffect(() => {
      const inputElement = ownInputRef.current;
      if (!inputElement) return;

      const listnener = (eve: Event) => {
        if (eve.target instanceof HTMLInputElement)
          handleValueChanged(eve.target.value);
      };

      inputElement.addEventListener('input', listnener);

      return () => {
        inputElement.removeEventListener('input', listnener);
      };
    }, [handleValueChanged]);

    const setRef = (newRef: HTMLInputElement | null) => {
      ownInputRef.current = newRef;

      let proxyRef = newRef;
      if (newRef) {
        // Using a proxy on 'value' here because the 'input' event isn't triggered when the input is resetted.
        proxyRef = createElementSetterProxy(newRef, (target, key, value) => {
          (target as any)[key] = value;

          if (key === 'value') {
            handleValueChanged(value);
          }
          return true;
        });
      }

      if (externalRef instanceof Function) {
        externalRef(proxyRef);
      } else if (externalRef) {
        externalRef.current = proxyRef;
      }
    };

    return (
      <Clicker className={className} ref={clickerRef}>
        <Input {...props} type="color" as={undefined} ref={setRef} />
      </Clicker>
    );
  }
);

export default ColorInput;
