import MediaQuery from 'constants/MediaQuery';
import { FC, PropsWithChildren } from 'react';
import {
  createGlobalStyle,
  css,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';
import makeThemeGetters from '../../utils/makeThemeGetters';
import phoneSmallTheme from './phoneSmallTheme';
import phoneTheme from './phoneTheme';
import desktopTheme from './desktopTheme';
import darkTheme from './darkTheme';
import makeUseLocalStorageState from 'utils/useLocalStorageState';
import tabletTheme from './tabletTheme';

export enum DarkMode {
  auto = 'auto',
  light = 'light',
  dark = 'dark',
}

export const useThemeSettings = makeUseLocalStorageState(
  'akspay-theme-settings',
  {
    darkmode: DarkMode.auto,
  },
  (json) => {
    const obj = JSON.parse(json);
    return {
      darkmode: obj?.darkmode || DarkMode.auto,
    };
  },
  (object) => JSON.stringify(object)
);

const supportsCSSVariables =
  window.CSS && CSS.supports('color', 'var(--fake-var)');

/** This is the base theme used for the smallest screens, and all larger screensizes can overwrite values */
const phoneSmallThemeGetters = makeThemeGetters(
  phoneSmallTheme,
  supportsCSSVariables
);

const phoneThemeGetters = makeThemeGetters(phoneTheme, supportsCSSVariables);
const tabletThemeGetters = makeThemeGetters(tabletTheme, supportsCSSVariables);
const desktopThemeGetters = makeThemeGetters(
  desktopTheme,
  supportsCSSVariables
);
const darkThemeGetters = makeThemeGetters(darkTheme, supportsCSSVariables);

const GlobalCSSVariables = createGlobalStyle<{ darkMode: DarkMode }>`
  :root {
    ${phoneSmallThemeGetters.cssVariableDefs ?? ''}
  }

  // The default theme (phoneSmallTheme) is used for very small screens such as an ipad split view.
  // "phoneTheme" should be used for most smartphones.
  @media only screen and (min-width: 325px){
    :root {
      ${phoneThemeGetters.cssVariableDefs ?? ''}
    }
  }

  ${MediaQuery.tablet} {
    :root {
      ${tabletThemeGetters.cssVariableDefs ?? ''}
    }
  }

  ${MediaQuery.desktop} {
    :root {
      ${desktopThemeGetters.cssVariableDefs ?? ''}
    }
  }

  ${({ darkMode }) => {
    switch (darkMode) {
      case DarkMode.auto:
        return css`
          @media (prefers-color-scheme: dark) {
            :root {
              ${darkThemeGetters.cssVariableDefs ?? ''}
            }
          }
        `;

      case DarkMode.dark:
        return css`
          :root {
            ${darkThemeGetters.cssVariableDefs ?? ''}
          }
        `;

      case DarkMode.light:
        return '';
    }
  }}
`;

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [{ darkmode }] = useThemeSettings();

  return (
    // Note that getting theme values via Styled components theme context will return values from the theme compatible with the smallest screen.
    <StyledComponentsThemeProvider theme={phoneSmallThemeGetters.themeValues}>
      <GlobalCSSVariables darkMode={darkmode} />
      {children}
    </StyledComponentsThemeProvider>
  );
};

/** declare types for Styled components context (shouldn't be used though) */
type DefaultThme = typeof phoneSmallThemeGetters.themeValues;
declare module 'styled-components' {
  export interface DefaultTheme extends DefaultThme {}
}

const Theme = phoneSmallThemeGetters.themeGetters;
export default Theme;
