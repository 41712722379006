import SelectedAkCompanyIcon from 'components/AkCompanyIcons/SelectedAkCompanyIcon';
import Select from 'components/inputs/Select';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import useSelectableAkCompanies from 'utils/useSelectableAkCompanies';

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  color: ${Theme.colors.fg.accent1};
  border: 1px solid currentColor;
  background-color: transparent;

  ${MediaQuery.tablet} {
    background-color: ${Theme.colors.bg.input};
    color: ${Theme.colors.fg.input};
    border: 1px solid ${Theme.colors.border.input};

    ${({ disabled }) =>
      disabled &&
      css`
        border: none;
        background-color: transparent;
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      border: none;
      background-color: transparent;
    `}
`;

const MySelectedAkCompanyIcon = styled(SelectedAkCompanyIcon)`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 15px;
`;

const MySelect = styled(Select)`
  position: relative;
  z-index: 2;
  padding-left: 35px;

  border: 0;
  background-color: transparent;
  color: inherit;

  option {
    background-color: ${Theme.colors.bg.background1};
    color: ${Theme.colors.fg.background1};
  }
`;

const DisabledCompanyName = styled.div`
  padding-left: 35px;
  padding-right: 10px;
`;

interface Props {
  disabled?: boolean;
  className?: string;
}

const AkCompanySelector: FC<Props> = ({ disabled, className }) => {
  const { accountInfo, onSelectAkCompany } = useAccountInfo();
  const { akCompaniesById } = usePricingSetup();

  const selectableAkCompanies = useSelectableAkCompanies();

  const handleSelect = (eve: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectAkCompany(Number(eve.currentTarget.value));
  };

  if (
    !accountInfo.user ||
    selectableAkCompanies.length === 0 ||
    akCompaniesById.size === 0
  ) {
    return null; // user is not logged in, or app is still loading
  }

  return (
    <Wrapper disabled={disabled} className={className}>
      <MySelectedAkCompanyIcon />

      {disabled ? (
        <DisabledCompanyName>
          {
            selectableAkCompanies.find(
              (company) => company.id === accountInfo.selectedAkCompanyId
            )?.name
          }
        </DisabledCompanyName>
      ) : (
        <MySelect
          onChange={handleSelect}
          value={accountInfo.selectedAkCompanyId ?? ''}
        >
          {selectableAkCompanies.map((company) => (
            <option value={company.id} key={company.id}>
              {company.name}
            </option>
          ))}
        </MySelect>
      )}
    </Wrapper>
  );
};

export default AkCompanySelector;
