import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import { FC } from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import Theme from 'constants/theme';
import usePricingSetup from 'contexts/usePricingSetup';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import CustomerFormInputGrid from './CustomerFormInputGrid';
import { CustomerCaseFormInputs } from './formUtils/caseFormConverters';
import Select from 'components/inputs/Select';
import useSelectedCustomerId, {
  useCustomerHasCentralAgreement,
} from 'contexts/useSelectedCustomer';
import useCustomerSelectableAkCompanies from './formUtils/useSelectableAkCompanies';

const Wrapper = styled.div`
  padding: 0 ${Theme.sizes.padding.screenInset};
`;

const MyTextArea = styled(AutoSizedTextArea)`
  resize: vertical;
  max-height: 150px;
`;

interface Props {
  form: UseFormReturn<CustomerCaseFormInputs>;
}

const CustomerCaseFields: FC<Props> = ({ form }) => {
  const { register } = form;

  const { customersById } = usePricingSetup();
  const selectedCustomerId = useSelectedCustomerId();

  const isCentralAgreementCustomer = useCustomerHasCentralAgreement();

  const mkField = <K extends keyof CustomerCaseFormInputs>(
    fieldName: K,
    label: string,
    type: React.HTMLInputTypeAttribute = 'text',
    options?: RegisterOptions<CustomerCaseFormInputs, K>
  ) => (
    <LabelWrap label={label}>
      <Input type={type} {...register(fieldName, options)} />
    </LabelWrap>
  );

  const akCompanyOptions = useCustomerSelectableAkCompanies();

  const mkSelect = <K extends keyof CustomerCaseFormInputs>(
    fieldName: K,
    label: string,
    options: Array<{
      value: number;
      label: string;
    }>,
    registerOptions?: RegisterOptions<CustomerCaseFormInputs, K>
  ) => (
    <LabelWrap label={label}>
      <Select
        {...register(fieldName, {
          valueAsNumber: true,
          ...registerOptions,
        })}
      >
        <option value="-1" disabled>
          Välj ett värde
        </option>
        {options.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </Select>
    </LabelWrap>
  );

  return (
    <Wrapper>
      <CustomerFormInputGrid>
        {isCentralAgreementCustomer &&
          mkSelect('akCompanyId', 'Välj station', akCompanyOptions, {
            validate: (v) =>
              v === -1 ? 'Du måste välja en station' : undefined,
          })}
        {mkField('externalReference', 'Er referens', undefined, {
          validate: (v) => {
            if (selectedCustomerId === null) return;

            const customer = customersById.get(selectedCustomerId);

            if (customer?.requireExternalReference && v.trim() === '') {
              return `Er referens krävs`;
            }
          },
        })}

        {mkField('streetAddress', 'Adress')}
        {mkField('zipCode', 'Postnr')}
        {mkField('city', 'Ort')}
        {mkField('meetupLocation', 'Mötesplats')}

        <LabelWrap label="Kommentar">
          <MyTextArea {...register('freetextNotes')} />
        </LabelWrap>

        <LabelWrap label="Platsbeskrivning">
          <MyTextArea {...register('locationDescription')} />
        </LabelWrap>
      </CustomerFormInputGrid>
    </Wrapper>
  );
};

export default CustomerCaseFields;
