import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import { LoginReturnUrlQueryParam } from 'contexts/useAccountInfo/RequireAuth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { useQueryParams } from 'utils/useQueryParam';
import useUpdateInterval from 'utils/useUpdateInterval';
import {
  AuthenticationClient,
  BankSignRequest,
  DEVONLYClient,
  LoginStatus,
} from 'api';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import LabelWrap from 'components/inputs/LabelWrap';
import LoadingSection from 'components/spinners/LoadingSection';
import { LoginForm, LoginTitle, ButtonsRow, ErrorText } from './components';
import bankIdLogoImg from 'assets/BankID_logo_white.svg';
import styled from 'styled-components';
import LoginOptionTabs from './LoginOptionTabs';

const BankIDIcon = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  background: url('${bankIdLogoImg}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const BankIdLoginSection: React.FC = () => {
  const [pnr, setPnr] = useState('');
  const { onLoggedIn } = useAccountInfo();
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const initiateBankIDCall = useApiCall(
    AuthenticationClient,
    (client, pnr: string) => client.initiateBankIDLogin(pnr)
  );
  const pollBankIDCall = useApiCall(
    AuthenticationClient,
    (client, model: BankSignRequest) => client.pollBankIDLoginStatus(model)
  );
  const devOnlyLoginCall = useApiCall(DEVONLYClient, (c, pnr: string) =>
    c.signInUser(pnr)
  );

  // BankId status poller
  const {
    startIntervalImmediately: startLoginStatusPoll,
    stopInterval: stopLoginStatusPoll,
  } = useUpdateInterval(async (orderRef: string) => {
    const [response, error] = await pollBankIDCall.run(
      new BankSignRequest({
        orderRef,
        rememberMe: true,
      })
    );

    if (response !== null && !error) {
      switch (response.status) {
        case LoginStatus.Complete: {
          stopLoginStatusPoll();

          if (response.jwtToken && response.jwtRefreshToken && response.user) {
            onLoggedIn(
              response.jwtToken,
              response.jwtRefreshToken,
              response.user
            );
            const navTo =
              queryParams.get(LoginReturnUrlQueryParam) ?? Routes.index;
            navigate(navTo, { replace: true });
          }
          break;
        }

        case LoginStatus.Error: {
          stopLoginStatusPoll();
          break;
        }
      }
    } else {
      stopLoginStatusPoll();
    }
  }, 1000);

  const handleLoginSubmit = async (eve: React.FormEvent) => {
    eve.preventDefault();

    pollBankIDCall.setResponse(undefined);
    const [response] = await initiateBankIDCall.run(pnr);

    if (response !== null) {
      if (
        response?.status === LoginStatus.InitiationSuccess &&
        response.orderRef
      ) {
        startLoginStatusPoll(response.orderRef);
      }
    }
  };

  const devOnlySignIn = async (eve: React.MouseEvent) => {
    eve.preventDefault();

    const [response, err] = await devOnlyLoginCall.run(pnr);

    if (
      !err &&
      response?.jwtToken &&
      response.jwtRefreshToken &&
      response.user
    ) {
      onLoggedIn(response.jwtToken, response.jwtRefreshToken, response.user);
      const navTo = queryParams.get(LoginReturnUrlQueryParam) ?? Routes.index;
      navigate(navTo, { replace: true });
    }
  };

  const blockingSpinner =
    initiateBankIDCall.status === RequestStatus.Fetching ||
    pollBankIDCall.status === RequestStatus.Fetching ||
    (pollBankIDCall.status === RequestStatus.Fetched &&
      pollBankIDCall.response?.status === LoginStatus.Pending);

  if (blockingSpinner) {
    return (
      <LoginForm>
        <LoadingSection>
          {pollBankIDCall.response?.statusMessage ?? 'Ansluter till BankID...'}
        </LoadingSection>
      </LoginForm>
    );
  }

  const errorMessage = [
    initiateBankIDCall.status,
    pollBankIDCall.status,
  ].includes(RequestStatus.Error)
    ? 'Ett fel inträffade. Försök igen.'
    : initiateBankIDCall.response?.status === LoginStatus.Error
    ? initiateBankIDCall.response.statusMessage
    : pollBankIDCall.response?.status === LoginStatus.Error
    ? pollBankIDCall.response.statusMessage
    : '';

  return (
    <>
      <LoginOptionTabs />
      <LoginForm onSubmit={handleLoginSubmit}>
        <LoginTitle>Stationsanvändare</LoginTitle>

        <LabelWrap label="Personnummer">
          <Input
            type="text"
            name="socialsecuritynumber"
            onChange={(e) =>
              setPnr(e.currentTarget.value.replaceAll(/\D/g, ''))
            }
            value={pnr}
          />
        </LabelWrap>

        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <ButtonsRow>
          <Button
            type="submit"
            disabled={pnr.length < 10 || pnr.length > 12}
            icon={<BankIDIcon />}
          >
            Logga In
          </Button>

          {process.env.NODE_ENV === 'development' && (
            <Button onClick={devOnlySignIn}>Dev sign in</Button>
          )}
        </ButtonsRow>
      </LoginForm>
    </>
  );
};

export default BankIdLoginSection;
