import { faEnvelope, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import Theme from 'constants/theme';
import { FC } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';
import CustomerCaseFields from './CustomerCaseFields';
import CaseJobs from './CustomerCaseJobs';
import { makeActivatableMediaQuery } from 'components/mediaQueries/ActivatableMediaQuery';
import FormErrorList from 'components/FormErrorList';
import { CustomerCaseFormInputs } from './formUtils/caseFormConverters';

const Wrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 800px) {
    width: auto;
    margin: 0;
  }
`;

const Actions = styled.div`
  position: sticky;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 20px ${Theme.sizes.padding.screenInset};
  gap: 0 20px;

  background-color: ${Theme.colors.bg.background1};
  border-top: 1px solid ${Theme.colors.border.main};
`;

const MyFormErrorList = styled(FormErrorList)`
  grid-column: 1/4;
  margin: 0;
  margin-bottom: 10px;

  ${makeActivatableMediaQuery(
    'tablet',
    css`
      margin: auto;
      margin-right: 0;
      grid-column: 1/2;
    `
  )}
`;

const ResetButton = styled(Button)`
  grid-column: 2/3;
`;
const SaveButton = styled(Button)`
  grid-column: 3/4;
`;

interface Props {
  form: UseFormReturn<CustomerCaseFormInputs>;
  submitHandler: SubmitHandler<CustomerCaseFormInputs>;
}

const CustomerCaseEditor: FC<Props> = ({ form, submitHandler }) => {
  const { handleSubmit, formState, reset } = form;

  const formIsValid = formState.isValid;
  const formIsDirty = formState.isDirty;
  const formErrors = formState.errors;

  return (
    <Wrapper onSubmit={handleSubmit(submitHandler)}>
      <CustomerCaseFields form={form} />
      <CaseJobs form={form} />

      <Actions>
        {!formIsValid && <MyFormErrorList errors={formErrors} />}

        {formIsDirty && (
          <ResetButton
            icon={<FontAwesomeIcon icon={faUndo} />}
            onClick={(eve) => {
              eve.preventDefault();
              reset();
            }}
          >
            Återställ
          </ResetButton>
        )}

        <SaveButton icon={<FontAwesomeIcon icon={faEnvelope} />}>
          Skicka ärende
        </SaveButton>
      </Actions>
    </Wrapper>
  );
};

export default CustomerCaseEditor;
