import { faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal, { modalBodyClass, modalContentClass } from 'components/Modal';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';

const MyModal = styled(Modal)`
  .${modalBodyClass} {
    ${MediaQuery.tablet} {
      max-width: 50vw;
    }
  }

  .${modalContentClass} {
    flex: 1;
    overflow: auto;

    svg {
      color: ${Theme.colors.bg.accent1};
    }
  }
`;

interface Props {
  onClose?(): void;
}

const PriceListUpdateInfoModal: FC<Props> = ({ onClose }) => {
  return (
    <MyModal
      title="Uppdatering av prislista"
      onClose={onClose}
      buttons={[{ label: 'Ok', onClick: onClose }]}
    >
      <p>
        När du uppdaterar priser i en prislista så uppdateras inte de
        ärendeutgifter som använder sig av de priserna automatiskt.
        <br />
        Om du vill att de ärendeutgifter som använder sig av ett pris som du
        uppdaterat nu också ska få sitt pris uppdaterat, så får du gå in på de
        påverkade ärendena och välja det uppdaterade priset igen via{' '}
        <FontAwesomeIcon icon={faList} /> -menyn
      </p>
      <p>
        Alla timtaxor som du justerat nu kommer att gälla för alla jobb (även
        påbörjade) vid nästa fakturering.
      </p>
    </MyModal>
  );
};

export default PriceListUpdateInfoModal;
