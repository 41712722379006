import DateSpanPresetsSelector from 'components/inputs/DateSpanPresetsSelector';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { toInputDateString } from 'utils/date';
import Input from './Input';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
`;

const DateInput = styled(Input)`
  flex: 1;
  width: 120px;
`;

interface Props {
  from?: Date;
  to?: Date;
  onSpanPicked(from: Date, to: Date): void;
  className?: string;
}

const DateSpanPicker: FC<Props> = ({
  from: fromProp,
  to: toProp,
  onSpanPicked,
  className,
}) => {
  const [from, setFrom] = useState(toInputDateString(fromProp || new Date()));
  const [to, setTo] = useState(toInputDateString(toProp || new Date()));

  useEffect(() => {
    setFrom(toInputDateString(fromProp || new Date()));
    setTo(toInputDateString(toProp || new Date()));
  }, [fromProp, toProp]);

  const handleBothSet = (from: Date, to: Date) => {
    if (!window.isNaN(from.valueOf()) && !window.isNaN(to.valueOf())) {
      onSpanPicked(from, to);
    }
  };

  const fromChanged = (v: string) => {
    let fromDate = new Date(v);
    let toDate = new Date(to);
    setFrom(toInputDateString(fromDate));

    if (fromDate > toDate) {
      toDate = fromDate;
      setTo(toInputDateString(toDate));
    }

    handleBothSet(fromDate, toDate);
  };

  const toChanged = (v: string) => {
    let fromDate = new Date(from);
    let toDate = new Date(v);
    setTo(toInputDateString(toDate));

    if (toDate < fromDate) {
      fromDate = toDate;
      setFrom(toInputDateString(fromDate));
    }

    handleBothSet(fromDate, toDate);
  };

  const handlePresetPicked = ([from, to]: [Date, Date]) => {
    setFrom(toInputDateString(from));
    setTo(toInputDateString(to));
    handleBothSet(from, to);
  };

  return (
    <Wrapper className={className}>
      <DateInput
        type="date"
        value={from}
        onChange={(eve) => fromChanged(eve.currentTarget.value)}
      />
      -
      <DateInput
        type="date"
        value={to}
        onChange={(eve) => toChanged(eve.currentTarget.value)}
      />
      <DateSpanPresetsSelector
        dateRange={[new Date(from), new Date(to)]}
        onPresetSelected={handlePresetPicked}
      />
    </Wrapper>
  );
};

export default DateSpanPicker;
