import Color from 'color';

export function safeColor(
  colorString: string | undefined,
  backupColor: string
) {
  try {
    return Color(colorString);
  } catch {}

  return Color(backupColor);
}

export function getReadableForegroundColor(backgroundColor: string) {
  return safeColor(backgroundColor, '#000000').isDark() ? '#FFFFFF' : '#000000';
}
