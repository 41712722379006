import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppTextsClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import EditableRichText from 'components/inputs/EditableRichText';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import { AppTextKeyLocationDescription } from 'constants/AppTextKeys';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';

const EditAppText: FC = () => {
  const { appTextKey } = useParams();

  const [editText, setEditText] = useState('');
  const [editMode, setEditMode] = useState(false);

  const appTextResponse = useResponse(
    AppTextsClient,
    async (c) => {
      if (!appTextKey) return null;
      const apptext = await c.getAppText(appTextKey);
      setEditText(apptext);
      return apptext;
    },
    [appTextKey]
  );

  const updateAppText = useApiCall(
    AppTextsClient,
    (c, key: string, value: string) => c.setAppText(key, value)
  );

  const renderContent = () => {
    switch (appTextResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection>Hämtar...</LoadingSection>;

      case RequestStatus.Fetched:
        if (updateAppText.status === RequestStatus.Fetching)
          return <LoadingSection>Sparar...</LoadingSection>;

        if (!appTextKey) return <b>Den här apptexten finns inte</b>;

        return (
          <EditableRichText
            editMode={editMode}
            editText={editText}
            onEditTextChanged={setEditText}
            onEditClick={() => setEditMode(true)}
            onCancelClick={() => {
              setEditMode(false);
              setEditText(appTextResponse.response ?? '');
            }}
            onSaveClick={async () => {
              setEditMode(false);
              await updateAppText.run(appTextKey, editText);
              appTextResponse.update();
            }}
          />
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => appTextResponse.update()} />;
    }
  };

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faEdit} />{' '}
            {AppTextKeyLocationDescription[
              appTextKey as keyof typeof AppTextKeyLocationDescription
            ] || 'Redigera webbtext'}
          </>
        }
      />

      {renderContent()}
    </>
  );
};

export default EditAppText;
