import Dropdown, { DropdownPosition } from 'components/Dropdown';
import { Item, Items } from 'components/inputs/MultiSelect';
import { FC, PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin-right: 10px;
  width: 1em;
`;

export interface ContextMenuOption {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onClick(): void;
}

export interface ContextMenuProps extends PropsWithChildren {
  options: ContextMenuOption[];
  position?: DropdownPosition;
}

interface StatelessProps extends ContextMenuProps {
  open: boolean;
  onClick?(): void;
  onLostFocus?(): void;
}

export const StatelessContextMenu: FC<StatelessProps> = ({
  options,
  open,
  onClick,
  onLostFocus,
  position,
  children,
}) => {
  return (
    <Dropdown
      content={
        open && (
          <Items>
            {options.map((option, i) => (
              <Item
                key={i}
                onClick={() => {
                  option.onClick();
                  onLostFocus?.();
                }}
              >
                {option.icon && <IconWrapper>{option.icon}</IconWrapper>}
                {option.label}
              </Item>
            ))}
          </Items>
        )
      }
      onClick={onClick}
      onLostFocus={onLostFocus}
      position={position}
    >
      {children}
    </Dropdown>
  );
};

const ContextMenu: FC<ContextMenuProps> = ({ options, position, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <StatelessContextMenu
      onClick={() => setOpen((o) => !o)}
      onLostFocus={() => setOpen(false)}
      open={open}
      options={options}
      position={position}
    >
      {children}
    </StatelessContextMenu>
  );
};

export default ContextMenu;
