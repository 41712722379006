import useAccountInfo from './useAccountInfo';
import useBasicData from './useBasicData';
import usePricingSetup from './usePricingSetup';

const useSelectedCustomerId = () => {
  const {
    accountInfo: { user },
  } = useAccountInfo();

  // Locked to first customer for now..
  return user?.customerMemberships[0]?.customerId ?? null;
};

export const useCustomerHasCentralAgreement = () => {
  const customerId = useSelectedCustomerId();

  const { akCompanyIdsByCustomerId } = usePricingSetup();
  const { hQ_AkCompanyId } = useBasicData();

  if (customerId === null) {
    return false;
  }

  const selectedAkCompanyId = akCompanyIdsByCustomerId.get(customerId)?.[0];

  return selectedAkCompanyId === hQ_AkCompanyId;
};

export default useSelectedCustomerId;
