import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface Props {
  className?: string;
}

const StationIcon: FC<Props> = ({ className }) => (
  <FontAwesomeIcon icon={faWarehouse} className={className} />
);

export default StationIcon;
