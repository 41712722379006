import { CaseEvent } from '../useMergedCaseJobEvents';
import { JobsFilterState } from './useJobsFilterState';

const useFilteredJobs = (
  filterState: JobsFilterState,
  caseEvents: CaseEvent[]
): CaseEvent[] => {
  const { selectedCustomerIds } = filterState;

  return caseEvents.filter((caseEvent) =>
    selectedCustomerIds.has(caseEvent.customerId ?? -1)
  );
};

export default useFilteredJobs;
