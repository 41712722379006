import { Invoice } from 'api';
import { useMemo, useState } from 'react';
import { ApiResponse, RequestStatus } from 'swaggerhooks';

/**
 *
 * Manage selections for a set of invoices that can
 * be used in column settings for controlling checkboxes.
 *
 */
export const useInvoiceSelections = (invoices: ApiResponse<Invoice[]>) => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState(
    new Set<number>()
  );

  const handleToggleOneInvoiceSelection = (invoice: Invoice) => {
    setSelectedInvoiceIds((currentSelection) => {
      const newSelection = new Set(currentSelection);
      if (newSelection.has(invoice.id)) {
        newSelection.delete(invoice.id);
      } else {
        newSelection.add(invoice.id);
      }

      return newSelection;
    });
  };

  const handleToggleSelectAllInvoices = () => {
    if (invoices.status === RequestStatus.Fetched && invoices.response) {
      if (hasSelectedAllInvoices) {
        setSelectedInvoiceIds(new Set<number>());
      } else {
        setSelectedInvoiceIds(
          new Set(invoices.response.map((invoice) => invoice.id))
        );
      }
    }
  };

  const hasSelectedAllInvoices = useMemo(() => {
    return !invoices.response?.find(
      (invoice) => !selectedInvoiceIds.has(invoice.id)
    );
  }, [invoices.response, selectedInvoiceIds]);

  const hasSomeSelectedInvoices = selectedInvoiceIds.size > 0;

  const resetSelection = () => {
    setSelectedInvoiceIds(new Set<number>());
  };

  return {
    selectedInvoiceIds,
    handleToggleOneInvoiceSelection,
    handleToggleSelectAllInvoices,
    hasSelectedAllInvoices,
    hasSomeSelectedInvoices,
    resetSelection,
  };
};
