import { useCallback, useRef } from 'react';
import { atom, SetterOrUpdater, useRecoilState } from 'recoil';

type UseLocalStorageStateHook<T> = () => readonly [T, SetterOrUpdater<T>];

function makeUseLocalStorageState<T>(
  storageKey: string,
  defaultValue: T,
  parser: (string: string) => T,
  serializer: (value: T) => string
): UseLocalStorageStateHook<T> {
  const item = window.localStorage.getItem(storageKey);

  let parsedItem = defaultValue;

  if (item !== null) {
    try {
      parsedItem = parser(item);
    } catch (err) {
      console.error("Could't parse state from localStorage: " + err);
      console.log('parse error: ', err);
    }
  }

  const recoilAtom = atom({
    key: storageKey,
    default: parsedItem,
  });

  const useLocalStorageState = () => {
    const [atomState, setAtomState] = useRecoilState(recoilAtom);
    const atomStateRef = useRef(atomState);
    atomStateRef.current = atomState;

    const setState: SetterOrUpdater<T> = useCallback((valOrUpdater) => {
      setAtomState((atomState) => {
        if (valOrUpdater instanceof Function) {
          const updatedValue = valOrUpdater(atomState);
          window.localStorage.setItem(storageKey, serializer(updatedValue));
          return updatedValue;
        }

        window.localStorage.setItem(storageKey, serializer(valOrUpdater));
        return valOrUpdater;
      });
    }, []);

    return [atomState, setState] as const;
  };

  return useLocalStorageState;
}

export default makeUseLocalStorageState;
