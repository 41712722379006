import { css } from 'styled-components';

export interface ColumnSetting<
  Row extends Object,
  RenderProps extends Object | undefined
> {
  head: React.ReactNode;
  sortFunction?: (a: Row, b: Row, renderProps: RenderProps) => number;
  width: number;
  focusable?: boolean;
  noPadding?: boolean;
  render(row: Row, renderProps: RenderProps, focused: boolean): React.ReactNode;
  css?: ReturnType<typeof css>;
}

export const tableHeadClassName = 'table-head';
export const tableCellClassName = 'table-cell';
export const focusableTableCellClassName = 'table-cell-focusable';

export function makeColumnClassName(index: number) {
  return `column-${index}`;
}
