import { AppText, AppTextsClient } from 'api';
import PageHeader from 'components/PageHeader';
import Table from 'components/Table';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import AppTextKey, {
  AppTextKeyLocationDescription,
} from 'constants/AppTextKeys';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useUsers from 'contexts/useUsers';
import { FC, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useResponse } from 'swaggerhooks';
import { formatDateTime } from 'utils/date';
import { makeStringComparator } from 'utils/sorting';

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const ManageAppTexts: FC = () => {
  const users = useUsers();
  const navigate = useNavigate();

  const appTextsResponse = useResponse(AppTextsClient, (c) =>
    c.getAllAppTexts()
  );

  const appTextsByKey = useMemo(() => {
    if (!appTextsResponse.response) return new Map<string, AppText>();

    return new Map(
      appTextsResponse.response.map((appText) => [appText.key, appText])
    );
  }, [appTextsResponse.response]);

  return (
    <>
      <PageHeader title="Webbtexter" />

      <MyTable
        columnSettings={[
          {
            head: 'Text',
            render: ({ appTextKey }) =>
              AppTextKeyLocationDescription[appTextKey],
            sortFunction: makeStringComparator(
              ({ appTextKey }) => AppTextKeyLocationDescription[appTextKey]
            ),
            width: 400,
          },
          {
            head: 'Senast redigerad',
            render: ({ appTextKey }) => {
              const modified = appTextsByKey.get(appTextKey)?.modifiedUtc;
              if (!modified) return '';
              return formatDateTime(modified);
            },
            width: 100,
          },
          {
            head: 'Redigerad av',
            render: ({ appTextKey }) => {
              const modifiedBy = appTextsByKey.get(appTextKey)?.modifiedById;
              if (!modifiedBy) return '';
              return users.usersById.get(modifiedBy)?.userName;
            },
            width: 100,
          },
        ]}
        rows={[
          { appTextKey: AppTextKey.AKCOMPANY_START_PAGE_TEXT },
          { appTextKey: AppTextKey.CUSTOMER_CREATE_CASE_PAGE_TEXT },
        ]}
        onRowClick={(row) =>
          navigate(
            generatePath(Routes.settings.manageAppTexts.appText, {
              appTextKey: row.appTextKey,
            })
          )
        }
      />
    </>
  );
};

export default ManageAppTexts;
