import { CaseExpense } from 'api';

export interface ExpenseFormInputs {
  expenseId: number;
  isRemoved: boolean;
  name: string;
  unitPrice: number;
  amount: number;
}

export function createExpenseForm(expense?: CaseExpense): ExpenseFormInputs {
  if (expense) {
    return {
      expenseId: expense.id,
      isRemoved: expense.isRemoved,
      name: expense.name,
      unitPrice: expense.unitPrice,
      amount: expense.amount,
    };
  }

  return {
    expenseId: 0,
    isRemoved: false,
    name: '',
    unitPrice: 0,
    amount: 0,
  };
}

export function expenseFormToCaseExpense(
  formInputs: ExpenseFormInputs,
  base?: CaseExpense
): CaseExpense {
  return new CaseExpense({
    id: formInputs.expenseId,
    isRemoved: formInputs.isRemoved,
    caseId: base?.caseId ?? 0,
    name: formInputs.name,
    unitPrice: formInputs.unitPrice,
    amount: formInputs.amount,
    timestamp: base?.timestamp ?? '',

    // Backend manages these, but I add them anyway
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    createdById: -1,
    modifiedById: -1,
  });
}
