import Theme from 'constants/theme';
import styled from 'styled-components';

const TextButton = styled.button`
  margin: 0;
  padding: 0;

  border: 0;
  background: none;
  font: inherit;
  font-weight: 600;
  color: ${Theme.colors.bg.accent1};
  cursor: pointer;

  &:disabled {
    color: ${Theme.colors.bg.background4};
    cursor: default;
  }
`;

export default TextButton;
