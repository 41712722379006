import Theme from 'constants/theme';
import React, { FC } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  hasAccessToRoute,
  isAdminOnlyRoute as isWebAdminOnlyRoute,
  ValidRouteString,
} from 'constants/routeConfiguration';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const Scroll = styled.div`
  overflow: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 ${Theme.sizes.padding.screenInset};
  min-width: max-content;
  border-bottom: 2px solid ${Theme.colors.border.main};
`;

const TabActiveClassName = 'tabstrip-tab-active';

const Tab = styled.div`
  & a,
  & button {
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
    gap: 5px;
    margin: 0;
    margin-bottom: -2px;
    margin-right: 20px;
    padding: 10px 0;

    background: transparent;
    border: 0;
    border-bottom: 3px solid transparent;
    text-decoration: none;
    font-family: inherit;
    font-weight: 700;
    cursor: pointer;
    color: ${Theme.colors.fg.background1};
    white-space: nowrap;

    &:focus {
      outline: none;
    }
  }

  & a.${TabActiveClassName}, & button.${TabActiveClassName} {
    border-color: ${Theme.colors.bg.accent1};
    color: ${Theme.colors.bg.accent1};
  }
`;

const FakeNavlink = styled.button`
  margin: 0;
  padding: 0;

  font: inherit;
  border: none;
  background-color: transparent;
`;

export interface RouteTabSettings {
  label: React.ReactNode;
  route: ValidRouteString;
  /** if true, the tab will only be highlighted when no descendant paths are matched */
  exact?: boolean;
}

export interface OnClickTabSettings {
  label: React.ReactNode;
  onClick(): void;
}

interface Props {
  tabs: RouteTabSettings[] | OnClickTabSettings[];
  activeTab?: number;
  className?: string;
}

const Tabstrip: FC<Props> = ({ tabs, activeTab, className }) => {
  const userClaims = useUserWebClaims();

  return (
    <Scroll>
      <Wrapper className={className}>
        {tabs.map((tab, index) => (
          <Tab key={index}>
            {'route' in tab ? (
              hasAccessToRoute(tab.route, userClaims) ? (
                <NavLink
                  to={tab.route}
                  end={tab.exact}
                  className={({ isActive }) =>
                    isActive || activeTab === index ? TabActiveClassName : ''
                  }
                >
                  {isWebAdminOnlyRoute(tab.route) && (
                    <FontAwesomeIcon
                      icon={faLock}
                      title="Syns endast för system-admins."
                    />
                  )}
                  {tab.label}
                </NavLink>
              ) : null
            ) : (
              <FakeNavlink
                onClick={(eve) => {
                  eve.preventDefault();
                  tab.onClick();
                }}
                className={activeTab === index ? TabActiveClassName : ''}
              >
                {tab.label}
              </FakeNavlink>
            )}
          </Tab>
        ))}
      </Wrapper>
    </Scroll>
  );
};

export default Tabstrip;
