import { FC } from 'react';
import styled from 'styled-components';

const Svg = styled.svg``;

const xs = 8;
const xe = 52;
const ys = 7;
const ye = 53;
const emMult = 1.1;
const aspect = (xs - xe) / (ys - ye);

const InvoiceCheck: FC = () => (
  <Svg
    width={`${aspect * emMult}em`}
    height={`${emMult / aspect}em`}
    viewBox={`${xs} ${ys} ${xe} ${ye}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="currentColor">
      <path
        d="m13.045 7c-1.1326 0-2.0449 0.9123-2.0449 2.0449v41.91c0 1.1326 0.9123 2.0449 2.0449 2.0449h21.135a12.288 12.288 0 0 1-2.0742-6.7559 12.288 12.288 0 0 1 12.287-12.287 12.288 12.288 0 0 1 0.60742 0.017578v-15.236c-5-5.7374-4.999-5.7383-11.074-11.738z"
        stroke-linejoin="round"
        stroke-width="3"
        style={{ paintOrder: 'markers stroke fill' }}
      />
      <path
        d="m18 27h20c0.554 0 1 0.446 1 1v6c0 0.554-0.446 1-1 1h-20c-0.554 0-1-0.446-1-1v-6c0-0.554 0.446-1 1-1z"
        stroke-linejoin="round"
        stroke-width="2"
        style={{ paintOrder: 'markers stroke fill' }}
      />
      <g>
        <g stroke-linecap="round" stroke-width="2">
          <path d="m17 14h10" />
          <path d="m17 18h10" />
          <path d="m27 44 5-0.02347" />
        </g>
        <g stroke-linejoin="round">
          <path
            transform="translate(5 .97653)"
            d="m28 6.0235v12h12"
            stroke-width="3"
          />
          <circle
            cx="44.393"
            cy="46.245"
            r="12.288"
            stroke-linecap="round"
            stroke-width="3"
            style={{ paintOrder: 'markers stroke fill' }}
          />
          <path
            d="m38.587 46.383 4 4 7.413-7.4064"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </g>
    </g>
  </Svg>
);

export default InvoiceCheck;
