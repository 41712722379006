import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  min-height: 1em;
`;

interface Props extends PropsWithChildren {
  label?: ReactNode;
  className?: string;
}

const LabelWrap: FC<Props> = ({ label, className, children }) => {
  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      {children}
    </Wrapper>
  );
};

export default LabelWrap;
