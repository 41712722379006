import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { FABStyle } from 'components/inputs/Button';
import Select from 'components/inputs/Select';
import Modal from 'components/Modal';
import PhoneSlideInView from 'components/Modal/PhoneSlideInView';
import PageHeader from 'components/PageHeader';
import Tabstrip from 'components/Tabstrip';
import MediaQuery from 'constants/MediaQuery';
import WebRoleIds from 'constants/RoleIds';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import usePricingSetup, { useAllPriceLists } from 'contexts/usePricingSetup';
import { FC, useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useQueryParam from 'utils/useQueryParam';
import PriceListEditor from './PriceListEditor/PriceListEditor';
import PriceListsTable from './PriceListsTable';
import PriceListStatusIndicator from './PriceListStatusIndicator';
import useFilteredPriceLists from './useFilteredPriceLists';
import usePriceListsGroupedByType from './usePriceListsGroupedByType';
import usePriceListsWithActiveTo from './usePriceListsWithActiveTo';

const PageHeaderActions = styled.div`
  grid-column: -2/-1;
  grid-row: 1/2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;

  ${MediaQuery.tablet} {
    justify-content: flex-end;
    margin-left: auto;
  }
`;

const FABButton = styled(Button)`
  ${MediaQuery.phone} {
    ${FABStyle}
  }
`;

const Horizontal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Vertical = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:not(:first-child) {
    border-left: 1px solid ${Theme.colors.border.main};
  }
`;

const MySlideIn = styled(PhoneSlideInView)`
  flex: 1;
  overflow: hidden;

  ${MediaQuery.tablet} {
    border-left: 1px solid ${Theme.colors.border.main};
    ${({ open }) => !open && 'display: none;'}
  }
`;

enum PriceListTabs {
  Internal = 0,
  External = 1,
}

const ManagePriceListsPage: FC = () => {
  const modalStack = useModalStack();
  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const userWebClaims = useUserWebClaims();

  const navigate = useNavigate();
  const params = useParams<{ customerId: string }>();
  const selectedCustomerId = Number(params.customerId ?? 'NaN');
  const [selectedTabStr, setSelectedTab] = useQueryParam(
    'tab',
    String(PriceListTabs.Internal)
  );
  const selectedTab = Number(selectedTabStr);

  const [editState, setEditState] = useState<{
    // null = none selected
    selectedPriceListId: number | null;
    editPaneOpen: boolean;
    saveAsNewPricelist: boolean;
  }>({
    selectedPriceListId: null,
    editPaneOpen: false,
    saveAsNewPricelist: true,
  });
  const [isPriceListEditorDirty, setIsPriceListEditorDirty] = useState(false);

  const allPriceLists = useAllPriceLists();
  const { internalPriceLists, externalPriceLists } =
    usePriceListsGroupedByType(allPriceLists);

  const internalPriceListsWithActiveTo =
    usePriceListsWithActiveTo(internalPriceLists);
  const externalPriceListsWithActiveTo =
    usePriceListsWithActiveTo(externalPriceLists);

  const filteredInternalPriceLists = useFilteredPriceLists(
    internalPriceListsWithActiveTo,
    selectedCustomerId
  );
  const filteredExternalPriceLists = useFilteredPriceLists(
    externalPriceListsWithActiveTo,
    selectedCustomerId
  );

  const priceListsToCompareTo = useMemo(
    () =>
      allPriceLists.filter(
        (pl) =>
          editState.saveAsNewPricelist ||
          pl.id !== editState.selectedPriceListId
      ),
    [allPriceLists, editState]
  );

  const selectedPriceList = useMemo(() => {
    if (editState.selectedPriceListId === null) return undefined;

    return [
      ...internalPriceListsWithActiveTo,
      ...externalPriceListsWithActiveTo,
    ].find((pl) => pl.id === editState.selectedPriceListId);
  }, [
    editState.selectedPriceListId,
    internalPriceListsWithActiveTo,
    externalPriceListsWithActiveTo,
  ]);

  const selectedPriceListCustomer = pricingSetup.customersById.get(
    selectedPriceList?.customerId ?? -1
  );

  const allowSwitchPriceList = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      if (!isPriceListEditorDirty) {
        resolve(true);
        return;
      }

      const modalId = modalStack.push(
        <Modal
          title="Avbryt redigering?"
          onClose={() => {
            modalStack.pop(modalId);
            resolve(false);
          }}
          buttons={[
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              label: 'Kasta ändringar',
              onClick: () => {
                modalStack.pop(modalId);
                resolve(true);
              },
            },
            {
              label: 'Fortsätt redigera',
              onClick: () => {
                modalStack.pop(modalId);
                resolve(false);
              },
            },
          ]}
        >
          Du har osparade ändringar i den öppna prislistan. Vill du verkligen
          kasta dem?
        </Modal>
      );
    });
  }, [isPriceListEditorDirty, modalStack]);

  const handleEditPriceList = async (priceListId: number) => {
    if (await allowSwitchPriceList()) {
      setEditState({
        editPaneOpen: true,
        saveAsNewPricelist: false,
        selectedPriceListId: priceListId,
      });
    }
  };

  const handleCreateNewPriceList = async () => {
    if (await allowSwitchPriceList()) {
      setEditState({
        editPaneOpen: true,
        saveAsNewPricelist: true,
        selectedPriceListId: null,
      });
    }
  };

  const handleDuplicatePriceList = async (priceListId: number) => {
    if (await allowSwitchPriceList()) {
      setEditState({
        editPaneOpen: true,
        saveAsNewPricelist: true,
        selectedPriceListId: priceListId,
      });
    }
  };

  return (
    <>
      <Horizontal>
        <Vertical>
          <PageHeader
            size={editState.editPaneOpen ? 'medium' : undefined}
            title="Prislistor"
          >
            <PageHeaderActions>
              <label>
                Visa:{' '}
                <Select
                  value={isNaN(selectedCustomerId) ? '' : selectedCustomerId}
                  onChange={(eve) => {
                    navigate(
                      generatePath(
                        Routes.settings.managePriceLists.listsForCustomer,
                        { customerId: eve.target.value }
                      ),
                      {
                        replace: true,
                      }
                    );
                  }}
                >
                  <option value="">Alla kunder</option>
                  {selectedAkCompanyId !== null &&
                    pricingSetup.customerIdsByAkCompanyId
                      .get(selectedAkCompanyId)
                      ?.map((customerId) => {
                        const customer =
                          pricingSetup.customersById.get(customerId);

                        if (!customer || customer.isRemoved) return null;

                        return (
                          <option value={customer.id} key={customer.id}>
                            {customer.name}
                          </option>
                        );
                      })}
                </Select>
              </label>

              {!editState.editPaneOpen && (
                <FABButton
                  small
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={handleCreateNewPriceList}
                >
                  Ny prislista
                </FABButton>
              )}
            </PageHeaderActions>
          </PageHeader>

          {userWebClaims.webRoleIds.has(WebRoleIds.admin) && (
            <Tabstrip
              tabs={[
                {
                  label: 'Interna',
                  onClick: () => setSelectedTab(String(PriceListTabs.Internal)),
                },
                {
                  label: 'Externa',
                  onClick: () => setSelectedTab(String(PriceListTabs.External)),
                },
              ]}
              activeTab={selectedTab}
            />
          )}

          <PriceListsTable
            onDuplicatePriceList={handleDuplicatePriceList}
            onEditPriceList={handleEditPriceList}
            selectedPriceListId={
              (!editState.saveAsNewPricelist
                ? editState.selectedPriceListId
                : undefined) ?? undefined
            }
            priceLists={
              selectedTab === PriceListTabs.External &&
              userWebClaims.webRoleIds.has(WebRoleIds.admin)
                ? filteredExternalPriceLists
                : filteredInternalPriceLists
            }
          />
        </Vertical>

        <MySlideIn
          open={editState.editPaneOpen}
          onClose={async () => {
            if (await allowSwitchPriceList()) {
              setEditState({
                editPaneOpen: false,
                selectedPriceListId: null,
                saveAsNewPricelist: false,
              });
              setIsPriceListEditorDirty(false);
            }
          }}
          title={
            <>
              <PriceListStatusIndicator
                priceList={selectedPriceList}
                savesAsNewPricelist={editState.saveAsNewPricelist}
              />

              {selectedPriceList
                ? `${editState.saveAsNewPricelist ? 'Kopia av ' : ''}${
                    selectedPriceList.name
                  } ${
                    selectedPriceListCustomer?.akCompanyId ===
                      basicData.hQ_AkCompanyId && !editState.saveAsNewPricelist
                      ? '(Central prislista)'
                      : ''
                  }`
                : 'Ny prislista'}
            </>
          }
          keepTitleForTablet
          headerSize="medium"
        >
          {editState.editPaneOpen && (
            <PriceListEditor
              priceList={selectedPriceList}
              defaultCustomerId={
                isNaN(selectedCustomerId) ? undefined : selectedCustomerId
              }
              saveAsNewPricelist={editState.saveAsNewPricelist}
              onIsDirtyChanged={setIsPriceListEditorDirty}
              onPriceListSaved={(pl) =>
                setEditState({
                  editPaneOpen: true,
                  saveAsNewPricelist: false,
                  selectedPriceListId: pl.id,
                })
              }
              priceListsToCompareTo={priceListsToCompareTo}
            />
          )}
        </MySlideIn>
      </Horizontal>
    </>
  );
};

export default ManagePriceListsPage;
