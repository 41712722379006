import GanttChart, { LaneGroupPreset } from 'components/GanttChart';
import {
  useVehicleByVehicleRegnr,
  useVehiclesByVehicleType,
} from 'contexts/useVehicles';
import { FC } from 'react';
import styled from 'styled-components';
import { RequestStatus } from 'swaggerhooks';
import { CaseJobEvent } from '../useCaseJobEvents';
import JobEventLine, { tooltipBoundsClassName } from './JobEventLine';
import { useJobPageContext } from '..';
import TimeNavigatorHeader from '../TimeNavigatorHeader';
import {
  EventLaneGroup,
  EventWithoutLane,
} from 'components/GanttChart/useGroupedEvents';
import { conformRegNr } from 'utils/string';

const Vertical = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MyGanttChart = styled(GanttChart)`
  padding-bottom: 50px;
` as typeof GanttChart;

const noVehicleGroupLabel = '⚠️ Fordon ej tilldelat';

function laneGroupSorter(
  laneGroups: EventLaneGroup<CaseJobEvent>[]
): EventLaneGroup<CaseJobEvent>[] {
  return laneGroups.sort((a, b) =>
    a.groupLabel === noVehicleGroupLabel && b.groupLabel === noVehicleGroupLabel
      ? 0
      : a.groupLabel === noVehicleGroupLabel
      ? -1
      : b.groupLabel === noVehicleGroupLabel
      ? 1
      : a.groupLabel > b.groupLabel
      ? -1
      : a.groupLabel < b.groupLabel
      ? 1
      : 0
  );
}

interface Props {
  type: 'week' | 'day';
}

const JobsGantt: FC<Props> = ({ type }) => {
  const {
    from,
    to,
    bufferedEvents,
    isDebouncingTimespan,
    searchStatus,
    setTimespan,
    handleEventClick,
    selectedCaseJobFormIndex,
    handleCreateCaseClick,
  } = useJobPageContext();

  const vehicleByRegnr = useVehicleByVehicleRegnr();
  const vehicleByType = useVehiclesByVehicleType();

  const makeJobEventClassName = (event: CaseJobEvent) =>
    `jobEventLine-${event.caseJobId}-${event.formIndex}`;

  return (
    <Vertical>
      <TimeNavigatorHeader
        type={type}
        selectedDate={from}
        onDateSelected={setTimespan}
        loading={
          isDebouncingTimespan || searchStatus === RequestStatus.Fetching
        }
      />

      <MyGanttChart
        from={from}
        to={to}
        daysVisible={type === 'day' ? 3 : 7}
        events={bufferedEvents}
        onDateSpanSelected={handleCreateCaseClick}
        renderEventLine={(eve, containerStart, containerEnd) => (
          <JobEventLine
            jobEvent={eve}
            from={eve.from}
            to={eve.to}
            containerStart={containerStart}
            containerEnd={containerEnd}
            onClick={(caseJob) => {
              handleEventClick(caseJob);
            }}
            selected={
              eve.formIndex?.jobGroupFormIndex ===
              selectedCaseJobFormIndex?.jobGroupFormIndex
            }
            jobEventClassName={makeJobEventClassName(eve)}
            showDateOnStickyEnd
            key={makeJobEventClassName(eve)}
          />
        )}
        laneGroupPresets={Array.from(vehicleByType.entries()).map(
          ([vehicleType, vehicles]): LaneGroupPreset => ({
            laneGroupLabel: vehicleType,
            laneLabels: vehicles.map((v) => conformRegNr(v.regNr)),
          })
        )}
        makeLaneLabel={(jobEvent) =>
          conformRegNr(jobEvent.regNr) || EventWithoutLane
        }
        makeLaneGroupLabel={(jobEvent) =>
          jobEvent.regNr.trim()
            ? vehicleByRegnr.get(conformRegNr(jobEvent.regNr))?.vehicleType ||
              'Övrigt'
            : noVehicleGroupLabel
        }
        modifyLaneGroups={laneGroupSorter}
        className={tooltipBoundsClassName}
      />
    </Vertical>
  );
};

export default JobsGantt;
