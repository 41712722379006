import useUsers from 'contexts/useUsers';
import { FC } from 'react';
import { FormOptionInput } from '../../components';

interface Props {
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
  noEdit?: boolean;
}

const UserInput: FC<Props> = ({ value, onChange, disabled, noEdit }) => {
  const { users } = useUsers();

  return (
    <FormOptionInput
      value={value}
      disabled={disabled}
      noEdit={!!noEdit}
      onChange={onChange}
      onOptionClicked={(opt) => {
        onChange(opt.label);
      }}
      onClear={() => onChange('')}
      options={users.map((u) => ({
        label: u.fullName,
      }))}
      searchFilter={(search, opts) =>
        opts.filter((o) => o.label.toLowerCase().includes(search.toLowerCase()))
      }
    />
  );
};

export default UserInput;
