import {
  faCheck,
  faCoins,
  faComment,
  faInfoCircle,
  faSave,
  faTrash,
  faUndo,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Case, CaseStatus } from 'api';
import Button from 'components/inputs/Button';
import Theme from 'constants/theme';
import { FC, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import styled, { css } from 'styled-components';
import CaseFields from './CaseFields';
import CaseJobs from './CaseJobGroups';
import ExpensesSection from './ExpensesSection';
import MessagesSection from './MessagesSection';
import { CaseFormInputs } from './formUtils/caseFormConverters';
import useAccountInfo from 'contexts/useAccountInfo';
import { saveMessageFunction } from './useCaseEditorState';
import MediaQuery from 'constants/MediaQuery';
import Tabstrip from 'components/Tabstrip';
import {
  DeactivateMediaQuery,
  makeActivatableMediaQuery,
} from 'components/mediaQueries/ActivatableMediaQuery';
import FormErrorList from 'components/FormErrorList';
import CaseStatusDisplay from './CaseStatusDisplay';
import TabCounterLabel from 'components/TabCounterLabel';
import { JobFormIndexes } from 'pages/PlannerTool/useCaseJobEvents';

const Wrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MobileTabstrip = styled(Tabstrip)`
  ${makeActivatableMediaQuery(
    'tablet',
    css`
      display: none;
    `
  )}
`;

const TabletVisible = styled.div<{ alwaysVisible: boolean }>`
  display: none;
  ${({ alwaysVisible }) =>
    alwaysVisible
      ? css`
          display: contents;
        `
      : makeActivatableMediaQuery(
          'tablet',
          css`
            display: contents;
          `
        )}
`;

const Horizontal = styled.div<{ useMobileView: boolean }>`
  height: 0;
  flex: 1;
  display: grid;

  border-bottom: 1px solid ${Theme.colors.border.main};

  ${({ useMobileView }) =>
    !useMobileView &&
    css`
      ${MediaQuery.tablet} {
        grid-template-columns: 350px auto 350px;
        grid-template-rows: 1fr;
        border-top: 1px solid ${Theme.colors.border.main};
      }
    `}
`;

const MiddleScroll = styled.div`
  overflow: auto;

  ${makeActivatableMediaQuery(
    'tablet',
    css`
      border-left: 1px solid ${Theme.colors.border.main};
      border-right: 1px solid ${Theme.colors.border.main};
    `
  )}
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 10px ${Theme.sizes.padding.screenInset};
  gap: 0 10px;
`;

const InfoList = styled.ul`
  grid-column: 1/4;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;

  list-style: none;
  color: ${Theme.colors.bg.selection};
  font-weight: 600;
`;

const MyFormErrorList = styled(FormErrorList)`
  grid-column: 1/4;
  margin: 0;
  margin-bottom: 10px;

  ${makeActivatableMediaQuery(
    'tablet',
    css`
      margin: auto;
      margin-right: 0;
      grid-column: 1/2;
    `
  )}
`;

const ResetButton = styled(Button)`
  grid-column: 2/3;
`;
const SaveButton = styled(Button)`
  grid-column: 3/4;
`;

enum Tab {
  messages = 2,
  editFields = 0,
  expenses = 1,
}

interface Props {
  originalCase?: Case;
  form: UseFormReturn<CaseFormInputs, any>;
  submitHandler: SubmitHandler<CaseFormInputs>;

  onSaveMessage: saveMessageFunction;
  isSavingMessage: boolean;
  onReopenCase(): void;

  mobileView?: boolean;
  highlightCaseJobIndex?: JobFormIndexes;
  onShowJobInCalendarClick?(jobIndex: JobFormIndexes): void;
}

const CaseEditor: FC<Props> = ({
  originalCase,
  form,
  submitHandler,

  onSaveMessage,
  isSavingMessage,
  onReopenCase,

  mobileView,
  highlightCaseJobIndex,
  onShowJobInCalendarClick,
}) => {
  const [selectedTab, setSelectedTab] = useState(Tab.editFields);
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const {
    control,
    register,
    handleSubmit,
    formState,
    reset,
    watch,
    getValues,
    trigger,
  } = form;

  // For some reason formState.isValid won't get the correct value if I use it in the render return...
  const formIsValid = formState.isValid;
  const formIsDirty = formState.isDirty;
  const formErrors = formState.errors;

  const caseStatus = watch('caseStatus');
  const saveCompletesCase =
    originalCase?.caseStatus !== caseStatus &&
    caseStatus === CaseStatus.ReadyToInvoice;
  const saveDiscardsCase =
    originalCase?.caseStatus !== caseStatus &&
    caseStatus === CaseStatus.Discarded;

  const isClosedCase =
    originalCase?.caseStatus === CaseStatus.InvoicesCompleted;
  const noEdit =
    !!originalCase &&
    originalCase.caseStatus !== CaseStatus.Open &&
    originalCase.caseStatus !== CaseStatus.ReadyToInvoice;

  if (selectedAkCompanyId === null) {
    // Should never happen as AkCompanySelector automatically sets companyId to the first option.
    return <b>Välj ett företag för att börja redigera</b>;
  }

  return (
    <DeactivateMediaQuery screenSize="tablet" disable={!mobileView}>
      <Wrapper onSubmit={handleSubmit(submitHandler)}>
        <MobileTabstrip
          tabs={[
            {
              label: 'Ärende',
              onClick: () => setSelectedTab(Tab.editFields),
            },
            {
              label: (
                <TabCounterLabel
                  label="Utgifter"
                  counter={
                    originalCase?.caseExpenses.filter((ce) => !ce.isRemoved)
                      .length ?? 0
                  }
                  faIcon={faCoins}
                />
              ),
              onClick: () => setSelectedTab(Tab.expenses),
            },
            {
              label: (
                <TabCounterLabel
                  label="Meddelanden"
                  counter={
                    originalCase?.caseMessages.filter((cm) => !cm.isRemoved)
                      .length ?? 0
                  }
                  faIcon={faComment}
                />
              ),
              onClick: () => setSelectedTab(Tab.messages),
            },
          ]}
          activeTab={selectedTab}
        />

        <Horizontal useMobileView={!!mobileView}>
          <TabletVisible alwaysVisible={selectedTab === Tab.messages}>
            <MessagesSection
              currentCase={originalCase}
              onSaveMessage={onSaveMessage}
              savingMessage={isSavingMessage}
            />
          </TabletVisible>

          <TabletVisible alwaysVisible={selectedTab === Tab.editFields}>
            <MiddleScroll>
              {originalCase && <CaseStatusDisplay caseModel={originalCase} />}

              <CaseFields
                formControl={control}
                register={register}
                getValues={getValues}
                trigger={trigger}
                noEdit={!!noEdit}
              />
              <CaseJobs
                form={form}
                highlightJobIndex={highlightCaseJobIndex}
                onShowJobInCalendarClick={onShowJobInCalendarClick}
                noEdit={!!noEdit}
              />
            </MiddleScroll>
          </TabletVisible>

          <TabletVisible alwaysVisible={selectedTab === Tab.expenses}>
            <ExpensesSection
              formControl={control}
              register={register}
              noEdit={noEdit}
            />
          </TabletVisible>
        </Horizontal>

        <Actions>
          {!formIsValid && <MyFormErrorList errors={formErrors} />}

          {saveCompletesCase && (
            <InfoList>
              <li>
                <FontAwesomeIcon icon={faInfoCircle} /> Ett email kommer att
                skickas ut till kund
              </li>
            </InfoList>
          )}

          {formIsDirty && (
            <ResetButton
              icon={<FontAwesomeIcon icon={faUndo} />}
              onClick={(eve) => {
                eve.preventDefault();
                reset();
              }}
            >
              Återställ
            </ResetButton>
          )}

          {isClosedCase ? (
            originalCase && (
              <SaveButton
                onClick={(eve) => {
                  eve.preventDefault();
                  onReopenCase();
                }}
              >
                <FontAwesomeIcon icon={faUnlock} />
                {' Öppna'}
              </SaveButton>
            )
          ) : (
            <SaveButton
              icon={
                <FontAwesomeIcon
                  icon={
                    saveCompletesCase
                      ? faCheck
                      : saveDiscardsCase
                      ? faTrash
                      : faSave
                  }
                />
              }
              disabled={noEdit || !formIsDirty}
            >
              {saveCompletesCase
                ? 'Slutför'
                : saveDiscardsCase
                ? 'Makulera'
                : 'Spara'}
            </SaveButton>
          )}
        </Actions>
      </Wrapper>
    </DeactivateMediaQuery>
  );
};

export default CaseEditor;
