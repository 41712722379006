import Routes from './routes';
import WebRoleIds from 'constants/RoleIds';
import { UserWebClaims } from 'contexts/useAccountInfo/useUserWebClaims';
import { AkCompanyRole } from 'api';

type AllPaths<T extends { [key: string]: any }> = {
  [K in keyof T]: T[K] extends string ? T[K] : AllPaths<T[K]>;
}[keyof T];

export type ValidRouteString = AllPaths<typeof Routes>;

export interface RouteConfiguration {
  /**
   * If user has any claim inside this object, the user will be allowed to visit this route.
   * If the object is empty, the route has public access.
   */
  allowedClaims: Partial<UserWebClaims>;
}

const publicAccess: RouteConfiguration = {
  allowedClaims: {},
};

const customerAccess: RouteConfiguration = {
  allowedClaims: {
    customer: true,
  },
};

const stationUserAccess: RouteConfiguration = {
  allowedClaims: {
    webRoleIds: new Set([WebRoleIds.admin]),
    akCompanyRoles: new Set([
      AkCompanyRole.CompanyManager,
      AkCompanyRole.CompanyUser,
    ]),
  },
};

const stationManagerAccess: RouteConfiguration = {
  allowedClaims: {
    webRoleIds: new Set([WebRoleIds.admin]),
    akCompanyRoles: new Set([AkCompanyRole.CompanyManager]),
  },
};

const adminAccess: RouteConfiguration = {
  allowedClaims: {
    webRoleIds: new Set([WebRoleIds.admin]),
  },
};

const routeConfigurations: {
  [k in ValidRouteString]: RouteConfiguration;
} = {
  [Routes.index]: stationUserAccess,
  [Routes.account.login.index]: publicAccess,
  [Routes.account.login.stationUser]: publicAccess,
  [Routes.account.login.customerUser]: publicAccess,
  [Routes.account.forgotPassword]: publicAccess,
  [Routes.account.confirmAccount]: publicAccess,
  [Routes.account.resetPassword]: publicAccess,

  [Routes.cases.index]: stationUserAccess,
  [Routes.cases.create]: stationUserAccess,
  [Routes.cases.case]: stationUserAccess,
  [Routes.plannerTool.index]: stationUserAccess,
  [Routes.plannerTool.monthView]: stationUserAccess,
  [Routes.plannerTool.listView]: stationUserAccess,
  [Routes.plannerTool.weekView]: stationUserAccess,
  [Routes.plannerTool.dayView]: stationUserAccess,
  [Routes.invoices.index]: stationManagerAccess,
  [Routes.invoices.create]: stationManagerAccess,
  [Routes.invoices.internal]: stationManagerAccess,
  [Routes.invoiceQueue.index]: adminAccess,
  [Routes.invoiceQueue.internal]: adminAccess,
  [Routes.invoiceQueue.external]: adminAccess,
  [Routes.invoiceQueue.fee]: adminAccess,

  [Routes.settings.index]: stationManagerAccess,
  [Routes.settings.manageMyAkCompany.index]: stationManagerAccess,
  [Routes.settings.manageUsers.index]: stationManagerAccess,
  [Routes.settings.manageUsers.add]: stationManagerAccess,
  [Routes.settings.manageUsers.user]: stationManagerAccess,
  [Routes.settings.manageCustomers.index]: stationManagerAccess,
  [Routes.settings.manageCustomerUsers.index]: stationManagerAccess,
  [Routes.settings.manageCustomerUsers.user]: stationManagerAccess,
  [Routes.settings.managePriceLists.index]: stationManagerAccess,
  [Routes.settings.managePriceLists.listsForCustomer]: stationManagerAccess,
  [Routes.settings.manageAkCompanies.index]: adminAccess,
  [Routes.settings.manageAppTexts.index]: adminAccess,
  [Routes.settings.manageAppTexts.appText]: adminAccess,
  [Routes.settings.manageVehicles.index]: stationManagerAccess,
  [Routes.settings.manageVehicles.add]: stationManagerAccess,
  [Routes.settings.manageVehicles.vehicle]: stationManagerAccess,

  [Routes.customerPortal.index]: customerAccess,
  [Routes.customerPortal.bookedCases.index]: customerAccess,
  [Routes.customerPortal.createCase]: customerAccess,
} as const;

export function hasAccessToRoute(
  route: ValidRouteString,
  userClaims: UserWebClaims
) {
  const routeAllowedClaims = routeConfigurations[route]?.allowedClaims;

  if (!routeAllowedClaims) return false;
  if (Object.keys(routeAllowedClaims).length === 0) return true;

  if (routeAllowedClaims.akCompanyRoles)
    for (const userAkCompanyRole of Array.from(userClaims.akCompanyRoles)) {
      if (routeAllowedClaims.akCompanyRoles.has(userAkCompanyRole)) return true;
    }

  if (routeAllowedClaims.webRoleIds)
    for (const userWebRole of Array.from(userClaims.webRoleIds)) {
      if (routeAllowedClaims.webRoleIds.has(userWebRole)) return true;
    }

  if (routeAllowedClaims.customer && userClaims.customer) return true;

  return false;
}

export function isAdminOnlyRoute(route: ValidRouteString) {
  const routeAllowedClaims = routeConfigurations[route]?.allowedClaims;
  return (
    !routeAllowedClaims.akCompanyRoles?.size &&
    !routeAllowedClaims.customer &&
    routeAllowedClaims.webRoleIds?.has(WebRoleIds.admin)
  );
}

export default routeConfigurations;
