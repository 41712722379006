import { PriceList } from 'api';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import { ChangeEvent, FC, useCallback } from 'react';
import {
  Control,
  useController,
  UseFormGetValues,
  UseFormTrigger,
} from 'react-hook-form';
import { PriceListFormInputs } from '../formUtils/priceListFormConverters';
import { toInputDateTimeString } from 'utils/date';

interface Props {
  control: Control<PriceListFormInputs, any>;
  getValues: UseFormGetValues<PriceListFormInputs>;
  trigger: UseFormTrigger<PriceListFormInputs>;
  disabled?: boolean;
}

const PriceListDateSpan: FC<Props> = ({
  control,
  trigger,
  getValues,
  disabled,
}) => {
  const activeFrom = useController({
    control,
    name: 'activeFromUtc',
  });

  const handleFromChanged = useCallback(
    (eve: ChangeEvent<HTMLInputElement>) => {
      let fromDate = new Date(eve.target.value);

      if (isNaN(fromDate.getTime())) {
        return;
      }

      activeFrom.field.onChange(toInputDateTimeString(fromDate));
      trigger('activeFromUtc');
    },
    [activeFrom.field, trigger]
  );

  return (
    <>
      <LabelWrap label="Aktiv från">
        <Input
          type="datetime-local"
          {...activeFrom.field}
          onChange={handleFromChanged}
          disabled={disabled}
        />
      </LabelWrap>
    </>
  );
};

export default PriceListDateSpan;
