import Theme from 'constants/theme';
import { FC, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;

  border-bottom: 1px solid ${Theme.colors.border.main};
`;

const H2 = styled.h2`
  margin: 0;
  margin-right: auto;
  padding: 0;

  font: inherit;
  font-weight: 600;
  font-size: ${Theme.sizes.font.medium};
`;

interface Props extends PropsWithChildren {
  title: ReactNode;
  className?: string;
}

const SectionHeader: FC<Props> = ({ title, children, className }) => (
  <Wrapper className={className}>
    <H2>{title}</H2>
    {children}
  </Wrapper>
);

export default SectionHeader;
