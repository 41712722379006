import { useCallback, useRef, useState } from 'react';

export type EditOperation<O extends {}> = (object: O) => O;

function useQueuedUpdating<O extends {}, ID>(
  upToDateObjects: Map<ID, O>,
  saveFunction: (object: O) => Promise<any>
) {
  const upToDateObjectsRef = useRef(upToDateObjects);
  upToDateObjectsRef.current = upToDateObjects;
  const saveFunctionRef = useRef(saveFunction);
  saveFunctionRef.current = saveFunction;

  const [editOperationsByObjectId, setEditOperationsByObjectId] = useState<
    Map<ID, EditOperation<O>[]>
  >(new Map());
  const editOperationsByObjectIdRef = useRef(editOperationsByObjectId);
  editOperationsByObjectIdRef.current = editOperationsByObjectId;

  const activeQueueWorkers = useRef<Map<ID, Promise<any>>>(new Map());

  const runQueueWorker = useCallback((objectId: ID) => {
    const workerBeforeMe = activeQueueWorkers.current.get(objectId);

    const queueWorker = async () => {
      if (workerBeforeMe) {
        await workerBeforeMe;
      }

      const unmodifiedObject = upToDateObjectsRef.current.get(objectId);
      const editOperations = editOperationsByObjectIdRef.current.get(objectId);

      if (!editOperations || !unmodifiedObject) return;

      const editedObject = editOperations.reduce(
        (acc, operation) => operation(acc),
        unmodifiedObject
      );

      await saveFunctionRef.current(editedObject);

      // remove applied editOperations (more can have been added during the await above)
      const newEditOperationsByObjectId = new Map(
        editOperationsByObjectIdRef.current
      );
      const unappliedEditOperations = newEditOperationsByObjectId
        .get(objectId)
        ?.filter((operation) => !editOperations.includes(operation));

      if (unappliedEditOperations?.length) {
        newEditOperationsByObjectId.set(objectId, unappliedEditOperations);
      } else {
        newEditOperationsByObjectId.delete(objectId);
      }

      editOperationsByObjectIdRef.current = newEditOperationsByObjectId;
      setEditOperationsByObjectId(newEditOperationsByObjectId);
    };

    activeQueueWorkers.current.set(objectId, queueWorker());
  }, []);

  const enqueueEditOperation = useCallback(
    (objectId: ID, editOperation: EditOperation<O>) => {
      setEditOperationsByObjectId((qe) => {
        const newMap = new Map(qe);

        newMap.set(objectId, [...(newMap.get(objectId) ?? []), editOperation]);

        // Update this ref immediately so that queueWorkers has the most recent value
        editOperationsByObjectIdRef.current = newMap;
        runQueueWorker(objectId);

        return newMap;
      });
    },
    [runQueueWorker]
  );

  return {
    enqueueEditOperation,
    queuedEdits: editOperationsByObjectId,
  };
}

export default useQueuedUpdating;
