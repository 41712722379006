import { faArrowRight, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/Table';
import { ButtonCell } from 'components/Table/inputCells';
import {
  ColumnSetting,
  tableCellClassName,
  tableHeadClassName,
} from 'components/Table/utils';
import WebRoleIds from 'constants/RoleIds';
import Theme from 'constants/theme';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useBasicData from 'contexts/useBasicData';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { formatDateTime } from 'utils/date';
import { makeDateComparator, makeStringComparator } from 'utils/sorting';
import { arraySpreadIf } from 'utils/spreading';
import { PriceListWithActiveTo } from './usePriceListsWithActiveTo';

const tableRowClassName = 'table-row';
const priceListSelectedClassName = 'pricelist-selected';
const priceListInactiveClassName = 'pricelist-inactive';
const priceListActiveClassName = 'pricelist-active';

const MyTable = styled(Table)`
  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }

  .${tableRowClassName} {
    background-color: ${Theme.colors.bg.background1};
    border-bottom: 1px solid ${Theme.colors.border.main};
  }

  .${priceListSelectedClassName} {
    background-color: ${Theme.colors.bg.selection};
    color: ${Theme.colors.fg.selection};
  }
  .${priceListInactiveClassName}:not(.${priceListSelectedClassName}) {
    color: #999;
    background-color: ${Theme.colors.bg.background2};
    border-color: #8885;

    .${tableCellClassName} {
      border-color: inherit;
    }
  }
  .${priceListActiveClassName} {
    font-weight: 700;
  }
` as typeof Table;

interface Props {
  onDuplicatePriceList(priceListId: number): void;
  onEditPriceList(priceListId: number): void;
  selectedPriceListId?: number;
  priceLists: PriceListWithActiveTo[];
}

const PriceListsTable: FC<Props> = ({
  onDuplicatePriceList,
  onEditPriceList,
  selectedPriceListId,
  priceLists,
}) => {
  const basicData = useBasicData();
  const pricingSetup = usePricingSetup();
  const userWebClaims = useUserWebClaims();

  const now = new Date();

  return (
    <MyTable
      columnSettings={[
        {
          head: '',
          render: (pl) =>
            pl.activeToUtc >= now &&
            pl.activeFromUtc <= now && (
              <FontAwesomeIcon
                icon={faArrowRight}
                title="Prislistan är aktiv"
              />
            ),
          width: 40,
        },
        {
          head: 'Kund',
          render: (pl) => {
            const customer = pricingSetup.customersById.get(pl.customerId);

            return `${customer?.name} ${
              customer?.akCompanyId === basicData.hQ_AkCompanyId
                ? '(Central)'
                : ''
            }`;
          },
          width: 200,
          sortFunction: makeStringComparator(
            (pl) => pricingSetup.customersById.get(pl.customerId)?.name ?? ''
          ),
        },
        {
          head: 'Aktiv från',
          render: (pl) => formatDateTime(pl.activeFromUtc),
          width: 140,
          sortFunction: makeDateComparator((pl) => pl.activeFromUtc),
        },
        {
          head: 'Listnamn',
          render: (pl) => pl.name,
          width: 200,
          sortFunction: makeStringComparator((pl) =>
            pl.name.toLocaleLowerCase()
          ),
        },
        ...arraySpreadIf<ColumnSetting<PriceListWithActiveTo, Object>>(
          userWebClaims.webRoleIds.has(WebRoleIds.admin),
          {
            head: 'Typ',
            render: (pl) => basicData.priceListTypeNames.get(pl.priceListType),
            width: 150,
            sortFunction: makeStringComparator(
              (pl) => basicData.priceListTypeNames.get(pl.priceListType) ?? ''
            ),
          }
        ),
        {
          head: 'Duplicera',
          render: (pl) => (
            <ButtonCell
              onClick={(e) => {
                e.stopPropagation();
                onDuplicatePriceList(pl.id);
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </ButtonCell>
          ),
          width: 70,
        },
      ]}
      initialSortState={{ ascending: false, sortedColumnIndex: 1 }}
      onRowClick={(pl) => onEditPriceList(pl.id)}
      rowClassName={(pl) => {
        const isSelected = selectedPriceListId === pl.id;
        const isInactive = pl.activeToUtc < now;
        const isActive = pl.activeFromUtc <= now && pl.activeToUtc >= now;

        return `${tableRowClassName} row-${pl.id} ${
          isSelected ? priceListSelectedClassName : ''
        } ${isInactive ? priceListInactiveClassName : ''} ${
          isActive ? priceListActiveClassName : ''
        }`;
      }}
      rows={priceLists}
    />
  );
};

export default PriceListsTable;
