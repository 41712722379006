import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import {
  ModalButton,
  modalButtonsClass,
  modalContentClass,
  modalTitleClass,
  slideIn,
} from 'components/Modal';
import Theme from 'constants/theme';
import {
  FC,
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';

export const PopOverClassName = 'pop-over-modal';

const Overlay = styled.div<{ draggable?: boolean }>`
  position: fixed;
  z-index: 2;
  margin: auto 0;
  display: inline-block;
  max-width: 500px;

  border: 1px solid ${Theme.colors.border.main};
  font-size: ${Theme.sizes.font.small};
  color: ${Theme.colors.fg.background1};
  text-align: left;
  background-color: ${Theme.colors.bg.background1};
  box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 2px, rgb(0 0 0 / 30%) 0px 5px 20px;
  animation: ${slideIn} 0.2s;
  cursor: default;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  padding-bottom: 5px;

  font-size: ${Theme.sizes.font.medium};
  font-weight: 600;
  background-color: ${Theme.colors.bg.background2};
  color: ${Theme.colors.fg.background2};

  ${({ draggable }) =>
    draggable &&
    css`
      cursor: move;
    `}
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: -5px;
  margin-left: auto;

  border: none;
  background: transparent;
  color: inherit;
  font-size: 20px;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 20px;
  padding-top: 0;
  gap: 10px;
`;

interface Props extends PropsWithChildren {
  title?: string;
  /** Element or className */
  attachTo: Element[] | string;
  onClose(): void;
  shouldClose?(event: MouseEvent): boolean;
  buttons?: ModalButton[];

  useWidthOfElement?: boolean;
  draggable?: boolean;
  className?: string;
}

const PopOver: FC<Props> = ({
  attachTo,
  useWidthOfElement,
  onClose,
  shouldClose,
  title,
  buttons,
  children,
  draggable,
  className,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const onCloseRef = useRef(onClose);
  onCloseRef.current = onClose;
  const shouldCloseRef = useRef(shouldClose);
  shouldCloseRef.current = shouldClose;

  const isDragging = useRef(false);

  useEffect(() => {
    const outsideClickHandler = (eve: MouseEvent) => {
      if (shouldCloseRef.current && !shouldCloseRef.current(eve)) {
        return;
      }

      if (
        !shouldCloseRef.current &&
        overlayRef.current &&
        (eve.target === overlayRef.current ||
          (eve.target instanceof Node &&
            overlayRef.current.contains(eve.target)))
      ) {
        return;
      }

      if (isDragging.current) return;

      onCloseRef.current();
    };

    // Add the outside click event listener after the first render, so that onClose isn't called immediatly if PopOver is mounted due to a click.
    const delayedListen = setTimeout(() => {
      window.addEventListener('click', outsideClickHandler);
    }, 0);

    return () => {
      clearTimeout(delayedListen);
      window.removeEventListener('click', outsideClickHandler);
    };
  }, []);

  useLayoutEffect(() => {
    let observedElement: Element = document.body;

    const handleRezised = () => {
      if (!overlayRef.current) return;

      const getY = (element: Element) => {
        const rect = element.getBoundingClientRect();
        return rect.top;
      };

      const attachToElements =
        attachTo instanceof Array
          ? attachTo
          : Array.from(document.getElementsByClassName(attachTo));

      /* --- Get topmost element --- */
      let topAttachTo = attachToElements[0];
      let topAttachToY = getY(topAttachTo);

      attachToElements.forEach((element) => {
        const y = getY(element);
        if (y < topAttachToY) {
          topAttachTo = element;
          topAttachToY = y;
        }
      });

      if (observedElement !== topAttachTo) {
        resizeObserver.disconnect();
        resizeObserver.observe(topAttachTo);
        resizeObserver.observe(document.body);
        observedElement = topAttachTo;
      }

      /* --- Calculate insets for the Overlay element --- */
      const attachToRect = topAttachTo.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();

      const xPercent = attachToRect.left / bodyRect.width;
      const yPercent = attachToRect.top / bodyRect.height;

      overlayRef.current.style.top = '';
      overlayRef.current.style.bottom = '';
      overlayRef.current.style.left = '';
      overlayRef.current.style.right = '';
      overlayRef.current.style.width = '';
      overlayRef.current.style.maxWidth = '';

      if (useWidthOfElement) {
        overlayRef.current.style.width = `${attachToRect.width}px`;
        overlayRef.current.style.maxWidth = `${attachToRect.width}px`;
      }

      if (xPercent > 0.5) {
        overlayRef.current.style.right = `${Math.max(
          0,
          bodyRect.width - attachToRect.right
        )}px`;
      } else {
        overlayRef.current.style.left = `${Math.max(0, attachToRect.left)}px`;
      }

      if (yPercent > 0.5) {
        overlayRef.current.style.bottom = `${Math.max(
          0,
          bodyRect.height - attachToRect.top
        )}px`;
      } else {
        overlayRef.current.style.top = `${Math.max(0, attachToRect.bottom)}px`;
      }
    };

    const resizeObserver = new ResizeObserver(handleRezised);
    resizeObserver.observe(observedElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [attachTo, useWidthOfElement]);

  const startDrag = (eve: React.MouseEvent) => {
    if (!overlayRef.current) return;

    isDragging.current = true;

    const overlayRect = overlayRef.current.getBoundingClientRect();
    const dragStart = [
      eve.clientX - overlayRect.left,
      eve.clientY - overlayRect.top,
    ] as const;

    const onMouseMove = (eve: MouseEvent) => {
      eve.stopPropagation();
      eve.preventDefault();

      if (overlayRef.current) {
        const bodyRect = document.body.getBoundingClientRect();
        const overlayRect = overlayRef.current.getBoundingClientRect();

        overlayRef.current.style.right = '';
        overlayRef.current.style.bottom = '';

        const left = Math.min(
          bodyRect.width - overlayRect.width,
          Math.max(0, eve.clientX - dragStart[0])
        );
        const right = Math.min(
          bodyRect.height - overlayRect.height,
          Math.max(0, eve.clientY - dragStart[1])
        );

        overlayRef.current.style.left = `${left}px`;
        overlayRef.current.style.top = `${right}px`;
      }
    };

    const onMouseUp = (eve: MouseEvent) => {
      // Let the outside-click event handler trigger before setting isDragging to false
      setTimeout(() => {
        isDragging.current = false;
      }, 0);

      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <Overlay
      ref={overlayRef}
      className={
        className ? `${className} ${PopOverClassName}` : PopOverClassName
      }
    >
      <Title
        draggable={draggable}
        onMouseDown={draggable ? startDrag : undefined}
        className={modalTitleClass}
      >
        {title}
        <CloseButton onClick={() => onClose()}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      </Title>

      <Content className={modalContentClass}>{children}</Content>

      {buttons && buttons.length > 0 && (
        <Buttons className={modalButtonsClass}>
          {buttons.map((button, i) => (
            <Button
              disabled={button.disabled}
              icon={button.icon}
              key={i}
              onClick={(eve) => {
                eve.stopPropagation();
                button.onClick?.(eve);
              }}
            >
              {button.label}
            </Button>
          ))}
        </Buttons>
      )}
    </Overlay>
  );
};

export default PopOver;
