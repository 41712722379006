import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import MediaQuery from 'constants/MediaQuery';
import moment from 'moment';
import { FC } from 'react';
import styled from 'styled-components';
import ContextMenu from '../ContextMenu';

export const getInitialDateRange = (): [Date, Date] => {
  const now = new Date();
  const startOfYesterday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1,
    0,
    0,
    0,
    0
  );
  const endOfTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    23,
    59,
    59,
    999
  );

  return [startOfYesterday, endOfTomorrow];
};

function isSameDay(a: Date, b: Date) {
  return (
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear()
  );
}

const RangeButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 30px;
  padding: 0 15px;
  margin-left: 5px;
  white-space: nowrap;

  ${MediaQuery.tablet} {
    padding: 0 10px;
  }
`;

const RangeLabel = styled.span`
  display: none;

  ${MediaQuery.tablet} {
    display: inline;
    margin-right: 5px;
  }
`;

interface Props {
  dateRange: [Date | null, Date | null];
  onPresetSelected(dateRange: [Date, Date]): void;
}

interface RangeOption {
  label: string;
  range: [Date, Date];
}

const DateSpanPresetsSelector: FC<Props> = ({
  dateRange,
  onPresetSelected,
}) => {
  const now = new Date();
  const rangeOptions: RangeOption[] = [
    { label: 'Idag', range: getInitialDateRange() },
    {
      label: 'Den här veckan',
      range: [
        moment(now).startOf('week').toDate(),
        moment(now).endOf('week').toDate(),
      ],
    },
    {
      label: 'En månad framåt',
      range: [
        now,
        new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      ],
    },
    {
      label: 'En månad bakåt',
      range: [
        new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
        now,
      ],
    },
    {
      label: 'Ett år bakåt',
      range: [
        new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
        now,
      ],
    },
  ];

  const currentRange = rangeOptions.find(
    (ro) =>
      dateRange[0] &&
      dateRange[1] &&
      isSameDay(dateRange[0], ro.range[0]) &&
      isSameDay(dateRange[1], ro.range[1])
  );

  return (
    <ContextMenu
      options={rangeOptions.map((ro) => ({
        label: ro.label,
        onClick: () => onPresetSelected(ro.range),
      }))}
      position="left"
    >
      <RangeButton>
        <RangeLabel>{currentRange ? currentRange.label : 'Frival'}</RangeLabel>
        <FontAwesomeIcon icon={faAngleDown} />
      </RangeButton>
    </ContextMenu>
  );
};

export default DateSpanPresetsSelector;
