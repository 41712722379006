import { CaseJobSearchResult } from 'api';
import PhoneSlideInView from 'components/Modal/PhoneSlideInView';
import MediaQuery from 'constants/MediaQuery';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import { FC, useEffect } from 'react';
import {
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ApiResponse, RequestStatus } from 'swaggerhooks';
import CaseSlideIn, { caseSlideInTabletWidth } from './CaseSlideIn';
import useJobSearchAndForm from './useJobSearchAndForm';
import { CaseJobEvent, JobFormIndexes } from './useCaseJobEvents';
import useTimespanState, { TimespanMode } from './useTimespanState';

const Horizontal = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const MySlideIn = styled(PhoneSlideInView)`
  z-index: 2;

  ${MediaQuery.tablet} {
    transition: width 0.2s;
    border-left: 1px solid ${Theme.colors.border.main};

    ${({ open }) =>
      open
        ? css`
            width: ${caseSlideInTabletWidth}px;
          `
        : css`
            width: 0;
          `}
  }
`;

function getTimespanModeFromPathname(pathname: string): TimespanMode {
  if (matchPath(Routes.plannerTool.monthView, pathname))
    return TimespanMode.Month;
  if (matchPath(Routes.plannerTool.weekView, pathname))
    return TimespanMode.Week;
  if (matchPath(Routes.plannerTool.dayView, pathname)) return TimespanMode.Day;
  if (matchPath(Routes.plannerTool.listView, pathname))
    return TimespanMode.Free;
  return TimespanMode.Month;
}

interface JobPageContext {
  from: Date;
  to: Date;
  bufferedEvents: CaseJobEvent[];
  isDebouncingTimespan: boolean;
  searchStatus: RequestStatus;
  searchRequest: ApiResponse<CaseJobSearchResult[]>;
  setTimespan(from: Date, to?: Date): void;
  selectedCaseJobFormIndex: JobFormIndexes | undefined;
  handleEventClick(caseJobEvent: CaseJobEvent): void;
  handleCreateCaseClick(from: Date, to: Date, regNr?: string): void;
}

export const useJobPageContext = () => useOutletContext<JobPageContext>();

const JobsPage: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    from,
    to,
    bufferedFrom,
    bufferedTo,
    setTimespan,
    isDebouncingTimespan,
  } = useTimespanState(getTimespanModeFromPathname(pathname));

  const {
    caseEditorState,
    caseJobEvents: bufferedEvents,
    handleEventClick,
    search,
    handleCreateCaseClick,
    showCaseSlideIn,
    selectedCaseJob,
    selectedCaseJobFormIndex,
    setSelectedCaseJobFormIndex,
    clearSelectedCaseJob,
  } = useJobSearchAndForm(bufferedFrom, bufferedTo);

  useEffect(() => {
    const match = matchPath(Routes.plannerTool.index, pathname);

    if (match) {
      // automatically navigate to /jobb/manad when landing on /jobb
      navigate(Routes.plannerTool.monthView, { replace: true });
    }
  }, [pathname]);

  const outletContext: JobPageContext = {
    from,
    to,
    bufferedEvents,
    searchRequest: search,
    searchStatus: search.status,
    isDebouncingTimespan,
    setTimespan,
    handleEventClick,
    selectedCaseJobFormIndex,
    handleCreateCaseClick,
  };

  return (
    <Horizontal>
      <Outlet context={outletContext} />

      <MySlideIn
        open={showCaseSlideIn}
        onClose={clearSelectedCaseJob}
        keepTitleForTablet
        title={
          selectedCaseJob.response
            ? `Ärende ${selectedCaseJob.response.case.id}`
            : 'Nytt ärende'
        }
      >
        {showCaseSlideIn && (
          <CaseSlideIn
            caseEditorState={caseEditorState}
            originalCase={selectedCaseJob.response?.case}
            highlightCaseJobIndex={selectedCaseJobFormIndex}
            onShowJobInCalendarClick={(jobFormIndex) => {
              const date = new Date(
                caseEditorState.form.getValues(
                  `jobGroups.${jobFormIndex.jobGroupFormIndex}.orderedStartUtc`
                )
              );
              if (!isNaN(date.getTime())) setTimespan(date);

              setTimeout(() => {
                setSelectedCaseJobFormIndex(jobFormIndex);
              }, 0);
            }}
          />
        )}
      </MySlideIn>
    </Horizontal>
  );
};

export default JobsPage;
