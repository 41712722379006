import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMondayOfWeek, getWeekNumber } from 'components/Calendar/utils';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`;

const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;

  border: none;
  border-radius: 1000px;
  background: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
  cursor: pointer;
`;

interface Props {
  type: 'day' | 'week' | 'month';
  selectedDate: Date;
  onDateSelected(date: Date): void;
  className?: string;
}

const TimeNavButtons: FC<Props> = ({
  type,
  selectedDate,
  onDateSelected,
  className,
}) => {
  const onIncDecreaseClick = (direction: number) => {
    switch (type) {
      case 'day':
        onDateSelected(
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate() + direction
          )
        );
        break;

      case 'week':
        onDateSelected(
          getMondayOfWeek(
            getWeekNumber(selectedDate) + direction,
            selectedDate.getFullYear()
          )
        );
        break;

      case 'month':
        onDateSelected(
          new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + direction,
            1
          )
        );
        break;
    }
  };

  return (
    <Wrapper className={className}>
      <NavButton onClick={() => onIncDecreaseClick(-1)}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </NavButton>
      <NavButton onClick={() => onIncDecreaseClick(1)}>
        <FontAwesomeIcon icon={faChevronRight} />
      </NavButton>
    </Wrapper>
  );
};

export default TimeNavButtons;
