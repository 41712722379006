import LabelWrap from 'components/inputs/LabelWrap';
import { FC } from 'react';
import {
  Control,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import styled from 'styled-components';
import InputGrid, { FormInput, FormSelect, FormTextArea } from '../components';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import useBasicData from 'contexts/useBasicData';
import Theme from 'constants/theme';
import usePricingSetup from 'contexts/usePricingSetup';
import useSelectableCustomers from 'utils/useSelectableCustomers';
import { CaseStatus } from 'api';
import { arraySpreadIf } from 'utils/spreading';

const Wrapper = styled.div`
  padding: 0 ${Theme.sizes.padding.screenInset};
`;

const MyTextArea = styled(FormTextArea)`
  resize: vertical;
  max-height: 150px;
`;

interface Props {
  register: UseFormRegister<CaseFormInputs>;
  formControl: Control<CaseFormInputs, any>;
  getValues: UseFormGetValues<CaseFormInputs>;
  trigger: UseFormTrigger<CaseFormInputs>;
  noEdit: boolean;
}

const CaseFields: FC<Props> = ({
  register,
  formControl,
  getValues,
  trigger,
  noEdit,
}) => {
  const { caseStatusNames } = useBasicData();
  const { customersById } = usePricingSetup();
  const selectableCustomers = useSelectableCustomers();
  const caseStatus = useWatch({
    control: formControl,
    name: 'caseStatus',
  });

  const mkField = <K extends keyof CaseFormInputs>(
    fieldName: K,
    label: string,
    type: React.HTMLInputTypeAttribute = 'text',
    options?: RegisterOptions<CaseFormInputs, K>
  ) => (
    <LabelWrap label={label}>
      <FormInput
        type={type}
        {...register(fieldName, options)}
        noEdit={noEdit}
      />
    </LabelWrap>
  );

  const mkSelect = <K extends keyof CaseFormInputs>(
    fieldName: K,
    label: string,
    selectOptions: { label: string; value: number }[],
    registerOptions?: RegisterOptions<CaseFormInputs, K>
  ) => (
    <LabelWrap label={label}>
      <FormSelect
        {...register(fieldName, {
          valueAsNumber: true,
          ...registerOptions,
        })}
        noEdit={noEdit}
      >
        {selectOptions.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </FormSelect>
    </LabelWrap>
  );

  return (
    <Wrapper>
      <InputGrid>
        {mkSelect('caseStatus', 'Status', [
          {
            label: caseStatusNames.get(CaseStatus.Open) ?? '',
            value: CaseStatus.Open,
          },
          {
            label: caseStatusNames.get(CaseStatus.ReadyToInvoice) ?? '',
            value: CaseStatus.ReadyToInvoice,
          },
          {
            label: caseStatusNames.get(CaseStatus.Discarded) ?? '',
            value: CaseStatus.Discarded,
          },
          ...arraySpreadIf(caseStatus === CaseStatus.InvoicesCompleted, {
            label: caseStatusNames.get(CaseStatus.InvoicesCompleted) ?? '',
            value: CaseStatus.InvoicesCompleted,
          }),
        ])}

        {mkSelect(
          'customerId',
          'Kund',
          selectableCustomers.map((customer) => ({
            label: customer.name,
            value: customer.id,
          })),
          {
            onChange() {
              trigger('externalReference');
            },
          }
        )}

        {mkField('caseDateUtc', 'Ärendedatum', 'date')}
        {mkField('externalReference', 'Ext. referens', undefined, {
          validate: (v) => {
            const customerId = getValues('customerId');
            const customer = customersById.get(customerId);

            if (customer && customer.requireExternalReference && !v.trim()) {
              return `Ext. referens krävs för kunden '${customer.name}'`;
            }
          },
        })}

        {mkField('streetAddress', 'Adress')}
        {mkField('zipCode', 'Postnr')}
        {mkField('city', 'Ort')}
        {mkField('meetupLocation', 'Mötesplats')}

        <LabelWrap label="Platsbeskrivning">
          <MyTextArea {...register('locationDescription')} noEdit={noEdit} />
        </LabelWrap>

        <LabelWrap label="Kommentar">
          <MyTextArea {...register('freetextNotes')} noEdit={noEdit} />
        </LabelWrap>
      </InputGrid>
    </Wrapper>
  );
};

export default CaseFields;
