import { Vehicle } from 'api';

export interface VehicleFormInputs {
  regNr: string;
  vehicleType: string;
}

export function createVehicleForm(vehicle?: Vehicle): VehicleFormInputs {
  if (vehicle) {
    return {
      regNr: vehicle.regNr,
      vehicleType: vehicle.vehicleType,
    };
  }

  return {
    regNr: '',
    vehicleType: '',
  };
}

export function vehicleFormToVehicle(
  formInputs: VehicleFormInputs,
  akCompanyId: number,
  base?: Vehicle
): Vehicle {
  return new Vehicle({
    regNr: formInputs.regNr,
    vehicleType: formInputs.vehicleType,
    akCompanyId,

    id: base?.id ?? 0,
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    createdById: base?.createdById ?? -1,
    modifiedById: base?.modifiedById ?? -1,
    timestamp: base?.timestamp ?? '',
    isRemoved: base?.isRemoved ?? false,
  });
}
