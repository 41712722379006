import MediaQuery from 'constants/MediaQuery';
import { FC, PropsWithChildren } from 'react';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';

/** screenSize is the type of screen that the style will be applied to.
 * The style will be applied either when the device's screen matches screenSize, or if it has ActivateMediaQuery as a parent/grandparent.
 * The style can also be deactivated when it has DectivateMediaQuery as a parent/grandparent */
export function makeActivatableMediaQuery(
  screenSize: keyof typeof MediaQuery,
  style:
    | string
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<DefaultTheme>>
) {
  return css`
    .activate-media-query-${screenSize} & {
      ${style}
    }

    ${MediaQuery[screenSize]} {
      &:not(.deactivate-media-query-${screenSize} &) {
        ${style}
      }
    }
  `;
}

const InvisDiv = styled.div`
  display: contents;
`;

interface Props extends PropsWithChildren {
  screenSize: keyof typeof MediaQuery;
  disable?: boolean;
}

export const ActivateMediaQuery: FC<Props> = ({
  screenSize,
  disable,
  children,
}) => (
  <InvisDiv
    className={disable ? undefined : `activate-media-query-${screenSize}`}
  >
    {children}
  </InvisDiv>
);

export const DeactivateMediaQuery: FC<Props> = ({
  screenSize,
  disable,
  children,
}) => (
  <InvisDiv
    className={disable ? undefined : `deactivate-media-query-${screenSize}`}
  >
    {children}
  </InvisDiv>
);
