import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import styled from 'styled-components';
import {
  ColumnSetting,
  makeColumnClassName,
  tableHeadClassName,
} from './utils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0;
  min-width: 100%;

  background-color: ${Theme.colors.bg.background1};
  border-bottom: 2px solid ${Theme.colors.border.main};
`;

const Span = styled.div`
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  padding: 5px 3px;
  box-sizing: border-box;
  cursor: default;
`;

const SortIcon = styled(FontAwesomeIcon)`
  font-size: 11px;
  margin-left: 2px;
  padding-bottom: 1px;
  cursor: pointer;
`;

interface Props<Row extends Object, RenderProps extends Object> {
  columnSettings: ColumnSetting<Row, RenderProps>[];

  toggleSort(columnIndex: number): void;
  sortedColumnIndex: number;
  sortAscending: boolean;
}

const TableHead = <Row extends Object, RenderProps extends Object>({
  columnSettings,
  toggleSort,
  sortedColumnIndex,
  sortAscending,
}: Props<Row, RenderProps>) => {
  return (
    <Container>
      {columnSettings.map((setting, i) => (
        <Span
          className={`${makeColumnClassName(i)} ${tableHeadClassName}`}
          key={i}
          onClick={setting.sortFunction ? () => toggleSort(i) : undefined}
        >
          {setting.head}{' '}
          {i === sortedColumnIndex &&
            (sortAscending ? (
              <SortIcon icon={faChevronDown} />
            ) : (
              <SortIcon icon={faChevronUp} />
            ))}
        </Span>
      ))}
    </Container>
  );
};

export default TableHead;
