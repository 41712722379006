import usePricingSetup from 'contexts/usePricingSetup';
import React, { FC } from 'react';
import { safeColor } from 'utils/color';
import BasicEventLine from 'components/Calendar/BasicEventLine';
import { CaseEvent } from './useMergedCaseJobEvents';
import styled, { css } from 'styled-components';
import { removedBackgroundGradient } from '../JobsGantt/JobEventLine';
import WarningTooltip from 'components/Tooltip/WarningTooltip';
import { CaseJobEvent } from '../useCaseJobEvents';

export const OrderAmount = styled.span<{ dark: boolean }>`
  display: inline-flex;
  vertical-align: 1px;
  padding: 0 0.3em;
  gap: 5px;

  align-items: center;
  justify-content: center;
  margin-right: 5px;

  border-radius: 5px;
  line-height: 1.25em;
  font-weight: 600;
  font-size: 0.8em;

  ${({ dark }) =>
    dark
      ? css`
          background-color: #0009;
          color: #fff;
        `
      : css`
          background-color: #fff;
          color: #000;
        `}
`;

interface Props {
  containerStart: Date;
  containerEnd: Date;
  from: Date;
  to: Date;
  showDateOnStickyEnd?: boolean;

  caseEvent: CaseEvent;
  onClick(caseJobEvent: CaseJobEvent): void;
  selected: boolean;
  caseEventClassName: string;
}

const CaseEventLine: FC<Props> = ({
  containerStart,
  containerEnd,
  showDateOnStickyEnd,
  caseEvent,
  onClick,
  selected,
  caseEventClassName,
}) => {
  const { customersById } = usePricingSetup();

  const customer = customersById.get(caseEvent.customerId ?? -1);
  const backgroundColor = safeColor(customer?.calendarColor, 'red');
  const timeHandleColors: React.CSSProperties = caseEvent.isRemoved
    ? { color: '#ddd', backgroundColor: 'transparent' }
    : {
        color: backgroundColor.hex(),
        backgroundColor: backgroundColor.isLight() ? '#000a' : '#fff',
      };

  const intersectingJobs = caseEvent.caseJobEvents.filter(
    (jobEve) => jobEve.regnrIntersectsWith || jobEve.driverIntersectsWith
  );

  return (
    <>
      <BasicEventLine
        containerStart={containerStart}
        containerEnd={containerEnd}
        event={caseEvent}
        eventClassName={caseEventClassName}
        from={caseEvent.from}
        to={caseEvent.to}
        onClick={() => onClick(caseEvent.caseJobEvents[0])}
        selected={selected}
        showDateOnStickyEnd={showDateOnStickyEnd}
        makeStyles={(caseEvent) => ({
          label: (
            <>
              <OrderAmount dark={backgroundColor.isLight()}>
                {caseEvent.caseJobEvents.length}
              </OrderAmount>
              <b>{customersById.get(caseEvent.customerId)?.name}</b>
            </>
          ),
          backgroundStyle: {
            background: caseEvent.isRemoved
              ? removedBackgroundGradient
              : backgroundColor.hex(),
          },

          timeHandlesStyle: timeHandleColors,
        })}
      />
      {intersectingJobs.length > 0 && (
        <WarningTooltip
          attachToElement={caseEventClassName}
          position="center"
          onClick={() => onClick(intersectingJobs[0])}
        >
          {intersectingJobs.some(
            (jobEvent) => jobEvent.regnrIntersectsWith
          ) && (
            <div>
              Bokning av{' '}
              <b>{intersectingJobs.map((j) => j.regNr).join(', ')}</b> krockar
              med ett annat jobb.
            </div>
          )}

          {intersectingJobs.some(
            (jobEvent) => jobEvent.driverIntersectsWith
          ) && (
            <div>
              Bokning av förare <b>{intersectingJobs[0].driverName}</b> krockar
              med ett annat jobb.
            </div>
          )}
        </WarningTooltip>
      )}
    </>
  );
};

export default CaseEventLine;
