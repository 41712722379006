import { Case, CaseExpense, CaseStatus } from 'api';
import { toInputDateString } from 'utils/date';
import {
  JobGroupFormInputs,
  jobGroupFormInputsToCaseJobs,
  createJobGroupFormInputsFromCaseJobs,
  createNewJobGroupFormInputs,
} from './caseJobFormConverters';
import {
  createExpenseForm,
  ExpenseFormInputs,
  expenseFormToCaseExpense,
} from './expenseFormConverters';

export interface CaseFormInputs {
  caseId: number;
  customerId: number;
  caseStatus: CaseStatus;
  caseDateUtc: string;
  streetAddress: string;
  zipCode: string;
  city: string;
  locationDescription: string;
  externalReference: string;
  meetupLocation: string;
  freetextNotes: string;

  jobGroups: JobGroupFormInputs[];
  caseExpenses: ExpenseFormInputs[];
}

export function createCaseForm(theCase?: Case): CaseFormInputs {
  if (theCase) {
    return {
      caseId: theCase.id,
      customerId: theCase.customerId,
      caseStatus: theCase.caseStatus,
      caseDateUtc: toInputDateString(theCase.caseDateUtc),
      streetAddress: theCase.streetAddress,
      zipCode: theCase.zipCode,
      city: theCase.city,
      locationDescription: theCase.locationDescription,
      externalReference: theCase.externalReference,
      meetupLocation: theCase.meetupLocation,
      freetextNotes: theCase.freetextNotes,
      jobGroups: createJobGroupFormInputsFromCaseJobs(theCase.caseJobs),
      caseExpenses: theCase.caseExpenses.map(createExpenseForm),
    };
  }

  const now = new Date();
  return {
    caseId: 0,
    customerId: 0,
    caseStatus: CaseStatus.Open,
    caseDateUtc: toInputDateString(now),
    streetAddress: '',
    zipCode: '',
    city: '',
    locationDescription: '',
    externalReference: '',
    meetupLocation: '',
    freetextNotes: '',
    jobGroups: [createNewJobGroupFormInputs()],
    caseExpenses: [],
  };
}

export function caseFormToCase(
  formInputs: CaseFormInputs,
  akCompanyId: number,
  base?: Case
): Case {
  const caseExpensesById = new Map<number, CaseExpense>();

  base?.caseExpenses.forEach((exp) => caseExpensesById.set(exp.id, exp));

  return new Case({
    id: base?.id ?? 0,
    customerId: formInputs.customerId,
    akCompanyId: base?.akCompanyId ?? akCompanyId,
    caseStatus: formInputs.caseStatus,
    caseDateUtc: new Date(formInputs.caseDateUtc),
    streetAddress: formInputs.streetAddress,
    zipCode: formInputs.zipCode,
    city: formInputs.city,
    locationDescription: formInputs.locationDescription,
    externalReference: formInputs.externalReference,
    meetupLocation: formInputs.meetupLocation,
    freetextNotes: formInputs.freetextNotes,
    caseJobs: jobGroupFormInputsToCaseJobs(
      formInputs.jobGroups,
      base?.caseJobs
    ),
    caseExpenses: formInputs.caseExpenses.map((formExpense) =>
      expenseFormToCaseExpense(
        formExpense,
        caseExpensesById.get(formExpense.expenseId)
      )
    ),
    caseMessages: base?.caseMessages ?? [],
    timestamp: base?.timestamp ?? '',

    // Backend manages these, but I add them anyway
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    createdById: -1,
    modifiedById: -1,
  });
}
