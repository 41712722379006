import { AppTextsClient } from 'api';
import EditableRichText from 'components/inputs/EditableRichText';
import AppTextKey from 'constants/AppTextKeys';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';
import { useResponse } from 'swaggerhooks';

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 10px ${Theme.sizes.padding.screenInset};
`;

const MyRichText = styled(EditableRichText)`
  background-color: ${Theme.colors.bg.background2};
  color: ${Theme.colors.fg.background2};
  border-radius: 10px;
`;

const CreateCaseInfo: FC = () => {
  const infoResponse = useResponse(AppTextsClient, (c) =>
    c.getAppText(AppTextKey.CUSTOMER_CREATE_CASE_PAGE_TEXT)
  );

  return (
    <Wrapper>
      <MyRichText editText={infoResponse.response ?? ''} />
    </Wrapper>
  );
};

export default CreateCaseInfo;
