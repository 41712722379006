import { DateSpan } from 'components/Calendar/utils';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import useDebouncedValue from 'utils/useDebouncedValue';
import useQueryParam from 'utils/useQueryParam';

export enum TimespanMode {
  Month,
  Week,
  Day,
  Free,
}

function getDefaultTimespan(mode: TimespanMode): DateSpan {
  const now = new Date();
  let from = now;
  let to = now;

  switch (mode) {
    case TimespanMode.Free:
    case TimespanMode.Month:
      from = moment(now).startOf('month').toDate();
      to = moment(now).endOf('month').toDate();
      break;
    case TimespanMode.Week:
      from = moment(now).startOf('week').toDate();
      to = moment(now).endOf('week').toDate();
      break;
    case TimespanMode.Day:
      from = moment(now).startOf('day').subtract(1, 'day').toDate();
      to = moment(now).endOf('day').add(1, 'day').toDate();
  }

  return { from, to };
}

function conformDates(mode: TimespanMode, from: Date, to: Date): DateSpan {
  if (isNaN(from.getTime()) || isNaN(to.getTime())) {
    return getDefaultTimespan(mode);
  }

  switch (mode) {
    case TimespanMode.Free:
      return {
        from,
        to,
      };
    case TimespanMode.Month:
      return {
        from: moment(from).startOf('month').toDate(),
        to: moment(from).endOf('month').toDate(),
      };
    case TimespanMode.Week:
      return {
        from: moment(from).startOf('week').toDate(),
        to: moment(from).endOf('week').toDate(),
      };
    case TimespanMode.Day:
      return {
        from: moment(from).startOf('day').toDate(),
        to: moment(from).endOf('day').add(2, 'day').toDate(),
      };
  }
}

export const fromDateQueryName = 'fran';
export const toDateQueryName = 'till';

const useTimespanState = (mode: TimespanMode) => {
  const [fromQuery, setFromQuery] = useQueryParam(fromDateQueryName);
  const [toQuery, setToQuery] = useQueryParam(toDateQueryName);

  const conformed = useMemo((): DateSpan => {
    const from = new Date(fromQuery ?? NaN);
    const to = new Date(toQuery ?? NaN);
    return conformDates(mode, from, to);
  }, [fromQuery, toQuery, mode]);

  const [debouncedConformed, isDebouncingTimespan] =
    useDebouncedValue(conformed);

  // Add a time buffer, so that it seems instant when you change to the next/previous months/weeks
  const bufferedTime = useMemo((): DateSpan => {
    switch (mode) {
      case TimespanMode.Free:
        return debouncedConformed;
      case TimespanMode.Month:
        return {
          from: moment(debouncedConformed.from).subtract(2, 'month').toDate(),
          to: moment(debouncedConformed.from).add(2, 'month').toDate(),
        };
      case TimespanMode.Week:
      case TimespanMode.Day:
        return {
          from: moment(debouncedConformed.from).subtract(2, 'week').toDate(),
          to: moment(debouncedConformed.from).add(2, 'week').toDate(),
        };
    }
  }, [debouncedConformed, mode]);

  const setTimespan = useCallback(
    (from: Date, to?: Date) => {
      const conformed = conformDates(
        mode,
        from,
        to ?? moment(from).add(1, 'day').toDate()
      );

      setFromQuery(conformed.from.toISOString(), true);
      setToQuery(conformed.to.toISOString(), true);
    },
    [mode, setFromQuery, setToQuery]
  );

  return {
    from: conformed.from,
    to: conformed.to,
    bufferedFrom: bufferedTime.from,
    bufferedTo: bufferedTime.to,
    setTimespan,
    isDebouncingTimespan,
  };
};

export default useTimespanState;
