import Theme from 'constants/theme';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ isOtherMonth?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid ${Theme.colors.border.main};
  margin-left: -1px;
  margin-bottom: -1px;

  ${({ isOtherMonth }) =>
    isOtherMonth &&
    css`
      opacity: 0.2;
    `}
`;

const DateNumber = styled.div<{ isToday?: boolean }>`
  margin: 5px auto;
  padding: 2px 5px;
  border-radius: 1000px;
  font-weight: bold;

  ${({ isToday }) =>
    isToday &&
    css`
      background-color: ${Theme.colors.bg.selection};
      color: ${Theme.colors.fg.selection};
    `}
`;

interface Props {
  date: Date;
  isOtherMonth?: boolean;
  onBodyClick?(date: Date): void;
  onDateNumberClick?(date: Date): void;
}

const DaySection: FC<Props> = ({
  date,
  onBodyClick,
  onDateNumberClick,
  isOtherMonth,
}) => {
  const now = new Date();
  return (
    <Wrapper
      isOtherMonth={isOtherMonth}
      onClick={onBodyClick ? () => onBodyClick(date) : undefined}
    >
      <DateNumber
        isToday={
          date.getDate() === now.getDate() &&
          date.getMonth() === now.getMonth() &&
          date.getFullYear() === now.getFullYear()
        }
        onClick={onDateNumberClick ? () => onDateNumberClick(date) : undefined}
      >
        {date.getDate()}
      </DateNumber>
    </Wrapper>
  );
};

export default DaySection;
