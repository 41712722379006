import { faComment, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseMessage } from 'api';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Button from 'components/inputs/Button';
import TextButton from 'components/inputs/TextButton';
import SpinnerIcon from 'components/spinners/SpinnerIcon';
import { saveMessageFunction } from '../../useCaseEditorState';
import React, { FC, useState } from 'react';
import styled from 'styled-components';
import AttachmentsEditor from '../AttachmentsEditor';
import useAttachmentsEditorState from '../AttachmentsEditor/useAttachmentsEditorState';
import { Header, HeaderIcon, Wrapper } from './components';

const TextArea = styled(AutoSizedTextArea)`
  min-height: 3em;
  max-height: 200px;
  resize: vertical;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
`;

const AttachFileButton = styled(TextButton)`
  margin-right: auto;
`;

interface Props {
  caseMessage: CaseMessage;
  onSaveMessage: saveMessageFunction;
  onAbortEdit(): void;
  savingMessage: boolean;
}

const CommentEditor: FC<Props> = ({
  caseMessage,
  onSaveMessage,
  onAbortEdit,
  savingMessage,
}) => {
  const attachmentsState = useAttachmentsEditorState(
    caseMessage.caseAttachments
  );
  const [comment, setComment] = useState(caseMessage.text);

  const handleSaveClick = (eve?: React.MouseEvent) => {
    eve?.preventDefault();

    onSaveMessage(
      new CaseMessage({
        ...caseMessage,
        text: comment,
        caseAttachments: attachmentsState.savedAttachments,
      }),
      attachmentsState.queuedFiles
    );
  };

  return (
    <Wrapper>
      <Header message={caseMessage}>
        <HeaderIcon icon={faComment} /> Redigera Kommentar
      </Header>

      <TextArea
        placeholder="Skriv ett meddelande..."
        value={comment}
        onChange={(eve) => setComment(eve.currentTarget.value)}
        onKeyDown={(eve) => {
          if (eve.ctrlKey && eve.key === 'Enter') {
            handleSaveClick();
          }
        }}
        disabled={savingMessage}
      />

      <AttachmentsEditor state={attachmentsState} disabled={savingMessage} />

      <Actions>
        <AttachFileButton
          onClick={(eve) => {
            eve.preventDefault();
            attachmentsState.pickFile();
          }}
          disabled={savingMessage}
        >
          <FontAwesomeIcon icon={faPaperclip} /> Bifoga fil
        </AttachFileButton>

        <Button
          onClick={(eve) => {
            eve.preventDefault();
            onAbortEdit();
          }}
          small
          disabled={savingMessage}
        >
          Avbryt
        </Button>
        <Button
          onClick={handleSaveClick}
          icon={savingMessage ? <SpinnerIcon size="small" /> : undefined}
          small
          disabled={savingMessage}
        >
          {savingMessage ? 'Sparar...' : 'Spara'}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default CommentEditor;
