import { AuthenticationClient, UserClient, WebUser } from 'api';
import { useCallback, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useApiCall, useResponse } from 'swaggerhooks';
import {
  getLoginToken,
  getRefreshToken,
  storeLoginToken,
  storeRefreshToken,
} from './authLocalStorage';
import {
  getSelectedAkCompanyId,
  storeSelectedAkCompanyId,
} from './userSettingsLocalStorage';

let token = getLoginToken();
let refreshToken = getRefreshToken();

export const ApiTokens = {
  get token() {
    return token;
  },
  get refreshToken() {
    return refreshToken;
  },
};

interface AccountInfo {
  /** User info is downloaded async */
  user: WebUser | null;
  selectedAkCompanyId: number | null;
}

export const accountInfoAtom = atom<AccountInfo>({
  key: 'AccountInfo',
  default: {
    user: null,
    selectedAkCompanyId: getSelectedAkCompanyId(),
  },
});

type selectedAkCompanyIdWasChangedCallback = (
  akCompanyId: number | null
) => void;
const selectedAkCompanyIdWasChangedCallbacks =
  new Set<selectedAkCompanyIdWasChangedCallback>();

export const useAccountInfoInitialization = (isLoggedIn: boolean) => {
  const [, setAccountInfo] = useRecoilState(accountInfoAtom);

  return useResponse(
    UserClient,
    async (c) => {
      if (!isLoggedIn) return null;
      const me = await c.getMe();
      setAccountInfo((ai) => ({ ...ai, user: me }));
    },
    [isLoggedIn]
  );
};

const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const logoutCall = useApiCall(AuthenticationClient, (c) => c.logoutUser());

  const onLoggedIn = useCallback(
    (_loginToken: string, _refreshToken: string, user: WebUser) => {
      storeLoginToken(_loginToken);
      storeRefreshToken(_refreshToken);

      token = _loginToken;
      refreshToken = _refreshToken;
      setAccountInfo((ai) => {
        let newSelectedAkCompanyId = ai.selectedAkCompanyId;

        // If user isn't assigned to selected company, then reset selectedAkCompanyId to first option.
        if (
          !user.akCompanyMemberships.find(
            (cm) => cm.akCompanyId === newSelectedAkCompanyId
          )
        ) {
          newSelectedAkCompanyId =
            user.akCompanyMemberships[0]?.akCompanyId ?? null;

          storeSelectedAkCompanyId(newSelectedAkCompanyId);
          selectedAkCompanyIdWasChangedCallbacks.forEach((callback) =>
            callback(newSelectedAkCompanyId)
          );
        }

        return {
          ...ai,
          user: user,
          selectedAkCompanyId: newSelectedAkCompanyId,
        };
      });
    },
    [setAccountInfo]
  );

  const onLogOut = useCallback(async () => {
    storeLoginToken(null);
    storeRefreshToken(null);

    await logoutCall.run();

    token = null;
    refreshToken = null;
    setAccountInfo((ai) => ({
      ...ai,
      user: null,
    }));
  }, [setAccountInfo]);

  const onTokenRefreshed = useCallback(
    (_token: string, _refreshToken: string) => {
      storeLoginToken(_token);
      storeRefreshToken(_refreshToken);

      token = _token;
      refreshToken = _refreshToken;
    },
    []
  );

  const onSelectAkCompany = useCallback(
    (akCompanyId: number | null) => {
      storeSelectedAkCompanyId(akCompanyId);
      selectedAkCompanyIdWasChangedCallbacks.forEach((callback) =>
        callback(akCompanyId)
      );
      setAccountInfo((ai) => ({ ...ai, selectedAkCompanyId: akCompanyId }));
    },
    [setAccountInfo]
  );

  return {
    accountInfo,
    isLoggedIn: !!(ApiTokens.token && ApiTokens.refreshToken),
    onLoggedIn,
    onLogOut,
    onTokenRefreshed,
    onSelectAkCompany,
  };
};

export const useSelectedAkCompanyChanged = (
  callback: selectedAkCompanyIdWasChangedCallback
) => {
  useEffect(() => {
    selectedAkCompanyIdWasChangedCallbacks.add(callback);

    return () => {
      selectedAkCompanyIdWasChangedCallbacks.delete(callback);
    };
  }, [callback]);
};

export default useAccountInfo;
