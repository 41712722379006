import MediaQuery from 'constants/MediaQuery';
import { css } from 'styled-components';

export const cssVar = {
  groupHeaderHeight: '--group-header-height',
  eventLineHeight: '--event-line-height',
  eventLinePadding: '--event-line-padding',
  groupNameWidth: '--group-name-width',
} as const;

const GanttChartCssVariableDefs = css`
  ${cssVar.groupHeaderHeight}: 40px;
  ${cssVar.eventLineHeight}: 40px;
  ${cssVar.eventLinePadding}: 10px;

  ${MediaQuery.desktop} {
    ${cssVar.groupHeaderHeight}: 40px;
    ${cssVar.eventLineHeight}: 30px;
    ${cssVar.eventLinePadding}: 5px;
  }
`;

export default GanttChartCssVariableDefs;
