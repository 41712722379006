import { CaseJobClient, CustomerCase, CustomerCaseJob, JobStatus } from 'api';
import { DateSpan } from 'components/Calendar/utils';
import useSelectedCustomerId from 'contexts/useSelectedCustomer';
import { useMemo } from 'react';
import { useResponse } from 'swaggerhooks';

export interface CustomerCaseEvent extends DateSpan {
  caseId: number;
  isRemoved: boolean;

  customerCase: CustomerCase;
  customerCaseJobs: CustomerCaseJob[];
}

function jobStatusEqualsRemoved(caseJobStatus: JobStatus) {
  return (
    caseJobStatus === JobStatus.Discarded ||
    caseJobStatus === JobStatus.LateCancel
  );
}

const useCustomerJobEvents = (from: Date, to: Date) => {
  const customerId = useSelectedCustomerId();
  const searchResults = useResponse(
    CaseJobClient,
    async (c) =>
      customerId === null
        ? null
        : await c.searchCustomerCaseJobs(customerId, from, to),
    [from, to]
  );

  const customerCaseEvents = useMemo(() => {
    const caseEventsByCaseId = new Map<number, CustomerCaseEvent>();

    searchResults.response?.forEach((searchResult) => {
      let caseEvent = caseEventsByCaseId.get(
        searchResult.customerCase?.id ?? 0
      );

      if (!caseEvent) {
        caseEventsByCaseId.set(searchResult.customerCase?.id ?? 0, {
          caseId: searchResult.customerCase?.id ?? 0,
          customerCase: searchResult.customerCase,
          customerCaseJobs: [searchResult.customerCaseJob],
          from: searchResult.customerCaseJob.orderedStartUtc,
          to: searchResult.customerCaseJob.orderedEndUtc,
          isRemoved: jobStatusEqualsRemoved(
            searchResult.customerCaseJob.jobStatus
          ),
        });

        return;
      }

      caseEvent.customerCaseJobs.push(searchResult.customerCaseJob);

      caseEvent.isRemoved =
        caseEvent.isRemoved &&
        jobStatusEqualsRemoved(searchResult.customerCaseJob.jobStatus);

      if (searchResult.customerCaseJob.orderedStartUtc < caseEvent.from) {
        caseEvent.from = searchResult.customerCaseJob.orderedStartUtc;
      }

      if (searchResult.customerCaseJob.orderedEndUtc > caseEvent.to) {
        caseEvent.to = searchResult.customerCaseJob.orderedEndUtc;
      }
    });

    return Array.from(caseEventsByCaseId.values());
  }, [searchResults]);

  return {
    customerCaseEvents,
    searchResults,
  };
};

export default useCustomerJobEvents;
