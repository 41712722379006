import { Vehicle, VehicleClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Modal from 'components/Modal';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';
import { useOnVehicleSaved } from '..';
import VehicleForm from './VehicleForm';
import {
  createVehicleForm,
  VehicleFormInputs,
  vehicleFormToVehicle,
} from './vehicleFormConverters';

interface Props {
  createNew?: boolean;
  prefillFields?: Partial<VehicleFormInputs>;
}

const VehicleModal: FC<Props> = ({ createNew, prefillFields }) => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const form = useForm<VehicleFormInputs>({
    mode: 'onChange',
  });
  const params = useParams<{ id: string }>();
  const vehicleId = Number(params.id);
  const navigate = useNavigate();
  const onVehicleSaved = useOnVehicleSaved();

  const vehicleResponse = useResponse(
    VehicleClient,
    async (c) => {
      if (createNew) {
        form.reset({ ...createVehicleForm(), ...prefillFields });
        return null;
      } else {
        const vehicle = await c.getVehicle(vehicleId);
        form.reset(createVehicleForm(vehicle));
        return vehicle;
      }
    },
    [vehicleId, createNew]
  );
  const saveVehicleCall = useApiCall(VehicleClient, (c, vehicle: Vehicle) =>
    c.saveVehicle(vehicle)
  );

  const formIsDirty = form.formState.isDirty;
  const formIsValid = form.formState.isValid;

  const handleClose = (eve: React.MouseEvent) => {
    eve.preventDefault();
    navigate(Routes.settings.manageVehicles.index);
  };

  const submitHandler: SubmitHandler<VehicleFormInputs> = async (
    formInputs
  ) => {
    if (
      selectedAkCompanyId !== null &&
      (createNew || !!vehicleResponse.response)
    ) {
      const [savedVehicle, error] = await saveVehicleCall.run(
        vehicleFormToVehicle(
          formInputs,
          selectedAkCompanyId,
          vehicleResponse.response ?? undefined
        )
      );

      if (savedVehicle && !error) {
        onVehicleSaved(savedVehicle);
        navigate(Routes.settings.manageVehicles.index);
      }
    }
  };

  const renderContent = () => {
    switch (vehicleResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection>Hämtar fordon...</LoadingSection>;

      case RequestStatus.Fetched:
        if (saveVehicleCall.status === RequestStatus.Fetching) {
          return <LoadingSection>Sparar fordon...</LoadingSection>;
        }
        return <VehicleForm form={form} />;

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => vehicleResponse.update()} />;
    }
  };

  const disableButtons = [
    saveVehicleCall.status,
    vehicleResponse.status,
  ].includes(RequestStatus.Fetching);

  return (
    <form onSubmit={form.handleSubmit(submitHandler)}>
      <Modal
        title={`${createNew ? 'Skapa' : 'Redigera'} ${
          vehicleResponse.response?.regNr || 'fordon'
        }`}
        onClose={disableButtons ? undefined : handleClose}
        buttons={
          disableButtons
            ? undefined
            : [
                {
                  label: createNew ? 'Skapa' : 'Spara',
                  disabled: !formIsDirty || !formIsValid,
                },
                { label: 'Avbryt', onClick: handleClose },
              ]
        }
      >
        {renderContent()}
      </Modal>
    </form>
  );
};

export default VehicleModal;
