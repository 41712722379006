import { JobStatus } from 'api';
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { CaseFormInputs } from '../formUtils/caseFormConverters';
import {
  JobGroupFormInputs,
  JobInstanceFormInputs,
} from '../formUtils/caseJobFormConverters';
import useJobGroupCalculatedValue from './useJobGroupCalculatedValue';

function calculateTotalOrderedTime(
  formInputs: DeepPartial<CaseFormInputs>,
  jobGroupIndex: number
) {
  const jobGroup = formInputs?.jobGroups?.[jobGroupIndex];

  if (jobGroup && jobGroup.jobInstances) {
    let totalTime = 0;

    for (let jobInstance of jobGroup.jobInstances) {
      if (jobInstance?.jobStatus === JobStatus.Discarded) continue;

      if (jobGroup.orderedEndUtc && jobGroup.orderedStartUtc) {
        totalTime +=
          new Date(jobGroup.orderedEndUtc).getTime() -
          new Date(jobGroup.orderedStartUtc).getTime();
      }
    }

    return totalTime;
  }

  return 0;
}

const watchedJobGroupProps: (keyof JobGroupFormInputs)[] = [
  'orderedStartUtc',
  'orderedEndUtc',
];
const watchedJobInstanceProps: (keyof JobInstanceFormInputs)[] = ['jobStatus'];

const useJobGroupTotalOrderedTime = (
  form: UseFormReturn<CaseFormInputs>,
  jobgroupIndex: number
) =>
  useJobGroupCalculatedValue(
    form,
    jobgroupIndex,
    watchedJobGroupProps,
    watchedJobInstanceProps,
    calculateTotalOrderedTime,
    0
  );

export default useJobGroupTotalOrderedTime;
