import Theme from 'constants/theme';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import SpinnerIcon from './SpinnerIcon';

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: ${Theme.colors.bg.opacityOverlay};
  color: ${Theme.colors.fg.opacityOverlay};
  font-weight: 600;
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
`;

const OverlayedSpinner: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Overlay>
      <SpinnerIcon />
      {children}
    </Overlay>
  );
};

export default OverlayedSpinner;
