const Routes = {
  index: '/',

  account: {
    login: {
      index: '/logga-in',
      stationUser: '/logga-in/station',
      customerUser: '/logga-in/kund',
    },
    confirmAccount: '/konto/bekrafta',
    forgotPassword: '/konto/glomt-losenord',
    resetPassword: '/konto/aterstall',
  },

  cases: {
    index: '/arenden',
    create: '/arenden/skapa',
    case: '/arenden/:id',
  },
  plannerTool: {
    index: '/planering',
    monthView: '/planering/manad',
    listView: '/planering/lista',
    weekView: '/planering/vecka',
    dayView: '/planering/dag',
  },
  invoices: {
    index: '/fakturor',
    create: '/fakturor/skapa',
    internal: '/fakturor/interna',
  },
  invoiceQueue: {
    index: '/faktura-ko',
    internal: '/faktura-ko/interna',
    external: '/faktura-ko/externa',
    fee: '/faktura-ko/avgifter',
  },

  settings: {
    index: '/installningar',

    manageMyAkCompany: {
      index: '/installningar/min-station',
    },
    manageUsers: {
      index: '/installningar/personal',
      add: '/installningar/personal/skapa',
      user: '/installningar/personal/:id',
    },
    manageCustomers: {
      index: '/installningar/kunder',
    },
    manageCustomerUsers: {
      index: '/installningar/kundanvandare',
      user: '/installningar/kundanvandare/:id',
    },
    managePriceLists: {
      index: '/installningar/prislistor',
      listsForCustomer: '/installningar/prislistor/:customerId',
    },
    manageAkCompanies: {
      index: '/installningar/stationer',
    },
    manageAppTexts: {
      index: '/installningar/webbtexter',
      appText: '/installningar/webbtexter/:appTextKey',
    },
    manageVehicles: {
      index: '/installningar/fordon',
      add: '/installningar/fordon/skapa',
      vehicle: '/installningar/fordon/:id',
    },
  },

  customerPortal: {
    index: '/kundportal',
    bookedCases: {
      index: '/kundportal/bokade-arenden',
    },
    createCase: '/kundportal/skapa-arende',
  },
} as const;

export default Routes;
