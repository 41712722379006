import { CaseJob, JobStatus, JobType } from 'api';
import moment from 'moment';
import { groupItemsSorted } from 'utils/array';
import { toInputDateTimeString } from 'utils/date';

export interface JobGroupFormInputs {
  jobType: JobType;
  orderedStartUtc: string;
  orderedEndUtc: string;
  isUnplannedJob: boolean;
  jobInstances: JobInstanceFormInputs[];
}

export interface JobInstanceFormInputs {
  caseJobId: number;
  jobStatus: JobStatus;
  caseId: number;
  useActualStartEnd: boolean;
  actualStartUtc?: string;
  actualEndUtc?: string;
  driverRequirement: string;
  driverName: string;
  vehicleRequirement: string;
  vehicleRegNr: string;
  totalKilometers: number;
}

/**
 * Groups together all caseJobs that has same values for all of the
 * fields specified in JobGroupFormInputs.
 */
function groupCaseJobs(caseJobs: CaseJob[]): CaseJob[][] {
  function makeGroupKey(caseJob: CaseJob) {
    return `${caseJob.orderedStartUtc.getTime()}-${caseJob.orderedEndUtc.getTime()}-${
      caseJob.jobType
    }-${caseJob.isUnplannedJob}`;
  }

  const groupedJobs = groupItemsSorted(caseJobs, makeGroupKey);

  return groupedJobs.map(([, items]) => items);
}

export function createNewJobGroupFormInputs(): JobGroupFormInputs {
  const from = moment(new Date()).startOf('day').toDate();
  const to = moment(from).endOf('day').toDate();

  return {
    jobType: JobType.TMA,
    orderedStartUtc: toInputDateTimeString(from),
    orderedEndUtc: toInputDateTimeString(to),
    isUnplannedJob: false,

    jobInstances: [createNewJobInstanceFormInputs()],
  };
}

export function createNewJobInstanceFormInputs(): JobInstanceFormInputs {
  return {
    caseJobId: 0,
    jobStatus: JobStatus.Normal,
    caseId: 0,
    useActualStartEnd: false,
    actualStartUtc: undefined,
    actualEndUtc: undefined,
    driverRequirement: '',
    driverName: '',
    vehicleRequirement: '',
    vehicleRegNr: '',
    totalKilometers: 0,
  };
}

export function createJobGroupFormInputsFromCaseJobs(
  caseJobs: CaseJob[]
): JobGroupFormInputs[] {
  const caseJobGroups = groupCaseJobs(caseJobs);

  return caseJobGroups.map((jobGroup): JobGroupFormInputs => {
    const firstJob = jobGroup[0];

    return {
      isUnplannedJob: firstJob.isUnplannedJob,
      jobType: firstJob.jobType,
      orderedStartUtc: toInputDateTimeString(firstJob.orderedStartUtc),
      orderedEndUtc: toInputDateTimeString(firstJob.orderedEndUtc),

      jobInstances: jobGroup.map(
        (caseJob): JobInstanceFormInputs => ({
          caseJobId: caseJob.id,
          jobStatus: caseJob.jobStatus,
          caseId: caseJob.caseId,
          useActualStartEnd: caseJob.useActualStartEnd,
          actualStartUtc:
            caseJob.actualStartUtc &&
            toInputDateTimeString(caseJob.actualStartUtc),
          actualEndUtc:
            caseJob.actualEndUtc && toInputDateTimeString(caseJob.actualEndUtc),
          driverRequirement: caseJob.driverRequirement,
          driverName: caseJob.driverName,
          vehicleRequirement: caseJob.vehicleRequirement,
          vehicleRegNr: caseJob.vehicleRegNr,
          totalKilometers: caseJob.totalKilometers,
        })
      ),
    };
  });
}

export function jobGroupFormInputsToCaseJobs(
  formJobGroups: JobGroupFormInputs[],
  baseCaseJobs?: CaseJob[]
): CaseJob[] {
  const baseCaseJobsById = new Map<number, CaseJob>(
    baseCaseJobs ? baseCaseJobs.map((cj) => [cj.id, cj]) : []
  );

  return formJobGroups
    .map((jobGroup) =>
      jobGroup.jobInstances.map((jobInstance) => {
        const baseCaseJob = baseCaseJobsById.get(jobInstance.caseJobId);

        return new CaseJob({
          id: jobInstance.caseJobId,
          jobStatus: jobInstance.jobStatus,
          caseId: jobInstance.caseId,
          jobType: jobGroup.jobType,
          orderedStartUtc: new Date(jobGroup.orderedStartUtc),
          orderedEndUtc: new Date(jobGroup.orderedEndUtc),
          useActualStartEnd: jobInstance.useActualStartEnd,
          actualStartUtc:
            jobInstance.useActualStartEnd && jobInstance.actualStartUtc
              ? new Date(jobInstance.actualStartUtc)
              : undefined,
          actualEndUtc:
            jobInstance.useActualStartEnd && jobInstance.actualEndUtc
              ? new Date(jobInstance.actualEndUtc)
              : undefined,
          driverRequirement: jobInstance.driverRequirement,
          driverName: jobInstance.driverName,
          vehicleRequirement: jobInstance.vehicleRequirement,
          vehicleRegNr: jobInstance.vehicleRegNr,
          totalKilometers: jobInstance.totalKilometers,
          isUnplannedJob: jobGroup.isUnplannedJob,
          timestamp: baseCaseJob?.timestamp ?? '',

          // Backend manages these, but I add them anyway
          createdUtc: baseCaseJob?.createdUtc ?? new Date(),
          modifiedUtc: baseCaseJob?.modifiedUtc ?? new Date(),
          createdById: -1,
          modifiedById: -1,
        });
      })
    )
    .flat(1);
}
