import { DateSpan } from 'components/Calendar/utils';
import { useMemo } from 'react';
import { CaseJobEvent } from '../useCaseJobEvents';

export interface CaseEvent extends DateSpan {
  caseId: number;
  customerId: number;
  isRemoved: boolean;
  caseJobEvents: CaseJobEvent[];
}

const useMergedCaseJobEvents = (caseJobEvents: CaseJobEvent[]): CaseEvent[] => {
  return useMemo(() => {
    const caseEventsByCaseId = new Map<number, CaseEvent>();

    caseJobEvents.forEach((jobEvent) => {
      let caseEvent = caseEventsByCaseId.get(jobEvent.caseId ?? 0);

      if (!caseEvent) {
        caseEventsByCaseId.set(jobEvent.caseId ?? 0, {
          caseId: jobEvent.caseId ?? 0,
          caseJobEvents: [jobEvent],
          customerId: jobEvent.customerId,
          from: jobEvent.from,
          to: jobEvent.to,
          isRemoved: jobEvent.isRemoved,
        });

        return;
      }

      caseEvent.caseJobEvents.push(jobEvent);

      caseEvent.isRemoved = caseEvent.isRemoved && jobEvent.isRemoved;

      if (jobEvent.from < caseEvent.from) {
        caseEvent.from = jobEvent.from;
      }
      if (jobEvent.to > caseEvent.to) {
        caseEvent.to = jobEvent.to;
      }
    });

    return Array.from(caseEventsByCaseId.values());
  }, [caseJobEvents]);
};

export default useMergedCaseJobEvents;
