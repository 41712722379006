import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';

export const arrowSize = 15;

const SpeechBubbleWrapper = styled.div`
  display: none;
  ${MediaQuery.tablet} {
    display: flex;
  }

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: 0 calc(10px + ${Theme.sizes.padding.screenInset});

  background-color: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
  font-weight: 600;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    box-sizing: border-box;

    border: ${arrowSize}px solid ${Theme.colors.bg.accent1};
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  @media only screen and (max-width: 1000px) {
    width: 65px;
    &:hover {
      width: auto;
    }
  }
`;

const MobileWrapper = styled.div`
  padding: 10px ${Theme.sizes.padding.screenInset};

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const Row = styled.div`
  a {
    color: inherit;
    font: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BaseSupportInfo: FC = () => (
  <>
    <Row>
      <b>SUPPORT</b>
    </Row>
    <Row>
      <a
        href="mailto:support@assistancekaren.se"
        target="_blank"
        rel="noreferrer"
      >
        support@assistancekaren.se
      </a>
    </Row>
  </>
);

export const MobileSupportInfo: FC = () => {
  return (
    <MobileWrapper>
      <BaseSupportInfo />
    </MobileWrapper>
  );
};

const SupportInfoSpeechBubble: FC = () => (
  <SpeechBubbleWrapper>
    <BaseSupportInfo />
  </SpeechBubbleWrapper>
);

export default SupportInfoSpeechBubble;
