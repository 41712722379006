import Theme from 'constants/theme';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { formatDate, formatTime } from 'utils/date';
import { DateSpan } from 'components/Calendar/utils';

const onlyShowWhenHover = 'only-show-when-hover';
const hoverClass = 'hover';

const Line = styled.div<{ selected: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 5px;
  height: 100%;
  min-width: 3em;

  background-color: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.8), 0 0 0 transparent;
  transition: box-shadow 0.1s;
  user-select: none;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  text-overflow: ellipsis;
  white-space: nowrap;

  .${onlyShowWhenHover} {
    display: none;
  }

  ${({ selected }) =>
    selected
      ? css`
          z-index: 1;
          min-width: max-content;

          box-shadow: inset 0 0 1px 0 ${Theme.colors.fg.selection},
            0 0 0 2px ${Theme.colors.bg.selection},
            0 1px 2px 0px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.5);

          .${onlyShowWhenHover} {
            display: flex;
          }
        `
      : css`
          &:hover,
          &.${hoverClass} {
            z-index: 1;
            min-width: max-content;
            box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.3),
              0 5px 10px rgba(0, 0, 0, 0.5);

            .${onlyShowWhenHover} {
              display: flex;
            }
          }
        `}
`;

const MainText = styled.div`
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TimeHandle = styled.div<{ isRightHandle?: boolean }>`
  /* display: flex; // Set by 'onlyShowWhenHover' class */
  flex-direction: row;
  gap: 5px;
  padding: 0 5px;

  b {
    font-weight: 600;
  }

  ${({ isRightHandle }) =>
    isRightHandle &&
    css`
      margin-left: auto;
    `}
`;

function clampValue(value: number, min: number, max: number) {
  return Math.min(max, Math.max(min, value));
}

interface EventLineStyles {
  label: ReactNode;
  backgroundStyle: React.CSSProperties;
  timeHandlesStyle: React.CSSProperties;
}

interface Props<E extends DateSpan> {
  containerStart: Date;
  containerEnd: Date;
  from: Date;
  to: Date;
  showDateOnStickyEnd?: boolean;

  event: E;
  onClick?(event: E): void;
  selected: boolean;
  eventClassName: string;
  makeStyles(event: E): EventLineStyles;

  className?: string;
}

const BasicEventLine = <E extends DateSpan>({
  from,
  to,
  containerStart,
  containerEnd,
  showDateOnStickyEnd,
  onClick,
  selected,
  event,
  eventClassName,
  makeStyles,
  className,
}: Props<E>) => {
  const getPercentageOfWeek = (date: Date) =>
    ((date.getTime() - containerStart.getTime()) /
      (containerEnd.getTime() - containerStart.getTime())) *
    100;

  const fromPercent = getPercentageOfWeek(from);
  const toPercent = getPercentageOfWeek(to);

  const stickyLeft = fromPercent < 0;
  const stickyRight = toPercent > 100;

  const radius = 5;

  const handleMouseEnter = () => {
    const allEventLines = document.getElementsByClassName(eventClassName);

    Array.from(allEventLines).forEach((eventLineEle) => {
      eventLineEle.classList.add(hoverClass);
    });
  };

  const handleMouseLeave = () => {
    const allEventLines = document.getElementsByClassName(eventClassName);

    Array.from(allEventLines).forEach((eventLineEle) => {
      eventLineEle.classList.remove(hoverClass);
    });
  };

  const { label, backgroundStyle, timeHandlesStyle } = makeStyles(event);

  return (
    <Line
      className={`${className ?? ''} ${eventClassName}`}
      selected={selected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={(eve) => eve.stopPropagation()} // Prevent creating new Jobs in JobWeek view when clicking on this event
      onClick={onClick ? () => onClick(event) : undefined}
      style={{
        left: stickyLeft ? 0 : `${clampValue(fromPercent, 0, 100)}%`,
        right: stickyRight ? 0 : `${100 - clampValue(toPercent, 0, 100)}%`,

        borderTopLeftRadius: stickyLeft ? 0 : radius,
        borderBottomLeftRadius: stickyLeft ? 0 : radius,

        borderTopRightRadius: stickyRight ? 0 : radius,
        borderBottomRightRadius: stickyRight ? 0 : radius,

        ...backgroundStyle,
      }}
    >
      {(showDateOnStickyEnd || !stickyLeft) && (
        <TimeHandle className={onlyShowWhenHover} style={timeHandlesStyle}>
          <b>{formatTime(from)}</b>{' '}
          {showDateOnStickyEnd && stickyLeft && ` • ${formatDate(from)}`}
        </TimeHandle>
      )}

      <MainText>{label}</MainText>

      {(showDateOnStickyEnd || !stickyRight) && (
        <TimeHandle
          isRightHandle
          className={onlyShowWhenHover}
          style={timeHandlesStyle}
        >
          {showDateOnStickyEnd && stickyRight && `${formatDate(to)} •`}{' '}
          <b>{formatTime(to)}</b>
        </TimeHandle>
      )}
    </Line>
  );
};

export default BasicEventLine;
