import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import BasicEventLine from 'components/Calendar/BasicEventLine';
import { OrderAmount } from 'pages/PlannerTool/CasesCalendar/CaseEventLine';
import { removedBackgroundGradient } from 'pages/PlannerTool/JobsGantt/JobEventLine';
import { FC } from 'react';
import { oneLineAddressString } from '../utils';
import { CustomerCaseEvent } from './useCustomerJobEvents';

interface Props {
  containerStart: Date;
  containerEnd: Date;
  from: Date;
  to: Date;
  showDateOnStickyEnd?: boolean;

  caseEvent: CustomerCaseEvent;
  onClick?(caseEvent: CustomerCaseEvent): void;
  selected: boolean;
  caseEventClassName: string;
}

const CustomerJobEventLine: FC<Props> = ({
  containerStart,
  containerEnd,
  showDateOnStickyEnd,
  caseEvent,
  onClick,
  selected,
  caseEventClassName,
}) => {
  const backgroundColor = Color('red');
  const timeHandleColors: React.CSSProperties = caseEvent.isRemoved
    ? { color: '#ddd', backgroundColor: 'transparent' }
    : {
        color: backgroundColor.hex(),
        backgroundColor: backgroundColor.isLight() ? '#000a' : '#fff',
      };

  return (
    <BasicEventLine
      containerStart={containerStart}
      containerEnd={containerEnd}
      event={caseEvent}
      eventClassName={caseEventClassName}
      from={caseEvent.from}
      to={caseEvent.to}
      onClick={onClick}
      selected={selected}
      showDateOnStickyEnd={showDateOnStickyEnd}
      makeStyles={(jobEvent) => ({
        label: (
          <>
            <OrderAmount dark={backgroundColor.isLight()}>
              {jobEvent.customerCaseJobs.length}x
              <FontAwesomeIcon icon={faTruck} />
            </OrderAmount>
            <b>{oneLineAddressString(jobEvent.customerCase)}</b>
          </>
        ),
        backgroundStyle: {
          background: jobEvent.isRemoved
            ? removedBackgroundGradient
            : backgroundColor.hex(),
        },
        timeHandlesStyle: timeHandleColors,
      })}
    />
  );
};

export default CustomerJobEventLine;
