const loginTokenStorageKey = 'authentication_token';
const refreshTokenStorageKey = 'authentication_refreshToken';

function setNullable(key: string, value: string | number | null) {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(
      key,
      typeof value === 'number' ? JSON.stringify(value) : value
    );
  }
}

export const getLoginToken = () => localStorage.getItem(loginTokenStorageKey);
export const storeLoginToken = (loginToken: string | null) => {
  setNullable(loginTokenStorageKey, loginToken);
};

export const getRefreshToken = () =>
  localStorage.getItem(refreshTokenStorageKey);
export const storeRefreshToken = (refreshToken: string | null) => {
  setNullable(refreshTokenStorageKey, refreshToken);
};
