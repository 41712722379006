import Theme from 'constants/theme';
import { FieldErrors, FieldValues } from 'react-hook-form';
import styled from 'styled-components';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;
  color: ${Theme.colors.bad};
`;

interface Props<TFieldValues extends FieldValues> {
  errors: FieldErrors<TFieldValues>;
  className?: string;
}

const FieldErrorListItems = <TFieldValues extends FieldValues>({
  errors,
}: Props<TFieldValues>) => {
  return (
    <>
      {Object.entries(errors).map(([key, value]) => {
        if (value) {
          if ('message' in value && typeof value.message === 'string') {
            return <li key={key}>{value.message}</li>;
          }

          return <FieldErrorListItems key={key} errors={value} />;
        }

        return null;
      })}
    </>
  );
};

const FormErrorList = <TFieldValues extends FieldValues>({
  errors,
  className,
}: Props<TFieldValues>) => {
  return (
    <List className={className}>
      <FieldErrorListItems errors={errors} />
    </List>
  );
};

export default FormErrorList;
