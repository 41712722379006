import Dropdown, { DropdownPosition } from 'components/Dropdown';
import { inputStyle } from 'components/inputs/Input';
import Theme from 'constants/theme';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import MediaQuery from 'constants/MediaQuery';

const Input = styled.input`
  ${inputStyle}
  text-align: start;
  display: flex;
  flex-direction: row;
  padding: 3px 5px;
  min-width: 0;
  padding-right: 40px;

  appearance: none;
  background-image: ${Theme.images.selectArrow};
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: right;

  &:focus {
    z-index: 10;
  }
`;
export const inputClassName = 'searchable-select-input';
export const focusedClassName = 'focused';

const Items = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &:hover > * {
    background: ${Theme.colors.bg.background1};
    color: ${Theme.colors.fg.background1};
  }
`;

export const Option = styled.div<{ selected?: boolean }>`
  margin: 0;
  padding: 10px;

  border: none;
  background: ${Theme.colors.bg.background1};
  color: ${Theme.colors.fg.background1};
  font: inherit;
  text-align: start;
  white-space: nowrap;
  border-bottom: 1px solid ${Theme.colors.border.main};

  &:hover,
  &.${focusedClassName} {
    background: ${Theme.colors.bg.selection};
    color: ${Theme.colors.fg.selection};
  }

  ${MediaQuery.desktop} {
    padding: 3px 5px;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${Theme.colors.bg.selection};
      color: ${Theme.colors.fg.selection};
    `}
`;

export type OptionBase = {
  /** label has to be unique, as it is used as key when rendering all options */
  label: string;
};

export type OptionInputProps<Option extends OptionBase> = {
  value: string;
  onChange(value: string): void;

  options: Option[];
  optionRender?(key: string, option: Option, onClick: () => void): ReactNode;
  onOptionClicked: (option: Option) => void;
  onClear?(): void;

  searchFilter(searchString: string, options: Option[]): Option[];

  dropdownPosition?: DropdownPosition;
  disabled?: boolean;
  autoFocus?: boolean;
  className?: string;
};

const OptionInput = <Option extends OptionBase>({
  value,
  disabled,
  onChange,

  onOptionClicked,
  onClear,
  options,
  optionRender,
  searchFilter,
  dropdownPosition,
  autoFocus,
  className,
}: OptionInputProps<Option>) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (option: Option) => {
    onOptionClicked(option);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const keyUpListener = (eve: KeyboardEvent) => {
        const focusedOptionElement = itemsRef.current?.querySelector(
          `.${focusedClassName}`
        );

        if (eve.key === 'Escape' && eve.target instanceof HTMLElement) {
          eve.target.blur();
        } else if (eve.key === 'ArrowDown' || eve.key === 'ArrowUp') {
          let nextFocusElement: Element | null | undefined;

          if (focusedOptionElement) {
            nextFocusElement =
              eve.key === 'ArrowDown'
                ? focusedOptionElement.nextElementSibling ?? inputRef.current
                : focusedOptionElement.previousElementSibling ??
                  inputRef.current;
          } else {
            nextFocusElement =
              eve.key === 'ArrowDown'
                ? itemsRef.current?.firstElementChild
                : itemsRef.current?.lastElementChild;
          }

          if (nextFocusElement instanceof HTMLElement) {
            focusedOptionElement?.classList.remove(focusedClassName);
            nextFocusElement.classList.add(focusedClassName);
          }
        } else if (eve.key === 'Enter' || eve.key === ' ') {
          if (focusedOptionElement instanceof HTMLElement)
            focusedOptionElement.click();
        }
      };

      window.addEventListener('keyup', keyUpListener);

      return () => window.removeEventListener('keyup', keyUpListener);
    }
    return () => {};
  }, [open]);

  const filteredOptions = useMemo(
    () => searchFilter(value, options),
    [options, searchFilter, value]
  );

  return (
    <Dropdown
      className={className}
      content={
        open &&
        !disabled && (
          <Items ref={itemsRef} onMouseDown={(eve) => eve.preventDefault()}>
            {onClear && value && (
              <Option
                onClick={(eve) => {
                  eve.preventDefault();
                  onClear();
                  inputRef.current?.focus();
                }}
              >
                <FontAwesomeIcon icon={faDeleteLeft} /> Rensa
              </Option>
            )}

            {optionRender
              ? filteredOptions.map((option) =>
                  optionRender(option.label, option, () =>
                    handleItemClick(option)
                  )
                )
              : filteredOptions.map((option) => (
                  <Option
                    key={option.label}
                    onClick={(eve) => {
                      eve.preventDefault();
                      handleItemClick(option);
                    }}
                    selected={option.label === value}
                  >
                    {option.label}
                  </Option>
                ))}
          </Items>
        )
      }
      onLostFocus={() => setOpen(false)} // setting timeout because the Option dissapears before it gets clicked :P
      position={dropdownPosition}
    >
      <Input
        ref={inputRef}
        autoFocus={autoFocus}
        disabled={disabled}
        className={inputClassName}
        onClick={(eve) => {
          eve.preventDefault();
          setOpen(true);
        }}
        onChange={(eve) => {
          setOpen(true);
          onChange(eve.target.value);
        }}
        onKeyUp={(eve) =>
          ['ArrowDown', 'ArrowUp'].includes(eve.key) && setOpen(true)
        }
        value={value}
      />
    </Dropdown>
  );
};

export default OptionInput;
