import { Invoice } from 'api';
import SectionHeader from 'components/SectionHeader';
import Table from 'components/Table';
import {
  ColumnSetting,
  tableCellClassName,
  tableHeadClassName,
} from 'components/Table/utils';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled from 'styled-components';
import { InvoiceTableProps } from './createInvoiceColumnSetting';

const TableHeader = styled(SectionHeader)`
  padding-inline: ${Theme.sizes.padding.screenInset};
`;

const InvoiceTable = styled(Table)`
  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
  margin-bottom: 20px;
` as typeof Table;

interface Props {
  tableHeader: string;
  invoices: Invoice[];
  columnSettings: ColumnSetting<Invoice, InvoiceTableProps>[];
  renderProps: InvoiceTableProps;
}

const SelectInvoicesTable: FC<Props> = ({
  tableHeader,
  invoices,
  columnSettings,
  renderProps,
}) => {
  return (
    <>
      <TableHeader title={tableHeader} />
      <InvoiceTable
        initialSortState={{ ascending: false, sortedColumnIndex: 1 }}
        columnSettings={columnSettings}
        rows={invoices}
        renderProps={renderProps}
      ></InvoiceTable>
    </>
  );
};

export default SelectInvoicesTable;
