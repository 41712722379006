import React, { useLayoutEffect, useRef } from 'react';
import { createElementSetterProxy } from 'utils/dom';
import TextArea from './TextArea';

function textAreaAdjust(e: HTMLTextAreaElement | null) {
  if (e) {
    e.style.height = '1px';
    e.style.height = `${e.scrollHeight + 10}px`;
  }
}

type Props = React.HTMLProps<HTMLTextAreaElement>;
const AutoSizedTextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (props, externalRef) => {
    const ownTextareaRef = useRef<HTMLTextAreaElement | null>();

    useLayoutEffect(() => {
      const textareaElement = ownTextareaRef.current;
      if (!textareaElement) return;

      const listnener = (eve: Event) => {
        if (eve.target instanceof HTMLTextAreaElement)
          handleValueChanged(eve.target.value);
      };

      textareaElement.addEventListener('input', listnener);

      return () => {
        textareaElement.removeEventListener('input', listnener);
      };
    }, []);

    const handleValueChanged = (value: string) => {
      if (!ownTextareaRef.current) return;

      textAreaAdjust(ownTextareaRef.current);
    };

    const setRef = (newRef: HTMLTextAreaElement | null) => {
      ownTextareaRef.current = newRef;

      let proxyRef = newRef;
      if (newRef) {
        // Using a proxy on 'value' here because the 'input' event isn't triggered when the input is resetted.
        proxyRef = createElementSetterProxy(newRef, (target, key, value) => {
          (target as any)[key] = value;

          if (key === 'value') {
            handleValueChanged(value);
          }
          return true;
        });
      }

      if (externalRef instanceof Function) {
        externalRef(proxyRef);
      } else if (externalRef) {
        externalRef.current = proxyRef;
      }
    };

    return <TextArea {...props} as={undefined} ref={setRef} />;
  }
);

export default AutoSizedTextArea;
