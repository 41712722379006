import { FC, PropsWithChildren, useEffect } from 'react';
import { AppHeader } from './AppHeader';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    // iPhone special case: prevent body scroll
    if (window.navigator.userAgent.match(/iPhone/i)) {
      const onScroll = (e: Event) => {
        if (document.activeElement !== document.body) return; // don't prevent window scroll if it is to scroll to an input
        e.preventDefault();
        window.scrollTo(0, 0);
      };

      window.addEventListener('scroll', onScroll);
      document.body.style.position = 'fixed';
      document.body.style.inset = '0';

      return () => {
        window.removeEventListener('scroll', onScroll);
        document.body.style.position = '';
        document.body.style.inset = '';
      };
    }
  }, []);

  return (
    <>
      <AppHeader />
      {children}
    </>
  );
};
