import styled from 'styled-components';
import { DateSpan, datespansIntersects, getWeekNumber } from './utils';
import { ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import OverflowedEvents from './OverflowedEvents';
import Theme from 'constants/theme';
import PopOver from 'components/Modal/PopOver';
import { modalContentClass } from 'components/Modal';

const laneHeight = 20;
const paddingTop = 30;
const gap = 2;
const overflowLaneHeight = 25;

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  left: 2em;
  display: flex;
  flex-direction: column;
  gap: ${gap}px;
  padding-top: ${paddingTop}px;

  /* allows for clicks to be captured by DaySections underneath WeekEventLanes */
  pointer-events: none;
`;

const Lane = styled.div`
  position: relative;
  height: ${laneHeight}px;

  & > * {
    pointer-events: auto;
  }
`;

const MyPopOver = styled(PopOver)`
  pointer-events: auto;

  .${modalContentClass} {
    padding: 0;
  }
`;

const OverflowLane = styled.div`
  position: relative;
  height: ${overflowLaneHeight}px;
`;

const OverflowTimeline = styled(Wrapper)`
  position: unset;
  padding: 10px 0;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent calc(${100 / 7}% - 1px),
    ${Theme.colors.border.main} calc(${100 / 7}% - 1px),
    ${Theme.colors.border.main} ${100 / 7}%
  );
`;

interface Props<DS extends DateSpan> {
  lanes: DS[][];
  from: Date;
  to: Date;
  renderEventLine(
    event: DS,
    containerStart: Date,
    containerEnd: Date
  ): ReactNode;

  shouldCloseWeekOverflow?(event: MouseEvent): boolean;
}

const WeekEventLanes = <DS extends DateSpan>({
  lanes,
  from,
  to,
  renderEventLine,
  shouldCloseWeekOverflow,
}: Props<DS>) => {
  const [showPopOverTimeline, setShowPopOverTimeline] = useState(false);
  const [maxLanes, setMaxLanes] = useState(lanes.length);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      if (wrapperRef.current)
        setMaxLanes(
          Math.max(
            0,
            Math.floor(
              (wrapperRef.current.clientHeight -
                paddingTop -
                overflowLaneHeight) /
                (laneHeight + gap)
            )
          )
        );
    });
    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const cappedLanes = useMemo(
    () => lanes.slice(0, maxLanes),
    [lanes, maxLanes]
  );

  const overflowedLanes = useMemo(
    () => lanes.slice(maxLanes),
    [lanes, maxLanes]
  );

  const eventsByDayTimestamp = useMemo(() => {
    const eventsByDayTimestamp = new Map<number, DS[]>();

    const insertIntoDay = (day: Date, event: DS) => {
      const timestamp = day.getTime();
      let events = eventsByDayTimestamp.get(timestamp);

      if (!events) {
        events = [];
        eventsByDayTimestamp.set(timestamp, events);
      }

      events.push(event);
    };

    for (let events of overflowedLanes) {
      for (let event of events) {
        if (!datespansIntersects(from, to, event.from, event.to)) continue;

        let startDay = moment(event.from).startOf('day').toDate();

        if (startDay.getTime() < from.getTime()) startDay = from;

        for (
          let currentDay = new Date(startDay);
          currentDay.getTime() <= event.to.getTime() &&
          currentDay.getTime() <= to.getTime();
          currentDay.setDate(currentDay.getDate() + 1)
        ) {
          insertIntoDay(currentDay, event);
        }
      }
    }

    return eventsByDayTimestamp;
  }, [from, overflowedLanes, to]);

  const wrapperClassName = `week-event-lanes-${from.getTime()}-${to.getTime()}`;

  return (
    <Wrapper ref={wrapperRef} className={wrapperClassName}>
      {cappedLanes.map((laneEvents, i) => (
        <Lane key={i}>
          {laneEvents
            .filter((event) =>
              datespansIntersects(from, to, event.from, event.to)
            )
            .map((event) => renderEventLine(event, from, to))}
        </Lane>
      ))}

      {eventsByDayTimestamp.size > 0 && showPopOverTimeline && (
        <MyPopOver
          title={`Vecka ${getWeekNumber(from)}`}
          attachTo={wrapperClassName}
          onClose={() => setShowPopOverTimeline(false)}
          shouldClose={shouldCloseWeekOverflow}
          useWidthOfElement
        >
          <OverflowTimeline>
            {overflowedLanes.map((laneEvents, i) => (
              <Lane key={i}>
                {laneEvents
                  .filter((event) =>
                    datespansIntersects(from, to, event.from, event.to)
                  )
                  .map((event) => renderEventLine(event, from, to))}
              </Lane>
            ))}
          </OverflowTimeline>
        </MyPopOver>
      )}

      <OverflowLane
        style={showPopOverTimeline ? { display: 'none' } : undefined}
      >
        {Array.from(eventsByDayTimestamp.entries()).map(
          ([dayTimestamp, events]) => (
            <OverflowedEvents
              containerStart={from}
              containerEnd={to}
              day={new Date(dayTimestamp)}
              events={events}
              onClick={() => {
                setShowPopOverTimeline(true);
              }}
              key={dayTimestamp}
            />
          )
        )}
      </OverflowLane>
    </Wrapper>
  );
};

export default WeekEventLanes;
