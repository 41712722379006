import useBasicData from 'contexts/useBasicData';
import { createNewPriceAdjustmentForm } from '../../formUtils/priceAdjustmentFormConverters';
import { PriceListFormInputs } from '../../formUtils/priceListFormConverters';
import { FC } from 'react';
import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import PriceAdjustmentItem from './PriceAdjustmentItem';
import PriceAdjustmentsTitle from './PriceAdjustmentsTitle';
import MediaQuery from 'constants/MediaQuery';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${MediaQuery.tablet} {
    max-height: 50%;
  }
`;

const PriceAdjustmentItems = styled.div`
  flex: 1;
  overflow: auto;
`;

interface Props {
  register: UseFormRegister<PriceListFormInputs>;
  control: Control<PriceListFormInputs, any>;
  editMode: boolean;
}

const PriceAdjustments: FC<Props> = ({ register, control, editMode }) => {
  const basicData = useBasicData();
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'priceAdjustments',
  });

  return (
    <Wrapper>
      <PriceAdjustmentsTitle
        control={control}
        onAddPriceAdjustment={(type) => {
          append(
            createNewPriceAdjustmentForm(
              type,
              basicData.priceAdjustmentTypeNames
            )
          );
        }}
        editMode={editMode}
      />

      <PriceAdjustmentItems>
        {fields.map((field, index) => (
          <PriceAdjustmentItem
            key={field.id}
            itemIndex={index}
            control={control}
            register={register}
            onRemoveFromForm={remove}
            editMode={editMode}
          />
        ))}
      </PriceAdjustmentItems>
    </Wrapper>
  );
};

export default PriceAdjustments;
