import {
  faGear,
  faSun,
  faMoon,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContextMenu from 'components/ContextMenu';
import { DarkMode, useThemeSettings } from 'constants/theme';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Check = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  margin-left: auto;
`;

const DarkModeContextMenu: FC<PropsWithChildren> = ({ children }) => {
  const [{ darkmode }, setThemeSettings] = useThemeSettings();

  return (
    <ContextMenu
      options={[
        {
          label: <>Auto {darkmode === DarkMode.auto && <Check />}</>,
          icon: <FontAwesomeIcon icon={faGear} />,
          onClick: () =>
            setThemeSettings((s) => ({
              ...s,
              darkmode: DarkMode.auto,
            })),
        },
        {
          label: <>Ljust tema {darkmode === DarkMode.light && <Check />}</>,
          icon: <FontAwesomeIcon icon={faSun} />,
          onClick: () =>
            setThemeSettings((s) => ({
              ...s,
              darkmode: DarkMode.light,
            })),
        },
        {
          label: <>Mörkt tema {darkmode === DarkMode.dark && <Check />}</>,
          icon: <FontAwesomeIcon icon={faMoon} />,
          onClick: () =>
            setThemeSettings((s) => ({
              ...s,
              darkmode: DarkMode.dark,
            })),
        },
      ]}
    >
      {children}
    </ContextMenu>
  );
};

export default DarkModeContextMenu;
