import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WebUser } from 'api';
import Button from 'components/inputs/Button';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import {
  booleanComparator,
  makeBooleanComparator,
  makeStringComparator,
} from 'utils/sorting';
import {
  tableCellClassName,
  tableHeadClassName,
  ColumnSetting,
} from 'components/Table/utils';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData, { useBasicDataValue } from 'contexts/useBasicData';
import { useCallback, useMemo } from 'react';
import {
  generatePath,
  Outlet,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus } from 'swaggerhooks';
import useUsers, { useUpdateUsersCall } from 'contexts/useUsers';

const HeaderRight = styled.div`
  margin-left: auto;
`;

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
  ${`.${tableCellClassName}:last-child, .${tableHeadClassName}:last-child`} {
    padding-right: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

interface TableRenderProps extends useBasicDataValue {}

const columnSettings: ColumnSetting<WebUser, TableRenderProps>[] = [
  {
    head: 'Personnummer',
    render: (u) => u.userName,
    width: 150,
    sortFunction: makeStringComparator((u) => u.userName),
  },
  {
    head: 'Namn',
    render: (u) => u.fullName,
    width: 200,
    sortFunction: makeStringComparator((u) => u.fullName),
  },
  {
    head: 'E-post',
    render: (u) => u.email,
    width: 200,
    sortFunction: makeStringComparator((u) => u.email),
  },
  {
    head: 'Telefonnummer',
    render: (u) => u.phoneNumber,
    width: 150,
    sortFunction: makeStringComparator((u) => u.phoneNumber),
  },
  {
    head: 'Deaktiverad',
    render: (u) => (u.deactivated ? 'Ja' : 'Nej'),
    width: 80,
    sortFunction: makeBooleanComparator((u) => u.deactivated),
  },
];

const EmployeeListPage: React.FC = () => {
  const basicData = useBasicData();
  const navigate = useNavigate();
  const { users } = useUsers();
  const updateUsersCall = useUpdateUsersCall();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  const onUserSaved = useCallback(
    () => {
      updateUsersCall.run(selectedAkCompanyId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateUsersCall.run, selectedAkCompanyId]
  );

  const usersWithSortedRoles = useMemo(
    () =>
      users.map(
        (usr) => new WebUser({ ...usr, roles: Array.from(usr.roles).sort() })
      ),
    [users]
  );

  const renderContent = () => {
    if (updateUsersCall.status === RequestStatus.Fetching)
      return <LoadingSection />;

    return (
      <MyTable
        columnSettings={columnSettings}
        rows={usersWithSortedRoles}
        onRowClick={(c) =>
          navigate(
            generatePath(Routes.settings.manageUsers.user, {
              id: String(c.id),
            })
          )
        }
        renderProps={basicData}
      />
    );
  };

  return (
    <>
      <PageHeader title="Personal">
        <HeaderRight>
          <Button
            small
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              navigate(Routes.settings.manageUsers.add);
            }}
          >
            Lägg till användare
          </Button>
        </HeaderRight>
      </PageHeader>
      {renderContent()}

      <Outlet context={onUserSaved} />
    </>
  );
};

export const useOnUserSaved = () =>
  useOutletContext<(savedUser: WebUser) => void>();

export default EmployeeListPage;
