import { FC, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';
import { AppTextsClient } from 'api';
import AppTextKey from 'constants/AppTextKeys';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import WebRoleIds from 'constants/RoleIds';
import LoadingSection from 'components/spinners/LoadingSection';
import EditableRichText from 'components/inputs/EditableRichText';
import styled from 'styled-components';

const MyRichText = styled(EditableRichText)`
  width: 100%;
  max-width: 1000px;
  margin: auto;
`;

const StartPage: FC = () => {
  const [editText, setEditText] = useState('');
  const [editMode, setEditMode] = useState(false);
  const webClaims = useUserWebClaims();

  const {
    response: originalText,
    status: getStatus,
    update,
  } = useResponse(AppTextsClient, async (c) => {
    const response = await c.getAppText(AppTextKey.AKCOMPANY_START_PAGE_TEXT);
    setEditText(response);
    return response;
  });

  const updateStartPageText = useApiCall(
    AppTextsClient,
    async (c, textValue) => {
      const updatedText = await c.setAppText(
        AppTextKey.AKCOMPANY_START_PAGE_TEXT,
        textValue
      );
      setEditText(updatedText);
    }
  );

  if (getStatus === RequestStatus.Fetching) return <LoadingSection />;

  if (updateStartPageText.status === RequestStatus.Fetching)
    return <LoadingSection>Sparar...</LoadingSection>;

  return (
    <MyRichText
      editMode={editMode}
      editText={editText}
      onEditTextChanged={setEditText}
      onEditClick={
        webClaims.webRoleIds.has(WebRoleIds.admin)
          ? () => setEditMode(true)
          : undefined
      }
      onCancelClick={() => {
        setEditMode(false);
        setEditText(originalText ?? '');
      }}
      onSaveClick={async () => {
        setEditMode(false);
        await updateStartPageText.run(editText);
        update();
      }}
    />
  );
};

export default StartPage;
