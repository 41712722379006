import {
  faAngleDown,
  faAngleUp,
  faCalendarDay,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getWeekNumber } from 'components/Calendar/utils';
import TextButton from 'components/inputs/TextButton';
import PageHeader, { PageHeaderTitleClassName } from 'components/PageHeader';
import MediaQuery from 'constants/MediaQuery';
import { FC, useState } from 'react';
import styled, { css } from 'styled-components';
import JobViewLinks from './JobViewLinks';
import TimeNavButtons from './TimeNavButtons';
import TimeInputs from './TimeInputs';
import Button, {
  IconWrapClassName,
  LabelWrapClassName,
} from 'components/inputs/Button';
import Theme from 'constants/theme';
import { formatDate } from 'utils/date';

const MyPageHeader = styled(PageHeader)`
  grid-template-columns: 70px auto 30px 30px;
  gap: 5px 10px;
  padding-bottom: 15px;

  .${PageHeaderTitleClassName} {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  ${MediaQuery.tablet} {
    gap: 40px;
  }
`;

const MobileTitle = styled.span`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  gap: 5px;

  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    font-size: 15px;
  }

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const TabletTitle = styled.span`
  display: none;
  ${MediaQuery.tablet} {
    display: inline;
  }
`;

const MyTimeNavButtons = styled(TimeNavButtons)`
  grid-column: 1/2;
  grid-row: 1/2;
`;

const MyTimeInputs = styled(TimeInputs)`
  grid-column: 1/-1;
`;

const MyJobViewLinks = styled(JobViewLinks)`
  grid-column: 1/-1;
`;

export const TodayButton = styled(Button)`
  .${IconWrapClassName} {
    display: none;
  }

  ${MediaQuery.phone} {
    grid-column: 4/5;
    grid-row: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    font-size: 20px;
    background-color: transparent;
    color: ${Theme.colors.bg.accent1};

    .${IconWrapClassName} {
      display: inline;
      margin: 0;
    }
    .${LabelWrapClassName} {
      display: none;
    }
  }
`;

const FilterButton = styled(TextButton)<{ filterHadEffect?: boolean }>`
  position: relative;
  grid-column: 3/4;
  grid-row: 1/2;
  aspect-ratio: 1;
  margin: auto 0;

  font-size: 20px;
  border-radius: 1000px;
  ${({ filterHadEffect }) =>
    filterHadEffect &&
    css`
      background-color: ${Theme.colors.bg.accent1};
      color: ${Theme.colors.fg.accent1};
      font-size: 15px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 100%;
        transform: translate(60%, -60%);
        width: 6px;
        height: 6px;

        border-radius: 100px;
        background-color: ${Theme.colors.fg.accent1};
        border: 5px solid ${Theme.colors.bg.accent1};
        box-shadow: 0 0 0 2px ${Theme.colors.fg.accent1};
      }
    `}

  ${MediaQuery.tablet} {
    display: none;
  }
`;

interface Props {
  loading?: boolean;
  type: 'month' | 'week' | 'day';
  selectedDate: Date;
  onDateSelected(date: Date): void;
  onShowFiltersClick?(): void;
  filterHadEffect?: boolean;
}

const TimeNavigatorHeader: FC<Props> = ({
  loading,
  type,
  selectedDate,
  onDateSelected,
  onShowFiltersClick,
  filterHadEffect,
}) => {
  const [showTimeInputs, setShowTimeInputs] = useState(false);

  const getMobileTitle = () => {
    switch (type) {
      case 'month':
        return `${selectedDate.toLocaleDateString(undefined, {
          month: 'short',
        })} ${selectedDate.getFullYear()}`;
      case 'week':
        return `Vecka ${getWeekNumber(selectedDate)}`;
      case 'day':
        return `${formatDate(selectedDate)}`;
    }
  };

  return (
    <MyPageHeader
      title={
        <>
          <MobileTitle onClick={() => setShowTimeInputs((s) => !s)}>
            {getMobileTitle()}
            {showTimeInputs ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </MobileTitle>
          <TabletTitle>{type === 'month' ? 'Ärenden' : 'Jobb'}</TabletTitle>
        </>
      }
      leftAlignActions
      loading={loading}
    >
      <MyTimeInputs
        type={type}
        selectedDate={selectedDate}
        onDateSelected={onDateSelected}
        closed={!showTimeInputs}
      />

      <MyTimeNavButtons
        type={type}
        selectedDate={selectedDate}
        onDateSelected={onDateSelected}
      />

      {onShowFiltersClick && (
        <FilterButton
          onClick={onShowFiltersClick}
          filterHadEffect={filterHadEffect}
        >
          <FontAwesomeIcon icon={faFilter} />
        </FilterButton>
      )}

      <TodayButton
        small
        onClick={() => onDateSelected(new Date())}
        icon={<FontAwesomeIcon icon={faCalendarDay} />}
      >
        Idag
      </TodayButton>

      <MyJobViewLinks selectedDate={selectedDate} />
    </MyPageHeader>
  );
};

export default TimeNavigatorHeader;
