import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PartialCheckBox, {
  CheckBoxButton,
} from 'components/inputs/PartialCheckbox';
import Theme from 'constants/theme';
import { ReactNode, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${Theme.colors.border.main};
  }
`;

export const Items = styled.ul`
  margin: 0;
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 0;
`;

export const Item = styled.li<{ indentLevel?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  ${({ indentLevel }) =>
    css`
      padding-left: calc(
        ${(indentLevel || 0) * 20}px + ${Theme.sizes.padding.screenInset}
      );
    `}
  cursor: pointer;
  border: 1px solid transparent;
  border-width: 1px 0;

  &:hover {
    background-color: ${Theme.colors.bg.background2};
    border-color: ${Theme.colors.border.main};
  }
`;

const Parent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-left: ${Theme.sizes.padding.screenInset};

  font-weight: 600;
  background-color: ${Theme.colors.bg.background2};
  cursor: pointer;
`;

const CloseButton = styled.button`
  margin: 0;
  padding: 5px;
  margin-left: auto;

  background: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
`;

interface Props<V> {
  groupName: string;
  items: { label: ReactNode; value: V }[];
  selectedItems: Set<V>;
  onSelectItem(item: V): void;
  onDeselectItem(item: V): void;
  onSelectAll(): void;
  onDeselectAll(): void;

  automaticSelectionEnabled?: boolean;
}

const GroupSection = <V,>({
  groupName,
  items,
  selectedItems,
  onSelectItem,
  onDeselectItem,
  onSelectAll,
  onDeselectAll,
  automaticSelectionEnabled,
}: Props<V>) => {
  const [open, setOpen] = useState(true);

  const [fullyActive, partiallyActive] = useMemo(() => {
    let fullyActive = true,
      partiallyActive = false;

    items.forEach((item) => {
      if (selectedItems.has(item.value)) {
        partiallyActive = true;
      } else {
        fullyActive = false;
      }
    });

    return [fullyActive, partiallyActive];
  }, [selectedItems, items]);

  return (
    <Wrapper>
      <Parent onClick={() => setOpen((o) => !o)}>
        <CheckBoxButton
          checked={fullyActive && items.length !== 0}
          onClick={(eve) => {
            eve.stopPropagation();
            !fullyActive ? onSelectAll() : onDeselectAll();
          }}
        >
          {automaticSelectionEnabled || (fullyActive && items.length !== 0) ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : partiallyActive ? (
            <FontAwesomeIcon icon={faMinus} />
          ) : null}
        </CheckBoxButton>

        {groupName}

        <CloseButton>
          <FontAwesomeIcon icon={open ? faAngleDown : faAngleUp} />
        </CloseButton>
      </Parent>

      {open && (
        <Items>
          {items.map((item, i) => {
            const checked = selectedItems.has(item.value);
            return (
              <Item
                key={i}
                indentLevel={1}
                onClick={() =>
                  checked
                    ? onDeselectItem(item.value)
                    : onSelectItem(item.value)
                }
              >
                <PartialCheckBox fullyChecked={checked} />

                {item.label}
              </Item>
            );
          })}
        </Items>
      )}
    </Wrapper>
  );
};

export default GroupSection;
