import { AkCompanyRole } from 'api';
import FormErrorList from 'components/FormErrorList';
import HQIcon from 'components/AkCompanyIcons/HQIcon';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import MediaQuery from 'constants/MediaQuery';
import WebRoleIds from 'constants/RoleIds';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useBasicData from 'contexts/useBasicData';
import { FC } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { validEmailRegex } from 'utils/regex';
import UserAkCompaniesTable from './UserAkCompaniesTable';
import { FormAkCompanyMembership, UserFormInputs } from './userFormConverters';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;

  ${MediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

interface Props {
  form: UseFormReturn<UserFormInputs>;
}

const UserForm: FC<Props> = ({ form }) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
  } = form;
  const { hQ_AkCompanyId } = useBasicData();
  const userClaims = useUserWebClaims();

  const mkField = <N extends keyof UserFormInputs>(
    name: N,
    label: string,
    options?: RegisterOptions<UserFormInputs, N>
  ) => (
    <LabelWrap label={label}>
      <Input {...register(name, options)} />
    </LabelWrap>
  );

  return (
    <>
      <Wrapper>
        {mkField('userName', 'Personnummer', {
          validate: (v) =>
            /^\d{12}$/.test(v)
              ? undefined
              : 'Ange ett 12-siffrigt personnummer',
        })}
        {mkField('fullName', 'Namn')}
        {mkField('email', 'E-post', {
          validate: (v) =>
            validEmailRegex.test(v)
              ? undefined
              : 'Email-adressen är inte giltig',
        })}
        {mkField('phoneNumber', 'Telefonnummer')}

        <CheckboxWrap>
          <Checkbox type="checkbox" {...register('deactivated')}>
            Deaktiverad
          </Checkbox>
        </CheckboxWrap>
      </Wrapper>

      <Wrapper>
        {userClaims.webRoleIds.has(WebRoleIds.admin) && (
          <CheckboxWrap>
            <Controller
              control={form.control}
              name="roles"
              render={({ field }) => (
                <Checkbox
                  checked={field.value.includes(WebRoleIds.admin)}
                  onChange={(eve) => {
                    const memberships = getValues('akCompanyMemberships');

                    if (eve.currentTarget.checked) {
                      field.onChange([WebRoleIds.admin]);

                      // Add hQ_AkCompany admin membership
                      const membershipsWithAkAdmin: FormAkCompanyMembership[] =
                        [
                          {
                            akCompanyId: hQ_AkCompanyId,
                            companyRoles: [
                              AkCompanyRole.CompanyManager,
                              AkCompanyRole.CompanyUser,
                            ],
                          },
                          ...memberships.filter(
                            (membership) =>
                              membership.akCompanyId !== hQ_AkCompanyId
                          ),
                        ];

                      setValue('akCompanyMemberships', membershipsWithAkAdmin);
                    } else {
                      field.onChange([]);

                      // Remove hQ_AkCompany membership
                      const membershipsWithoutAkAdmin = memberships.filter(
                        (membership) =>
                          membership.akCompanyId !== hQ_AkCompanyId
                      );

                      setValue(
                        'akCompanyMemberships',
                        membershipsWithoutAkAdmin
                      );
                    }
                  }}
                >
                  <HQIcon />
                  Ge tillgång till allt i AK Services
                </Checkbox>
              )}
            />
          </CheckboxWrap>
        )}
      </Wrapper>

      <UserAkCompaniesTable form={form} />

      <FormErrorList errors={errors} />
    </>
  );
};

export default UserForm;
