import { DateSpan } from 'components/Calendar/utils';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { ReactNode, useId, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import GanttChartCssVariableDefs, { cssVar } from './cssVariables';
import LaneGroup from './LaneGroup';
import EventLineContainer from './EventLineContainer';
import GanttMonthTimeline, {
  dayWidthCssVariable,
  displayClockStrokesCssVariable,
  monthHeadHeightCssVariable,
} from './GanttMonthTimeline';
import useGroupedEventLanes, {
  EventLaneGroup,
  LaneGroupLabelMaker,
  LaneLabelMaker,
} from './useGroupedEvents';

const dayMinWidth = 120;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;

  ${GanttChartCssVariableDefs}
`;

const EventGroups = styled.div`
  position: sticky;
  left: 0;
  z-index: 1;
  width: max-content;
  height: max-content;
  max-width: 100px;

  background-color: ${Theme.colors.bg.background1};
  color: ${Theme.colors.fg.background1};
  border-right: 1px solid ${Theme.colors.border.main};

  ${MediaQuery.tablet} {
    max-width: 500px;
  }
`;

const EventGroupsPadding = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  height: var(${monthHeadHeightCssVariable});
  background-color: ${Theme.colors.bg.background1};
  border-bottom: 1px solid ${Theme.colors.border.main};
`;

const EventLines = styled.div`
  position: absolute;
  inset: 0;
  top: var(${monthHeadHeightCssVariable});
  z-index: 0;
  display: flex;
  flex-direction: column;
`;

interface EventLinesGroupProps {
  collapsed: boolean;
  numberOfEvents: number;
}
const EventLinesGroup = styled.div<EventLinesGroupProps>`
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${Theme.colors.border.main};
  overflow: hidden;
  transition: min-height 0.1s, max-height 0.1s;

  ${({ collapsed, numberOfEvents }) =>
    collapsed
      ? css`
          min-height: ${0}px;
          max-height: ${0}px;
          margin-top: var(${cssVar.groupHeaderHeight});
        `
      : css`
          min-height: calc(
            ${numberOfEvents} * var(${cssVar.eventLineHeight}) + 3px
          );
          max-height: calc(
            ${numberOfEvents} * var(${cssVar.eventLineHeight}) + 3px
          );
          margin-top: var(${cssVar.groupHeaderHeight});
          margin-bottom: var(${cssVar.eventLineHeight});
          border-bottom: 1px solid ${Theme.colors.border.main};
        `}
`;

const EventLineInnerWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const MonthsWrapper = styled.div`
  flex: 1;
  position: relative;
  display: flex;
`;

export interface LaneGroupPreset {
  laneGroupLabel: string;
  laneLabels: string[];
}

interface Props<E extends DateSpan> {
  from: Date;
  to: Date;
  daysVisible: number;

  events: E[];
  laneGroupPresets: LaneGroupPreset[];
  renderEventLine(
    event: E,
    containerStart: Date,
    containerEnd: Date
  ): ReactNode;

  makeLaneLabel: LaneLabelMaker<E>;
  makeLaneGroupLabel: LaneGroupLabelMaker<E>;
  modifyLaneGroups?: (eventGroups: EventLaneGroup<E>[]) => EventLaneGroup<E>[];

  onDateSpanSelected?(start: Date, end: Date, eventLabel: string): void;
  className?: string;
}

const GanttChart = <E extends DateSpan>({
  from,
  to,
  daysVisible,
  events,
  laneGroupPresets: groupPresets,
  renderEventLine,
  makeLaneLabel,
  makeLaneGroupLabel,
  modifyLaneGroups,
  onDateSpanSelected,
  className,
}: Props<E>) => {
  const [collapsedGroups, setCollapsedGroups] = useState(new Set<string>());
  const wrapperRef = useRef<HTMLDivElement>(null);
  const eventGroupsRef = useRef<HTMLDivElement>(null);
  const monthsWrapperRef = useRef<HTMLDivElement>(null);
  const ganttChartId = useId();

  // Automatically update dayWidthCssVariable so that each day has the correct width:
  useLayoutEffect(() => {
    if (!wrapperRef.current || !eventGroupsRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (!wrapperRef.current || !eventGroupsRef.current) return;

      const wrapperWidth = wrapperRef.current.clientWidth;

      const eventGroupsDims = eventGroupsRef.current?.getBoundingClientRect();
      const viewportWidth = wrapperWidth - (eventGroupsDims?.width ?? 0);

      // Automatically update MonthsWrapper div height to match height of EventGroups
      // (their parent div has overflow: auto, so their heights won't match each other automatically :'( )
      if (eventGroupsDims && monthsWrapperRef.current)
        monthsWrapperRef.current.style.height = `${eventGroupsDims.height}px`;

      if (wrapperWidth) {
        const dayWidth = Math.max(viewportWidth / daysVisible, dayMinWidth);

        wrapperRef.current?.style.setProperty(
          dayWidthCssVariable,
          `${dayWidth}px`
        );

        const displayClockStrokes = dayWidth > 200;
        wrapperRef.current?.style.setProperty(
          monthHeadHeightCssVariable,
          `${displayClockStrokes ? 90 : 70}px`
        );

        if (displayClockStrokes) {
          wrapperRef.current?.style.removeProperty(
            displayClockStrokesCssVariable
          );
        } else {
          wrapperRef.current?.style.setProperty(
            displayClockStrokesCssVariable,
            'none'
          );
        }
      }

      wrapperRef.current?.style.setProperty(
        cssVar.groupNameWidth,
        `${wrapperWidth}px`
      );
    });
    resizeObserver.observe(wrapperRef.current);
    resizeObserver.observe(eventGroupsRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [daysVisible]);

  let groupedEventLanes = useGroupedEventLanes(
    events,
    from,
    to,
    makeLaneLabel,
    makeLaneGroupLabel,
    groupPresets
  );

  if (modifyLaneGroups) {
    groupedEventLanes = modifyLaneGroups(groupedEventLanes);
  }

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <EventGroups ref={eventGroupsRef}>
        <EventGroupsPadding />

        {groupedEventLanes.map((group) => (
          <LaneGroup
            groupName={group.groupLabel}
            laneLabels={[
              ...new Array(group.eventsWithoutLane?.length ?? 0).fill(''),
              ...group.lanes.map(([laneLabel]) => laneLabel),
            ]}
            collapsed={collapsedGroups.has(group.groupLabel)}
            onCollapse={(collapse) => {
              setCollapsedGroups((collapsedGroups) => {
                const newState = new Set(collapsedGroups);
                collapse
                  ? newState.add(group.groupLabel)
                  : newState.delete(group.groupLabel);
                return newState;
              });
            }}
            key={group.groupLabel}
          />
        ))}
      </EventGroups>

      <MonthsWrapper ref={monthsWrapperRef}>
        <EventLines>
          {groupedEventLanes.map((group) => (
            <EventLinesGroup
              collapsed={collapsedGroups.has(group.groupLabel)}
              numberOfEvents={
                group.lanes.length + (group.eventsWithoutLane?.length ?? 0)
              }
              key={group.groupLabel}
            >
              {group.eventsWithoutLane?.map((event, i) => (
                <EventLineContainer
                  from={from}
                  to={to}
                  ganttChartId={ganttChartId}
                  key={i}
                >
                  <EventLineInnerWrapper>
                    {renderEventLine(event, from, to)}
                  </EventLineInnerWrapper>
                </EventLineContainer>
              ))}

              {group.lanes.map(([laneLabel, laneEvents]) => (
                <EventLineContainer
                  from={from}
                  to={to}
                  onDateSpanSelected={
                    onDateSpanSelected
                      ? (from, to) => {
                          onDateSpanSelected(from, to, laneLabel);
                        }
                      : undefined
                  }
                  ganttChartId={ganttChartId}
                  key={laneLabel}
                >
                  <EventLineInnerWrapper>
                    {laneEvents.map((eve) => renderEventLine(eve, from, to))}
                  </EventLineInnerWrapper>
                </EventLineContainer>
              ))}
            </EventLinesGroup>
          ))}
        </EventLines>

        <GanttMonthTimeline from={from} to={to} />
      </MonthsWrapper>
    </Wrapper>
  );
};

export default GanttChart;
