import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { JobType, PriceType } from 'api';
import Table from 'components/Table';
import {
  ButtonCell,
  CheckBoxCell,
  InputCell,
  SelectCell,
} from 'components/Table/inputCells';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Theme from 'constants/theme';
import useBasicData from 'contexts/useBasicData';
import { FC, useMemo } from 'react';
import {
  Control,
  Controller,
  UseFieldArrayReturn,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import styled, { createGlobalStyle, css } from 'styled-components';
import { getAllEnumValues } from 'utils/enum';
import { defaultPriceItemNameFromPriceType } from '../formUtils/priceItemFormConverters';
import { PriceListFormInputs } from '../formUtils/priceListFormConverters';

const PriceListItemTable = styled(Table)`
  flex: 1;
  border-bottom: 1px solid ${Theme.colors.border.main};
  padding-bottom: 100px;

  ${`.${tableCellClassName}:first-child > input, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const StyleRowAsRemoved = createGlobalStyle<{ rowIndex: number }>`
  .price-item-row-${({ rowIndex }) => rowIndex},
  .price-item-row-${({ rowIndex }) => rowIndex}:nth-child(odd) {
    position: relative;

    .${tableCellClassName} {
      background-color: ${Theme.colors.bg.background3};
    }

    button {
      color: ${Theme.colors.bg.accent1};
    }
  
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: calc(${(30 / (100 * 4)) * 100}% - 4px);
      padding-left: ${Theme.sizes.padding.screenInset};
      display: grid;
      align-items: center;
      justify-content: center;

      content: 'Borttagen';
      font-weight: 600;
      color: ${Theme.colors.bad};
      background-color: rgba(208, 208, 208, 0.9);
    }
  }
`;

interface Props {
  register: UseFormRegister<PriceListFormInputs>;
  fieldArray: UseFieldArrayReturn<PriceListFormInputs, 'priceItems', 'id'>;
  control: Control<PriceListFormInputs>;
  setValue: UseFormSetValue<PriceListFormInputs>;
  getValues: UseFormGetValues<PriceListFormInputs>;
  editMode: boolean;
}

const PriceItemsTable: FC<Props> = ({
  register,
  fieldArray,
  control,
  setValue,
  getValues,
  editMode,
}) => {
  const basicData = useBasicData();
  const { fields } = fieldArray;

  const fieldsWithIndex = useMemo(
    () =>
      fields
        .map((f, index) => [f, index] as const)
        .filter(([f, i]) => !f.isRemoved),
    [fields]
  );

  return (
    <PriceListItemTable
      columnSettings={[
        {
          head: 'Prisnamn',
          render: ([, i]) => (
            <InputCell
              {...register(`priceItems.${i}.name`)}
              disabled={!editMode}
            />
          ),
          noPadding: true,
          width: 150,
        },
        {
          head: 'Pris',
          render: ([, i]) => (
            <InputCell
              type="number"
              {...register(`priceItems.${i}.price`)}
              disabled={!editMode}
            />
          ),
          noPadding: true,
          width: 60,
        },
        {
          head: 'Pristyp',
          render: ([, i]) => (
            <Controller
              name={`priceItems.${i}.priceType`}
              control={control}
              render={({ field }) => (
                <SelectCell
                  disabled={!editMode}
                  value={field.value}
                  onChange={(eve) => {
                    const newPriceType = Number(eve.target.value);

                    const [
                      currentName,
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs,
                    ] = getValues([
                      `priceItems.${i}.name`,
                      `priceItems.${i}.jobType`,
                      `priceItems.${i}.priceType`,
                      `priceItems.${i}.forUnplannedJobs`,
                    ]);

                    const currentPresetName = defaultPriceItemNameFromPriceType(
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs
                    );

                    // Only change price item name if it doesn't differ from the default name for the current priceType.
                    if (currentName === currentPresetName) {
                      setValue(
                        `priceItems.${i}.name`,
                        defaultPriceItemNameFromPriceType(
                          currentJobType,
                          newPriceType,
                          currentForUnplannedJobs
                        )
                      );
                    }

                    field.onChange(newPriceType);
                  }}
                >
                  {getAllEnumValues(PriceType).map((plt) => (
                    <option key={plt} value={plt}>
                      {basicData.priceTypeNames.get(plt)}
                    </option>
                  ))}
                </SelectCell>
              )}
            />
          ),
          noPadding: true,
          width: 150,
        },
        {
          head: 'Appliceras på jobbtyp',
          render: ([, i]) => (
            <Controller
              name={`priceItems.${i}.jobType`}
              control={control}
              render={({ field }) => (
                <SelectCell
                  disabled={!editMode}
                  value={field.value ?? 'none'}
                  style={field.value === null ? { color: 'gray' } : undefined}
                  onChange={(eve) => {
                    const newJobType =
                      eve.target.value === 'none'
                        ? null
                        : Number(eve.target.value);

                    const [
                      currentName,
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs,
                    ] = getValues([
                      `priceItems.${i}.name`,
                      `priceItems.${i}.jobType`,
                      `priceItems.${i}.priceType`,
                      `priceItems.${i}.forUnplannedJobs`,
                    ]);

                    const currentPresetName = defaultPriceItemNameFromPriceType(
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs
                    );

                    // Only change price item name if it doesn't differ from the default name for the current priceType.
                    if (currentName === currentPresetName) {
                      setValue(
                        `priceItems.${i}.name`,
                        defaultPriceItemNameFromPriceType(
                          newJobType,
                          currentPriceType,
                          currentForUnplannedJobs
                        )
                      );
                    }

                    field.onChange(newJobType);
                  }}
                >
                  <option value="none">Ingen</option>

                  {getAllEnumValues(JobType).map((plt) => (
                    <option key={plt} value={plt}>
                      {basicData.jobTypeNames.get(plt)}
                    </option>
                  ))}
                </SelectCell>
              )}
            />
          ),
          noPadding: true,
          width: 180,
        },
        {
          head: 'För oplanerade jobb',
          noPadding: true,
          render: ([, i]) => (
            <Controller
              name={`priceItems.${i}.forUnplannedJobs`}
              control={control}
              render={({ field }) => (
                <CheckBoxCell
                  checked={field.value}
                  disabled={!editMode}
                  onChange={(eve) => {
                    const newForUnplannedJobs = eve.currentTarget.checked;

                    const [
                      currentName,
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs,
                    ] = getValues([
                      `priceItems.${i}.name`,
                      `priceItems.${i}.jobType`,
                      `priceItems.${i}.priceType`,
                      `priceItems.${i}.forUnplannedJobs`,
                    ]);

                    const currentPresetName = defaultPriceItemNameFromPriceType(
                      currentJobType,
                      currentPriceType,
                      currentForUnplannedJobs
                    );

                    // Only change price item name if it doesn't differ from the default name for the current priceType.
                    if (currentName === currentPresetName) {
                      setValue(
                        `priceItems.${i}.name`,
                        defaultPriceItemNameFromPriceType(
                          currentJobType,
                          currentPriceType,
                          newForUnplannedJobs
                        )
                      );
                    }

                    field.onChange(newForUnplannedJobs);
                  }}
                />
              )}
            />
          ),
          width: 170,
        },
        {
          head: 'Ta bort',
          noPadding: true,
          render: ([, i]) => (
            <Controller
              name={`priceItems.${i}.isRemoved`}
              control={control}
              render={({ field }) => (
                <>
                  <ButtonCell
                    onClick={(eve) => {
                      eve.preventDefault();
                      field.onChange(!field.value);
                    }}
                    disabled={!editMode}
                  >
                    {field.value ? 'Ångra' : <FontAwesomeIcon icon={faTrash} />}
                  </ButtonCell>

                  {field.value && <StyleRowAsRemoved rowIndex={i} />}
                </>
              )}
            />
          ),
          width: editMode ? 60 : 0,
          css: !editMode
            ? css`
                & {
                  display: none;
                }
              `
            : undefined,
        },
      ]}
      initialSortState={{ sortedColumnIndex: -1, ascending: false }}
      rows={fieldsWithIndex}
      rowClassName={([, i]) => `price-item-row-${i}`}
    />
  );
};

export default PriceItemsTable;
