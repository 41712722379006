import { InvoiceClient } from 'api';
import { useApiCall } from 'swaggerhooks';

const useZipDownloader = () => {
  const downloadZipCall = useApiCall(
    InvoiceClient,
    async (c, invoiceIds: number[]) => {
      try {
        const zip = await c.getInvoicesZip(invoiceIds);

        if (!zip) {
          alert('Kunde inte hämta några fakturor.');
          return;
        }

        const blobUrl = URL.createObjectURL(zip.data);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = zip.fileName ?? 'fakturor.zip';
        link.click();
      } catch (err) {
        throw err;
      }
    }
  );

  return {
    downloadZipCall,
  };
};

export default useZipDownloader;
