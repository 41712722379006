import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

export const AccordionTogglerClassName = 'accordion-togggler';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TogglerRow = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;

  font: inherit;
  font-weight: 600;
  border-bottom: 1px solid #0002;
  background-color: ${Theme.colors.bg.background3};
  color: ${Theme.colors.fg.background3};

  &:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: -1;

    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const TogglerClickArea = styled.button`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px calc(${Theme.sizes.padding.screenInset} / 2);

  font: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px calc(${Theme.sizes.padding.screenInset} / 2);
`;

const ChildWrapper = styled.div`
  max-height: 0;
  transition: max-height 0.2s;
  overflow: hidden;
  border-bottom: 1px solid ${Theme.colors.border.main};
`;

interface StatelessProps extends PropsWithChildren {
  title: ReactNode | ReactNode[];
  actions: ReactNode | ReactNode[];
  open?: boolean;
  onToggleOpen(): void;
  className?: string;
}

export const StatelessAccordion: FC<StatelessProps> = ({
  children,
  title,
  actions,
  open = false,
  onToggleOpen,
  className,
}) => {
  const childWrapRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!childWrapRef.current) return;

    if (open) {
      childWrapRef.current.style.maxHeight = `${childWrapRef.current.scrollHeight}px`;
    } else {
      childWrapRef.current.style.maxHeight = '0';
    }
  });

  return (
    <Wrapper className={className}>
      <TogglerRow className={AccordionTogglerClassName}>
        <TogglerClickArea
          onClick={(eve) => {
            eve.preventDefault();
            eve.stopPropagation();
            onToggleOpen();
          }}
        >
          <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          {title}
        </TogglerClickArea>

        <Actions>{actions}</Actions>
      </TogglerRow>
      <ChildWrapper ref={childWrapRef}>{children}</ChildWrapper>
    </Wrapper>
  );
};

interface Props extends Omit<StatelessProps, 'open' | 'onToggleOpen'> {
  isDefaultOpen?: boolean;
}

const Accordion: FC<Props> = ({ isDefaultOpen, ...props }) => {
  const [open, setOpen] = useState(isDefaultOpen);

  return (
    <StatelessAccordion
      open={open}
      onToggleOpen={() => setOpen((o) => !o)}
      {...props}
    />
  );
};

export default Accordion;
