import { Case } from 'api';
import Modal from 'components/Modal';
import useModalStack from 'contexts/useModalStack';
import { useEffect, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import useSelectableCustomers from 'utils/useSelectableCustomers';
import { CaseFormInputs, createCaseForm } from './formUtils/caseFormConverters';

export const useAutoUpdateForm = (
  form: UseFormReturn<CaseFormInputs>,
  currentCase?: Case
) => {
  const modalStack = useModalStack();

  const latestModifiedTimestamp = useMemo(() => {
    let latestModification = currentCase?.modifiedUtc?.getTime() ?? -1;

    currentCase?.caseJobs.forEach((job) => {
      if (job.modifiedUtc.getTime() > latestModification) {
        latestModification = job.modifiedUtc.getTime();
      }
    });

    return latestModification;
  }, [currentCase]);

  const lastCaseId = useRef(currentCase?.id);

  useEffect(() => {
    if (
      form.formState.isDirty &&
      currentCase &&
      lastCaseId.current === currentCase.id
    ) {
      const modalId = modalStack.push(
        <Modal title="Varning" onClose={() => modalStack.pop(modalId)}>
          Detta ärende har uppdaterats av någon annan och din redigering kommer
          inte att kunna sparas. Ladda om sidan och försök att redigera ärendet
          igen.
        </Modal>
      );
    } else {
      if (currentCase) {
        form.reset(createCaseForm(currentCase));
      } else if (form.getValues('caseId') !== 0) {
        // case id !== 0, so the current case is an existing case. Replace it!
        form.reset(createCaseForm());
      }
    }

    lastCaseId.current = currentCase?.id;
  }, [currentCase?.id, latestModifiedTimestamp]);
};

export const useValidCustomerId = (
  form: UseFormReturn<CaseFormInputs>,
  currentCase?: Case
) => {
  const selectableCustomers = useSelectableCustomers();

  const customerId = form.watch('customerId');

  useEffect(() => {
    if (selectableCustomers.length === 0) return;

    const ensureValidCustomerId = (selectedCustomerId: number | undefined) => {
      const selectedCustomerIsSelectable = selectableCustomers.find(
        (customer) => customer.id === selectedCustomerId
      );

      if (!selectedCustomerIsSelectable) {
        form.setValue('customerId', selectableCustomers[0].id, {
          shouldDirty: !!currentCase,
        });
      }
    };

    const subscription = form.watch((formValues, { name }) => {
      if (name === 'customerId') {
        const selectedCustomerId = formValues.customerId;
        ensureValidCustomerId(selectedCustomerId);
      }
    });

    // Run once on mount
    ensureValidCustomerId(form.getValues('customerId'));

    return () => {
      subscription.unsubscribe();
    };
  }, [selectableCustomers, customerId]);
};
