import {
  IPriceList,
  PriceAdjustment,
  PriceItem,
  PriceList,
  PriceListType,
} from 'api';
import WebRoleIds from 'constants/RoleIds';
import { UserWebClaims } from 'contexts/useAccountInfo/useUserWebClaims';
import { toInputDateTimeString } from 'utils/date';
import {
  createPriceAdjustmentFormFromExisting,
  PriceAdjustmentFormInputs,
  priceAdjustmentFormToPriceAdjustment,
} from './priceAdjustmentFormConverters';
import {
  createPriceItemForm,
  PriceItemFormInputs,
  priceItemFormToPriceItem,
} from './priceItemFormConverters';

export interface PriceListFormInputs {
  isRemoved: boolean;
  customerId: number;
  priceListType: PriceListType;
  name: string;
  activeFromUtc: string;

  priceItems: PriceItemFormInputs[];
  priceAdjustments: PriceAdjustmentFormInputs[];
}

export function createPriceListForm(
  priceList?: IPriceList,
  defaultCustomerId?: number
): PriceListFormInputs {
  if (priceList) {
    return {
      isRemoved: priceList.isRemoved,
      customerId: priceList.customerId,
      priceListType: priceList.priceListType,
      name: priceList.name,
      activeFromUtc: toInputDateTimeString(priceList.activeFromUtc),

      priceItems: priceList.priceItems.map(createPriceItemForm),
      priceAdjustments: priceList.priceAdjustments.map(
        createPriceAdjustmentFormFromExisting
      ),
    };
  }

  const now = new Date();
  return {
    isRemoved: false,
    customerId: defaultCustomerId ?? -1,
    priceListType: PriceListType.InternalAndExternal,
    name: '',
    activeFromUtc: toInputDateTimeString(now),

    priceItems: [],
    priceAdjustments: [],
  };
}

export function priceListFormToPriceList(
  form: PriceListFormInputs,
  base: IPriceList | undefined,
  userWebClaims: UserWebClaims
): PriceList {
  const priceItemsById = new Map<number, PriceItem>();
  const priceAdjustmentsById = new Map<number, PriceAdjustment>();

  base?.priceItems.forEach((pi) => priceItemsById.set(pi.id, pi));
  base?.priceAdjustments.forEach((pi) => priceAdjustmentsById.set(pi.id, pi));

  return new PriceList({
    id: base?.id ?? 0,

    isRemoved: form.isRemoved,
    customerId: form.customerId,
    priceListType: userWebClaims.webRoleIds.has(WebRoleIds.admin)
      ? form.priceListType
      : PriceListType.InternalAndExternal,
    name: form.name,
    activeFromUtc: new Date(form.activeFromUtc),

    priceItems: form.priceItems.map(
      (pi) =>
        new PriceItem({
          ...priceItemFormToPriceItem(pi, priceItemsById.get(pi.priceItemId)),
          priceListId: base?.id ?? 0,
        })
    ),
    priceAdjustments: form.priceAdjustments.map(
      (pa) =>
        new PriceAdjustment({
          ...priceAdjustmentFormToPriceAdjustment(
            pa,
            priceAdjustmentsById.get(pa.priceAdjustmentId)
          ),
          priceListId: base?.id ?? 0,
        })
    ),

    createdById: base?.createdById ?? -1,
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedById: base?.modifiedById ?? -1,
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    timestamp: base?.timestamp ?? '',
  });
}
