import {
  faAngleDown,
  faAngleUp,
  faCalendarDay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader, { PageHeaderTitleClassName } from 'components/PageHeader';
import MediaQuery from 'constants/MediaQuery';
import { FC, useState } from 'react';
import styled from 'styled-components';
import TimeNavButtons from 'pages/PlannerTool/TimeNavigatorHeader/TimeNavButtons';
import TimeInputs from 'pages/PlannerTool/TimeNavigatorHeader/TimeInputs';
import { TodayButton } from 'pages/PlannerTool/TimeNavigatorHeader';

const MyPageHeader = styled(PageHeader)`
  grid-template-columns: 70px auto 30px 30px;
  gap: 5px 10px;
  padding-bottom: 15px;

  .${PageHeaderTitleClassName} {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  ${MediaQuery.tablet} {
    justify-content: flex-start;
    gap: 40px;
  }
`;

const MobileTitle = styled.span`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  gap: 5px;

  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    font-size: 15px;
  }

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const TabletTitle = styled.span`
  display: none;
  ${MediaQuery.tablet} {
    display: inline;
  }
`;

const MyTimeNavButtons = styled(TimeNavButtons)`
  grid-column: 1/2;
  grid-row: 1/2;
`;

const MyTimeInputs = styled(TimeInputs)`
  grid-column: 1/-1;
`;

interface Props {
  loading?: boolean;
  selectedDate: Date;
  onDateSelected(date: Date): void;
}

const TimeNavigatorHeader: FC<Props> = ({
  loading,
  selectedDate,
  onDateSelected,
}) => {
  const [showTimeInputs, setShowTimeInputs] = useState(false);

  const getMobileTitle = () => {
    return `${selectedDate.toLocaleDateString(undefined, {
      month: 'short',
    })} ${selectedDate.getFullYear()}`;
  };

  return (
    <MyPageHeader
      title={
        <>
          <MobileTitle onClick={() => setShowTimeInputs((s) => !s)}>
            {getMobileTitle()}
            {showTimeInputs ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </MobileTitle>
          <TabletTitle>Bokade ärenden</TabletTitle>
        </>
      }
      leftAlignActions
      loading={loading}
    >
      <MyTimeInputs
        type={'month'}
        selectedDate={selectedDate}
        onDateSelected={onDateSelected}
        closed={!showTimeInputs}
      />

      <MyTimeNavButtons
        type={'month'}
        selectedDate={selectedDate}
        onDateSelected={onDateSelected}
      />

      <TodayButton
        small
        onClick={() => onDateSelected(new Date())}
        icon={<FontAwesomeIcon icon={faCalendarDay} />}
      >
        Idag
      </TodayButton>
    </MyPageHeader>
  );
};

export default TimeNavigatorHeader;
