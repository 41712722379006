import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ disabled }) =>
    disabled
      ? css`
          border: 1px solid ${Theme.colors.bg.background3};
          color: ${Theme.colors.bg.background4};
        `
      : css`
          border: 1px solid ${Theme.colors.bg.accent1};
        `}
`;

const Name = styled.div`
  padding: 5px;
  word-break: break-all;
`;

const RemoveButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  margin: 0;

  border: none;
  background: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
`;

interface Props {
  name: string;
  onRemove(): void;
  disabled?: boolean;
}

const Attachment: FC<Props> = ({ name, onRemove, disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <Name>
        <FontAwesomeIcon icon={faFile} /> {name}
      </Name>
      <RemoveButton
        onClick={(eve) => {
          eve.preventDefault();
          onRemove();
        }}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faTimes} />
      </RemoveButton>
    </Wrapper>
  );
};

export default Attachment;
