import { DateSpan } from 'components/Calendar/utils';
import { useMemo } from 'react';

/**
 * Returns all events E[] as IntersectingEvent<E>[].
 * If event "A" starts after event "B", and A intersects with B, then A.intersectsWith will contain B.
 * B.intersectsWith will then also be null unless there's a previous event that B intersects with.
 *
 * The "makeResourceIdentifier" function should output any type of value that identifies what resource an event E uses.
 * Intersecting events that doesn't use the same resource won't be marked as intersecting.
 * Events that has null as resource identifier won't either be marked as intersecting.
 */
const useIntersectingEvents = <E extends DateSpan, RID, R>(
  events: E[],
  makeResourceIdentifier: (event: E) => RID | null,
  eventIntersectsMutator: (event: E, intersectsWith: E | null) => R
) => {
  return useMemo((): R[] => {
    const eventsByResourceId = new Map<RID | null, E[]>();

    events.forEach((event) => {
      const resourceId = makeResourceIdentifier(event);

      const resourceEvents = eventsByResourceId.get(resourceId);

      if (!resourceEvents) {
        eventsByResourceId.set(resourceId, [event]);
      } else {
        resourceEvents.push(event);
      }
    });

    // Add all events from the resource 'null' and mark them as non-intersecting
    const intersectingEvents: R[] =
      eventsByResourceId
        .get(null)
        ?.map((event) => eventIntersectsMutator(event, null)) ?? [];

    eventsByResourceId.delete(null);

    for (const resourceEvents of Array.from(eventsByResourceId.values())) {
      resourceEvents.sort((a, b) => a.from.getTime() - b.from.getTime());

      if (resourceEvents.length > 0) {
        // First event in resource doesn't intersect with any other
        // event, since there's no events before it.
        intersectingEvents.push(
          eventIntersectsMutator(resourceEvents[0], null)
        );

        for (let i = 1; i < resourceEvents.length; i++) {
          intersectingEvents.push(
            eventIntersectsMutator(
              resourceEvents[i],
              resourceEvents[i].from < resourceEvents[i - 1].to
                ? resourceEvents[i - 1]
                : null
            )
          );
        }
      }
    }

    return intersectingEvents;
  }, [eventIntersectsMutator, events, makeResourceIdentifier]);
};

export default useIntersectingEvents;
