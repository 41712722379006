import FormErrorList from 'components/FormErrorList';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import MediaQuery from 'constants/MediaQuery';
import { FC } from 'react';
import { Controller, RegisterOptions, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { conformRegNr } from 'utils/string';
import { VehicleFormInputs } from './vehicleFormConverters';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  ${MediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface Props {
  form: UseFormReturn<VehicleFormInputs>;
}

const VehicleForm: FC<Props> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;

  const mkField = <N extends keyof VehicleFormInputs>(
    name: N,
    label: string,
    options?: RegisterOptions<VehicleFormInputs, N>
  ) => (
    <LabelWrap label={label}>
      <Input {...register(name, options)} />
    </LabelWrap>
  );

  return (
    <>
      <Wrapper>
        <LabelWrap label="Registreringsnummer">
          <Controller
            name={`regNr`}
            control={form.control}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(eve) =>
                  field.onChange(conformRegNr(eve.target.value))
                }
              />
            )}
          />
        </LabelWrap>

        {mkField('vehicleType', 'Fordonstyp')}
      </Wrapper>
      <FormErrorList errors={errors} />
    </>
  );
};

export default VehicleForm;
