import { UserClient, WebUser } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Modal from 'components/Modal';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import { useUpdatePricingSetupCall } from 'contexts/usePricingSetup';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';
import { useOnUserSaved } from '..';
import {
  createUserForm,
  UserFormInputs,
  userFormToWebUser,
} from '../../ManageUsers/UserModal/userFormConverters';
import CustomerUserForm from './CustomerUserForm';

const CustomerUserModal: FC = () => {
  const params = useParams<{ id: string }>();
  const userId = Number(params.id);
  const navigate = useNavigate();
  const onUserSaved = useOnUserSaved();
  const updatePricingSetupCall = useUpdatePricingSetupCall();

  const form = useForm<UserFormInputs>({
    mode: 'onChange',
  });

  const userResponse = useResponse(
    UserClient,
    async (c) => {
      if (isNaN(userId)) {
        form.reset(createUserForm());
        return null;
      }

      const user = await c.getById(userId);

      form.reset(createUserForm(user ?? undefined));

      return user ?? null;
    },
    [userId]
  );

  const updateUserCall = useApiCall(
    UserClient,
    (c, userId: number, webUser: WebUser) => c.updateUser(userId, webUser)
  );

  const createUserCall = useApiCall(UserClient, async (c, webUser: WebUser) =>
    c.createCustomerUser(webUser)
  );

  const createNewUser = isNaN(userId) || userResponse.response === null;

  const formIsDirty = form.formState.isDirty;
  const formIsValid = form.formState.isValid;

  const handleClose = (eve: React.MouseEvent) => {
    eve.preventDefault();
    navigate(Routes.settings.manageCustomerUsers.index);
  };

  const submitHandler: SubmitHandler<UserFormInputs> = async (formInputs) => {
    if (userResponse.response) {
      // Update existing user
      const updateWebUser = userFormToWebUser(
        formInputs,
        userResponse.response
      );
      const [updatedUser, error] = await updateUserCall.run(
        userResponse.response?.id,
        updateWebUser
      );

      await updatePricingSetupCall.run();

      if (updatedUser && !error) {
        onUserSaved(updatedUser);
        navigate(Routes.settings.manageCustomerUsers.index);
      }
    } else {
      // Create new user
      const createWebUser = userFormToWebUser(formInputs);
      const [savedUser, error] = await createUserCall.run(createWebUser);

      await updatePricingSetupCall.run();

      if (savedUser && !error) {
        onUserSaved(savedUser);
        navigate(Routes.settings.manageCustomerUsers.index);
      }
    }
  };

  const renderContent = () => {
    switch (userResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection>Hämtar användare...</LoadingSection>;

      case RequestStatus.Fetched:
        if (updateUserCall.status === RequestStatus.Fetching) {
          return <LoadingSection>Sparar användare...</LoadingSection>;
        }
        if (createUserCall.status === RequestStatus.Fetching) {
          return <LoadingSection>Skapar användare...</LoadingSection>;
        }
        return (
          <>
            <CustomerUserForm form={form} />
            {createNewUser && (
              <span>
                Ett email kommer att skickas ut till användaren för att välja
                sitt lösenord.
              </span>
            )}
          </>
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => userResponse.update()} />;
    }
  };

  const disableButtons = [
    updateUserCall.status,
    createUserCall.status,
  ].includes(RequestStatus.Fetching);

  return (
    <form onSubmit={form.handleSubmit(submitHandler)}>
      <Modal
        scrollContent
        title={`${createNewUser ? 'Skapa' : 'Redigera'} ${
          userResponse.response?.fullName || 'kundanvändare'
        }`}
        onClose={disableButtons ? undefined : handleClose}
        buttons={
          disableButtons
            ? undefined
            : [
                {
                  label: createNewUser ? 'Skapa' : 'Spara',
                  disabled: !formIsDirty || !formIsValid,
                },
                { label: 'Avbryt', onClick: handleClose },
              ]
        }
      >
        {renderContent()}
      </Modal>
    </form>
  );
};

export default CustomerUserModal;
