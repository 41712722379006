import { overwritesPhoneSmallTheme } from './utils';

const phoneTheme = overwritesPhoneSmallTheme({
  sizes: {
    font: {
      huge: '30px',
      large: '25px',
      medium: '20px',
      small: '15px',
    },
  } as const,
});

export default phoneTheme;
