import Theme from 'constants/theme';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button, { FABStyle } from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  flex: 1;
  overflow: auto;

  .ql-editor {
    padding-left: ${Theme.sizes.padding.screenInset};
    padding-right: ${Theme.sizes.padding.screenInset};

    h1 {
      font-size: ${Theme.sizes.font.huge};
    }
    h2 {
      font-size: ${Theme.sizes.font.large};
    }
    h3 {
      font-size: ${Theme.sizes.font.medium};
    }
    a {
      color: ${Theme.colors.bg.accent1};
      font-weight: 500;
    }
  }
  .ql-container {
    font-family: inherit;
    font-size: ${Theme.sizes.font.small};
  }

  .ql-tooltip.ql-hidden {
    display: none;
  }
`;

const EditButton = styled(Button)`
  ${FABStyle};
`;

const SaveButton = styled(Button)`
  ${FABStyle};
  right: 100px;
`;

const QuillWrapper = styled.div`
  background-color: white;
  color: black;
`;

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    [{ font: [] }],
    [{ align: [] }, { indent: '-1' }, { indent: '+1' }],

    ['bold', 'italic', 'underline', 'strike'],

    [{ color: [] }, { background: [] }],

    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ direction: 'rtl' }],

    ['clean'],
    ['link', 'image'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
  'direction',
  'code-block',
  'background',
  'color',
  'font',
  'code',
  'size',
  'script',
];

interface Props {
  editText: string;
  onEditTextChanged?(editText: string): void;
  editMode?: boolean;
  onEditClick?(): void;
  onSaveClick?(): void;
  onCancelClick?(): void;
  className?: string;
}

const EditableRichText = ({
  editText,
  onEditTextChanged,
  editMode,
  onSaveClick,
  onCancelClick,
  onEditClick,
  className,
}: Props) => {
  return (
    <Wrapper className={className}>
      {editMode ? (
        <QuillWrapper>
          <ReactQuill
            theme="snow"
            value={editText}
            onChange={onEditTextChanged}
            modules={modules}
            formats={formats}
          />
        </QuillWrapper>
      ) : (
        <ReactQuill theme="bubble" value={editText} readOnly />
      )}

      {onEditClick && onSaveClick && onCancelClick && (
        <>
          {editMode ? (
            <>
              <SaveButton
                onClick={() => {
                  onSaveClick?.();
                }}
                icon={<FontAwesomeIcon icon={faSave} />}
                title="Spara"
              />
              <EditButton
                onClick={() => {
                  onCancelClick?.();
                }}
                icon={<FontAwesomeIcon icon={faCancel} />}
                title="Avbryt"
              />
            </>
          ) : (
            <EditButton
              onClick={() => onEditClick?.()}
              icon={<FontAwesomeIcon icon={faEdit} />}
              title="Redigera"
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default EditableRichText;
