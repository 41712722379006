import MonthCalendar, { CalendarClassName } from 'components/Calendar';
import Routes from 'constants/routes';
import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus } from 'swaggerhooks';
import JobsFilter from './JobsFilter';
import useJobsFilterState from './JobsFilter/useJobsFilterState';
import useFilteredJobs from './JobsFilter/useFilteredJobs';
import { datespansIntersects } from 'components/Calendar/utils';
import { useQueryParams } from 'utils/useQueryParam';
import moment from 'moment';
import { useJobPageContext } from '..';
import { fromDateQueryName, toDateQueryName } from '../useTimespanState';
import TimeNavigatorHeader from '../TimeNavigatorHeader';
import PhoneSlideInView from 'components/Modal/PhoneSlideInView';
import { PopOverClassName } from 'components/Modal/PopOver';
import { DeviceMinWidths } from 'constants/MediaQuery';
import useMergedCaseJobEvents, { CaseEvent } from './useMergedCaseJobEvents';
import CaseEventLine from './CaseEventLine';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Horizontal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const JobsCalendar: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [showFilters, setShowFilters] = useState(false);
  const {
    from,
    to,
    bufferedEvents,
    isDebouncingTimespan,
    searchStatus,
    setTimespan,
    handleEventClick,
    selectedCaseJobFormIndex,
    handleCreateCaseClick,
  } = useJobPageContext();

  const caseJobEvents = useMemo(() => {
    const visibleStart = moment(from).startOf('week').toDate();
    const visibleEnd = moment(to).endOf('week').toDate();

    return bufferedEvents.filter((cj) =>
      datespansIntersects(cj.from, cj.to, visibleStart, visibleEnd)
    );
  }, [bufferedEvents, from.getTime(), to.getTime()]);

  const caseEvents = useMergedCaseJobEvents(caseJobEvents);

  const jobsFilterState = useJobsFilterState();
  const filteredJobEvents = useFilteredJobs(
    jobsFilterState.filterState,
    caseEvents
  );
  const filterHadEffect = caseEvents.length !== filteredJobEvents.length;

  const makeCaseEventClassName = (event: CaseEvent) =>
    `jobEventLine-${event.caseId}`;

  return (
    <Wrapper>
      <TimeNavigatorHeader
        loading={
          isDebouncingTimespan || searchStatus === RequestStatus.Fetching
        }
        selectedDate={from}
        onDateSelected={setTimespan}
        onShowFiltersClick={() => setShowFilters(true)}
        filterHadEffect={filterHadEffect}
        type="month"
      />

      <Horizontal>
        <PhoneSlideInView
          title="Filter"
          open={showFilters}
          onClose={() => setShowFilters(false)}
        >
          <JobsFilter jobsFilterState={jobsFilterState} />
        </PhoneSlideInView>

        <MonthCalendar
          showDate={from}
          events={filteredJobEvents}
          renderEventLine={(eve, containerStart, containerEnd) => (
            <CaseEventLine
              caseEvent={eve}
              from={eve.from}
              to={eve.to}
              containerStart={containerStart}
              containerEnd={containerEnd}
              onClick={handleEventClick}
              selected={
                selectedCaseJobFormIndex !== undefined &&
                !!eve.caseJobEvents.find(
                  (je) =>
                    je.formIndex?.jobGroupFormIndex ===
                      selectedCaseJobFormIndex.jobGroupFormIndex &&
                    je.formIndex.jobInstanceFormIndex ===
                      selectedCaseJobFormIndex.jobInstanceFormIndex
                )
              }
              caseEventClassName={makeCaseEventClassName(eve)}
              key={makeCaseEventClassName(eve)}
            />
          )}
          onWeekRowClick={(weekStart, weekEnd) => {
            const newQueryParams = new URLSearchParams(queryParams);
            newQueryParams.set(fromDateQueryName, weekStart.toISOString());
            newQueryParams.set(toDateQueryName, weekEnd.toISOString());

            navigate(
              `${Routes.plannerTool.weekView}?${newQueryParams.toString()}`
            );
          }}
          onDateBodyClick={(date) =>
            handleCreateCaseClick(
              moment(date).startOf('day').toDate(),
              moment(date).endOf('day').toDate()
            )
          }
          shouldCloseWeekOverflow={(eve) => {
            // always close week overflow on phones
            if (window.innerWidth < DeviceMinWidths.tablet) return true;

            if (eve.target instanceof Element) {
              if (eve.target.closest(`.${PopOverClassName}`)) return false;
              if (eve.target.closest(`.${CalendarClassName}`)) return true;
            }
            return false;
          }}
        />
      </Horizontal>
    </Wrapper>
  );
};

export default JobsCalendar;
