import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { FC, PropsWithChildren, ReactNode, useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Button from './inputs/Button';

export const PageHeaderButton = styled(Button)`
  margin-top: auto;
  margin-left: auto;
  margin-bottom: auto;
`;

const loadingAnimation = keyframes`
  0% {
    left: 0%;
    right: 100%;
  }

  25% {
    left: 0%;
    right: 50%;
  }

  50% {
    left: 25%;
    right: 0%;
  }

  100% {
    left: 100%;
    right: 0%;
  }
`;

const loadingClassName = 'page-header-loading';
export const PageHeaderTitleClassName = 'page-header-title';

const Wrapper = styled.div<{ size?: PageHeaderSize }>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  border-bottom: 1px solid ${Theme.colors.border.main};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0%;
    right: 0%;
    height: 2px;
    background-color: ${Theme.colors.bg.selection};
    animation: ${loadingAnimation} 1.2s linear infinite;
    animation-play-state: paused;
    opacity: 0;
  }

  &${`.${loadingClassName}`}::after {
    opacity: 1;
    animation-play-state: running;
  }
  ${({ size }) => {
    switch (size) {
      case 'medium':
        return css`
          gap: 10px;
          padding: 10px ${Theme.sizes.padding.screenInset};
        `;
      case 'small':
        return css`
          gap: 10px;
          padding: 5px ${Theme.sizes.padding.screenInset};
        `;
      case 'large':
      default:
        return css`
          gap: 10px;
          padding: 10px ${Theme.sizes.padding.screenInset};
        `;
    }
  }};

  ${MediaQuery.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    overflow: auto;

    ${({ size }) => {
      switch (size) {
        case 'medium':
          return css`
            gap: 20px;
            padding: 15px ${Theme.sizes.padding.screenInset};
          `;
        case 'small':
          return css`
            gap: 20px;
            padding: 10px ${Theme.sizes.padding.screenInset};
          `;
        case 'large':
        default:
          return css`
            gap: 40px;
            padding: 20px ${Theme.sizes.padding.screenInset};
          `;
      }
    }};
  }
`;

export type PageHeaderSize = 'large' | 'medium' | 'small';
const Title = styled.h1<{ size?: PageHeaderSize; leftAlignActions?: boolean }>`
  font-size: ${({ size }) => {
    switch (size) {
      case 'medium':
        return Theme.sizes.font.medium;
      case 'small':
        return Theme.sizes.font.small;
      case 'large':
      default:
        return Theme.sizes.font.large;
    }
  }};
  padding: 0;
  margin: 0;

  ${({ leftAlignActions }) => (leftAlignActions ? '' : 'margin-right: auto;')}
`;

interface Props extends PropsWithChildren {
  title: ReactNode;
  leftAlignActions?: boolean;
  size?: PageHeaderSize;
  loading?: boolean;
  className?: string;
}

const PageHeader: FC<Props> = ({
  title,
  leftAlignActions,
  size,
  loading,
  children,
  className,
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;
    const currentElement = wrapperRef.current;

    const animationEnder = () => {
      currentElement.classList.remove(loadingClassName);
      currentElement.removeEventListener('animationiteration', animationEnder);
    };

    if (loading) {
      currentElement.classList.add(loadingClassName);
    } else {
      currentElement.addEventListener('animationiteration', animationEnder);
    }

    return () => {
      currentElement.removeEventListener('animationiteration', animationEnder);
    };
  }, [loading]);

  return (
    <Wrapper size={size} ref={wrapperRef} className={className}>
      <Title
        size={size}
        className={PageHeaderTitleClassName}
        leftAlignActions={leftAlignActions}
      >
        {title}
      </Title>
      {children}
    </Wrapper>
  );
};

export default PageHeader;
