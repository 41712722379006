import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Modal, { modalContentClass } from 'components/Modal';
import { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import { toInputDateString } from 'utils/date';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    max-width: 700px;
  }
`;

const MyLabelWrap = styled(LabelWrap)`
  margin-left: auto;
`;

interface Props {
  onClose(): void;
  onReopenCase(creditInvoiceDate: Date): void;
}

const ReOpenCaseModal: FC<Props> = ({ onClose, onReopenCase }) => {
  const [date, setDate] = useState(new Date());

  const handleOpenCaseClick = async () => {
    onReopenCase(date);
  };

  const handleDateChange = (eve: ChangeEvent<HTMLInputElement>) => {
    setDate(new Date(eve.target.value));
  };

  return (
    <MyModal
      title={'Återöppna ärende'}
      onClose={onClose}
      buttons={[
        { label: 'Avbryt', onClick: onClose },
        { label: 'Öppna', onClick: handleOpenCaseClick },
      ]}
    >
      <b>Är du säker på att du vill öppna detta ärende?</b>
      <p>
        Ärendet är avslutat och fakturerat, om du öppnar ärendet kommer en
        kreditfaktura att genereras till kund. (Sen kan ärendet ändras och
        faktureras på nytt).
      </p>
      <MyLabelWrap label={'Välj faktureringsdatum för kreditfakturan:'}>
        <Input
          type="date"
          value={toInputDateString(date)}
          onChange={(eve) => handleDateChange(eve)}
        ></Input>
      </MyLabelWrap>
    </MyModal>
  );
};

export default ReOpenCaseModal;
