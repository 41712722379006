import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AkCompanyRole } from 'api';
import HQIcon from 'components/AkCompanyIcons/HQIcon';
import Button from 'components/inputs/Button';
import Select from 'components/inputs/Select';
import Table from 'components/Table';
import { ButtonCell, MultiSelectCell } from 'components/Table/inputCells';
import WebRoleIds from 'constants/RoleIds';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useBasicData from 'contexts/useBasicData';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { makeStringComparator } from 'utils/sorting';
import useSelectableAkCompanies from 'utils/useSelectableAkCompanies';
import { UserFormInputs } from './userFormConverters';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MyTable = styled(Table)`
  border: 1px solid ${Theme.colors.border.main};
` as typeof Table;

const FullAccessMsg = styled.span`
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  padding: 4px;
`;

const BottonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const akCompanyRoleNames = new Map([
  [AkCompanyRole.CompanyUser, 'Användare'],
  [AkCompanyRole.CompanyManager, 'Administratör'],
]);

interface Props {
  form: UseFormReturn<UserFormInputs>;
}

const UserAkCompaniesTable: FC<Props> = ({ form }) => {
  const {
    accountInfo: { user },
  } = useAccountInfo();
  const userClaims = useUserWebClaims();
  const { akCompaniesById } = usePricingSetup();
  const { hQ_AkCompanyId } = useBasicData();
  const selectableAkCompanies = useSelectableAkCompanies();
  const [selectedAkCompany, setSelectedAkCompany] = useState(-1);

  const { control, watch } = form;
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'akCompanyMemberships',
  });
  const akCompanyMemberships = watch('akCompanyMemberships');

  const fieldsWithFormIndex = useMemo(
    () => fields.map((f, i) => [f, i] as const),
    [fields]
  );

  const isAdmin = userClaims.webRoleIds.has(WebRoleIds.admin);

  const hasManagerRoleForAkCompany = useCallback(
    (akCompanyId: number) =>
      user?.akCompanyMemberships.find(
        (cm) =>
          cm.akCompanyId === akCompanyId &&
          cm.companyRole === AkCompanyRole.CompanyManager
      ) !== undefined,
    [user?.akCompanyMemberships]
  );

  const addableAkCompanies = useMemo(() => {
    return selectableAkCompanies
      .filter(
        // Only show alternatives for akCompanies which the user is a CompanyManager or Admin
        (akCompany) => isAdmin || hasManagerRoleForAkCompany(akCompany.id)
      )
      .filter(
        // Only show alternatives which hasn't been selected yet
        (akCompany) =>
          akCompanyMemberships.find((cm) => cm.akCompanyId === akCompany.id) ===
          undefined
      )
      .filter(
        // Remove hQ_akCompany option, since its added when clicking the admin checkbox.
        (akCompany) => akCompany.id !== hQ_AkCompanyId
      )
      .map((akCompany) => ({
        label: akCompany.name,
        value: akCompany.id,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : a.label === b.label ? 0 : -1));
  }, [
    akCompanyMemberships,
    hQ_AkCompanyId,
    hasManagerRoleForAkCompany,
    isAdmin,
    selectableAkCompanies,
  ]);

  return (
    <Wrapper>
      <MyTable
        columnSettings={[
          {
            head: 'Tilldelad station',
            render: ([c]) => (
              <>
                {akCompaniesById.get(c.akCompanyId)?.name}{' '}
                {c.akCompanyId === hQ_AkCompanyId && <HQIcon />}
              </>
            ),
            sortFunction: makeStringComparator(
              ([c]) => akCompaniesById.get(c.akCompanyId)?.name ?? ''
            ),
            width: 200,
          },
          {
            head: 'Roller',
            noPadding: true,
            render: ([c, formIndex]) =>
              isAdmin || hasManagerRoleForAkCompany(c.akCompanyId) ? (
                <Controller
                  control={form.control}
                  name={`akCompanyMemberships.${formIndex}.companyRoles`}
                  render={({ field }) => {
                    if (
                      c.akCompanyId === hQ_AkCompanyId &&
                      field.value.includes(AkCompanyRole.CompanyManager)
                    ) {
                      return (
                        <FullAccessMsg>
                          Tillgång till allt i AK Services
                        </FullAccessMsg>
                      );
                    }

                    return (
                      <MultiSelectCell
                        options={Array.from(akCompanyRoleNames.entries()).map(
                          ([roleId, roleName]) => ({
                            label: roleName,
                            value: roleId,
                          })
                        )}
                        value={new Set(field.value)}
                        onChange={(value) => {
                          field.onChange(Array.from(value));
                        }}
                      />
                    );
                  }}
                />
              ) : null,
            width: 300,
          },
          {
            head: 'Ta bort',
            noPadding: true,
            render: ([c, formIndex]) => {
              if (
                c.akCompanyId === hQ_AkCompanyId &&
                c.companyRoles.includes(AkCompanyRole.CompanyManager)
              )
                return null;

              if (isAdmin || hasManagerRoleForAkCompany(c.akCompanyId)) {
                return (
                  <ButtonCell
                    onClick={(eve) => {
                      eve.preventDefault();
                      remove(formIndex);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ButtonCell>
                );
              }

              return null;
            },
            width: 70,
          },
        ]}
        rows={fieldsWithFormIndex}
        rowClassName={([c]) => c.id}
      />
      <BottonRow>
        <Select
          value={selectedAkCompany}
          onChange={(eve) => setSelectedAkCompany(Number(eve.target.value))}
        >
          <option value="-1">Välj en station</option>

          {addableAkCompanies.map((company) => (
            <option key={company.value} value={company.value}>
              {company.label}
            </option>
          ))}
        </Select>

        <Button
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={selectedAkCompany === -1}
          small
          onClick={(eve) => {
            eve.preventDefault();
            setSelectedAkCompany(-1);
            append({
              akCompanyId: selectedAkCompany,
              companyRoles: [AkCompanyRole.CompanyUser],
            });
          }}
        >
          Lägg till
        </Button>
      </BottonRow>
    </Wrapper>
  );
};

export default UserAkCompaniesTable;
