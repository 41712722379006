const selectedAkCompanyId = 'userSettings_selectedAkCompanyId';

function setNullable(key: string, value: string | number | null) {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(
      key,
      typeof value === 'number' ? JSON.stringify(value) : value
    );
  }
}

export function getSelectedAkCompanyId() {
  const companyIdStr = localStorage.getItem(selectedAkCompanyId);
  return companyIdStr === null ? null : Number(companyIdStr);
}
export function storeSelectedAkCompanyId(akCompanyId: number | null) {
  setNullable(selectedAkCompanyId, akCompanyId);
}
