import { faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import { FC } from 'react';
import {
  Control,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegisterReturn,
  UseFormWatch,
} from 'react-hook-form';
import styled, { css } from 'styled-components';
import Theme from 'constants/theme';
import { makeActivatableMediaQuery } from 'components/mediaQueries/ActivatableMediaQuery';
import CustomerFormInputGrid from '../CustomerFormInputGrid';
import { CustomerCaseJobFormInputs } from '../formUtils/caseJobFormConverters';
import { CustomerCaseFormInputs } from '../formUtils/caseFormConverters';
import MediaQuery from 'constants/MediaQuery';

const showInCalendarButtonClass = 'show-casejob-in-calendar';

const Wrapper = styled.div<{ highlight: boolean }>`
  position: relative;
  padding: 10px ${Theme.sizes.padding.screenInset};

  input:disabled,
  select:disabled {
    height: auto;
    padding: 0;
    padding-bottom: 5px;

    border: none;
    font-weight: 600;
    color: currentColor;
    opacity: 1;
    appearance: none;
    background: none;
  }

  &:not(:hover) .${showInCalendarButtonClass} {
    visibility: hidden;
  }

  ${({ highlight }) =>
    highlight &&
    css`
      background-color: ${Theme.colors.bg.background2};
      border: 1px solid ${Theme.colors.bg.selection};
      border-width: 1px 0;
    `}

  ${MediaQuery.tablet} {
    padding: 10px calc(${Theme.sizes.padding.screenInset} - 10px);
  }
`;

const AmountInput = styled(Input)`
  margin-right: 5px;
  width: 3em;
  font-size: ${Theme.sizes.font.small};
`;

const MultiplyMarker = styled(FontAwesomeIcon)`
  font-size: ${Theme.sizes.font.small};
  padding-right: 5px;
`;

const JobDatesInputGrid = styled(CustomerFormInputGrid)`
  padding: 0px 10px;

  ${makeActivatableMediaQuery(
    'phone',
    css`
      padding: 0px;
    `
  )}
`;

interface MySectionHeaderProps {
  highlighted: boolean;
}

const MySectionHeader = styled(SectionHeader)<MySectionHeaderProps>`
  padding-left: 0;
  padding-right: 0;

  ${({ highlighted }) =>
    highlighted &&
    css`
      border-bottom: 1px solid ${Theme.colors.bg.selection};

      &:not(:hover),
      &:hover {
        & .${showInCalendarButtonClass} {
          visibility: visible;
        }
      }
    `}

  ${makeActivatableMediaQuery(
    'tablet',
    css`
      padding-left: 10px;
      padding-right: 10px;
    `
  )}
`;

interface Props {
  defaultValues: CustomerCaseJobFormInputs;
  register(
    name: keyof CustomerCaseJobFormInputs,
    options?: RegisterOptions
  ): UseFormRegisterReturn;
  watch: UseFormWatch<CustomerCaseFormInputs>;
  getValues: UseFormGetValues<CustomerCaseFormInputs>;
  /** Removes this CaseJobFields section from the form. Can only be called for CaseJobs that hasn't been saved */
  onDelete(): void;
  caseJobFormIndex: number;
  formControl: Control<CustomerCaseFormInputs, any>;

  highlighted: boolean;

  className?: string;
}

const CaseJobFields: FC<Props> = ({
  register,
  watch,
  onDelete,
  highlighted,
  className,
  caseJobFormIndex,
}) => {
  const isEditable = true;

  const watchedStartDate = watch(
    `caseJobs.${caseJobFormIndex}.orderedStartUtc`
  );
  const watchedEndDate = watch(`caseJobs.${caseJobFormIndex}.orderedEndUtc`);

  return (
    <Wrapper className={className} highlight={highlighted}>
      <MySectionHeader
        title={
          <>
            <AmountInput type="number" min="1" {...register('amount')} />
            <MultiplyMarker icon={faXmark} />
            TMA jobb
          </>
        }
        highlighted={highlighted}
      >
        <Button
          onClick={(eve) => {
            eve.preventDefault();
            onDelete();
          }}
          small
          icon={<FontAwesomeIcon icon={faTrash} />}
        >
          Ta bort
        </Button>
      </MySectionHeader>

      <JobDatesInputGrid>
        <LabelWrap label="Bokad starttid">
          <Input
            type="datetime-local"
            max={watchedEndDate}
            {...register('orderedStartUtc', {
              validate: (value) => {
                if (!value) return 'Ange ett startdatum';
              },
            })}
            disabled={!isEditable}
          />
        </LabelWrap>
        <LabelWrap label="Bokad sluttid">
          <Input
            type="datetime-local"
            min={watchedStartDate}
            {...register('orderedEndUtc', {
              validate: (value) => {
                if (!value) return 'Ange ett slutdatum';
              },
            })}
            disabled={!isEditable}
          />
        </LabelWrap>
      </JobDatesInputGrid>
    </Wrapper>
  );
};

export default CaseJobFields;
