import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { cssVar } from './cssVariables';

interface LabelsProps {
  collapsed: boolean;
  numberOfLabels: number;
}
const LaneLabels = styled.div<LabelsProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: height 0.1s;

  ${({ collapsed, numberOfLabels }) =>
    collapsed
      ? css`
          height: 0;
        `
      : css`
          height: calc(
            ${numberOfLabels} * var(${cssVar.eventLineHeight}) + 1px
          );
          border-bottom: 1px solid ${Theme.colors.border.main};

          &:not(:last-child) {
            margin-bottom: var(${cssVar.eventLineHeight});
          }
        `}
`;

const GroupNameContainer = styled.div`
  position: sticky;
  top: 70px;
  height: calc(var(${cssVar.groupHeaderHeight}) + 2px);
`;

const GroupName = styled.div<{ collapsed?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: calc(var(${cssVar.groupHeaderHeight}) + 2px);
  width: var(${cssVar.groupNameWidth}, 100%);
  padding: var(${cssVar.eventLinePadding});
  padding-left: ${Theme.sizes.padding.screenInset};
  overflow: hidden;
  text-overflow: ellipsis;

  background-color: ${Theme.colors.bg.background1};
  font-weight: bold;
  font-size: ${Theme.sizes.font.medium};
  border-top: 1px solid ${Theme.colors.border.main};
  border-bottom: 1px solid ${Theme.colors.border.main};
  background-color: ${Theme.colors.bg.background2};

  ${({ collapsed }) =>
    collapsed &&
    css`
      border-bottom: none;
    `}
  cursor: pointer;
`;

const LaneLabel = styled.div`
  height: var(${cssVar.eventLineHeight});
  padding: var(${cssVar.eventLinePadding});
  padding-left: ${Theme.sizes.padding.screenInset};
  width: max-content;
  min-width: 100%;

  &:nth-child(even) {
    background-color: ${Theme.colors.bg.background2};
  }
`;

const CloseButton = styled.button`
  padding: 5px;
  margin: 0;
  border: none;
  background: transparent;
  color: ${Theme.colors.fg.background2};
  cursor: pointer;
`;

interface Props {
  groupName: string;
  laneLabels: string[];
  collapsed: boolean;
  onCollapse(collapsed: boolean): void;
}

const LaneGroup: FC<Props> = ({
  groupName,
  laneLabels,
  collapsed,
  onCollapse,
}) => {
  return (
    <>
      <GroupNameContainer>
        <GroupName collapsed={collapsed} onClick={() => onCollapse(!collapsed)}>
          <CloseButton>
            <FontAwesomeIcon icon={collapsed ? faAngleUp : faAngleDown} />
          </CloseButton>

          {groupName}
        </GroupName>
      </GroupNameContainer>

      <LaneLabels collapsed={collapsed} numberOfLabels={laneLabels.length}>
        {laneLabels.map((label, i) => (
          <LaneLabel key={i}>{label}</LaneLabel>
        ))}
      </LaneLabels>
    </>
  );
};

export default LaneGroup;
