import { UserClient, WebUser } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Modal from 'components/Modal';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks';
import { useOnUserSaved } from '..';
import UserForm from './UserForm';
import {
  createUserForm,
  UserFormInputs,
  userFormToWebUser,
} from './userFormConverters';

interface Props {
  createNew?: boolean;
  prefillFields?: Partial<UserFormInputs>;
}

const UserModal: FC<Props> = ({ createNew, prefillFields }) => {
  const form = useForm<UserFormInputs>({
    mode: 'onChange',
  });
  const params = useParams<{ id: string }>();
  const userId = Number(params.id);
  const navigate = useNavigate();
  const onUserSaved = useOnUserSaved();

  const userResponse = useResponse(
    UserClient,
    async (c) => {
      if (createNew) {
        form.reset({ ...createUserForm(), ...prefillFields });
        return null;
      } else {
        const user = await c.getById(userId);
        form.reset(createUserForm(user));
        return user;
      }
    },
    [userId, createNew]
  );
  const updateUserCall = useApiCall(
    UserClient,
    (c, userId: number, webUser: WebUser) => c.updateUser(userId, webUser)
  );
  const createUserCall = useApiCall(UserClient, async (c, webUser: WebUser) =>
    c.createAkCompanyUser(webUser)
  );

  const formIsDirty = form.formState.isDirty;
  const formIsValid = form.formState.isValid;

  const handleClose = (eve: React.MouseEvent) => {
    eve.preventDefault();
    navigate(Routes.settings.manageUsers.index);
  };

  const submitHandler: SubmitHandler<UserFormInputs> = async (formInputs) => {
    if (userResponse.response) {
      const updateWebUser = userFormToWebUser(
        formInputs,
        userResponse.response
      );
      const [updatedUser, error] = await updateUserCall.run(
        userResponse.response?.id,
        updateWebUser
      );

      if (updatedUser && !error) {
        onUserSaved(updatedUser);
        navigate(Routes.settings.manageUsers.index);
      }
    } else {
      const createWebUser = userFormToWebUser(formInputs);
      const [savedUser, error] = await createUserCall.run(createWebUser);
      if (savedUser && !error) {
        onUserSaved(savedUser);
        navigate(Routes.settings.manageUsers.index);
      }
    }
  };

  const renderContent = () => {
    switch (userResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection>Hämtar användare...</LoadingSection>;

      case RequestStatus.Fetched:
        if (updateUserCall.status === RequestStatus.Fetching) {
          return <LoadingSection>Sparar användare...</LoadingSection>;
        }
        if (createUserCall.status === RequestStatus.Fetching) {
          return <LoadingSection>Skapar användare...</LoadingSection>;
        }
        return <UserForm form={form} />;

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => userResponse.update()} />;
    }
  };

  const disableButtons = [
    updateUserCall.status,
    createUserCall.status,
  ].includes(RequestStatus.Fetching);

  return (
    <form onSubmit={form.handleSubmit(submitHandler)}>
      <Modal
        fullscreenOnPhones
        scrollContent
        title={`${createNew ? 'Skapa' : 'Redigera'} ${
          userResponse.response?.fullName || 'användare'
        }`}
        onClose={disableButtons ? undefined : handleClose}
        buttons={
          disableButtons
            ? undefined
            : [
                {
                  label: createNew ? 'Skapa' : 'Spara',
                  disabled: !formIsDirty || !formIsValid,
                },
                { label: 'Avbryt', onClick: handleClose },
              ]
        }
      >
        {renderContent()}
      </Modal>
    </form>
  );
};

export default UserModal;
