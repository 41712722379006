import { faBuildingLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface Props {
  className?: string;
}

const HQIcon: FC<Props> = ({ className }) => (
  <FontAwesomeIcon
    title="Huvudkontor"
    icon={faBuildingLock}
    className={className}
  />
);

export default HQIcon;
