import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AkCompanyClient, Customer, CustomerUserResponse, WebUser } from 'api';
import ColorDot from 'components/ColorDot';
import ErrorTryAgain from 'components/ErrorTryAgain';
import PageHeader, { PageHeaderButton } from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import {
  ColumnSetting,
  tableCellClassName,
  tableHeadClassName,
} from 'components/Table/utils';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import { FC } from 'react';
import {
  generatePath,
  Outlet,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks';
import { makeStringComparator, makeBooleanComparator } from 'utils/sorting';

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

interface TableRenderProps {
  customersById: Map<number, Customer>;
}

const columnSettings: ColumnSetting<CustomerUserResponse, TableRenderProps>[] =
  [
    {
      head: 'Kundnamn',
      render: (u, { customersById }) => {
        const customer = customersById.get(u.customerId);
        if (!customer) return;
        return (
          <ColorDot color={customer.calendarColor}>{customer.name}</ColorDot>
        );
      },
      width: 200,
      sortFunction: makeStringComparator(
        (u, { customersById }) => customersById.get(u.customerId)?.name ?? ''
      ),
    },
    {
      head: 'Användarnamn',
      render: ({ user }) => user.userName,
      width: 150,
      sortFunction: makeStringComparator(({ user }) => user.userName),
    },
    {
      head: 'Namn',
      render: ({ user }) => user.fullName,
      width: 200,
      sortFunction: makeStringComparator(({ user }) => user.fullName),
    },
    {
      head: 'Telefonnummer',
      render: ({ user }) => user.phoneNumber,
      width: 150,
      sortFunction: makeStringComparator(({ user }) => user.phoneNumber),
    },
    {
      head: 'E-post',
      render: ({ user }) => user.email,
      width: 200,
      sortFunction: makeStringComparator(({ user }) => user.email),
    },
    {
      head: 'E-post bekräftad',
      render: ({ user }) => (user.emailConfirmed ? 'Ja' : 'Nej'),
      width: 150,
      sortFunction: makeBooleanComparator(({ user }) => user.emailConfirmed),
    },
    {
      head: 'Deaktiverad',
      render: ({ user }) => (user.deactivated ? 'Ja' : 'Nej'),
      width: 100,
      sortFunction: makeBooleanComparator(({ user }) => user.deactivated),
    },
  ];

const ManageCustomerUsersPage: FC = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const navigate = useNavigate();
  const { customersById } = usePricingSetup();

  const customerUsersResponse = useResponse(
    AkCompanyClient,
    async (c) =>
      selectedAkCompanyId !== null
        ? await c.getAllAkCompanyCustomerUsers(selectedAkCompanyId)
        : [],
    [selectedAkCompanyId]
  );

  const handleCreateNewUserClick = () => {
    navigate(
      generatePath(Routes.settings.manageCustomerUsers.user, { id: 'ny' })
    );
  };

  const renderContent = () => {
    switch (customerUsersResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched: {
        if (!customerUsersResponse.response) return;

        return (
          <MyTable
            columnSettings={columnSettings}
            rows={customerUsersResponse.response}
            onRowClick={({ user }) =>
              navigate(
                generatePath(Routes.settings.manageCustomerUsers.user, {
                  id: String(user.id),
                })
              )
            }
            renderProps={{ customersById }}
          />
        );
      }

      case RequestStatus.Error:
        return (
          <ErrorTryAgain onTryAgain={() => customerUsersResponse.update()} />
        );
    }
  };

  return (
    <>
      <PageHeader title="Kundanvändare">
        <PageHeaderButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          small
          onClick={handleCreateNewUserClick}
        >
          Lägg till
        </PageHeaderButton>
      </PageHeader>

      {renderContent()}

      <Outlet context={() => customerUsersResponse.update()} />
    </>
  );
};

export const useOnUserSaved = () =>
  useOutletContext<(savedUser: WebUser) => void>();

export default ManageCustomerUsersPage;
