import { FC } from 'react';
import { Option } from 'components/inputs/OptionInput';
import useVehicles from 'contexts/useVehicles';
import { FormOptionInput } from '../../components';

interface Props {
  value: string;
  onChange(value: string): void;
  disabled?: boolean;
  noEdit?: boolean;
}

const VehicleInput: FC<Props> = ({ value, onChange, disabled, noEdit }) => {
  const vehicles = useVehicles();

  return (
    <FormOptionInput
      value={value}
      disabled={disabled}
      noEdit={!!noEdit}
      onChange={onChange}
      onOptionClicked={(opt) => {
        onChange(opt.regNr);
      }}
      onClear={() => onChange('')}
      options={vehicles.map((v) => ({
        label: v.regNr,
        regNr: v.regNr,
        vehicleType: v.vehicleType,
      }))}
      searchFilter={(search, opts) =>
        opts.filter((o) => o.label.toLowerCase().includes(search.toLowerCase()))
      }
      optionRender={(key, opt, onClick) => (
        <Option
          key={key}
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          {opt.regNr} - <i>{opt.vehicleType}</i>
        </Option>
      )}
    />
  );
};

export default VehicleInput;
