import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResetPasswordRequest, UserClient } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import SectionHeader from 'components/SectionHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import { useCallback, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { validPasswordDescription, validPasswordRegex } from 'utils/regex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  gap: 20px;

  button {
    margin-left: auto;
  }
`;

const ErrorMsg = styled.p`
  color: red;
`;

const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userIdParam = searchParams.get('userId');
  const resetTokenParam = searchParams.get('resetPasswordToken');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetPasswordCall = useApiCall(
    UserClient,
    (c, userId: number, resetPasswordToken: string, newPassword: string) =>
      c.resetPassword(
        new ResetPasswordRequest({ newPassword, resetPasswordToken, userId })
      )
  );

  const handleSubmit = useCallback(async () => {
    const userId = Number(userIdParam);

    if (newPassword !== confirmPassword || isNaN(userId) || !resetTokenParam) {
      return;
    }

    await resetPasswordCall.run(
      userId,
      window.atob(resetTokenParam),
      newPassword
    );
  }, [
    confirmPassword,
    userIdParam,
    newPassword,
    resetPasswordCall,
    resetTokenParam,
  ]);

  if (!userIdParam || !resetTokenParam) {
    return <Navigate replace to={Routes.index} />;
  }

  const renderContent = () => {
    switch (resetPasswordCall.status) {
      case RequestStatus.Idle:
      case RequestStatus.Error:
        const isPasswordValid =
          newPassword === confirmPassword &&
          newPassword.match(validPasswordRegex);

        return (
          <>
            <SectionHeader title="Välj ett nytt lösenord" />

            <p>{validPasswordDescription}</p>
            <Input
              onChange={(e) => setNewPassword(e.currentTarget.value)}
              placeholder="Nytt lösenord"
              type="password"
              value={newPassword}
            />
            <Input
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
              placeholder="Bekräfta lösenordet"
              type="password"
              value={confirmPassword}
            />

            <Button disabled={!isPasswordValid} onClick={() => handleSubmit()}>
              Byt lösenord
            </Button>

            {resetPasswordCall.status === RequestStatus.Error && (
              <ErrorMsg>Något gick fel, försök igen.</ErrorMsg>
            )}
          </>
        );

      case RequestStatus.Fetching:
        return <LoadingSection>Uppdaterar lösenord...</LoadingSection>;

      case RequestStatus.Fetched:
        if (!resetPasswordCall.response?.success) {
          return (
            <>
              <SectionHeader title="Felaktig länk" />
              <p>
                Det verkar som att du angivit en felaktig länk eller så har den
                här länken redan använts tidigare. Vänligen försök skriva in
                länken korrekt, eller skicka en ny återställningslänk till din
                epost genom att{' '}
                <Link to={Routes.account.forgotPassword}>
                  återställa ditt lösenord
                </Link>
                .
              </p>
            </>
          );
        }
        return (
          <>
            <SectionHeader title="Lösenord återställt" />
            <p>Det går nu att använda ditt nya lösenord för att logga in.</p>
            <Button
              onClick={() => {
                navigate(Routes.account.login.customerUser, { replace: true });
              }}
            >
              Logga in <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </>
        );
    }
  };

  return <Container>{renderContent()}</Container>;
};

export default ResetPasswordPage;
