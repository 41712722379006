import { FC, PropsWithChildren, useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Routes from '../../constants/routes';
import Theme from '../../constants/theme';
import SupportInfoSpeechBubble, {
  arrowSize,
  MobileSupportInfo,
} from './SupportInfo';
import useAccountInfo from 'contexts/useAccountInfo';
import AkCompanySelector from './AkCompanySelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears, faMoon, faPlus } from '@fortawesome/free-solid-svg-icons';
import MediaQuery from 'constants/MediaQuery';
import { ReactComponent as XmarkImg } from 'assets/xmark.svg';
import { ReactComponent as BarsImg } from 'assets/bars.svg';
import DarkModeContextMenu from './DarkModeContextMenu';
import {
  hasAccessToRoute,
  ValidRouteString,
} from 'constants/routeConfiguration';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import useSelectedCustomerId from 'contexts/useSelectedCustomer';
import usePricingSetup from 'contexts/usePricingSetup';
import useSelectableAkCompanies from 'utils/useSelectableAkCompanies';

const arrowWidth = arrowSize * 2;
const Header = styled.header`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  min-height: 60px;

  ${MediaQuery.tablet} {
    min-height: ${95 + arrowWidth}px;
    padding-bottom: ${arrowWidth}px;
    margin-bottom: -${arrowWidth}px;
  }
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: baseline;

  padding: 10px ${Theme.sizes.padding.screenInset};
  border-top: 4px solid ${Theme.colors.bg.accent1};
  border-bottom: 2px solid ${Theme.colors.border.main};

  ${MediaQuery.tablet} {
    padding: 0px ${Theme.sizes.padding.screenInset};
    padding-right: calc(10px + ${Theme.sizes.padding.screenInset});
  }
`;

const LogoImgLink = styled(NavLink)`
  ${MediaQuery.tablet} {
    margin: 20px 0;
  }
`;

const LogoImg = styled.div`
  width: 250px;
  height: 33.36px;
  background-image: ${Theme.images.logo};
  background-size: contain;
  background-repeat: no-repeat;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const OpacityBg = styled.div`
  position: fixed;
  inset: 0;
  background-color: ${Theme.colors.bg.opacityOverlay};
  z-index: 5;
  animation: ${fadeIn} 0.1s;

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const MenuPane = styled.div<{ open: boolean }>`
  position: fixed;
  inset: 0;
  left: auto;
  right: -100vw;
  z-index: 5;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100vw;
  max-width: 500px;
  height: 100vh;

  background-color: ${Theme.colors.bg.accent1};
  color: ${Theme.colors.fg.accent1};
  transition: right 0.2s;

  ${({ open }) =>
    open &&
    css`
      right: 0;
    `}

  ${MediaQuery.tablet} {
    display: contents;
    color: inherit;
  }
`;

const MenuButtonBase = styled.button`
  border: none;
  background-color: transparent;
  color: inherit;

  svg {
    width: 20px;
    height: 20px;
  }

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const OpenMenuButton = styled(MenuButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: -10px;
  align-self: center;
  margin-left: auto;

  ${MediaQuery.tablet} {
    display: none;
  }
`;

const CloseMenuButton = styled(MenuButtonBase)`
  position: absolute;
  top: 20px;
  right: ${Theme.sizes.padding.screenInset};
  padding: 10px;
  margin: -5px -10px;
`;

const NavLinks = styled.ul`
  margin: 20px ${Theme.sizes.padding.screenInset};
  padding: 0;

  li {
    display: flex;
    padding: 15px 5px;
    list-style: none;
    border-top: 1px solid currentColor;

    &:last-child {
      border-bottom: 1px solid currentColor;
    }

    a {
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
      white-space: nowrap;

      &.active {
        font-weight: bold;
      }
    }
  }

  ${MediaQuery.tablet} {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: max-content;
    padding: 10px 20px;
    padding-right: 25px;
    margin: 0;
    overflow: auto;

    li,
    li:last-child {
      padding: 0;
      border: none;

      a {
        transition: border-bottom-color 0.15s;
        border-bottom: 2px solid transparent;
        text-transform: none;

        &.active {
          border-bottom-color: ${Theme.colors.bg.accent1};
          font-weight: 600;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      background: ${Theme.colors.bg.background2};
      border-radius: 1000px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${Theme.colors.bg.background4};
      border-radius: 1000px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${Theme.colors.bg.background3};
    }
  }

  ${MediaQuery.desktop} {
    gap: 40px;
    padding: 10px 40px;
    padding-right: 25px;
    margin: 0;
  }
`;

const MyAkCompanySelector = styled(AkCompanySelector)`
  select {
    width: 200px;
    max-width: 200px;
  }
`;

const AccountControls = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 20px;
  padding: 20px ${Theme.sizes.padding.screenInset};

  ${MediaQuery.tablet} {
    grid-template-columns: auto auto auto;
    padding: 20px 0;
    flex-direction: row;
    align-items: center;
  }
`;

const UserInfo = styled.div`
  grid-column: 1/3;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  ${MediaQuery.tablet} {
    grid-column: 3/4;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: -10px;
    margin-bottom: -5px;
  }
`;

const NamesWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  ${MediaQuery.tablet} {
    align-items: flex-end;
  }
`;

const UserName = styled.span`
  font-weight: 700;
`;

const CustomerName = styled.span`
  font-weight: 700;
  font-size: ${Theme.sizes.font.medium};

  ${MediaQuery.tablet} {
    position: static;
    font-size: ${Theme.sizes.font.small};
  }
`;

const TextButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const DarkModeButton = styled(TextButton)`
  margin-right: auto;
  padding: 5px 10px;
  font-size: 20px;

  ${MediaQuery.tablet} {
    grid-column: 1/2;
    grid-row: 1/2;
  }
`;

interface MenuItemProps extends PropsWithChildren {
  to: ValidRouteString;
  title?: string;
  className?: string;
  onClick(): void;
}
const MenuItem: FC<MenuItemProps> = ({
  to,
  title,
  children,
  onClick,
  className,
}) => {
  const userClaims = useUserWebClaims();

  if (!hasAccessToRoute(to, userClaims)) return null;

  return (
    <li className={className}>
      <NavLink
        onClick={onClick}
        to={to}
        title={title}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        {children}
      </NavLink>
    </li>
  );
};

const AdminMenuItem = styled(MenuItem)`
  & a::after {
    margin-left: 10px;
    content: 'Inställningar';
    text-transform: uppercase;
  }

  ${MediaQuery.tablet} {
    position: relative;
    bottom: 5px;
    margin-left: auto;
    font-size: 20px;

    & a::after {
      content: none;
    }

    a {
      padding-bottom: 0;
    }
  }
`;

export const AppHeader: FC = () => {
  const { customersById } = usePricingSetup();
  const { onLogOut, isLoggedIn, accountInfo } = useAccountInfo();
  const selectedCustomerId = useSelectedCustomerId();
  const selectableAkCompanies = useSelectableAkCompanies();

  const isCaseRoute = useMatch(Routes.cases.case);

  const [menuOpen, setMenuOpen] = useState(false);

  const close = () => setMenuOpen(false);

  const hasMenuPaneOptions = isLoggedIn;

  return (
    <Header>
      <Main>
        <LogoImgLink to={Routes.index}>
          <LogoImg />
        </LogoImgLink>

        {menuOpen && <OpacityBg onClick={close} />}

        <MenuPane open={menuOpen}>
          <CloseMenuButton onClick={close}>
            <XmarkImg />
          </CloseMenuButton>

          <MobileSupportInfo />

          <NavLinks>
            <MenuItem onClick={close} to={Routes.index}>
              Hem
            </MenuItem>
            <MenuItem onClick={close} to={Routes.cases.index}>
              Ärenden
            </MenuItem>
            <MenuItem onClick={close} to={Routes.plannerTool.index}>
              Planering
            </MenuItem>
            <MenuItem onClick={close} to={Routes.invoices.index}>
              Fakturering
            </MenuItem>
            <MenuItem onClick={close} to={Routes.invoiceQueue.index}>
              Fakturakö
            </MenuItem>

            <MenuItem
              onClick={close}
              to={Routes.customerPortal.bookedCases.index}
            >
              Bokade ärenden
            </MenuItem>
            <MenuItem onClick={close} to={Routes.customerPortal.createCase}>
              <FontAwesomeIcon icon={faPlus} /> Skapa ärende
            </MenuItem>

            <AdminMenuItem
              onClick={close}
              to={Routes.settings.index}
              title="Inställningar"
            >
              <FontAwesomeIcon icon={faGears} />
            </AdminMenuItem>
          </NavLinks>

          <AccountControls>
            <MyAkCompanySelector disabled={!!isCaseRoute} />

            <DarkModeContextMenu>
              <DarkModeButton>
                <FontAwesomeIcon icon={faMoon} />
              </DarkModeButton>
            </DarkModeContextMenu>

            {isLoggedIn && (
              <UserInfo
                style={
                  selectableAkCompanies.length
                    ? undefined
                    : { alignItems: 'flex-end' }
                }
              >
                <TextButton
                  onClick={() => {
                    onLogOut();
                    close();
                  }}
                >
                  Logga ut
                </TextButton>

                <NamesWrapper>
                  <UserName>{accountInfo.user?.fullName}</UserName>

                  {selectedCustomerId !== null && (
                    <CustomerName>
                      {customersById.get(selectedCustomerId)?.name}
                    </CustomerName>
                  )}
                </NamesWrapper>
              </UserInfo>
            )}
          </AccountControls>
        </MenuPane>

        {hasMenuPaneOptions && (
          <OpenMenuButton onClick={() => setMenuOpen(true)}>
            <BarsImg />
          </OpenMenuButton>
        )}
      </Main>
      <SupportInfoSpeechBubble />
    </Header>
  );
};
