import { overwritesPhoneSmallTheme } from './utils';

const desktopTheme = overwritesPhoneSmallTheme({
  sizes: {
    padding: { screenInset: 20 },
    font: {
      huge: '40px',
      large: '30px',
      medium: '20px',
      small: '15px',
    },
  } as const,
});

export default desktopTheme;
