import { Case, CaseJobSearchResult, JobStatus } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import DateSpanPicker from 'components/inputs/DateSpanPicker';
import PageHeader from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { RequestStatus } from 'swaggerhooks';
import { useJobPageContext } from '..';
import JobViewLinks from '../TimeNavigatorHeader/JobViewLinks';
import useCaseJobColumnSettings, {
  useCaseJobTableRenderProps,
} from './useCaseJobColumnSettings';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const MyDateSpanPicker = styled(DateSpanPicker)`
  grid-column: 1/-1;
  grid-row: 3/4;

  ${MediaQuery.phone} {
    margin: 10px 0 5px;
  }
`;

const MyJobViewLinks = styled(JobViewLinks)`
  grid-column: 1/-1;
  grid-row: 2/3;
`;

function makeJobRowClassName(jobId: number) {
  return `case-job-${jobId}`;
}

const TableStyler = styled.div<{ queuedEdits: Map<number, any> }>`
  display: contents;

  ${({ queuedEdits }) => {
    const ids = Array.from(queuedEdits.keys());
    return ids.map(
      (id) => css`
        .${makeJobRowClassName(id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    );
  }}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${Theme.colors.bg.background1};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const JobListPage: React.FC = () => {
  const {
    from,
    to,
    isDebouncingTimespan,
    searchRequest,
    searchStatus,
    setTimespan,
    handleEventClick,
  } = useJobPageContext();

  const caseJobsById = useMemo(() => {
    return new Map(searchRequest.response?.map((cj) => [cj.caseJob!.id, cj]));
  }, [searchRequest.response]);

  const columnSettings = useCaseJobColumnSettings();
  const { renderProps: tableRenderProps, queuedEdits } =
    useCaseJobTableRenderProps(caseJobsById, (updatedCaseJob) =>
      searchRequest.update((response) =>
        response?.map((cj) =>
          cj.caseJob.id === updatedCaseJob.id
            ? new CaseJobSearchResult({
                ...cj,
                case: new Case({
                  ...cj.case,
                  caseJobs: cj.case.caseJobs.map((job) =>
                    job.id === updatedCaseJob.id ? updatedCaseJob : job
                  ),
                }),
                caseJob: updatedCaseJob,
              })
            : cj
        )
      )
    );

  const renderContent = () => {
    switch (searchStatus) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
      case RequestStatus.Fetched:
        if (searchRequest.response === undefined) return <LoadingSection />;

        return (
          <TableStyler queuedEdits={queuedEdits}>
            <MyTable
              columnSettings={columnSettings}
              renderProps={tableRenderProps}
              rows={searchRequest.response ?? []}
              onRowClick={(jr) =>
                handleEventClick({
                  from: jr.caseJob.orderedStartUtc,
                  to: jr.caseJob.orderedEndUtc,
                  caseId: jr.case.id,
                  caseJobId: jr.caseJob.id,
                  driverName: jr.caseJob.driverName,
                  regNr: jr.caseJob.vehicleRegNr,
                  customerId: jr.case.customerId,
                  isRemoved: [
                    JobStatus.Discarded,
                    JobStatus.LateCancel,
                  ].includes(jr.caseJob.jobStatus),
                  regnrIntersectsWith: null,
                  driverIntersectsWith: null,
                })
              }
              rowClassName={(cj) => makeJobRowClassName(cj.caseJob.id)}
            />
          </TableStyler>
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => searchRequest.update()} />;
    }
  };

  return (
    <Wrapper>
      <PageHeader
        title="Jobb"
        leftAlignActions
        loading={
          isDebouncingTimespan ||
          searchRequest.status === RequestStatus.Fetching ||
          queuedEdits.size > 0
        }
      >
        <MyDateSpanPicker from={from} to={to} onSpanPicked={setTimespan} />

        <MyJobViewLinks selectedDate={from} />
      </PageHeader>
      {renderContent()}
    </Wrapper>
  );
};

export default JobListPage;
