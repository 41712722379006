import { DateSpan, datespansIntersects } from 'components/Calendar/utils';
import { useMemo } from 'react';
import { groupItems } from 'utils/array';
import { makeStringComparator } from 'utils/sorting';
import { LaneGroupPreset } from '.';

export const EventWithoutLane = Symbol(
  'Each event grouped by this symbol will get their own lane'
);
export type LaneLabelMaker<E> = (event: E) => string | typeof EventWithoutLane;
export type LaneGroupLabelMaker<E> = (event: E) => string;

export interface EventLaneGroup<E extends DateSpan> {
  groupLabel: string;
  eventsWithoutLane: E[];
  lanes: [laneLabel: string, events: E[]][];
}

const useGroupedEventLanes = <E extends DateSpan>(
  events: E[],
  from: Date,
  to: Date,

  makeLaneLabel: LaneLabelMaker<E>,
  makeLaneGroupLabel: LaneGroupLabelMaker<E>,
  groupPresets: LaneGroupPreset[]
): EventLaneGroup<E>[] => {
  return useMemo(() => {
    const thisWeeksEvents = events.filter((event) =>
      datespansIntersects(from, to, event.from, event.to)
    );

    const eventsByGroupLabel = Object.entries(
      groupItems(thisWeeksEvents, makeLaneGroupLabel)
    );

    const eventsByLaneLabelByGroupLabel = new Map(
      eventsByGroupLabel.map(([groupLabel, events]) => {
        const eventsByLaneLabelObj = groupItems(events, makeLaneLabel);

        const eventsByLaneLabelMap: Map<string | typeof EventWithoutLane, E[]> =
          new Map(Object.entries(eventsByLaneLabelObj));

        // Object.entries doesn't copy any Symbols
        eventsByLaneLabelMap.set(
          EventWithoutLane,
          eventsByLaneLabelObj[EventWithoutLane]
        );

        return [groupLabel, eventsByLaneLabelMap] as const;
      })
    );

    // Go through all groupPresets and add empty data structures so that the presets will be shown.
    groupPresets.forEach((preset) => {
      const eventsByLabels =
        eventsByLaneLabelByGroupLabel.get(preset.laneGroupLabel) ??
        new Map<string, E[]>();

      preset.laneLabels.forEach((eventLabel) => {
        eventsByLabels.set(eventLabel, eventsByLabels.get(eventLabel) ?? []);
      });

      eventsByLaneLabelByGroupLabel.set(preset.laneGroupLabel, eventsByLabels);
    });

    // Return Map:s as sorted arrays (easier to loop through)
    return Array.from(eventsByLaneLabelByGroupLabel.entries())
      .map(
        ([groupLabel, eventsByLaneLabel]): EventLaneGroup<E> => ({
          groupLabel,
          eventsWithoutLane: eventsByLaneLabel.get(EventWithoutLane) ?? [],
          lanes: Array.from(eventsByLaneLabel.entries())
            .filter(
              (entry): entry is [string, E[]] => entry[0] !== EventWithoutLane
            )
            .sort(makeStringComparator(([laneLabel]) => laneLabel)),
        })
      )
      .sort(makeStringComparator(({ groupLabel }) => groupLabel));
  }, [events, from, makeLaneGroupLabel, groupPresets, makeLaneLabel, to]);
};

export default useGroupedEventLanes;
