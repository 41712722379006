export const DeviceMinWidths = {
  tablet: 821,
  desktop: 1200,
} as const;

const MediaQuery = {
  /** OBS: make phone styling the default and override it with tablet or desktop media queries */
  phone: `@media only screen and (max-width: ${DeviceMinWidths.tablet}px)`,
  tablet: `@media only screen and (min-width: ${DeviceMinWidths.tablet}px)`,
  desktop: `@media only screen and (min-width: ${DeviceMinWidths.desktop}px)`,
} as const;

export default MediaQuery;
