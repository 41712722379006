import { AkCompanyRole } from 'api';
import WebRoleIds from 'constants/RoleIds';
import { selector, useRecoilValue } from 'recoil';
import { accountInfoAtom } from '.';

export interface UserWebClaims {
  webRoleIds: Set<typeof WebRoleIds[keyof typeof WebRoleIds]>;
  akCompanyRoles: Set<AkCompanyRole>;
  customer: boolean;
}

const userWebClaimsSelector = selector({
  key: 'userWebClaimsSelector',
  get: ({ get }): UserWebClaims => {
    const { user, selectedAkCompanyId } = get(accountInfoAtom);

    return {
      webRoleIds: user ? new Set(user.roles as any) : new Set(),
      akCompanyRoles: user
        ? new Set(
            user.akCompanyMemberships
              .filter((cm) => cm.akCompanyId === selectedAkCompanyId)
              .map((cm) => cm.companyRole)
          )
        : new Set(),
      customer: !!user?.customerMemberships.length,
    };
  },
});

/** Returns the current roles for the user (depending on selected AkCompany etc.) */
const useUserWebClaims = () => {
  return useRecoilValue(userWebClaimsSelector);
};

export default useUserWebClaims;
