import MediaQuery from 'constants/MediaQuery';
import Routes from 'constants/routes';
import Theme from 'constants/theme';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useQueryParams } from 'utils/useQueryParam';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-left: 6px; // because <a> has margin-left: -1px;

  border-radius: 10px;
  background-color: ${Theme.colors.bg.background2};

  ${MediaQuery.tablet} {
    margin-left: auto;
  }

  a,
  a:hover,
  a:active,
  a:visited {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 5px 0;
    padding: 0 15px;

    font-weight: 600;
    color: ${Theme.colors.fg.background2};
    text-decoration: none;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid ${Theme.colors.border.input};
    }

    &.active {
      margin: 0;
      margin-left: -1px;
      padding: 5px 15px;

      color: ${Theme.colors.fg.accent1};
      border: none;
      border-radius: 7px;
      background-color: ${Theme.colors.bg.accent1};
    }
  }
`;

interface Props {
  selectedDate: Date;
  className?: string;
}

const JobViewLinks: FC<Props> = ({ selectedDate, className }) => {
  const queryParams = useQueryParams();

  const withQuery = (path: string) => `${path}?${queryParams.toString()}`;

  return (
    <Wrapper className={className}>
      <NavLink to={withQuery(Routes.plannerTool.monthView)}>Månad</NavLink>
      <NavLink to={withQuery(Routes.plannerTool.weekView)}>Vecka</NavLink>
      <NavLink to={withQuery(Routes.plannerTool.dayView)}>Dag</NavLink>
      <NavLink to={withQuery(Routes.plannerTool.listView)}>Lista</NavLink>
    </Wrapper>
  );
};

export default JobViewLinks;
