import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ICase } from 'api';
import { CheckBoxButton } from 'components/inputs/PartialCheckbox';
import { CheckBoxCell } from 'components/Table/inputCells';
import { ColumnSetting, tableHeadClassName } from 'components/Table/utils';
import useCaseColumnSettings, {
  CaseTableRenderProps,
} from 'pages/CaseList/useCaseColumnSettings';
import { useMemo } from 'react';
import { css } from 'styled-components';
import { formatDateTime } from 'utils/date';
import {
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';

export interface CreateInvoiceTableRenderProps extends CaseTableRenderProps {
  selectedCaseIds: Set<number>;
  onToggleSelectCase(caseObj: CaseRow): void;
}

export type CaseRow = ICase & { failureReason?: string };

export const useInvocableColumnSettings = (
  onToggleSelection: () => void,
  isAllSelected: boolean,
  isSomeSelected: boolean
): ColumnSetting<CaseRow, CreateInvoiceTableRenderProps>[] => {
  const caseColumnSettings = useCaseColumnSettings();

  return useMemo(
    (): ColumnSetting<CaseRow, CreateInvoiceTableRenderProps>[] => [
      {
        head: (
          <CheckBoxButton
            checked={isAllSelected && isSomeSelected}
            onClick={onToggleSelection}
          >
            {isAllSelected && isSomeSelected && (
              <FontAwesomeIcon icon={faCheck} />
            )}
            {!isAllSelected && isSomeSelected && (
              <FontAwesomeIcon icon={faMinus} />
            )}
          </CheckBoxButton>
        ),
        noPadding: true,
        css: css`
          &.${tableHeadClassName} {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
          }
        `,
        render: (caseObj, { selectedCaseIds, onToggleSelectCase }) => (
          <CheckBoxCell
            checked={selectedCaseIds.has(caseObj.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={() => onToggleSelectCase(caseObj)}
          />
        ),
        width: 50,
      },
      ...caseColumnSettings,
    ],
    [caseColumnSettings, isSomeSelected, isAllSelected, onToggleSelection]
  );
};

export const useNonInvocableColumnSettings = (): ColumnSetting<
  CaseRow,
  CreateInvoiceTableRenderProps
>[] => {
  const caseColumnSettings = useCaseColumnSettings();

  return useMemo(
    (): ColumnSetting<CaseRow, CreateInvoiceTableRenderProps>[] => [
      {
        head: 'Datum',
        render: (c) => formatDateTime(c.caseDateUtc),
        sortFunction: makeDateComparator((c) => c.caseDateUtc),
        width: 150,
      },
      {
        head: 'Ärende',
        render: (c) => c.id,
        sortFunction: makeNumberComparator((c) => c.id),
        width: 80,
      },
      {
        head: 'Kund',
        render: (c, { customersById }) => customersById.get(c.customerId)?.name,
        sortFunction: makeStringComparator(
          (c, { customersById }) => customersById.get(c.customerId)?.name ?? ''
        ),
        width: 200,
      },
      {
        head: 'Felmeddelande',
        render: (r) => r.failureReason,
        width: 400,
      },
    ],
    [caseColumnSettings]
  );
};
