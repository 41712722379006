import { IPriceList, PriceListType } from 'api';
import { useMemo } from 'react';

const usePriceListsGroupedByType = (priceLists: IPriceList[]) =>
  useMemo(() => {
    const internalPriceLists = priceLists.filter(
      (pl) =>
        pl.priceListType === PriceListType.Internal ||
        pl.priceListType === PriceListType.InternalAndExternal
    );
    const externalPriceLists = priceLists.filter(
      (pl) =>
        pl.priceListType === PriceListType.External ||
        pl.priceListType === PriceListType.InternalAndExternal
    );

    return {
      internalPriceLists,
      externalPriceLists,
    };
  }, [priceLists]);

export default usePriceListsGroupedByType;
