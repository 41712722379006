import { UtilClient } from 'api';
import OverlayedSpinner from 'components/spinners/OverlayedSpinner';
import { pollersErrorModalId } from 'constants/AppConstants';
import FrontendBuildCommit from 'constants/FrontendBuildCommit';
import ApiErrorModalIds from 'contexts/ApiCallConfiguration/ApiErrorModalIds';
import useModalStack from 'contexts/useModalStack';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useApiCall } from 'swaggerhooks';
import useUpdateInterval from './useUpdateInterval';

interface Versions {
  apiVersion: string;
}

const versionsAtom = atom<Versions>({
  key: 'versionsAtom',
  default: {
    apiVersion: FrontendBuildCommit,
  },
});

export const RouteReloadsAppVersion: FC<PropsWithChildren> = ({ children }) => {
  const { apiVersion } = useRecoilValue(versionsAtom);
  // Capture value so that the spinner only shows when a new route mounts
  const showShouldReload = useRef(apiVersion !== FrontendBuildCommit);

  // If component got mounted & app should reload, then reload browser
  useEffect(() => {
    if (apiVersion !== FrontendBuildCommit) {
      window.location.reload();
    }
  }, []);

  if (showShouldReload.current)
    return <OverlayedSpinner>Uppdaterar app...</OverlayedSpinner>;

  return <>{children}</>;
};

const useFrontendVersionPoll = () => {
  const setVersions = useSetRecoilState(versionsAtom);
  const modalStack = useModalStack();
  const getApiVersionCall = useApiCall(
    UtilClient,
    (c) => c.getApiVersion(),
    undefined,
    { errorModalId: pollersErrorModalId }
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Frontend build commit: ', FrontendBuildCommit);
  }, []);

  useUpdateInterval(
    async () => {
      const [apiVersion, err] = await getApiVersionCall.run();

      if (!err && apiVersion) {
        setVersions({
          apiVersion,
        });

        modalStack.pop(pollersErrorModalId);
        modalStack.pop(ApiErrorModalIds.ConnectionError);

        if (FrontendBuildCommit !== apiVersion) {
          console.log(
            `New version available: ${apiVersion} navigate anywhere to update`
          );
        }
      }
    },
    10000,
    true
  );
};

export default useFrontendVersionPoll;
