import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Case, CaseMessage, MessageType } from 'api';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import useModalStack from 'contexts/useModalStack';
import { saveMessageFunction } from '../useCaseEditorState';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import AddMessage from './AddMessage';
import Message from './Message';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Scroll = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Empty = styled.i`
  display: block;
  margin: 20px;
  text-align: center;
  opacity: 0.8;
`;

interface Props {
  currentCase?: Case;
  onSaveMessage: saveMessageFunction;
  savingMessage: boolean;
}

const MessagesSection: FC<Props> = ({
  currentCase,
  onSaveMessage,
  savingMessage,
}) => {
  const modalStack = useModalStack();
  const [editMessageId, setEditMessageId] = useState(-1);

  const handleAddMessage = async (message: string, queuedFiles: File[]) => {
    if (!currentCase) return;

    onSaveMessage(
      new CaseMessage({
        id: 0,
        caseId: currentCase.id,
        messageType: MessageType.UserComment,
        text: message,
        caseAttachments: [],
        isRemoved: false,
        timestamp: '',

        // Backend manages these, but I add them anyway
        createdUtc: new Date(),
        modifiedUtc: new Date(),
        createdById: -1,
        modifiedById: -1,
      }),
      queuedFiles
    );
  };

  const handleUpdateMessage = async (
    message: CaseMessage,
    newAttachments: File[]
  ) => {
    await onSaveMessage(message, newAttachments);
    setEditMessageId(-1);
  };

  const handleEditMessageClick = (message: CaseMessage) => {
    setEditMessageId(message.id);
  };

  const handleDeleteMessageClick = (message: CaseMessage) => {
    const modalId = modalStack.push(
      <Modal
        title="Ta bort kommentar?"
        onClose={() => modalStack.pop(modalId)}
        buttons={[
          {
            icon: <FontAwesomeIcon icon={faTrash} />,
            label: 'Ta bort',
            onClick: () => {
              modalStack.pop(modalId);
              onSaveMessage(
                new CaseMessage({
                  ...message,
                  isRemoved: true,
                }),
                []
              );
            },
          },
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(modalId),
          },
        ]}
      >
        Är du säker på att du vill ta bort kommentaren "{message.text}"?
      </Modal>
    );
  };

  const sortedMessages = useMemo(
    () =>
      currentCase?.caseMessages
        .filter((message) => !message.isRemoved)
        .sort((a, b) =>
          a.createdUtc > b.createdUtc ? -1 : a.createdUtc < b.createdUtc ? 1 : 0
        ),
    [currentCase?.caseMessages]
  );

  return (
    <Wrapper>
      <SectionHeader title="Meddelanden" />

      <Scroll>
        {!currentCase && (
          <Empty>Spara ärendet för att kunna lägga till meddelanden</Empty>
        )}
        {currentCase?.caseMessages.length === 0 && (
          <Empty>Inga meddelanden</Empty>
        )}
        {sortedMessages?.map((message) => (
          <Message
            caseMessage={message}
            onEditMessage={handleEditMessageClick}
            onDeleteMessage={handleDeleteMessageClick}
            edit={editMessageId === message.id}
            onSaveMessage={handleUpdateMessage}
            onAbortEdit={() => setEditMessageId(-1)}
            savingMessage={savingMessage}
            key={message.id}
          />
        ))}
      </Scroll>

      <AddMessage
        onAddMessage={handleAddMessage}
        disabled={!currentCase || savingMessage}
        savingMessage={savingMessage}
      />
    </Wrapper>
  );
};

export default MessagesSection;
