import usePricingSetup from 'contexts/usePricingSetup';
import React, { FC } from 'react';
import { safeColor } from 'utils/color';
import { CaseJobEvent } from '../useCaseJobEvents';
import BasicEventLine from 'components/Calendar/BasicEventLine';
import WarningTooltip from 'components/Tooltip/WarningTooltip';

const gradSize = 3;
const gradColor1 = '#888';
const gradColor2 = '#999';
export const removedBackgroundGradient = `repeating-linear-gradient( 45deg, ${gradColor1} 0px, ${gradColor1} ${gradSize}px, ${gradColor2} ${gradSize}px, ${gradColor2} ${
  gradSize * 2
}px)`;
export const tooltipBoundsClassName = 'TooltipBounds';

interface Props {
  containerStart: Date;
  containerEnd: Date;
  from: Date;
  to: Date;
  showDateOnStickyEnd?: boolean;

  jobEvent: CaseJobEvent;
  onClick(caseJob: CaseJobEvent): void;
  selected: boolean;
  jobEventClassName: string;
}

const JobEventLine: FC<Props> = ({
  containerStart,
  containerEnd,
  showDateOnStickyEnd,
  jobEvent,
  onClick,
  selected,
  jobEventClassName,
}) => {
  const { customersById } = usePricingSetup();

  const customer = customersById.get(jobEvent.customerId ?? -1);
  const backgroundColor = safeColor(customer?.calendarColor, 'red');
  const timeHandleColors: React.CSSProperties = jobEvent.isRemoved
    ? { color: '#ddd', backgroundColor: 'transparent' }
    : {
        color: backgroundColor.hex(),
        backgroundColor: backgroundColor.isLight() ? '#000a' : '#fff',
      };

  return (
    <>
      <BasicEventLine
        containerStart={containerStart}
        containerEnd={containerEnd}
        event={jobEvent}
        eventClassName={jobEventClassName}
        from={jobEvent.from}
        to={jobEvent.to}
        onClick={onClick}
        selected={selected}
        showDateOnStickyEnd={showDateOnStickyEnd}
        makeStyles={(jobEvent) => ({
          label: <b>{jobEvent.driverName}</b>,
          backgroundStyle: {
            background: jobEvent.isRemoved
              ? removedBackgroundGradient
              : backgroundColor.hex(),
          },

          timeHandlesStyle: timeHandleColors,
        })}
      />
      {(jobEvent.regnrIntersectsWith || jobEvent.driverIntersectsWith) && (
        <WarningTooltip
          attachToElement={jobEventClassName}
          position="start"
          boundingElement={tooltipBoundsClassName}
          onClick={() => onClick(jobEvent)}
        >
          {jobEvent.regnrIntersectsWith && (
            <div>Fordonsbokning krockar med ett annat jobb.</div>
          )}
          {jobEvent.driverIntersectsWith && (
            <div>
              Bokning av förare <b>{jobEvent.driverName}</b> krockar med ett
              annat jobb.
            </div>
          )}
        </WarningTooltip>
      )}
    </>
  );
};

export default JobEventLine;
