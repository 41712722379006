import { InvoiceType } from 'api';
import { FC } from 'react';
import DownloadInvoices from './DownloadInvoices';

const InternalInvoicesQueuePage: FC = () => {
  return (
    <>
      <DownloadInvoices
        title={'Interna fakturor'}
        invoiceType={InvoiceType.Internal}
      />
    </>
  );
};

export default InternalInvoicesQueuePage;
