import Theme from 'constants/theme';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import TableHead from './TableHead';
import TableRow from './TableRow';
import { ColumnSetting, makeColumnClassName } from './utils';

interface TableWrapperProps {
  columnSettings: ColumnSetting<any, any>[];
  columnWidthAsFlexAmount?: boolean;
}

const TableWrapper = styled.div<TableWrapperProps>`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${({ columnSettings, columnWidthAsFlexAmount }) => {
    const totalColumnWidth = columnSettings.reduce(
      (acc, setting) => acc + setting.width,
      0
    );

    return css`
      // I couldn't get the rows to be as wide as all of its cells in css,
      // so I set the width 'manually' here.
      & > * {
        width: ${totalColumnWidth}px;
      }

      ${columnWidthAsFlexAmount
        ? columnSettings.map(
            (cs, i) => css`
              .${makeColumnClassName(i)} {
                flex: ${cs.width};
                border-right: 1px solid ${Theme.colors.border.main};

                ${cs.css}
              }
            `
          )
        : columnSettings.map(
            (cs, i) => css`
              .${makeColumnClassName(i)} {
                flex-basis: ${(cs.width / totalColumnWidth) * 100}%;
                box-sizing: border-box;
                min-width: ${cs.width}px;
                border-right: 1px solid ${Theme.colors.border.main};

                ${cs.css}
              }
            `
          )}
    `;
  }}
`;

export interface SortState {
  ascending: boolean;
  sortedColumnIndex: number;
}

interface Props<Row extends Object, RenderProps extends Object> {
  columnSettings: ColumnSetting<Row, RenderProps>[];
  renderProps?: RenderProps;
  rows: Row[];
  initialSortState?: SortState;
  useColumnWidthAsFlexAmount?: boolean;
  onRowClick?(row: Row): void;
  onSortStateChange?(sortState: SortState): void;
  /** creates the className for the row, also used for row component key */
  rowClassName?: (row: Row) => string;
  className?: string;
}

const Table = <Row extends Object, RenderProps extends Object>({
  columnSettings,
  renderProps,
  rows,
  initialSortState,
  useColumnWidthAsFlexAmount,
  onRowClick,
  onSortStateChange,
  rowClassName,
  className,
}: Props<Row, RenderProps>) => {
  const [sortState, setSortState] = useState<SortState>(
    initialSortState ?? {
      ascending: true,
      sortedColumnIndex: 0,
    }
  );
  const onSortStateChangeRef = useRef(onSortStateChange);
  onSortStateChangeRef.current = onSortStateChange;

  const handleToggleSortedColumn = useCallback((columnIndex: number) => {
    setSortState((s) => {
      let newState = { ...s, sortedColumnIndex: columnIndex };

      if (columnIndex === s.sortedColumnIndex) {
        newState = { ...s, ascending: !s.ascending };
      }

      onSortStateChangeRef.current?.(newState);
      return newState;
    });
  }, []);

  const sortedRows = useMemo(() => {
    const sortFunction =
      columnSettings[sortState.sortedColumnIndex]?.sortFunction;
    const ascending = sortState.ascending ? 1 : -1;
    if (sortFunction) {
      const theSortedRows = [...rows].sort(
        (a, b) => sortFunction(a, b, renderProps!) * ascending
      );
      return theSortedRows;
    }
    return rows;
  }, [
    columnSettings,
    renderProps,
    rows,
    sortState.ascending,
    sortState.sortedColumnIndex,
  ]);

  return (
    <TableWrapper
      className={className}
      columnSettings={columnSettings}
      columnWidthAsFlexAmount={useColumnWidthAsFlexAmount}
    >
      <TableHead
        columnSettings={columnSettings}
        sortAscending={sortState.ascending}
        sortedColumnIndex={sortState.sortedColumnIndex}
        toggleSort={handleToggleSortedColumn}
      />

      {sortedRows.map((row, i) => (
        <TableRow<Row, RenderProps>
          className={rowClassName?.(row)}
          columnSettings={columnSettings}
          key={rowClassName ? rowClassName(row) : i}
          onClick={onRowClick}
          renderProps={renderProps}
          row={row}
        />
      ))}
    </TableWrapper>
  );
};

export default Table;
