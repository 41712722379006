import Theme from 'constants/theme';
import styled from 'styled-components';
import { inputStyle } from './Input';

const Select = styled.select`
  ${inputStyle}
  min-width: 0;
  padding-right: 40px;

  appearance: none;
  background-image: ${Theme.images.selectArrow};
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: right;
`;

export default Select;
