import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomerCaseJob, JobStatus, JobType } from 'api';
import Button from 'components/inputs/Button';
import React, { FC, useRef } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { getVerticalScrollParent } from 'utils/dom';
import { CustomerCaseFormInputs } from '../formUtils/caseFormConverters';
import { createCaseJobForm } from '../formUtils/caseJobFormConverters';
import CaseJobFields from './CustomerCaseJobFields';

const Wrapper = styled.div`
  padding-bottom: 40px;
`;

const AddButton = styled(Button)`
  margin: 20px 10px;
`;

interface Props {
  form: UseFormReturn<CustomerCaseFormInputs>;
  highlightCaseJobIndex?: number;
}

const CaseJobs: FC<Props> = ({ form, highlightCaseJobIndex }) => {
  const { control: formControl, register, watch, getValues } = form;

  const { fields, append, remove } = useFieldArray({
    control: formControl,
    name: 'caseJobs',
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const makeJobClassName = (jobIndex: number) => `case-job-${jobIndex}`;

  const handleAppendCaseJob = (eve: React.MouseEvent) => {
    eve.preventDefault();

    let customerCaseJob;

    if (fields.length > 0) {
      const from = getValues(`caseJobs.${fields.length - 1}.orderedStartUtc`);
      const to = getValues(`caseJobs.${fields.length - 1}.orderedEndUtc`);

      customerCaseJob = new CustomerCaseJob({
        id: fields.length,
        jobType: JobType.TMA,
        jobStatus: JobStatus.Normal,
        orderedStartUtc: new Date(from),
        orderedEndUtc: new Date(to),
      });
    }

    append(createCaseJobForm(customerCaseJob), { shouldFocus: true });

    // Scroll down to bottom after the next render.
    setTimeout(() => {
      const scrollParent = getVerticalScrollParent(wrapperRef.current);
      scrollParent?.scrollTo({
        top: scrollParent.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  return (
    <Wrapper ref={wrapperRef}>
      {fields.map((field, formIndex) => (
        <CaseJobFields
          key={field.id}
          defaultValues={field}
          register={(name, options) =>
            register(`caseJobs.${formIndex}.${name}`, options)
          }
          watch={watch}
          getValues={getValues}
          onDelete={() => remove(formIndex)}
          caseJobFormIndex={formIndex}
          formControl={formControl}
          highlighted={highlightCaseJobIndex === formIndex}
          className={makeJobClassName(formIndex)}
        />
      ))}

      <AddButton
        onClick={handleAppendCaseJob}
        icon={<FontAwesomeIcon icon={faPlus} />}
      >
        Lägg till jobb
      </AddButton>
    </Wrapper>
  );
};

export default CaseJobs;
