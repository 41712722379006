import Theme from 'constants/theme';
import { FC, PropsWithChildren, useLayoutEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

export const tooltipCSSVariables = {
  border: '--tooltip-border',
  background: '--tooltip-background',
  foreground: '--tooltip-foreground',
};

const attachesRightClassName = 'tooltip-attaches-right';
const attachesTopClassName = 'tooltip-attaches-top';

const TooltipBody = styled.div`
  position: fixed;
  z-index: 1;
  padding: 2px 5px;

  border: 1px solid
    var(${tooltipCSSVariables.border}, ${Theme.colors.border.main});
  border-radius: 3px;
  background-color: var(
    ${tooltipCSSVariables.background},
    ${Theme.colors.bg.background2}
  );
  color: var(${tooltipCSSVariables.foreground}, ${Theme.colors.fg.background2});
  filter: drop-shadow(1px 1px 1px #0008) drop-shadow(0px 5px 5px #0005);
  transition: box-shadow 0.1s;
  transform: translate(-5px, 5px);
  user-select: none;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;

    border: 5px solid transparent;
    border-bottom-color: var(
      ${tooltipCSSVariables.border},
      ${Theme.colors.border.main}
    );
    border-top-width: 0;
  }

  &.${attachesRightClassName} {
    transform: translate(5px, 5px);

    &:before {
      left: auto;
      right: 0;
    }
  }

  &.${attachesTopClassName} {
    transform: translate(-5px, -5px);

    &:before {
      top: 100%;
      bottom: auto;

      border: 5px solid transparent;
      border-top-color: var(
        ${tooltipCSSVariables.border},
        ${Theme.colors.border.main}
      );
      border-bottom-width: 0;
    }
  }

  &.${attachesRightClassName}.${attachesTopClassName} {
    transform: translate(5px, -5px);
  }
`;

export interface Props {
  /** class name of element to attach to */
  attachToElement: string;
  position: 'start' | 'end' | 'center';

  /**
   * class name of element that this tooltip should stay inside of.
   * (when its body is scrolling for example)
   */
  boundingElement?: string;

  onClick?(): void;
  className?: string;
}

const Tooltip: FC<PropsWithChildren<Props>> = ({
  attachToElement,
  position,
  boundingElement,
  onClick,
  className,
  children,
}) => {
  const tooltipBodyRef = useRef<HTMLDivElement>(null);

  // Automatically update Tooltip's position on scroll, window resize etc.
  useLayoutEffect(() => {
    const matchingElements = document.getElementsByClassName(attachToElement);

    if (matchingElements.length === 0) return;

    let topAttachToElement = matchingElements.item(0)!;

    // Find the top element to attach to
    for (let i = 0; i < matchingElements.length; i++) {
      if (topAttachToElement.clientTop < matchingElements.item(i)!.clientTop) {
        topAttachToElement = matchingElements.item(i)!;
      }
    }

    const updatePosition = () => {
      requestAnimationFrame(() => {
        if (!topAttachToElement || !tooltipBodyRef.current) return;

        const attachToRect = topAttachToElement.getBoundingClientRect();

        const tooltipBoundingElement = boundingElement
          ? document.getElementsByClassName(boundingElement).item(0) ??
            document.body
          : document.body;
        const tooltipBoundingRect =
          tooltipBoundingElement.getBoundingClientRect();

        tooltipBodyRef.current.style.top = `${attachToRect.bottom}px`;

        const posLeft = Math.max(
          tooltipBoundingRect.left,
          Math.min(
            position === 'start'
              ? attachToRect.left
              : position === 'end'
              ? attachToRect.right
              : attachToRect.left + attachToRect.width / 2,
            tooltipBoundingRect.right
          )
        );

        const attachToTop = attachToRect.top > window.innerHeight / 2;
        const posTop = Math.max(
          tooltipBoundingRect.top,
          Math.min(
            attachToTop ? attachToRect.top : attachToRect.bottom,
            tooltipBoundingRect.bottom
          )
        );

        // If tooltip attaches at the right side of screen, then it is attached by the tooltip's right side.
        if (posLeft > window.innerWidth / 2) {
          tooltipBodyRef.current.style.left = '';
          tooltipBodyRef.current.style.right = `${
            window.innerWidth - posLeft
          }px`;
          tooltipBodyRef.current.classList.add(attachesRightClassName);
        } else {
          tooltipBodyRef.current.style.left = `${posLeft}px`;
          tooltipBodyRef.current.style.right = '';
          tooltipBodyRef.current.classList.remove(attachesRightClassName);
        }

        if (attachToTop) {
          tooltipBodyRef.current.style.top = '';
          tooltipBodyRef.current.style.bottom = `${
            window.innerHeight - posTop
          }px`;
          tooltipBodyRef.current.classList.add(attachesTopClassName);
        } else {
          tooltipBodyRef.current.style.top = `${posTop}px`;
          tooltipBodyRef.current.style.bottom = '';
          tooltipBodyRef.current.classList.remove(attachesTopClassName);
        }
      });
    };

    document.addEventListener('scroll', updatePosition, true);
    window.addEventListener('resize', updatePosition);

    const resizeObserver = new ResizeObserver(updatePosition);
    resizeObserver.observe(topAttachToElement);

    return () => {
      document.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('resize', updatePosition);
      resizeObserver.disconnect();
    };
  }, []);

  return ReactDOM.createPortal(
    <TooltipBody
      className={className}
      onMouseDown={(eve) => eve.stopPropagation()}
      onClick={onClick}
      ref={tooltipBodyRef}
    >
      {children}
    </TooltipBody>,
    document.body
  );
};

export default Tooltip;
