import Checkbox from 'components/inputs/Checkbox';
import ColorInput from 'components/inputs/ColorInput';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import MediaQuery from 'constants/MediaQuery';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import { CustomerForm } from './customerFormConverters';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;

  ${MediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const MyInput = styled(Input)`
  min-width: 0;
  width: 100%;
`;

const LeftLabel = styled(LabelWrap)`
  margin-right: auto;
`;

const MyColorInput = styled(ColorInput)`
  margin: auto 0;
`;

const MyCheckbox = styled(Checkbox)`
  margin: auto 0;
`;

interface Props {
  form: UseFormReturn<CustomerForm>;
  editable: boolean;
}

const CustomerFormFields: FC<Props> = ({ form, editable }) => {
  const { register } = form;

  const mkInput = (
    name: keyof CustomerForm,
    label: string,
    type?: React.HTMLInputTypeAttribute,
    disabled?: boolean
  ) => (
    <LabelWrap label={label}>
      <MyInput
        {...register(name)}
        type={type}
        disabled={!editable || disabled}
      />
    </LabelWrap>
  );

  return (
    <>
      <Inputs>
        {mkInput('name', 'Kundnamn')}
        <LeftLabel label="Kalenderfärg">
          <MyColorInput {...register('calendarColor')} disabled={!editable} />
        </LeftLabel>

        {mkInput('organizationNumber', 'Organisationsnummer')}
        {mkInput('contactPerson', 'Kontaktperson')}
        {mkInput('addressLine1', 'Adressrad 1')}
        {mkInput('city', 'Ort')}
        {mkInput('addressLine2', 'Adressrad 2')}
        {mkInput('zipCode', 'Postnr')}

        {mkInput('vat', 'VAT')}
        {mkInput(
          'invoiceNrOfPaymentDays',
          'Betalning oss tillhanda X dagar från fakturadatum',
          'number',
          true
        )}
      </Inputs>

      <Inputs>
        {mkInput('invoiceEmail', 'Epost för faktura')}

        <LabelWrap>
          <MyCheckbox
            {...register('requireExternalReference')}
            disabled={!editable}
          >
            Kräv ext. ref. för kund
          </MyCheckbox>
        </LabelWrap>
      </Inputs>
    </>
  );
};

export default CustomerFormFields;
