import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AkCompany } from 'api';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import Modal from 'components/Modal';
import MediaQuery from 'constants/MediaQuery';
import useUsers from 'contexts/useUsers';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { arraySpreadIf } from 'utils/spreading';
import { conformInvoiceReferencePrefix } from 'utils/string';
import {
  AkCompanyForm,
  AkCompanyFormToAkCompany,
  createAkCompanyForm,
} from './AkCompanyFormConverters';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  ${MediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

interface Props {
  akCompany?: AkCompany;
  onSaveAkCompany(akCompany: AkCompany): void;
  onCancel(): void;
}

const EditAkCompanyModal: FC<Props> = ({
  akCompany,
  onSaveAkCompany,
  onCancel,
}) => {
  const { users } = useUsers();

  const { register, handleSubmit, formState, reset, control } = useForm({
    defaultValues: createAkCompanyForm(akCompany),
  });

  const { isDirty, isValid } = formState;

  const handleSave = handleSubmit((formValues) => {
    onSaveAkCompany(AkCompanyFormToAkCompany(formValues, akCompany));
  });

  const mkInput = (name: keyof AkCompanyForm, label: string) => (
    <LabelWrap label={label}>
      <Input {...register(name)} />
    </LabelWrap>
  );

  return (
    <Modal
      scrollContent
      title={akCompany ? 'Redigera station' : 'Lägg till ny station'}
      onClose={onCancel}
      buttons={[
        { label: 'Spara', onClick: handleSave, disabled: !isDirty || !isValid },
        { label: 'Avbryt', onClick: onCancel },
        ...arraySpreadIf(isDirty, {
          label: 'Återställ',
          icon: <FontAwesomeIcon icon={faRotateLeft} />,
          onClick: () => reset(),
        }),
      ]}
    >
      <Inputs>
        {mkInput('name', 'Namn')}

        <LabelWrap label="Stationsägare">
          <Select
            {...register('akCompanyOwnerId', {
              setValueAs: (value) => (value === '' ? undefined : Number(value)),
            })}
          >
            <option value="">Ingen</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.fullName}
              </option>
            ))}
          </Select>
        </LabelWrap>
        {mkInput('phoneNumber', 'Telefon')}
        {mkInput('email', 'Epost')}
        {mkInput('contactPerson', 'Kontaktperson')}

        {mkInput('addressLine1', 'Adressrad 1')}
        {mkInput('addressLine2', 'Adressrad 2')}
        {mkInput('zipCode', 'Postnr')}
        {mkInput('city', 'Ort')}
        {mkInput('organizationNumber', 'Organisationsnummer')}
        {mkInput('vat', 'VAT')}

        {mkInput('bankgiro', 'Bankgiro')}
        {mkInput('plusgiro', 'Plusgiro')}

        <Controller
          name="invoiceReferenceIdPrefix"
          control={control}
          render={({ field }) => (
            <LabelWrap label="Prefix till fakturanummer">
              <Input
                value={field.value}
                onChange={(eve) => {
                  field.onChange(
                    conformInvoiceReferencePrefix(eve.target.value)
                  );
                }}
              />
            </LabelWrap>
          )}
        />
      </Inputs>
    </Modal>
  );
};

export default EditAkCompanyModal;
