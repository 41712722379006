import Theme from 'constants/theme';
import styled from 'styled-components';

const IconButton = styled.button<{
  iconColor?: string | (() => string);
  size?: keyof typeof Theme.sizes.font;
}>`
  padding: 5px;
  margin: -5px;
  color: ${({ iconColor }) => iconColor ?? 'inherit'};
  font: inherit;
  font-weight: 600;
  font-size: ${({ size }) => (size ? Theme.sizes.font[size] : 'inherit')};
  border: none;
  background: transparent;
  cursor: pointer;
`;

export default IconButton;
