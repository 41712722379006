import {
  faArrowRight,
  faInfoCircle,
  faRotateLeft,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Customer } from 'api';
import Button from 'components/inputs/Button';
import Modal from 'components/Modal';
import Routes from 'constants/routes';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { arraySpreadIf } from 'utils/spreading';
import CustomerFormFields from './CustomerForm';
import {
  createCustomerForm,
  customerFormToCustomer,
} from './customerFormConverters';

const EditPriceListsButton = styled(Button)`
  margin-top: 50px;
  margin-bottom: 20px;
`;

interface Props {
  customer?: Customer;
  onSaveCustomer(customer: Customer): void;
  onCancel(): void;
}

const EditCustomerModal: FC<Props> = ({
  customer,
  onSaveCustomer,
  onCancel,
}) => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { hQ_AkCompanyId } = useBasicData();
  const navigate = useNavigate();
  const modalStack = useModalStack();

  const form = useForm({
    defaultValues: createCustomerForm(customer),
  });

  const { isDirty, isValid } = form.formState;
  const editMode = !customer || selectedAkCompanyId === customer.akCompanyId;

  const handleSave = form.handleSubmit((formValues) => {
    if (selectedAkCompanyId === null) return;
    onSaveCustomer(
      customerFormToCustomer(formValues, selectedAkCompanyId, customer)
    );
  });

  const handleOpenCustomerPriceListClick = () => {
    if (!customer) return;

    const completeNavigation = () =>
      navigate(
        generatePath(Routes.settings.managePriceLists.listsForCustomer, {
          customerId: String(customer.id),
        })
      );

    if (!isDirty) {
      completeNavigation();
      return;
    }

    const modalId = modalStack.push(
      <Modal
        title="Kasta ändringar?"
        buttons={[
          {
            icon: <FontAwesomeIcon icon={faTrash} />,
            label: 'Kasta ändringar',
            onClick: () => {
              modalStack.pop(modalId);
              completeNavigation();
            },
          },
          {
            label: 'Avbryt',
            onClick: () => {
              modalStack.pop(modalId);
            },
          },
        ]}
      >
        Du har osparade ändringar, är du säker på att du vill kasta dem?
      </Modal>
    );
  };

  return (
    <Modal
      fullscreenOnPhones
      scrollContent
      title={
        !editMode
          ? 'Visar kund'
          : customer
          ? 'Redigera kund'
          : 'Lägg till ny kund'
      }
      onClose={onCancel}
      buttons={
        editMode
          ? [
              {
                label: 'Spara',
                onClick: handleSave,
                disabled: !isDirty || !isValid,
              },
              { label: 'Avbryt', onClick: onCancel },
              ...arraySpreadIf(isDirty, {
                label: 'Återställ',
                icon: <FontAwesomeIcon icon={faRotateLeft} />,
                onClick: () => form.reset(),
              }),
            ]
          : [{ label: 'Stäng', onClick: onCancel }]
      }
    >
      {!editMode && (
        <p>
          <i>
            <FontAwesomeIcon icon={faInfoCircle} /> Du har inte rättigheter till
            att redigera denna kund.{' '}
            {customer?.akCompanyId === hQ_AkCompanyId &&
              'Detta är en central kund.'}
          </i>
        </p>
      )}
      <CustomerFormFields form={form} editable={editMode} />

      {editMode && customer && (
        <EditPriceListsButton small onClick={handleOpenCustomerPriceListClick}>
          Redigera prislistor <FontAwesomeIcon icon={faArrowRight} />
        </EditPriceListsButton>
      )}
    </Modal>
  );
};

export default EditCustomerModal;
