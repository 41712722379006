export function makeNumberComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => number
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue - bValue;
  };
}

export function makeStringComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => string
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
  };
}

export function makeBooleanComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => boolean
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue && !bValue ? 1 : bValue && !aValue ? -1 : 0;
  };
}

export function makeDateComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => Date
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue.getTime() - bValue.getTime();
  };
}

export function stringComparator(a: string, b: string) {
  return a > b ? 1 : a < b ? -1 : 0;
}

export function booleanComparator(a: boolean, b: boolean) {
  return a && !b ? 1 : b && !a ? -1 : 0;
}
