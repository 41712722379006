import { useMemo } from 'react';
import { AkCompany } from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import usePricingSetup from 'contexts/usePricingSetup';
import WebRoleIds from 'constants/RoleIds';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';

const useSelectableAkCompanies = () => {
  const { accountInfo } = useAccountInfo();
  const userClaims = useUserWebClaims();
  const { akCompaniesById } = usePricingSetup();

  return useMemo(() => {
    if (accountInfo.user) {
      if (userClaims.webRoleIds.has(WebRoleIds.admin))
        return Array.from(akCompaniesById.values());

      const akCompanyMembershipIds = new Set(
        accountInfo.user.akCompanyMemberships.map((cm) => cm.akCompanyId)
      );

      return (Array.from(akCompanyMembershipIds)
        .map((companyId) => akCompaniesById.get(companyId))
        .filter((c) => !!c) ?? []) as AkCompany[];
    }
    return [];
  }, [accountInfo.user, akCompaniesById]);
};

export default useSelectableAkCompanies;
