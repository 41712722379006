import { faClock, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Invoice, InvoiceStatus } from 'api';
import SpinnerIcon from 'components/spinners/SpinnerIcon';
import { ButtonCell } from 'components/Table/inputCells';
import { ColumnSetting, tableCellClassName } from 'components/Table/utils';
import { usePricingSetupValue } from 'contexts/usePricingSetup';
import { useMemo } from 'react';
import { css } from 'styled-components';
import { formatDateTime } from 'utils/date';
import {
  makeBooleanComparator,
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';

const sekNumberFormat = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

export interface InvoiceColumnSettingsRenderProps {
  currentlyDownloading: Set<number>;
  pricingSetup: usePricingSetupValue;
}

const useInvoiceColumnSettings = (
  onDownloadClick: (invoiceId: number) => void
) => {
  return useMemo(
    (): ColumnSetting<Invoice, InvoiceColumnSettingsRenderProps>[] => [
      {
        head: 'Skapad',
        render: (inv) => formatDateTime(inv.createdUtc),
        sortFunction: makeDateComparator((inv) => inv.createdUtc),
        width: 150,
      },
      {
        head: 'Fakturanummer',
        render: (inv) => inv.invoiceReferenceId,
        sortFunction: makeStringComparator((inv) => inv.invoiceReferenceId),
        width: 100,
      },
      {
        head: 'Kund',
        render: (inv, { pricingSetup: { customersById } }) =>
          customersById.get(inv.customerId)?.name ?? '',
        sortFunction: makeStringComparator(
          (inv, { pricingSetup: { customersById } }) =>
            customersById.get(inv.customerId)?.name ?? ''
        ),
        width: 150,
      },
      {
        head: 'Kreditfaktura',
        render: (inv) => (inv.isCreditInvoice ? 'Ja' : 'Nej'),
        sortFunction: makeBooleanComparator((inv) => inv.isCreditInvoice),
        width: 100,
      },
      {
        head: 'Summa',
        render: (inv) => sekNumberFormat.format(inv.total),
        sortFunction: makeNumberComparator((inv) => inv.total),
        css: css`
          &.${tableCellClassName} {
            text-align: right;
          }
        `,
        width: 150,
      },
      {
        head: 'Ladda ner',
        noPadding: true,
        render: (invoice, { currentlyDownloading }) =>
          invoice.invoiceStatus === InvoiceStatus.AwaitingCreatePdf ? (
            <ButtonCell disabled title="Fakturan håller på att skapas">
              <FontAwesomeIcon icon={faClock} />
            </ButtonCell>
          ) : currentlyDownloading.has(invoice.id) ? (
            <ButtonCell disabled>
              <SpinnerIcon size="small" />
            </ButtonCell>
          ) : (
            <ButtonCell onClick={() => onDownloadClick(invoice.id)}>
              <FontAwesomeIcon icon={faFilePdf} />
            </ButtonCell>
          ),
        width: 100,
      },
    ],
    []
  );
};

export default useInvoiceColumnSettings;
