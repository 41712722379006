import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Theme from 'constants/theme';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 5px;
`;

const Input = styled.input`
  position: fixed;
  top: -1000px;
  left: -1000px;
` as FC<React.HTMLProps<HTMLInputElement>>;

export const checkBoxStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  border: 1px solid ${Theme.colors.border.input};
`;

const RenderedCheckbox = styled.div<{ padRight?: boolean }>`
  ${checkBoxStyle}

  ${({ padRight }) =>
    padRight &&
    css`
      margin-right: 5px;
    `}

  input + & {
    color: currentColor;

    & > * {
      opacity: 0;
    }
  }

  input:checked + & {
    background-color: ${Theme.colors.bg.accent1};
    border-color: ${Theme.colors.bg.accent1};
    color: ${Theme.colors.fg.accent1};

    & > * {
      opacity: 1;
    }
  }

  input:disabled + & {
    opacity: 0.5;
  }

  input:disabled:checked + & {
    background-color: ${Theme.colors.bg.background4};
    border-color: ${Theme.colors.bg.background4};
    color: ${Theme.colors.fg.background4};
  }
`;

interface Props extends React.HTMLProps<HTMLInputElement> {}

const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ type, children, className, onClick, ...inputProps }, ref) => {
    return (
      <Wrapper className={className} onClick={onClick as any}>
        <Input {...inputProps} ref={ref} type="checkbox" />
        <RenderedCheckbox padRight={!!children}>
          <FontAwesomeIcon icon={faCheck} />
        </RenderedCheckbox>
        {children}
      </Wrapper>
    );
  }
);

export default Checkbox;
