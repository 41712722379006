import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';
import styled, { css } from 'styled-components';
import { getVerticalScrollParent } from 'utils/dom';
import { Overlay } from './Modal';

export const dropdownContentClassName = 'dropdown-content';

const Wrapper = styled.div`
  display: contents;
`;

const MyOverlay = styled(Overlay)`
  ${MediaQuery.tablet} {
    display: none;
  }
`;

const DropdownContent = styled.div<{ positionStyle?: ReturnType<typeof css> }>`
  z-index: 100;
  position: fixed;
  ${({ positionStyle }) => positionStyle}
  left: 50%;
  width: 90vw;
  transform: translateX(-50%);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  background-color: ${Theme.colors.bg.input};
  border: 1px solid ${Theme.colors.border.input};
  box-shadow: ${Theme.colors.shadow.dropdown};

  ${MediaQuery.tablet} {
    left: auto;
    top: auto;
    transform: none;
    width: auto;
    ${({ positionStyle }) => positionStyle}
  }
`;

export type DropdownPosition = 'fullwidth' | 'left' | 'right';

interface Props extends PropsWithChildren {
  content?: ReactNode;
  onLostFocus?(): void;
  /** Gets called when wrapper gets clicked */
  onClick?(): void;
  /** defaults to 'fullwidth', which is equal to Dropdown's Wrapper width that you can style by passing down className. */
  position?: DropdownPosition;
  className?: string;
}

const Dropdown: FC<Props> = ({
  children,
  content,
  onLostFocus,
  onClick,
  position = 'fullwidth',
  className,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onLostFocusRef = useRef(onLostFocus);
  onLostFocusRef.current = onLostFocus;

  useLayoutEffect(() => {
    if (!wrapperRef.current) return;

    const handleLostFocus = () => {
      if (
        onLostFocusRef.current &&
        !wrapperRef.current?.matches(':focus-within')
      ) {
        onLostFocusRef.current();
      }
    };

    const handleParentScroll = () => {
      onLostFocusRef.current?.();
    };

    const element = wrapperRef.current;
    element.addEventListener('focusout', handleLostFocus);

    const scrollParent = getVerticalScrollParent(element);
    if (scrollParent instanceof HTMLElement)
      scrollParent?.addEventListener('scroll', handleParentScroll);

    return () => {
      element.removeEventListener('focusout', handleLostFocus);
      scrollParent?.removeEventListener('scroll', handleParentScroll);
    };
  }, []);

  const getDropdownPositionStyle = (): ReturnType<typeof css> | undefined => {
    if (
      wrapperRef.current &&
      wrapperRef.current.lastChild instanceof HTMLElement
    ) {
      const dropdownFromElement = wrapperRef.current.lastChild;
      const dropdownFromBoundingRect =
        dropdownFromElement.getBoundingClientRect();

      const bodyBoundingRect = document.body.getBoundingClientRect();

      const dropUpwards =
        dropdownFromBoundingRect.top -
          bodyBoundingRect.top +
          dropdownFromBoundingRect.height / 2 >
        window.innerHeight * 0.5;

      if (!dropUpwards) {
        const top =
          dropdownFromBoundingRect.top -
          bodyBoundingRect.top +
          dropdownFromBoundingRect.height;
        const maxHeight = window.innerHeight - top - 5;
        switch (position) {
          case 'left':
            return css`
              top: ${top}px;
              right: ${window.innerWidth - dropdownFromBoundingRect.right}px;
              max-height: ${maxHeight}px;
            `;

          case 'right':
            return css`
              top: ${top}px;
              left: ${dropdownFromBoundingRect.left +
              dropdownFromBoundingRect.width}px;
              max-height: ${maxHeight}px;
            `;

          case 'fullwidth':
          default:
            return css`
              top: ${top}px;
              left: ${dropdownFromBoundingRect.left}px;
              min-width: ${dropdownFromBoundingRect.width}px;
              max-height: ${maxHeight}px;
            `;
        }
      } else {
        const bottom =
          window.innerHeight -
          dropdownFromBoundingRect.bottom +
          dropdownFromBoundingRect.height;
        const maxHeight = window.innerHeight - bottom - 5;
        switch (position) {
          case 'left':
            return css`
              flex-direction: column-reverse;
              bottom: ${bottom}px;
              right: ${window.innerWidth - dropdownFromBoundingRect.right}px;
              max-height: ${maxHeight}px;
            `;

          case 'right':
            return css`
              flex-direction: column-reverse;
              bottom: ${bottom}px;
              left: ${dropdownFromBoundingRect.left +
              dropdownFromBoundingRect.width}px;
              max-height: ${maxHeight}px;
            `;

          case 'fullwidth':
          default:
            return css`
              flex-direction: column-reverse;
              bottom: ${bottom}px;
              left: ${dropdownFromBoundingRect.left}px;
              min-width: ${dropdownFromBoundingRect.width}px;
              max-height: ${maxHeight}px;
            `;
        }
      }
    }

    return undefined;
  };

  return (
    <Wrapper onClick={onClick} ref={wrapperRef} className={className}>
      {content && (
        <DropdownContent
          onClick={(eve) => eve.stopPropagation()}
          positionStyle={getDropdownPositionStyle()}
          className={dropdownContentClassName}
        >
          {content}
        </DropdownContent>
      )}
      {content && (
        <MyOverlay
          onClick={(eve) => {
            eve.stopPropagation();
            onLostFocus?.();
          }}
        />
      )}

      {/* Needs to be last since we use last element to get the position for the DropdownContent */}
      {children}
    </Wrapper>
  );
};

export default Dropdown;
