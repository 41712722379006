import { FC } from 'react';
import styled from 'styled-components';
import Attachment from './Attachment';
import useAttachmentsEditorState from './useAttachmentsEditorState';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

interface Props {
  state: ReturnType<typeof useAttachmentsEditorState>;
  disabled?: boolean;
}

const AttachmentsEditor: FC<Props> = ({ state, disabled }) => {
  const { savedAttachments, queuedFiles, removeSaved, removeQueuedFile } =
    state;

  if (savedAttachments.length === 0 && queuedFiles.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      {savedAttachments
        .filter((attachment) => !attachment.isRemoved)
        .map((attachment) => (
          <Attachment
            key={attachment.id}
            name={attachment.displayName + attachment.fileEnding}
            onRemove={() => removeSaved(attachment.id)}
            disabled={disabled}
          />
        ))}

      {queuedFiles.map((file, i) => (
        <Attachment
          key={i}
          name={file.name}
          onRemove={() => removeQueuedFile(file)}
          disabled={disabled}
        />
      ))}
    </Wrapper>
  );
};

export default AttachmentsEditor;
