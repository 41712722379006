import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { formatDateTime } from 'utils/date';
import { cssVar } from './cssVariables';

const selectionPreviewClassName = 'ganttchart-selection-preview';
const timeTooltipClassName = 'ganttchart-time-tooltip';

const selectionPreviewArrowSize = 2;
const EventLineWrapper = styled.div<{ canSelect: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: var(${cssVar.eventLineHeight});
  padding: var(${cssVar.eventLinePadding}) 0;
  ${({ canSelect }) => canSelect && `cursor: col-resize;`}

  &:nth-child(even)::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: ${Theme.colors.bg.background2};
    opacity: 0.5;
  }

  .${selectionPreviewClassName} {
    position: absolute;
    top: 0;
    border-top: 5px solid ${Theme.colors.bg.selection};
    height: 100%;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border: ${selectionPreviewArrowSize * 2}px solid
        ${Theme.colors.bg.selection};
      height: ${selectionPreviewArrowSize}px;
      width: ${selectionPreviewArrowSize}px;
      box-sizing: border-box;
      border-bottom-color: transparent;
    }

    &::before {
      left: 0;
      border-right-color: transparent;
    }

    &::after {
      right: 0;
      border-left-color: transparent;
    }
  }
`;

const hourInMilliseconds = 1000 * 60 * 60;

const GlobalCSS = createGlobalStyle`
  .${timeTooltipClassName} {
    display: none; 

    ${MediaQuery.tablet} {
      display: block;
      position: fixed;
      z-index: 5;
      transform: translate(-50%, -100%);
      padding: 5px;

      box-shadow: ${Theme.colors.shadow.dropdown};
      background-color: ${Theme.colors.bg.input};
      color: ${Theme.colors.fg.input};
      border: 1px solid ${Theme.colors.border.input};
      pointer-events: none;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        box-sizing: border-box;

        width: 3px;
        height: 3px;
        border: 6px solid transparent;
        border-top-color: ${Theme.colors.border.input};
      }
    }
  }
  
`;

interface Props extends PropsWithChildren {
  from: Date;
  to: Date;
  onDateSpanSelected?(from: Date, to: Date): void;
  ganttChartId: string;
}

const EventLineContainer: FC<Props> = ({
  from,
  to,
  onDateSpanSelected,
  ganttChartId,
  children,
}) => {
  const timeTooltipId = `gantt-chart-tooltip-${ganttChartId}`;

  useEffect(() => {
    return () => {
      const tooltipDiv = document.getElementById(timeTooltipId);
      tooltipDiv?.remove();
    };
  }, [timeTooltipId]);

  const roundHoursMillis = (milliseconds: number) =>
    Math.round(milliseconds / hourInMilliseconds) * hourInMilliseconds;

  // Click, drag and release, to trigger onDateSpanSelected() callback.
  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (eve) => {
    const element = eve.currentTarget;
    const eleRect = element.getBoundingClientRect();

    const xStartPercent = (eve.clientX - eleRect.left) / eleRect.width;

    const selectionPreviewElement = document.createElement('div');
    selectionPreviewElement.classList.add(selectionPreviewClassName);

    element.appendChild(selectionPreviewElement);

    const handleMouseMove = (eve: MouseEvent) => {
      eve.preventDefault();

      const xEndPercent = (eve.clientX - eleRect.left) / eleRect.width;

      selectionPreviewElement.style.left = `${
        Math.min(xStartPercent, xEndPercent) * 100
      }%`;
      selectionPreviewElement.style.right = `${
        100 - Math.max(xStartPercent, xEndPercent) * 100
      }%`;
    };

    const handleMouseUp = (eve: MouseEvent) => {
      element.removeChild(selectionPreviewElement);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      const xEndPercent = (eve.clientX - eleRect.left) / eleRect.width;

      const percentToTime = (percent: number) =>
        from.getTime() + (to.getTime() - from.getTime()) * percent;

      const startTime = percentToTime(Math.min(xStartPercent, xEndPercent));
      const endTime = percentToTime(Math.max(xStartPercent, xEndPercent));

      onDateSpanSelected?.(
        new Date(roundHoursMillis(startTime)),
        new Date(roundHoursMillis(endTime))
      );
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Hover over element to view a tooltip
  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = (eve) => {
    const element = eve.currentTarget;

    const getTooltip = () => {
      let tooltip = document.getElementById(timeTooltipId);
      if (!tooltip) {
        tooltip = document.createElement('div');
        tooltip.id = timeTooltipId;
        tooltip.classList.add(timeTooltipClassName);
        document.body.appendChild(tooltip);
      }
      return tooltip;
    };

    const handleMouseMove = (eve: MouseEvent) => {
      const elementRect = element.getBoundingClientRect();

      const tooltip = getTooltip();
      tooltip.style.left = `${eve.clientX}px`;
      tooltip.style.top = `${elementRect.top}px`;

      const xPercent = (eve.clientX - elementRect.left) / elementRect.width;
      const date = new Date(
        roundHoursMillis(
          from.getTime() + (to.getTime() - from.getTime()) * xPercent
        )
      );

      tooltip.innerText = formatDateTime(date);
    };

    const handleMouseLeave = (eve: MouseEvent) => {
      element.removeEventListener('mousemove', handleMouseMove);
      element.removeEventListener('mouseleave', handleMouseLeave);

      const tooltip = document.getElementById(timeTooltipId);
      tooltip?.remove();
    };

    element.addEventListener('mousemove', handleMouseMove);
    element.addEventListener('mouseleave', handleMouseLeave);
  };

  return (
    <EventLineWrapper
      canSelect={!!onDateSpanSelected}
      onMouseDown={onDateSpanSelected ? handleMouseDown : undefined}
      onMouseEnter={onDateSpanSelected ? handleMouseEnter : undefined}
    >
      <GlobalCSS />
      {children}
    </EventLineWrapper>
  );
};

export default EventLineContainer;
