import {
  Case,
  CaseClient,
  CaseJob,
  CaseMessage,
  CaseStatus,
  InvoiceClient,
  JobStatus,
  ReopenCaseAndCreditInvoicesRequest,
} from 'api';
import useAccountInfo from 'contexts/useAccountInfo';
import useModalStack from 'contexts/useModalStack';
import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useApiCall } from 'swaggerhooks';
import ConfirmDiscardCaseModal from './ConfirmDiscardCaseModal';
import {
  CaseFormInputs,
  caseFormToCase,
  createCaseForm,
} from './formUtils/caseFormConverters';
import ReOpenCaseModal from './ReOpenCaseModal';
import { useAutoUpdateForm, useValidCustomerId } from './useCaseFormEffects';

export type saveMessageFunction = (
  message: CaseMessage,
  newAttachments: File[]
) => Promise<any>;

const defaultFormValues = createCaseForm();

const useCaseEditorState = (
  currentCase?: Case,
  caseWasSaved?: (updatedCase: Case) => void
) => {
  const modalStack = useModalStack();
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();

  const saveCaseCall = useApiCall(CaseClient, (c, theCase: Case) =>
    c.saveCase(theCase)
  );
  const saveCaseMessageCall = useApiCall(
    CaseClient,
    (c, message: CaseMessage) => c.saveCaseMessage(message)
  );
  const uploadAttachmentCall = useApiCall(
    CaseClient,
    (c, file: File, messageId: number) =>
      c.uploadAttachment({ data: file, fileName: file.name }, messageId)
  );
  const reopenCaseCall = useApiCall(
    InvoiceClient,
    (c, caseId: number, invoiceDate: Date) =>
      c.reopenCaseAndCreateCreditInvoices(
        new ReopenCaseAndCreditInvoicesRequest({
          caseId,
          invoiceDate,
        })
      )
  );

  const form = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
  });

  useAutoUpdateForm(form, currentCase);
  useValidCustomerId(form, currentCase);

  const submitHandler: SubmitHandler<CaseFormInputs> = useCallback(
    async (formInputs: CaseFormInputs) => {
      if (selectedAkCompanyId === null) return;

      const editedCase = caseFormToCase(
        formInputs,
        selectedAkCompanyId,
        currentCase
      );

      const saveCase = async (caseToSave: Case) => {
        const [savedCase, error] = await saveCaseCall.run(caseToSave);

        if (!error && savedCase) {
          form.reset(createCaseForm(savedCase));
          caseWasSaved?.(savedCase);
        }
      };

      const saveCaseWithClosedJobs = async (caseToSaveClosed: Case) => {
        await saveCase(
          new Case({
            ...caseToSaveClosed,

            caseJobs: caseToSaveClosed.caseJobs.map(
              (cj) =>
                new CaseJob({
                  ...cj,

                  // Set job status to Discarded if it is Normal
                  jobStatus:
                    cj.jobStatus === JobStatus.Normal
                      ? JobStatus.Discarded
                      : cj.jobStatus,
                })
            ),
          })
        );
      };

      // User has changed case status to discarded.
      // Show confirmation dialog and mark all CaseJobs as discarded as well.
      if (
        editedCase.caseStatus !== currentCase?.caseStatus &&
        editedCase.caseStatus === CaseStatus.Discarded
      ) {
        const modalId = modalStack.push(
          <ConfirmDiscardCaseModal
            onClose={() => modalStack.pop(modalId)}
            onDiscardCase={() => {
              modalStack.pop(modalId);
              saveCaseWithClosedJobs(editedCase);
            }}
          />
        );
      } else {
        saveCase(editedCase);
      }
    },
    [currentCase, caseWasSaved, saveCaseCall, selectedAkCompanyId]
  );

  const handleSaveMessage: saveMessageFunction = async (
    message: CaseMessage,
    newAttachments: File[]
  ) => {
    const [savedMessage, error] = await saveCaseMessageCall.run(message);

    if (savedMessage && !error) {
      for (const newAttachment of newAttachments) {
        const [uploadedAttachment, error] = await uploadAttachmentCall.run(
          newAttachment,
          savedMessage.id
        );

        if (uploadedAttachment && !error) {
          savedMessage.caseAttachments.push(uploadedAttachment);
        }
      }

      if (currentCase) {
        const filteredList = currentCase.caseMessages.filter(
          (cm) => cm.id !== savedMessage.id
        );

        caseWasSaved?.(
          new Case({
            ...currentCase,
            caseMessages: [...filteredList, savedMessage],
          })
        );
      }
    }
  };

  const handleReopenCase = async () => {
    if (!currentCase) return;

    const modalId = modalStack.push(
      <ReOpenCaseModal
        onClose={() => modalStack.pop(modalId)}
        onReopenCase={async (creditInvoiceDate) => {
          modalStack.pop(modalId);
          await reopenCaseCall.run(currentCase.id, creditInvoiceDate);

          caseWasSaved?.(
            new Case({
              ...currentCase,
              caseStatus: CaseStatus.Open,
            })
          );
        }}
      />
    );
  };

  return {
    form,
    submitHandler,
    handleSaveMessage,
    handleReopenCase,

    saveCaseMessageCall,
    uploadAttachmentCall,
    saveCaseCall,
    reopenCaseCall,
  };
};

export default useCaseEditorState;
