import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Customer, CustomerClient } from 'api';
import ColorDot from 'components/ColorDot';
import Modal from 'components/Modal';
import PageHeader, { PageHeaderButton } from 'components/PageHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Table from 'components/Table';
import { ButtonCell } from 'components/Table/inputCells';
import { tableCellClassName, tableHeadClassName } from 'components/Table/utils';
import Theme from 'constants/theme';
import useAccountInfo from 'contexts/useAccountInfo';
import useBasicData from 'contexts/useBasicData';
import useModalStack from 'contexts/useModalStack';
import usePricingSetup, {
  useUpdatePricingSetupCall,
} from 'contexts/usePricingSetup';
import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { makeBooleanComparator, makeStringComparator } from 'utils/sorting';
import EditCustomerModal from './EditCustomerModal';

const MyTable = styled(Table)`
  flex: 1;

  ${`.${tableCellClassName}:first-child, .${tableHeadClassName}:first-child`} {
    padding-left: ${Theme.sizes.padding.screenInset};
  }
` as typeof Table;

const ManageCustomersPage: FC = () => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const { hQ_AkCompanyId } = useBasicData();
  const { customerIdsByAkCompanyId, customersById } = usePricingSetup();
  const updatePricingSetupCall = useUpdatePricingSetupCall();
  const modalStack = useModalStack();
  const saveCustomerCall = useApiCall(CustomerClient, (c, customer: Customer) =>
    c.saveCustomer(customer)
  );

  const [editCustomerId, setEditCustomerId] = useState<number | null>(null);

  const myCompanyCustomers = useMemo(
    () =>
      customerIdsByAkCompanyId
        .get(selectedAkCompanyId ?? -1)
        ?.map((customerId) => customersById.get(customerId)!)
        .filter((customer) => customer && !customer.isRemoved) ?? [],
    [customerIdsByAkCompanyId, customersById, selectedAkCompanyId]
  );

  const handleSaveCustomer = useCallback(async (customer: Customer) => {
    setEditCustomerId(null);
    await saveCustomerCall.run(customer);
    await updatePricingSetupCall.run();
  }, []);

  const handleDeleteCustomer = useCallback(async (customer: Customer) => {
    const modalId = modalStack.push(
      <Modal
        title={`Ta bort "${customer.name}"?`}
        buttons={[
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(modalId),
          },
          {
            label: 'Ta bort',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: async () => {
              modalStack.pop(modalId);
              await saveCustomerCall.run(
                new Customer({ ...customer, isRemoved: true })
              );
              await updatePricingSetupCall.run();
            },
          },
        ]}
        onClose={() => modalStack.pop(modalId)}
      >
        Är du säker på att du vill ta bort kunden "{customer.name}"?
      </Modal>
    );
  }, []);

  const renderContent = () => {
    if (
      saveCustomerCall.status === RequestStatus.Fetching ||
      updatePricingSetupCall.status === RequestStatus.Fetching
    )
      return <LoadingSection />;

    return (
      <MyTable
        onRowClick={(customer) => setEditCustomerId(customer.id)}
        columnSettings={[
          {
            head: 'Namn',
            render: (c) => (
              <ColorDot color={c.calendarColor}>{c.name}</ColorDot>
            ),
            width: 200,
            sortFunction: makeStringComparator((c) => c.name),
          },
          {
            head: 'Org. nummer',
            render: (c) => c.organizationNumber,
            width: 110,
            sortFunction: makeStringComparator((c) => c.organizationNumber),
          },
          {
            head: 'Kontaktperson',
            render: (c) => c.contactPerson,
            width: 200,
            sortFunction: makeStringComparator((c) => c.contactPerson),
          },
          {
            head: 'Adressrad 1',
            render: (c) => c.addressLine1,
            width: 200,
            sortFunction: makeStringComparator((c) => c.addressLine1),
          },
          {
            head: 'Adressrad 2',
            render: (c) => c.addressLine2,
            width: 200,
            sortFunction: makeStringComparator((c) => c.addressLine2),
          },
          {
            head: 'Ort',
            render: (c) => c.city,
            width: 200,
            sortFunction: makeStringComparator((c) => c.city),
          },
          {
            head: 'Postnr',
            render: (c) => c.zipCode,
            width: 100,
            sortFunction: makeStringComparator((c) => c.zipCode),
          },
          {
            head: 'Epost för faktura',
            render: (c) => c.invoiceEmail,
            width: 200,
            sortFunction: makeStringComparator((c) => c.invoiceEmail),
          },
          {
            head: 'Kräv ext.ref.',
            render: (c) => (c.requireExternalReference ? 'Ja' : 'Nej'),
            width: 100,
            sortFunction: makeBooleanComparator(
              (c) => c.requireExternalReference
            ),
          },

          {
            head: 'Central kund',
            render: (c) => (c.akCompanyId === hQ_AkCompanyId ? 'Ja' : 'Nej'),
            width: 100,
            sortFunction: makeBooleanComparator(
              (c) => c.akCompanyId === hQ_AkCompanyId
            ),
          },

          {
            head: 'Ta bort',
            noPadding: true,
            render: (customer) =>
              customer.akCompanyId === selectedAkCompanyId ? (
                <ButtonCell
                  onClick={(eve) => {
                    eve.stopPropagation();
                    handleDeleteCustomer(customer);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonCell>
              ) : null,
            width: 80,
          },
        ]}
        rows={myCompanyCustomers}
      />
    );
  };

  return (
    <>
      <PageHeader title="Kunder">
        <PageHeaderButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          small
          onClick={() => setEditCustomerId(0)}
        >
          Lägg till
        </PageHeaderButton>
      </PageHeader>
      {renderContent()}

      {editCustomerId !== null && (
        <EditCustomerModal
          customer={customersById.get(editCustomerId)}
          onCancel={() => setEditCustomerId(null)}
          onSaveCustomer={handleSaveCustomer}
        />
      )}
    </>
  );
};

export default ManageCustomersPage;
