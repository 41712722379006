import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import MediaQuery from 'constants/MediaQuery';
import Theme from 'constants/theme';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Checkbox from './Checkbox';
import { inputStyle } from './Input';

const FakeSelect = styled.button`
  ${inputStyle}
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
`;

const SelectedValue = styled.div`
  flex: 1;
  min-height: 1.2em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  margin: 0;
  gap: 10px;

  border: none;
  background-color: ${Theme.colors.bg.background1};
  color: ${Theme.colors.fg.background1};
  font: inherit;
  text-align: start;
  white-space: nowrap;
  border-bottom: 1px solid ${Theme.colors.border.main};

  ${MediaQuery.desktop} {
    padding: 5px 10px;
  }

  &:hover {
    background: ${Theme.colors.bg.selection};
    color: ${Theme.colors.fg.selection};
  }
`;

export interface Option<V> {
  value: V;
  label: string;
}

interface Props<V> {
  options: Option<V>[];
  onChange(selection: Set<V>): void;
  value: Set<V>;
  renderSelected?(item: Option<V> | undefined): ReactNode | null;
  className?: string;
}

const MultiSelect = <V,>({
  options,
  onChange,
  value,
  renderSelected,
  className,
}: Props<V>) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      content={
        open && (
          <Items>
            {options.map((option, i) => (
              <Item
                key={i}
                onClick={(eve) => {
                  eve.preventDefault();
                  const newValue = new Set(value);
                  if (value.has(option.value)) {
                    newValue.delete(option.value);
                  } else {
                    newValue.add(option.value);
                  }
                  onChange(newValue);
                }}
              >
                <Checkbox checked={value.has(option.value)} />
                {option.label}
              </Item>
            ))}
          </Items>
        )
      }
      onLostFocus={() => setOpen(false)}
    >
      <FakeSelect
        className={className}
        onClick={(eve) => {
          eve.preventDefault();
          setOpen((o) => !o);
        }}
      >
        {renderSelected ? (
          Array.from(value).map((v) =>
            renderSelected(options.find((o) => o.value === v))
          )
        ) : (
          <SelectedValue>
            {Array.from(value)
              .map((v) => options.find((o) => o.value === v)?.label)
              .join(', ')}
          </SelectedValue>
        )}
        <FontAwesomeIcon icon={faAngleDown} />
      </FakeSelect>
    </Dropdown>
  );
};

export default MultiSelect;
