import { InvoiceClient } from 'api';
import { useState } from 'react';
import { useApiCall } from 'swaggerhooks';

const usePdfDownloader = () => {
  const [currentlyDownloading, setCurrentlyDownloading] = useState(
    new Set<number>()
  );
  const downloadPdfCall = useApiCall(
    InvoiceClient,
    async (c, invoiceId: number) => {
      setCurrentlyDownloading((cd) => {
        const newCd = new Set(cd);
        newCd.add(invoiceId);
        return newCd;
      });

      try {
        const pdf = await c.downloadInvoicePDF(invoiceId);

        if (!pdf) {
          alert('Kunde inte hämta PDF, försök igen.');
          return;
        }

        const blobUrl = URL.createObjectURL(pdf.data);
        const link = document.createElement('a');

        link.href = blobUrl;
        link.download = pdf.fileName ?? 'faktura.pdf';
        link.click();
      } catch (err) {
        throw err;
      } finally {
        setCurrentlyDownloading((cd) => {
          const newCd = new Set(cd);
          newCd.delete(invoiceId);
          return newCd;
        });
      }
    }
  );

  return {
    downloadPdfCall,
    currentlyDownloading,
  };
};

export default usePdfDownloader;
