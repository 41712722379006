import 'moment/locale/sv';
import moment from 'moment';

export function getWeekDays(locale?: Intl.LocalesArgument) {
  var baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  var weekDays = [];
  for (let i = 0; i < 7; i++) {
    weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'narrow' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
}

export function getWeekNumber(thisWeek: Date) {
  return moment(thisWeek).week();
}

export function getMondayOfWeek(weekNumber: number, year: number) {
  return moment().year(year).week(weekNumber).startOf('week').toDate();
}

export function datespansIntersects(
  a1d: Date,
  a2d: Date,
  b1d: Date,
  b2d: Date
) {
  const a1 = a1d.getTime();
  const a2 = a2d.getTime();
  const b1 = b1d.getTime();
  const b2 = b2d.getTime();

  const doesNotIntersect =
    (a1 > b1 && a1 > b2 && a2 > b1 && a2 > b2) ||
    (a1 < b1 && a1 < b2 && a2 < b1 && a2 < b2);

  return !doesNotIntersect;
}

export interface DateSpan {
  from: Date;
  to: Date;
}

export function groupIntoSequentialLanes<DS extends DateSpan>(dateSpans: DS[]) {
  const lanes: DS[][] = [];

  // sorted by span length
  const sortedDateSpans = dateSpans.sort(
    (a, b) =>
      b.to.getTime() - b.from.getTime() - (a.to.getTime() - a.from.getTime())
  );

  const tryInsertIntoLane = (laneIndex: number, toInsert: DS) => {
    let lane = lanes[laneIndex];

    if (!lane) {
      lanes[laneIndex] = lane = [];
    }

    if (lane[0] && toInsert.from <= lane[0].from) {
      if (
        datespansIntersects(
          toInsert.from,
          toInsert.to,
          lane[0].from,
          lane[0].to
        )
      ) {
        tryInsertIntoLane(laneIndex + 1, toInsert);
        return;
      }

      lane.unshift(toInsert);
      return;
    }

    for (let i = 0; i < lane.length; i++) {
      const currentSpan = lane[i];
      const nextSpan = lane[i + 1] as DS | undefined;

      if (!nextSpan) {
        if (
          datespansIntersects(
            toInsert.from,
            toInsert.to,
            currentSpan.from,
            currentSpan.to
          )
        ) {
          tryInsertIntoLane(laneIndex + 1, toInsert);
          return;
        }

        lane.push(toInsert);
        return;
      }

      if (toInsert.from >= currentSpan.from && toInsert.from < nextSpan.from) {
        if (
          datespansIntersects(
            toInsert.from,
            toInsert.to,
            currentSpan.from,
            currentSpan.to
          )
        ) {
          tryInsertIntoLane(laneIndex + 1, toInsert);
          return;
        }

        if (
          datespansIntersects(
            toInsert.from,
            toInsert.to,
            nextSpan.from,
            nextSpan.to
          )
        ) {
          tryInsertIntoLane(laneIndex + 1, toInsert);
          return;
        }

        lane.splice(i + 1, 0, toInsert);
        return;
      }
    }

    lane.push(toInsert);
    return;
  };

  sortedDateSpans.forEach((dateSpan) => tryInsertIntoLane(0, dateSpan));

  return lanes;
}
