import { JobStatus, CaseJobSearchResult } from 'api';
import { DateSpan } from 'components/Calendar/utils';
import { CaseFormInputs } from 'pages/Case/CaseEditor/formUtils/caseFormConverters';
import {
  JobGroupFormInputs,
  JobInstanceFormInputs,
} from 'pages/Case/CaseEditor/formUtils/caseJobFormConverters';
import { useEffect, useMemo, useState } from 'react';
import { DeepPartial, UseFormReturn } from 'react-hook-form';
import { ApiResponse } from 'swaggerhooks';
import { conformRegNr } from 'utils/string';
import useIntersectingEvents from './useIntersectingEvents';

export interface JobFormIndexes {
  jobGroupFormIndex: number;
  jobInstanceFormIndex: number;
}

interface BaseCaseJobEvent extends DateSpan {
  /** undefined if the case hasn't been saved yet. */
  caseId?: number;
  /** undefined if the caseJob hasn't been saved yet. */
  caseJobId?: number;

  /** If formIndex isn't undefined, this event is included in the form,
   * and formIndex says what index the caseJobEvent has in the form */
  formIndex?: JobFormIndexes;

  // These are 'live' updated from the case form, and only used to display in calendar/week views.
  customerId: number;
  regNr: string;
  driverName: string;
  isRemoved: boolean;
}

export interface CaseJobEvent extends BaseCaseJobEvent {
  regnrIntersectsWith: BaseCaseJobEvent | null;
  driverIntersectsWith: BaseCaseJobEvent | null;
}

interface JobInstanceFormData {
  jobGroup: DeepPartial<JobGroupFormInputs>;
  jobInstance: DeepPartial<JobInstanceFormInputs>;
  formIndex: JobFormIndexes;
}

const useCaseJobEvents = (
  searchResponse: CaseJobSearchResult[],
  selectedCaseJob: ApiResponse<CaseJobSearchResult | undefined>,
  form: UseFormReturn<CaseFormInputs, any>,
  showCaseSlideIn: boolean
): CaseJobEvent[] => {
  const [formJobsById, setFormJobsById] = useState(
    new Map<number, JobInstanceFormData>()
  );
  const formCustomerId = form.watch('customerId');
  const [newFormJobs, setNewFormJobs] = useState<JobInstanceFormData[]>([]);

  // Update local state that is used to create events based on form inputs, when any input is changed
  useEffect(() => {
    const watcher = form.watch((formValues, { name, type }) => {
      const triggeringJobGroupProps: (keyof JobGroupFormInputs)[] = [
        'orderedStartUtc',
        'orderedEndUtc',
      ];

      const triggeringJobInstanceProps: (keyof JobInstanceFormInputs)[] = [
        'driverName',
        'vehicleRegNr',
        'jobStatus',
      ];

      const jobGroupNameRegex = new RegExp(
        `^jobGroups\\.\\d*\\.(${triggeringJobGroupProps.join('|')})$`
      );
      const jobInstanceNameRegex = new RegExp(
        `^jobGroups\\.\\d*\\.jobInstances\\.\\d*\\.(${triggeringJobInstanceProps.join(
          '|'
        )})$`
      );

      if (
        type === undefined ||
        (name &&
          (jobGroupNameRegex.test(name) || jobInstanceNameRegex.test(name)))
      ) {
        setFormJobsById(
          new Map(
            formValues.jobGroups
              ?.map(
                (jobGroup, jobGroupFormIndex) =>
                  jobGroup?.jobInstances?.map(
                    (
                      jobInstance,
                      jobInstanceFormIndex
                    ): [number, JobInstanceFormData] => [
                      jobInstance!.caseJobId!,
                      {
                        jobGroup: jobGroup!,
                        jobInstance: jobInstance!,
                        formIndex: {
                          jobGroupFormIndex,
                          jobInstanceFormIndex,
                        },
                      },
                    ]
                  ) ?? []
              )
              .flat(1) ?? []
          )
        );
        setNewFormJobs(
          (
            formValues.jobGroups?.map(
              (jobGroup, jobGroupFormIndex) =>
                jobGroup?.jobInstances
                  ?.filter((caseJob) => caseJob?.caseJobId === 0)
                  .map(
                    (
                      jobInstance,
                      jobInstanceFormIndex
                    ): JobInstanceFormData => ({
                      jobGroup,
                      jobInstance: jobInstance!,
                      formIndex: {
                        jobGroupFormIndex,
                        jobInstanceFormIndex,
                      },
                    })
                  ) ?? []
            ) ?? []
          ).flat(1)
        );
      }
    });

    return () => {
      watcher.unsubscribe();
    };
  }, [form.watch]);

  const caseJobEvents = useMemo((): BaseCaseJobEvent[] => {
    const newJobEvents = newFormJobs.map(
      ({
        jobGroup,
        jobInstance,
        formIndex: { jobGroupFormIndex, jobInstanceFormIndex },
      }): BaseCaseJobEvent => ({
        from: new Date(jobGroup.orderedStartUtc ?? NaN),
        to: new Date(jobGroup.orderedEndUtc ?? NaN),
        caseId: selectedCaseJob.response?.case.id,
        caseJobId: selectedCaseJob.response?.caseJob.id,
        formIndex: {
          jobGroupFormIndex,
          jobInstanceFormIndex,
        },
        driverName: jobInstance.driverName ?? '',
        regNr: jobInstance.vehicleRegNr ?? '',
        customerId: formCustomerId,
        isRemoved: [JobStatus.Discarded, JobStatus.LateCancel].includes(
          jobInstance.jobStatus ?? JobStatus.Normal
        ),
      })
    );

    const existingJobEvents =
      searchResponse
        ?.map((cj): BaseCaseJobEvent => {
          const jobInstanceFormData = formJobsById.get(cj.caseJob.id);

          // If job has form data, return event with current form data.
          if (jobInstanceFormData) {
            const formJobStart = new Date(
              jobInstanceFormData.jobGroup.orderedStartUtc ?? NaN
            );
            const formJobEnd = new Date(
              jobInstanceFormData.jobGroup.orderedEndUtc ?? NaN
            );

            if (
              !isNaN(formJobStart.getTime()) &&
              !isNaN(formJobEnd.getTime())
            ) {
              return {
                from: formJobStart,
                to: formJobEnd,
                caseJobId: cj.caseJob.id,
                caseId: cj.case.id,
                formIndex: {
                  jobGroupFormIndex:
                    jobInstanceFormData.formIndex.jobGroupFormIndex,
                  jobInstanceFormIndex:
                    jobInstanceFormData.formIndex.jobInstanceFormIndex,
                },
                driverName:
                  jobInstanceFormData.jobInstance.driverName ??
                  cj.caseJob.driverName,
                regNr:
                  jobInstanceFormData.jobInstance.vehicleRegNr ??
                  cj.caseJob.vehicleRegNr,
                customerId: formCustomerId,
                isRemoved: [JobStatus.Discarded, JobStatus.LateCancel].includes(
                  jobInstanceFormData.jobInstance.jobStatus ?? JobStatus.Normal
                ),
              };
            }
          }

          // If job doesn't have form data, return event with original caseJob data.
          return {
            from: cj.caseJob.orderedStartUtc,
            to: cj.caseJob.orderedEndUtc,
            caseJobId: cj.caseJob.id,
            caseId: cj.case.id,
            formIndex: undefined,
            driverName: cj.caseJob.driverName,
            regNr: cj.caseJob.vehicleRegNr,
            customerId: cj.case.customerId,
            isRemoved: [JobStatus.Discarded, JobStatus.LateCancel].includes(
              cj.caseJob.jobStatus ?? JobStatus.Normal
            ),
          };
        })
        // Only show removed caseJobs for the currently open case, all other removed jobs are filtered out.
        .filter((eve) => !(eve.isRemoved && eve.formIndex === undefined)) ?? [];

    if (showCaseSlideIn) return [...existingJobEvents, ...newJobEvents];

    return existingJobEvents;
  }, [
    newFormJobs,
    searchResponse,
    showCaseSlideIn,
    selectedCaseJob.response?.case.id,
    selectedCaseJob.response?.caseJob.id,
    formCustomerId,
    formJobsById,
  ]);

  // Get intersecting events using the same regnumber or same driver.
  return useIntersectingEvents(
    useIntersectingEvents(
      caseJobEvents,
      (event) => event.driverName.trim().toUpperCase() || null,
      (event, intersectsWith): Omit<CaseJobEvent, 'regnrIntersectsWith'> => ({
        ...event,
        driverIntersectsWith: intersectsWith,
      })
    ),
    (event) => conformRegNr(event.regNr) || null,
    (event, intersectsWith): CaseJobEvent => ({
      ...event,
      regnrIntersectsWith: intersectsWith,
    })
  );
};

export default useCaseJobEvents;
