import { JobStatus } from 'api';
import Select from 'components/inputs/Select';
import Modal from 'components/Modal';
import { FC, useState } from 'react';

interface Props {
  onSetJobStatus(newJobStatus: JobStatus): void;
  onClose(): void;
}

const CancelCaseJobModal: FC<Props> = ({ onSetJobStatus, onClose }) => {
  const [selectedStatus, setSelectedStatus] = useState(JobStatus.Discarded);

  const getOkButtonLabel = () => {
    switch (selectedStatus) {
      case JobStatus.Discarded:
        return 'Makulera';
      case JobStatus.LateCancel:
        return 'Avboka';
    }
  };

  return (
    <Modal
      title="Avboka uppdrag"
      onClose={onClose}
      buttons={[
        { label: 'Avbryt', onClick: onClose },
        {
          label: getOkButtonLabel(),
          onClick: () => onSetJobStatus(selectedStatus),
        },
      ]}
    >
      <p>Vilken typ av avbokning är det?</p>

      <Select
        value={selectedStatus}
        onChange={(eve) => {
          setSelectedStatus(Number(eve.target.value));
        }}
        style={{ width: '100%' }}
      >
        <option value={JobStatus.Discarded}>Makulera</option>
        <option value={JobStatus.LateCancel}>Sen avbokning</option>
      </Select>
    </Modal>
  );
};

export default CancelCaseJobModal;
