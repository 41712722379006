import { CreateInvoicesRequest, InvoiceClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Modal, {
  modalBodyClass,
  modalButtonsClass,
  modalContentClass,
  modalTitleClass,
} from 'components/Modal';
import LoadingSection from 'components/spinners/LoadingSection';
import ZoomAndScroll, { canvasClassName } from 'components/ZoomAndScroll';
import useAccountInfo from 'contexts/useAccountInfo';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks';
import { toInputDateString } from 'utils/date';
import useDebouncedValue from 'utils/useDebouncedValue';

const MyModal = styled(Modal)`
  .${modalBodyClass} {
    overflow: hidden;
  }
  .${modalTitleClass} {
    padding-left: 10px;
    margin-left: 0;
  }

  .${modalButtonsClass} {
    padding-left: 10px;
    padding-right: 10px;
  }
  .${modalContentClass} {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow: hidden;
  }
`;

const InvoiceDateLabel = styled(LabelWrap)`
  margin: 10px 15px 10px auto;
`;

const pageResolutionWidth = 880; // including all margins added in IFramePageStyling
const pageResolutionHeight = 1211.35;

const MyZoomAndScroll = styled(ZoomAndScroll)`
  flex: 1;

  .${canvasClassName} {
    position: relative;

    /* to prevent IFrame capturing touches */
    &::after {
      content: '';
      position: absolute;
      inset: 0;
    }
  }
`;

const IFrame = styled.object`
  width: ${pageResolutionWidth}px;
  min-width: ${pageResolutionWidth}px;
  margin: 0;

  border: none;
  transform-origin: 0 0;
`;

const IFramePageStyling = `
  html, body {
    width: min-content;
    box-sizing: border-box;
  }

  body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .page {
    margin: 0 20px;
    background-color: white;
    border: 20px solid transparent;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,0.5);
  }
`;

interface Props {
  selectedCaseIds: number[];
  onClose(): void;
  onCreateInvoiceClick(selectedInvoiceDate: Date): void;
}

const PreviewInvoiceModal: FC<Props> = ({
  selectedCaseIds,
  onClose,
  onCreateInvoiceClick,
}) => {
  const {
    accountInfo: { selectedAkCompanyId },
  } = useAccountInfo();
  const [selectedInvoiceDate, setSelectedInvoiceDate] = useState(new Date());
  const [debouncedSelectedInvoiceDate, isDebouncing] = useDebouncedValue(
    selectedInvoiceDate,
    500
  );

  const invoicePreview = useResponse(
    InvoiceClient,
    async (c) =>
      selectedAkCompanyId === null
        ? null
        : await c.previewInvoicesHTML(
            new CreateInvoicesRequest({
              akCompanyId: selectedAkCompanyId,
              invoiceDate: debouncedSelectedInvoiceDate,
              selectedCaseIds,
            })
          ),
    [selectedCaseIds, debouncedSelectedInvoiceDate]
  );

  const restyledIFrameSrc = useMemo(() => {
    if (invoicePreview.response?.internalInvoiceHTML) {
      const html = invoicePreview.response.internalInvoiceHTML.replace(
        '</head>',
        `<style>${IFramePageStyling}</style></head>`
      );
      return `data:text/html;base64,${btoa(html)}`;
    }
    return undefined;
  }, [invoicePreview.response]);

  const pageCounts = useMemo(() => {
    if (invoicePreview.response?.internalInvoiceHTML) {
      const matches = invoicePreview.response.internalInvoiceHTML.match(
        /<div [^<]*class="(?:[^"]* |)page(?: [^"]*|)"/g
      );

      return matches?.length ?? 1;
    }
    return 1;
  }, [invoicePreview.response]);

  const renderContent = () => {
    switch (invoicePreview.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSection />;

      case RequestStatus.Fetched:
        const canvasHeight = (pageResolutionHeight + 20) * pageCounts + 40;

        return (
          <MyZoomAndScroll
            canvasWidth={pageResolutionWidth}
            canvasHeight={canvasHeight}
          >
            <IFrame
              data={restyledIFrameSrc}
              width={pageResolutionWidth}
              height={canvasHeight}
            />
          </MyZoomAndScroll>
        );

      case RequestStatus.Error:
        return <ErrorTryAgain onTryAgain={() => invoicePreview.update()} />;
    }
  };

  return (
    <MyModal
      fullscreenOnPhones
      title="Förhandsvisning faktura"
      onClose={onClose}
      buttons={[
        { label: 'Avbryt', onClick: onClose },
        {
          label: 'Skapa faktura',
          onClick: () => {
            onCreateInvoiceClick(selectedInvoiceDate);
          },
        },
      ]}
    >
      <InvoiceDateLabel label="Fakturadatum:">
        <Input
          type="date"
          value={toInputDateString(selectedInvoiceDate)}
          onChange={(eve) => {
            setSelectedInvoiceDate(new Date(eve.target.value));
          }}
        />
      </InvoiceDateLabel>

      {renderContent()}
    </MyModal>
  );
};

export default PreviewInvoiceModal;
