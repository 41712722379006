import { PriceItem, PriceList } from 'api';
import Dropdown from 'components/Dropdown';
import { Item, Items } from 'components/inputs/MultiSelect';
import Theme from 'constants/theme';
import usePricingSetup from 'contexts/usePricingSetup';
import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import styled from 'styled-components';

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const PriceListHeader = styled.div`
  padding: 0 10px;
  font-weight: 600;
  background-color: ${Theme.colors.bg.background2};
  color: ${Theme.colors.fg.background2};
`;

const PriceListItem = styled(Item)`
  gap: 10px;
  justify-content: space-between;
`;

const useAllPriceLists = (customerId: number): PriceList[] => {
  const {
    priceListsById,
    externalPriceListIdsByCustomerId,
    internalPriceListIdsByCustomerId,
  } = usePricingSetup();

  return useMemo(
    () =>
      [
        ...(externalPriceListIdsByCustomerId.get(customerId) ?? []),
        ...(internalPriceListIdsByCustomerId.get(customerId) ?? []),
      ]
        .map((id) => priceListsById.get(id))
        .filter((pl) => !!pl) as PriceList[],
    [
      customerId,
      externalPriceListIdsByCustomerId,
      internalPriceListIdsByCustomerId,
      priceListsById,
    ]
  );
};

const useSelectablePriceLists = (customerId: number): PriceList[] => {
  const allPriceLists = useAllPriceLists(customerId);

  return useMemo(() => {
    const now = new Date();

    return allPriceLists
      .sort((a, b) => a.activeFromUtc.getTime() - b.activeFromUtc.getTime())
      .filter((pl, i, sortedPriceLists) => {
        const nextPriceList = sortedPriceLists[i + 1];
        return (
          pl.activeFromUtc <= now &&
          (!nextPriceList || nextPriceList.activeFromUtc >= now) &&
          !pl.isRemoved
        );
      })
      .map(
        (pl) =>
          new PriceList({
            ...pl,
            priceItems: pl.priceItems.filter(
              (pi) =>
                !pi.isRemoved &&
                (pi.jobType === null || pi.jobType === undefined)
            ),
          })
      )
      .filter((pl) => pl.priceItems.length > 0);
  }, [allPriceLists]);
};

interface Props extends PropsWithChildren {
  customerId: number;
  onSelectPriceItem(priceItem: PriceItem): void;
}

const PriceListSelect: FC<Props> = ({
  customerId,
  onSelectPriceItem,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const priceLists = useSelectablePriceLists(customerId);

  if (priceLists.length === 0) {
    return null;
  }

  return (
    <Dropdown
      onClick={() => setOpen(true)}
      onLostFocus={() => setOpen(false)}
      content={
        open && (
          <Scroll>
            {priceLists.map((pl) => (
              <React.Fragment key={pl.id}>
                <PriceListHeader>{pl.name}</PriceListHeader>
                <Items>
                  {pl.priceItems.map((pi) => (
                    <PriceListItem
                      onClick={(eve) => {
                        eve.preventDefault();
                        setOpen(false);
                        onSelectPriceItem(pi);
                      }}
                      key={pi.id}
                    >
                      {pi.name} <i>{pi.price}kr</i>
                    </PriceListItem>
                  ))}
                </Items>
              </React.Fragment>
            ))}
          </Scroll>
        )
      }
      position="left"
    >
      {children}
    </Dropdown>
  );
};

export default PriceListSelect;
