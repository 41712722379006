import SelectedAkCompanyIcon from 'components/AkCompanyIcons/SelectedAkCompanyIcon';
import Tabstrip from 'components/Tabstrip';
import WebRoleIds from 'constants/RoleIds';
import Routes from 'constants/routes';
import useUserWebClaims from 'contexts/useAccountInfo/useUserWebClaims';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { arraySpreadIf } from 'utils/spreading';

const TabAkCompanyIcon = styled(SelectedAkCompanyIcon)`
  margin-right: 5px;
`;

const SettingsRoute: FC = () => {
  const userClaims = useUserWebClaims();

  return (
    <>
      <Tabstrip
        tabs={[
          {
            route: Routes.settings.manageMyAkCompany.index,
            label: (
              <>
                <TabAkCompanyIcon /> Station
              </>
            ),
          },
          {
            route: Routes.settings.managePriceLists.index,
            label: 'Prislistor',
          },
          {
            route: Routes.settings.manageCustomers.index,
            label: 'Kunder',
            exact: true,
          },
          {
            route: Routes.settings.manageCustomerUsers.index,
            label: 'Kundanvändare',
          },
          { route: Routes.settings.manageVehicles.index, label: 'Fordon' },
          { route: Routes.settings.manageUsers.index, label: 'Personal' },

          {
            route: Routes.settings.manageAkCompanies.index,
            label: 'Stationer',
          },
          {
            route: Routes.settings.manageAppTexts.index,
            label: 'Webbtexter',
          },
        ]}
      />
      <Outlet />
    </>
  );
};

export default SettingsRoute;
