import moment from 'moment';

export const MaxDate = new Date(8640000000000000);

export const formatTime = (date: Date | undefined, seconds = false): string => {
  if (date) {
    const hString = date.getHours().toString().padStart(2, '0');
    const mString = date.getMinutes().toString().padStart(2, '0');
    const sString = date.getSeconds().toString().padStart(2, '0');

    if (seconds) {
      return `${hString}:${mString}:${sString}`;
    }
    return `${hString}:${mString}`;
  }

  return ' ';
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const dateStr = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${dateStr}`;
};

export const formatDateTime = (date: Date, seconds = false): string => {
  return `${formatDate(date)} ${formatTime(date, seconds)}`;
};

export const toInputDateString = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();

  return `${year.toString().padStart(4, '0')}-${month
    .toString()
    .padStart(2, '0')}-${date.toString().padStart(2, '0')}`;
};

export const toInputDateTimeString = (d: Date) => {
  const hour = d.getHours().toString().padStart(2, '0');
  const minute = d.getMinutes().toString().padStart(2, '0');
  return `${toInputDateString(d)}T${hour}:${minute}`;
};

export const toInputTimeString = (d: Date) => {
  const hour = d.getHours().toString().padStart(2, '0');
  const minute = d.getMinutes().toString().padStart(2, '0');
  return `${hour}:${minute}`;
};

export const isSameDay = (a: Date, b: Date) =>
  a.getFullYear() === b.getFullYear() &&
  a.getMonth() === b.getMonth() &&
  a.getDate() === b.getDate();

export const humanReadableTimeDurationBetweenDates = (from: Date, to: Date) => {
  return humanReadableTimeDuration(to.getTime() - from.getTime());
};

export const humanReadableTimeDuration = (millisDiff: number) => {
  let dayDiff = millisDiff / (24 * 60 * 60 * 1000);
  let hourDiff = (millisDiff / (60 * 60 * 1000)) % 24;
  let minDiff = (millisDiff / (60 * 1000)) % 60;

  if (millisDiff >= 0) {
    dayDiff = Math.floor(dayDiff);
    hourDiff = Math.floor(hourDiff);
    minDiff = Math.round(minDiff);
  } else {
    dayDiff = Math.ceil(dayDiff);
    hourDiff = Math.ceil(hourDiff);
    minDiff = Math.round(minDiff);
  }

  let timeString = '';

  if (hourDiff !== 0) {
    timeString = `${hourDiff}h`;
  }
  if (dayDiff !== 0) {
    timeString = `${dayDiff}d ${timeString}`;
  }
  if (minDiff !== 0) {
    timeString = `${timeString} ${minDiff}min`;
  }

  return timeString.trim() || '0h';
};
