import { Path } from './path';

type ThemeObj = { [key: string]: ThemeObj | string | number };

type ThemeGetter<T> = () => string;
type ThemeGetters<TO extends ThemeObj> = {
  [key in keyof TO]: TO[key] extends object
    ? ThemeGetters<TO[key]>
    : ThemeGetter<TO[key]>;
};

/**
 * Creates CSS variable declarations, values that can be used in styled-components theme, and styled-components theme selectors.
 */
function makeThemeGetters<
  T extends ThemeObj,
  FlattenedThemeProps extends Path<T>
>(
  theme: T,
  useCSSVariables = true
): {
  themeGetters: ThemeGetters<T>;
  themeValues: { [k in FlattenedThemeProps]: string };
  cssVariableDefs: string | null;
} {
  const flattenedTheme = {} as any;
  const cssVariableRows: string[] = [];

  const addVariablesFor = <TO extends ThemeObj>(
    themeObj: TO,
    path: string[]
  ): ThemeGetters<TO> => {
    const themeGetters = {} as any;

    for (let key in themeObj) {
      const themeObjChild = themeObj[key];

      if (typeof themeObjChild === 'object') {
        themeGetters[key] = addVariablesFor(themeObjChild, [...path, key]);
      } else {
        const varName = [...path, key].join('-');
        const cssVarName = `--${varName}`;

        const valueIsNumber = typeof themeObjChild === 'number';

        cssVariableRows.push(
          `${cssVarName}: ${
            valueIsNumber ? `${themeObjChild}px` : themeObjChild
          };`
        );
        flattenedTheme[varName] = themeObjChild;

        if (useCSSVariables) {
          // Reference to a css variable
          themeGetters[key] = () => `var(${cssVarName})`;
        } else {
          // Use styled-components theme
          if (valueIsNumber) {
            themeGetters[key] = ({ theme }: any) => `${theme[varName]}px`;
          } else {
            themeGetters[key] = ({ theme }: any) => theme[varName];
          }
        }
      }
    }

    return themeGetters;
  };

  const allThemeGetters = addVariablesFor(theme, []);
  const cssVariableDefs = `\n${cssVariableRows.join('\n')}\n`;

  return {
    themeGetters: allThemeGetters,
    themeValues: flattenedTheme,
    cssVariableDefs: cssVariableDefs,
  };
}

export default makeThemeGetters;
