import Tabstrip from 'components/Tabstrip';
import Routes from 'constants/routes';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const AkCompanyInvoicingRoute: FC = () => {
  return (
    <>
      <Tabstrip
        tabs={[
          {
            label: 'Att fakturera ',
            route: Routes.invoices.create,
          },
          {
            label: 'Skapade fakturor',
            route: Routes.invoices.internal,
          },
        ]}
      />

      <Outlet />
    </>
  );
};

export default AkCompanyInvoicingRoute;
