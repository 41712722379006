import { ConfirmEmailRequest, ResetPasswordRequest, UserClient } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import LoadingSection from 'components/spinners/LoadingSection';
import Routes from 'constants/routes';
import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks';
import { validPasswordDescription, validPasswordRegex } from 'utils/regex';
import { useQueryParams } from 'utils/useQueryParam';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 100px;

  input {
    width: 100%;
  }

  button {
    margin-left: auto;
  }
`;

const useValidConfirmParams = () => {
  const queryParams = useQueryParams();
  const b64EmailConfirmToken = queryParams.get('emailConfirmToken');
  const b64resetPasswordToken = queryParams.get('resetPasswordToken');
  const userId = queryParams.get('userId');

  return useMemo(() => {
    if (b64EmailConfirmToken && b64resetPasswordToken && userId)
      return {
        b64EmailConfirmToken,
        b64resetPasswordToken,
        userId,
      };

    return null;
  }, [b64EmailConfirmToken, b64resetPasswordToken, userId]);
};

const ConfirmAccountPage: FC = () => {
  const navigate = useNavigate();
  const confirmParams = useValidConfirmParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const isPasswordValid =
    password === confirmPassword && password.match(validPasswordRegex);

  const confirmEmailCall = useApiCall(
    UserClient,
    (c, userId: number, emailConfirmToken: string) =>
      c.confirmEmail(
        new ConfirmEmailRequest({
          emailConfirmToken,
          userId,
        })
      )
  );
  const resetPasswordCall = useApiCall(
    UserClient,
    (c, userId: number, resetPasswordToken: string, newPassword: string) =>
      c.resetPassword(
        new ResetPasswordRequest({ newPassword, resetPasswordToken, userId })
      )
  );

  // Confirm user's email on mount
  useEffect(() => {
    if (confirmParams && confirmEmailCall.status === RequestStatus.Idle) {
      confirmEmailCall.run(
        Number(confirmParams.userId),
        window.atob(confirmParams.b64EmailConfirmToken)
      );
    }
  }, [confirmEmailCall, confirmParams]);

  const handleSavePassword = async () => {
    if (!isPasswordValid || !confirmParams) return;

    const [resetResult, error] = await resetPasswordCall.run(
      Number(confirmParams.userId),
      window.atob(confirmParams.b64resetPasswordToken),
      password
    );

    if (!error && resetResult?.success) {
      navigate(Routes.account.login.customerUser, { replace: true });
    }
  };

  if (
    !confirmParams ||
    (resetPasswordCall.response && !resetPasswordCall.response.success)
  ) {
    return (
      <Wrapper>
        <Section>
          <SectionHeader title="Felaktig länk" />
          <p>
            Det verkar som att du angivit en felaktig länk eller så har den här
            länken redan använts tidigare. Vänligen försök skriva in länken
            korrekt, eller skicka en ny länk till din epost genom att{' '}
            <Link to={Routes.account.forgotPassword}>
              återställa ditt lösenord
            </Link>
            .
          </p>
        </Section>
      </Wrapper>
    );
  }

  if (confirmEmailCall.status === RequestStatus.Fetching) {
    return <LoadingSection />;
  }

  return (
    <Wrapper>
      {resetPasswordCall.status === RequestStatus.Fetching ? (
        <LoadingSection>Sparar lösenord...</LoadingSection>
      ) : (
        <Section>
          <SectionHeader title="Skapa lösenord till ditt konto" />

          <p>{validPasswordDescription}</p>

          <LabelWrap label="Lösenord">
            <Input
              onChange={(eve) => setPassword(eve.target.value)}
              type="password"
              value={password}
            />
          </LabelWrap>

          <LabelWrap label="Bekräfta lösenord">
            <Input
              onChange={(eve) => setConfirmPassword(eve.target.value)}
              type="password"
              value={confirmPassword}
            />
          </LabelWrap>

          <Button disabled={!isPasswordValid} onClick={handleSavePassword}>
            Spara lösenord
          </Button>
        </Section>
      )}
    </Wrapper>
  );
};

export default ConfirmAccountPage;
