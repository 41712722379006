import Theme from 'constants/theme';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const monthHeadHeightCssVariable = '--month-head-height';
export const displayClockStrokesCssVariable = '--display-clock-strokes';
export const dayWidthCssVariable = '--day-width';

const MonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${Theme.colors.border.main};
`;

const MonthHead = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: var(${monthHeadHeightCssVariable});
  min-height: var(${monthHeadHeightCssVariable});
  overflow: hidden;
  background-color: ${Theme.colors.bg.background1};
`;

const MonthName = styled.div`
  padding: 5px 10px;
  background-color: ${Theme.colors.bg.background4};
  color: ${Theme.colors.fg.background4};
  border-right: 1px solid ${Theme.colors.border.main};
`;

const DayNumbers = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${Theme.colors.border.main};
`;

const DayNumber = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  border-right: 1px solid ${Theme.colors.border.main};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const WeekDayStringContainer = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
`;

const WeekDayString = styled.span<{ isToday?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 2px 5px;
  margin: auto;
  margin-left: 5px;

  ${({ isToday }) =>
    isToday &&
    css`
      padding-inline: 10px;
      border-radius: 1000px;
      background-color: ${Theme.colors.bg.selection};
      color: ${Theme.colors.fg.selection};
    `}
`;

const DayClockStrokes = styled.div`
  flex: 1;
  display: var(${displayClockStrokesCssVariable}, flex);
  flex-direction: row;
  border-top: 1px solid ${Theme.colors.border.main};
`;

const ClockStroke = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:not(:last-child) {
    border-right: 1px solid ${Theme.colors.border.main};
  }
`;

const DayBodies = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const DayBody = styled.div`
  flex: 1;
  padding: 10px;
  border-right: 1px solid ${Theme.colors.border.main};
`;

interface GanttMonthTimelineProps {
  from: Date;
  to: Date;
}

const GanttMonthTimeline: FC<GanttMonthTimelineProps> = ({ from, to }) => {
  const now = new Date();

  const isToday = (date: Date) =>
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear();

  const monthWrappers: ReactNode[] = [];

  const currentDate = new Date(from);

  while (currentDate.getTime() <= to.getTime()) {
    const monthDays: ReactNode[] = [];

    const startOfMonth = new Date(currentDate);
    const startingMonthNr = currentDate.getMonth();

    while (
      currentDate.getMonth() === startingMonthNr &&
      currentDate.getTime() <= to.getTime()
    ) {
      const weekdayString = (
        <>
          <b>{currentDate.getDate()}</b>{' '}
          {currentDate.toLocaleString(undefined, {
            weekday: 'long',
          })}
        </>
      );

      monthDays.push(
        <DayNumber key={currentDate.getDate()}>
          <WeekDayStringContainer>
            <WeekDayString isToday={isToday(currentDate)}>
              {weekdayString}
            </WeekDayString>
          </WeekDayStringContainer>

          <DayClockStrokes>
            <ClockStroke>00:00</ClockStroke>
            <ClockStroke>06:00</ClockStroke>
            <ClockStroke>12:00</ClockStroke>
            <ClockStroke>18:00</ClockStroke>
          </DayClockStrokes>
        </DayNumber>
      );

      currentDate.setDate(currentDate.getDate() + 1);
    }

    monthWrappers.push(
      <MonthWrapper
        key={`${startOfMonth.getMonth()}-${startOfMonth.getFullYear()}`}
        style={{
          minWidth: `calc(var(${dayWidthCssVariable})*${monthDays.length})`,
        }}
      >
        <MonthHead>
          <MonthName>
            {startOfMonth.toLocaleDateString(undefined, { month: 'long' })}
          </MonthName>
          <DayNumbers>{monthDays}</DayNumbers>
        </MonthHead>

        <DayBodies>
          {monthDays.map((_, i) => (
            <DayBody key={i} />
          ))}
        </DayBodies>
      </MonthWrapper>
    );
  }

  return <>{monthWrappers}</>;
};

export default GanttMonthTimeline;
