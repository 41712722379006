import { JobType, PriceItem, PriceType } from 'api';

export interface PriceItemFormInputs {
  priceItemId: number;
  isRemoved: boolean;
  name: string;
  jobType: JobType | null;
  priceType: PriceType;
  forUnplannedJobs: boolean;
  price: number;
}

export function createPriceItemForm(
  priceItem?: PriceItem
): PriceItemFormInputs {
  if (priceItem) {
    return {
      priceItemId: priceItem.id,
      isRemoved: priceItem.isRemoved,
      name: priceItem.name,
      jobType: priceItem.jobType ?? null,
      priceType: priceItem.priceType,
      forUnplannedJobs: priceItem.forUnplannedJobs,
      price: priceItem.price,
    };
  }

  return {
    priceItemId: 0,
    isRemoved: false,
    name: defaultPriceItemNameFromPriceType(
      JobType.TMA,
      PriceType.PerUnit,
      false
    ),
    jobType: JobType.TMA,
    priceType: PriceType.PerUnit,
    forUnplannedJobs: false,
    price: 0,
  };
}

export function priceItemFormToPriceItem(
  form: PriceItemFormInputs,
  base?: PriceItem
): PriceItem {
  return new PriceItem({
    id: base?.id ?? 0,
    isRemoved: form.isRemoved,
    priceListId: base?.priceListId ?? 0,
    name: form.name,
    jobType: form.jobType ?? undefined,
    priceType: form.priceType,
    forUnplannedJobs: form.forUnplannedJobs,
    price: form.price,

    createdById: base?.createdById ?? -1,
    createdUtc: base?.createdUtc ?? new Date(),
    modifiedById: base?.modifiedById ?? -1,
    modifiedUtc: base?.modifiedUtc ?? new Date(),
    timestamp: base?.timestamp ?? '',
  });
}

export function defaultPriceItemNameFromPriceType(
  jobType: JobType | null,
  priceType: PriceType,
  validForUnplannedJobs: boolean
) {
  let jobTypeName = '';

  switch (jobType) {
    case JobType.TMA:
      jobTypeName = 'TMA ';
      break;
    case JobType.CraneTruck:
      jobTypeName = 'Kranflytt ';
      break;
    case JobType.HeavyTransport:
      jobTypeName = 'Tung transport ';
      break;
  }

  let priceTypeName = '';

  switch (priceType) {
    case PriceType.PerHour:
      priceTypeName = 'timtaxa';
      break;
    case PriceType.PerUnit:
      priceTypeName = 'enhetstaxa';
      break;
  }

  if (jobTypeName)
    return `${jobTypeName}${priceTypeName}${
      validForUnplannedJobs ? ' (oplanerad)' : ''
    }`;

  return '';
}
