import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/Modal';
import { FC } from 'react';

interface Props {
  onClose(): void;
  onDiscardCase(): void;
}

const ConfirmDiscardCaseModal: FC<Props> = ({ onClose, onDiscardCase }) => {
  return (
    <Modal
      title="Makulera ärende?"
      onClose={onClose}
      buttons={[
        {
          icon: <FontAwesomeIcon icon={faTrash} />,
          label: 'Makulera ärende',
          onClick: () => onDiscardCase(),
        },
        {
          label: 'Avbryt',
          onClick: () => onClose(),
        },
      ]}
    >
      Är du säker på att du vill makulera ärendet? Alla ej avbokade jobb i
      ärendet kommer också att makuleras (ej som sen avbokning).
    </Modal>
  );
};

export default ConfirmDiscardCaseModal;
